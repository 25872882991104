import $ from "jquery";
import { cards } from "../donations/card-toggles";
import { donation } from "../donations/current-donation";
import { payment_flow } from "../donations/payment-flow";
import { stripe_style } from "../donations/style-stripe-elements";
import { donate_stripe } from "../general/stripe";
import { vl_util } from "../general/util";

/**
 * Night of power controller class.
 */
export class NightOfPowerController
{
    /**
     * Parent controller.
     * 
     * @var {App|null}
     */
    parent = null;

    /**
     * Stripe instance.
     * 
     * @var {Stripe}
     */
    stripe = null;

    /**
     * Stripe elements instance.
     * 
     * @var {Elements}
     */
    
    /**
     * Current page identifier
     * 
     * @var {String}
     */
    currentPage = "select-projects";

    /**
     * Page hooks.
     * 
     * @var {Array<Object>}
     */
    pageHooks = [];

    /**
     * Defines where each page's back button will actually lead you.
     * 
     * @var {Object<String,String|null>}
     */
    backMappings = 
    {
        "select-projects":      null,
        "select-amounts":       "select-projects",
        "select-splits":        "select-amounts"
    };

    /**
     * Defines where each page's forward button will actually lead you.
     * 
     * @var {Object<String,String|null>}
     */
    forwardMappings = 
    {
        "select-projects":      "select-amounts",
        "select-amounts":       "select-splits",
        "select-splits":        "DONATE"
    };

    /**
     * IDs of currently selected projects.
     * 
     * @var {Array<Number>}
     */
    selectedProjects = [];

    /**
     * Class constructor.
     * 
     * @var {App} parent
     */
    constructor ( parent )
    {
        this.parent = parent;
        
        // Use this component only when the actual HTML component exists.
        if ( ! $( "#nop-container" ).length )
        {
            return;
        }

        // Detect current page automatically.
        this.currentPage = $( ".nop-card:not(.hidden)" ).data( "page-name" ) ?? "select-projects";

        // Bind clicking the NOP project selectors.
        $( ".nop-project" ).on( "click", this.onClickProjectButton.bind( this ) );

        // Bind clicking the donation levels.
        $( ".nop-single-project-amount" ).on( "click", this.onClickNopSelectVal.bind( this ) );

        // On click nav next.
        $( ".nop-forward" ).on( "click", this.onClickNavigationNext.bind( this ) );

        // On click nav back.
        $( ".nop-back" ).on( "click", this.onClickNavigationBack.bind( this ) );

        // On change admin contr amount.
        $( "#nop-setup--donate_cr_amount, #nop-setup--donate_cr" ).on( "change", this.recalculateTotal.bind( this ) );

        $( ".nop-allocate-donation" ).on( "click", function ( e )
        {
            // If we click one of the inner ones, fix it.
            if ( !( e.target.classList.contains( "nop-allocate-donation" ) ) )
            {
                e.target = $( e.target ).parents( ".nop-allocate-donation" ).get( 0 );
            }

            const $target = $( e.target );
            const splitTypeId = $target.data( "value" );

            $( ".nop-allocate-donation" ).removeClass( "active" );

            $target.addClass( "active" );

            $( `input[name="nop-setup--split-type"]` ).prop( "checked", false );
            $( `input[name="nop-setup--split-type"][value="${ splitTypeId }"]` ).prop( "checked", true );

            $( `.split-coins` ).addClass( "hidden" );
            $( `.split-coins[data-split-type-id="${ splitTypeId }"]` ).removeClass( "hidden" );
            
            $( `.split-type-string` ).addClass( "hidden" );
            $( `.split-type-string[data-split-type-id="${ splitTypeId }"]` ).removeClass( "hidden" );
        } );

        this.addTransitionHook( "select-splits", "DONATE", this.onClickFinalise.bind( this ) );

        // Bind the unbindable.
        this.bindAmountInputting();

        // Auto recalc totals incase of query strs etc.
        this.recalculateTotal();

        // So donation values auto-select.
        $( ".nop-amount-input" ).trigger( "input" ).trigger( "focusout" );

        // If we're on donate flow, intitialise stripe.
        if ( this.onDonateFlow() )
        {
            // Initialise stripe
            donate_stripe.initialise_stripe( $ );

            this.stripe = donate_stripe.get_stripe();

            // Initialise the payment flow (requires stripe)
            payment_flow.initialise_payment_flow( $ );

            // Add card hook for when the payment details are requested to be shown.
            cards.addHook( "on_show_donation_details", this.onShowDonationDetails.bind( this ) );

            // On show payment details.
            cards.addHook( "on_show_payment_details", this.onShowPaymentDetails.bind( this ) );

            // Bind the complete button.
            this.bindCompleteButton();

            // Auto setting Zakat option.
            if ( this.isZakat() )
            {
                $( "#zakat_tag" ).removeClass( "hidden" );
            }
            else
            {
                $( "#zakat_tag" ).addClass( "hidden" );
            }

            // If already set as company donation then we need to disable giftaid and hide it.
            if ( this.isCompany() )
            {
                $( "#company_tag" ).removeClass( "hidden" ).addClass( "inline-block" );
                $( "#giftaid_tag" ).addClass( "hidden" ).removeClass( "inline-block" );
                $( "#gift_aid_yes" ).prop( "checked", false );
                $( "#giftaid_wrap" ).addClass( "hidden" );
                $( "#company_details" ).removeClass( "hidden" );
            }
            else
            {
                $( "#company_tag" ).addClass( "hidden" ).removeClass( "inline-block" );
            }

            const splitTypeId = $( ".nop-allocate-donation.active" ).data( "value" );

            $( `.split-coins` ).addClass( "hidden" );
            $( `.split-coins[data-split-type-id="${ splitTypeId }"]` ).removeClass( "hidden" );

            $( `.split-type-string` ).addClass( "hidden" );
            $( `.split-type-string[data-split-type-id="${ splitTypeId }"]` ).removeClass( "hidden" );

            if ( this.isCampaign() )
            {
                this.setPage( "select-amounts" );
            }
        }

        // Expose to DOM for now. 
        /**
         * @TODO remove.
         */
        window.nopApp = this;
    }

    /**
     * On show donation details.
     */
    onShowDonationDetails()
    {
        this.setPage( "select-splits" );
    }

    /**
     * Are we using an already available card on an authenticated user?
     * 
     * @var {Boolean}
     */
    usingKnownCard = false;

    /**
     * Has the stripe setup intent card form loaded?
     */
    cardFormLoaded = false;

    /**
     * Donate flow, on show payment details.
     */
    onShowPaymentDetails()
    {
        this.finalStepStore().then( this.onCompleteIntermediaryUpdate.bind( this ) );
    }

    onCompleteIntermediaryUpdate()
    {
        payment_flow.set_is_one_off( true );

        $( "#payment_one_off" ).removeClass( "hidden" );

        if ( $( "#has-cards" ).length && $( "#has-cards" ).val() === "true" )
        {
            this.usingKnownCard = true;

            $( "#payment-details-waiting" ).addClass( "hidden" );
            $( "#payment-details-loaded" ).removeClass( "hidden" );
            $( "#payment-form" ).removeClass( "hidden" ).css( { display: "inherit" } );

            // When card options pressed, we need to flick between using known card / setting up one.
            $( "#card-options" ).on( "click", ( function ()
            {
                this.usingKnownCard = !this.usingKnownCard;

                if ( ! this.cardFormLoaded )
                {
                    this.loadCardForm();
                }
            } ).bind( this ) );
        }

        // network request to setup intent.
        if ( ! this.cardFormLoaded && ( ! this.usingKnownCard ) )
        {
            // Load card form.
            this.loadCardForm();
        }
        else
        {
            // Make the complete button active.
            this.toggleCompleteButton( true );
        }         
    }

    /**
     * Load the card form, if not already done.
     */
    loadCardForm()
    {
        // Disable complete button.
        this.toggleCompleteButton( false );

        // Create setup intent then load the card form.
        this.createSetupIntent().then( this.onCreateSetupIntent.bind( this ) );
    }

    /**
     * Called on generation to the client secret.
     * 
     * @param {String|null} client_secret 
     */
    onCreateSetupIntent( client_secret )
    {
        // Check the client secret.
        if ( ( ! client_secret ) || ( ! client_secret.length ) )
        {
            return alert( "An error has occurred while generating setup intent which cannot be recovered." );
        }

        // Display options + client secret for the form.
        const options = 
        {
            clientSecret: client_secret,
            // Fully customizable with appearance API.
            appearance: stripe_style.appearance(),
        };
        
        // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2 (https://stripe.com/docs/payments/save-and-reuse)
        this.elements = this.stripe.elements( options );
        
        // Create and mount the Payment Element
        const paymentElement = this.elements.create( "payment" );

        // Mount the payment element to our, well, element.
        paymentElement.mount( "#payment-element" );

        // Add event handler to the form so we can know when to make the button active.
        paymentElement.on( "ready", this.onPaymentFormLoaded.bind( this ) );
    }

    /**
     * Called when the setup intent payment form is loaded.
     */
    onPaymentFormLoaded()
    {
        $( "#payment-details-waiting" ).addClass( "hidden" );
        $( "#payment-details-loaded" ).removeClass( "hidden" );
        $( "#payment-form" ).removeClass( "hidden" ).css( { display: "inherit" } );

        // Make the complete button active.
        this.toggleCompleteButton( true );

        this.cardFormLoaded = true;
    }

    /**
     * On click to complete button, do things.
     */
    bindCompleteButton()
    {
        $( "#nop-setup-donation" ).on( "click", this.onClickFinaliseDonation.bind( this ) );
    }

    /**
     * Called on click to the final donate / setup button.
     */
    async onClickFinaliseDonation( event )
    {
        // Prevent any default button behaviour.
        event.preventDefault();

        // Get the reference code
        const referenceCode = this.getReferenceCode();

        // Form the return URL for when the setup intent / payment is confirmed.
        const returnUrl = `${ vl_util.site_url() }/api/v1/front/night-of-power/intermediary/${ referenceCode }`;

        // Disable the button to prevent multiple fires.
        this.toggleCompleteButton( false );

        // If we're on setup in tent.
        if ( ! this.usingKnownCard )
        {
            // Confirm the setup intent through Stripe's API.
            const { error } = await this.stripe.confirmSetup(
            {
                elements: this.elements,
                confirmParams: 
                {
                    return_url: returnUrl
                }
            } );

            if ( error ) 
            {
                // This point will only be reached if there is an immediate error when
                // confirming the payment. Show error to your customer (for example, payment
                // details incomplete)
                $( "#error-message" ).removeClass( "hidden" ).text( error.message );

                vl_util.dbgout( "[NOP] Payment Flow", "Stripe elements card payment error: " + error.message );

                this.toggleCompleteButton( true );
            } 
            else 
            {
                // Your customer will be redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer will be redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
            }
        }
        // Otherwise we're on the pre-known card form.
        else
        {
            // Get the currently selected (Stripe) PM ID.
            const selectedCardId = $( `input[name="chosen-card"]:checked` ).parent( "label" ).data( "card-id" );

            // If no selected card ID then just re-toggle complete.
            if ( ! selectedCardId )
            {
                // Show user an error.
                $( "#error-message" ).removeClass( "hidden" ).text( "Please select a payment method." );

                // Log on debug.
                vl_util.dbgout( "[NOP] Payment Flow", "No PM selected." );

                // Enable the complete button.
                this.toggleCompleteButton( true );

                return;
            }

            // Redirect through script.
            window.location.href = returnUrl + `?payment_method_id=${ selectedCardId }`;
            return;
        }
    }

    /**
     * Active state etc on complete button
     * 
     * @param {Boolean} state 
     */
    toggleCompleteButton( state )
    {
        if ( state )
        {
            $( "#nop-setup-donation" ).removeClass( "btn-disabled" ).addClass( "btn-active" );
        }
        else
        {
            $( "#nop-setup-donation" ).removeClass( "btn-active" ).addClass( "btn-disabled" );
        }
    }

    /**
     * Generate a new setup intent for card form.
     * 
     * @return {Promise<String|null>}
     */
    async createSetupIntent()
    {
        const response = await $.ajax(
        {
            url: vl_util.site_url() + "/api/v1/front/night-of-power/create-setup-intent",
            dataType: "JSON",
            method: "POST",
            data: 
            {
                _token: vl_util.csrf_token()
            }
        } );

        if ( ! response )
        {
            return alert( "Error creating setup intent." );
        }

        if ( ! response.status )
        {
            if ( response.error )
            {
                return alert( response.error );
            }
            else
            {
                return alert( "Unknown error while initialising card form." );
            }
        }

        // Check client secret.
        if ( response.client_secret )
        {
            return response.client_secret;
        }
        else
        {
            return null;
        }
    }

    /**
     * Bind the amount inputting event.
     */
    bindAmountInputting()
    {
        $( ".nop-amount-input" ).on( "input", this.onInputAmounts.bind( this ) );
        $( ".nop-amount-input" ).on( "focusout", this.onFocusOutAmount.bind( this ) );
    }

    /**
     * Unbind the amount inputting event.
     */
    unbindAmountInputting()
    {
        $( ".nop-amount-input" ).off( "input", this.onInputAmounts.bind( this ) );
        $( ".nop-amount-input" ).off( "focusout", this.onFocusOutAmount.bind( this ) );
    }

    /**
     * Is there a reference set?
     * 
     * @returns {Boolean}
     */
    hasReferenceSet() 
    {
        return nopApp.getReferenceCode() !== undefined;
    }

    /**
     * Called to transition to final step, validates inputs, generates new ID, moves to donate flow.
     * 
     * @todo BETTER LOG + USER FEEDBACK.
     */
    async onClickFinalise()
    {
        // If we're on donate flow we want to move to the next step in cards.
        if ( this.onDonateFlow() )
        {
            if ( this.hasReferenceSet() )
            {
                this.networkUpdate().then( this.onProcessedStep1NetworkUpdate.bind( this ) );
            }
            else
            {
                this.networkSetup().then( this.onProcessedNetworkSetup.bind( this ) );
            }

            // Set the split type.
            this.setPage( "select-splits" );
        }
        else
        {
            const response = await $.ajax( 
            {
                url: vl_util.site_url() + "/api/v1/front/night-of-power/setup",
                method: "POST",
                data: 
                {
                    _token: vl_util.csrf_token(),
                    ...this.collectData()
                },
                dataType: "JSON",
            } );

            // Check response.
            if ( ! response )
            {
                return alert( "There was an error uploading your donation information." );
            } 

            // Check the response
            if ( ! response.status )
            {
                return alert( "Error processing your donation info: " + response.error );
            }

            // Check for redirectUrl.
            if ( ! response.redirectUrl )
            {
                return alert( "A fatal error occurred while setting up your donation." );
            }

            // Redirect to the responses demand.
            window.location.href = response.redirectUrl;
        }
    }

    /**
     * Updates our donation through the network, asynchronously.
     */
    async networkUpdate()
    {
        const response = await $.ajax(
        {
            url:        vl_util.site_url() + "/api/v1/front/night-of-power/update/" + this.getReferenceCode(),
            method:     "POST",
            dataType:   "JSON",
            data: 
            {
                _token: vl_util.csrf_token(),
                ...this.collectData()
            }
        } ); 

        // Check response.
        if ( ! response )
        {
            alert( "There was an error uploading your donation information." );

            return false;
        } 

        // Check the response
        if ( ! response.status )
        {
            alert( "Error processing your donation info: " + response.error );

            return false;
        }

        return true;
    }

    /**
     * Sets up instead of creating.
     * 
     * @returns {Promise<Boolean>}
     */
    async networkSetup()
    {
        const response = await $.ajax(
        {
            url:        vl_util.site_url() + "/api/v1/front/night-of-power/setup",
            method:     "POST",
            dataType:   "JSON",
            async:      true,
            data: 
            {
                _token: vl_util.csrf_token(),
                ...this.collectData()
            }
        } ); 

        // Check response.
        if ( ! response )
        {
            alert( "There was an error uploading your donation information." );

            return false;
        } 

        // Check the response
        if ( ! response.status )
        {
            alert( "Error processing your donation info: " + response.error );

            return false;
        }

        return response;
    }

    /**
     * Stores the rest of the data against the donation (Gift aid + company info etc).
     * 
     * @returns {Promise<Boolean>}
     */
    async finalStepStore()
    {
        const response = await $.ajax(
        {
            url:        vl_util.site_url() + "/api/v1/front/night-of-power/update-final/" + this.getReferenceCode(),
            method:     "POST",
            dataType:   "JSON",
            async:      true,
            data:       
            {
                _token: vl_util.csrf_token(),
                ...this.collectFinalData()
            }
        } );

        // Check response.
        if ( ! response )
        {
            alert( "There was an error uploading your donation information." );

            return false;
        } 

        // Check the response
        if ( ! response.status )
        {
            alert( "Error processing your donation info: " + response.error );

            return false;
        }

        return true;
    }

    /**
     * Called when the network update has completed for step 1.
     * 
     * @param {Boolean} status
     */
    onProcessedStep1NetworkUpdate( status )
    {
        // Do nothing in event of failure.
        if ( ! status )
        {
            return;
        }

        // Show donor details.
        cards.toggle_donor_details();
        cards.set_donation_details_all_good( true );

        // Complete step 1
        cards.fireHook( "step_1_complete" );
    }

    /**
     * 
     * @param {Object} response 
     */
    onProcessedNetworkSetup( response )
    {
        if ( ! response.referenceCode )
        {
            return;
        }

        if ( this.onDonateFlow() )
        {
            // Push into DOM for future finding.
            if ( this.isCampaign() )
            {
                // Add history so when user presses refresh they don't lose their donation.
                window.history.pushState( "", "", "/nightofpower/campaign/donate/" + this.campaignId() + "/" + response.referenceCode );

                $( "html" ).attr( "data-seg-4", response.referenceCode );
                $( "html" ).get( 0 ).dataset[ "seg-4" ] = response.referenceCode; // just to be safe.
            }
            else
            {
                // Add history so when user presses refresh they don't lose their donation.
                window.history.pushState( "", "", "/nightofpower/donate/" + response.referenceCode );

                $( "html" ).attr( "data-seg-2", response.referenceCode );
                $( "html" ).get( 0 ).dataset[ "seg-2" ] = response.referenceCode; // just to be safe.
            }
        }

        // Show donor details.
        cards.toggle_donor_details();
        cards.set_donation_details_all_good( true );

        // Complete step 1
        cards.fireHook( "step_1_complete" );
    }

    /**
     * On click to night of power donation level value.
     * 
     * @param {MouseEvent} event
     */
    onClickNopSelectVal( event )
    {
        // Prevent default behaviour.
        event.preventDefault();

        // Ensure button is valid.
        if ( ! event.target )
        {
            return;
        }

        // Update button to parent button if not found.
        if ( ! ( event.target instanceof HTMLButtonElement ) )
        {
            event.target = $( event.target ).parent( "button" ).get( 0 );
        }

        // Full failure.
        if ( ! ( event.target instanceof HTMLButtonElement ) )
        {
            return;
        }

        // Get target as jQuery collection.
        const $target = $( event.target );

        // Get target input.
        const $targetInput = $( $target.data( "target" ) );

        // Ensure the target input exists.
        if ( ! $targetInput.length )
        {
            return;
        }

        // Unbind the amount inputting.
        this.unbindAmountInputting();

        // Set inputs value, will not trigger `input` event since it's automatic.
        $targetInput.val( $target.data( "amount" ) );

        // Re-bind the amount inputting/
        this.bindAmountInputting();

        // Remove active from all buttons.
        $( `button[data-target="${ $target.data( "target" )}"]` ).removeClass( "active" );

        // Add active to just our button.
        $target.addClass( "active" );

        // Update the "provides x school meals" values here.
        /**
         * @todo
         */

        // Recalculate the amounts.
        this.recalculateTotal();
    }

    /**
     * Called on focus out to the input.
     * 
     * @param {Event} event 
     */
    onFocusOutAmount( event )
    {
        // Prevent default behaviour.
        event.preventDefault();

        // Ensure button is valid.
        if ( ! event.target )
        {
            return;
        }

        // Get target as jQuery collection.
        const $target = $( event.target );

        // Convert amount to normal number, 2 dp, then to string
        var amount = Number( $target.val() ).toFixed( 2 ).toString();

        // If there's a min prop and the value is lower than min, update and exit.
        if ( $target.prop( "min" ) && Number( $target.prop( "min" ) ) && ( amount < Number( $target.prop( "min" ) ) ) )
        {
            $target.val( Number( $target.prop( "min" ) ).toFixed( 2 ) );
        }
        else
        {
            // This removes the trailing dps.
            $target.val( amount );
        }
    }

    /**
     * When the values are updated, we have to also update the donation levels.
     * 
     * @param {InputEvent} event 
     */
    onInputAmounts( event )
    {
        // Prevent default behaviour
        event.preventDefault();
        
        // Grab the input as a jQuery set.
        const $target = $( event.target );

        // Remove active from all buttons for this input.
        $( `.nop-single-project-amount[data-target="${ event.target.id }"]` ).removeClass( "active" );

        // Convert amount to normal number, no dp, then to string.
        var amount = Number( $target.val() ).toFixed( 2 ).toString();

        // Get the button which is linked to the amount and add active.
        const $btn = $( `.nop-single-project-amount[data-target="#${ event.target.id }"][data-amount="${ amount }"]` );

        // Remove active from ALL buttons with same target.
        $( `.nop-single-project-amount[data-target="#${ event.target.id }"]` ).removeClass( 'active' );

        // Add value to active.
        $btn.addClass( "active" );

        // update the strings.
        /**
         * @todo
         */

        // Recalculate the totals.
        this.recalculateTotal();
    }

    /**
     * Check there is at least one project selected.
     * 
     * @return {Boolean}
     */
    checkProjects()
    {
        return ( $( ".nop-project-btn.active" ).length > 0 ) || ( $( ".nop-project-btn.btn-active" ).length > 0 );
    }

    /**
     * When no project is selected and next is clicked, this is run.
     */
    onNoProjectsSelected()
    {
        alert( "You must select a project (change this)" );
    }

    enableBack()
    {
        $( ".soh-back" ).removeClass( "btn-disabled" ).addClass( "btn-active" ).prop( "disabled", false );
    }

    enableForward()
    {
        $( ".soh-next" ).removeClass( "btn-disabled" ).addClass( "btn-active" ).prop( "disabled", false );
    }

    disableBack()
    {
        $( ".soh-back" ).addClass( "btn-disabled" ).removeClass( "btn-active" ).prop( "disabled", true );
    }

    disableForward()
    {
        $( ".soh-next" ).addClass( "btn-disabled" ).removeClass( "btn-active" ).prop( "disabled", true );
    }

    /**
     * On click to the forward nav button(s).
     * 
     * @param {MouseEvent} event 
     */
    onClickNavigationNext( event )
    {
        // Prevent default behaviour.
        event.preventDefault();

        // Ensure button is valid.
        if ( ! event.target )
        {
            return;
        }

        // Update button to parent button if not found.
        if ( ! ( event.target instanceof HTMLButtonElement ) )
        {
            event.target = $( event.target ).parent( "button" ).get( 0 );
        }

        // Full failure.
        if ( ! ( event.target instanceof HTMLButtonElement ) )
        {
            return;
        }

        // Get the next page we'll be going to.
        const desiredPage = this.forwardMappings[ this.currentPage ];

        if ( this.fireHook( this.currentPage, desiredPage ) )
        {
            // If we're on donate flow, prevent showing blank page.
            if ( this.onDonateFlow() && ( desiredPage === "DONATE" && this.currentPage === "select-splits" ) )
            {
                return false;
            }
            else
            {
                this.setPage( desiredPage );
            }
        }
    }

    /**
     * On click to the back nav button(s)
     * 
     * @param {MouseEvent} event 
     */
    onClickNavigationBack( event )
    {
        // Prevent default behaviour.
        event.preventDefault();

        // Ensure button is valid.
        if ( ! event.target )
        {
            return;
        }

        // Update button to parent button if not found.
        if ( ! ( event.target instanceof HTMLButtonElement ) )
        {
            event.target = $( event.target ).parent( "button" ).get( 0 );
        }

        // Full failure.
        if ( ! ( event.target instanceof HTMLButtonElement ) )
        {
            return;
        }

        // Get target as jQuery collection.
        const $target = $( event.target );

        // Get the next page we'll be going to.
        const desiredPage = this.backMappings[ this.currentPage ];

        if ( this.fireHook( this.currentPage, desiredPage ) )
        {
            this.setPage( desiredPage );
        }
    }

    /**
     * Update the displayed projects strings.
     */
    updateProjectsStrings()
    {
        // hacky bad code.
        if ( $( `.nop-project[data-project-id="10"].active` ).length )
        {
            $( "#nop-setup--school-meals-cart" ).removeClass( "hidden" );
        }
        else
        {
            $( "#nop-setup--school-meals-cart" ).addClass( "hidden" );
        }

        // hacky bad code.
        if ( $( `.nop-project[data-project-id="12"].active` ).length )
        {
            $( "#nop-setup--quran-programme-cart" ).removeClass( "hidden" );
        }
        else
        {
            $( "#nop-setup--quran-programme-cart" ).addClass( "hidden" );
        }

        // Calculate the totals.
        var { total } = this.calcTotalNoAdmin();
        
        if ( this.hasAdminContribution() )
        {
            $( ".dv2-total-fees-percent" ).html( this.adminContributionPercent() );
            $( ".dv2-total-fees" ).html( donation.amounts.format_money( total * ( this.adminContributionPercent() / 100.0 ) ) );
            $( "#fees-wrap" ).removeClass( "hidden" ).addClass( "flex" );
        }
        else
        {
            $( "#fees-wrap" ).addClass( "hidden" ).addClass( "flex" );
        }

        // Update the gift aid string.
        $( ".dv2-total-giftaid" ).html( donation.amounts.format_money( total * 1.25 ) );
    }

    /**
     * On click project button.
     * 
     * @param {MouseEvent} event 
     */
    onClickProjectButton( event )
    {
        // Prevent default behaviour.
        event.preventDefault();

        // Ensure button is valid.
        if ( ! event.target )
        {
            return;
        }

        if ( ! $( event.target ).hasClass( "nop-project" ) )
        {
            event.target = $( event.target ).parents( ".nop-project" ).get( 0 );
        }

        // Disabled statge.
        if ( $( event.target ).hasClass( "disabled" ) )
        {
            return;
        }

        // Get target as jQuery collection.
        const $target = $( event.target );

        var isNowActive = false;

        if ( $target.hasClass( "active" ) ) 
        {
            $target.removeClass( "active" );
            isNowActive = true; // why is this backwards?
        }
        else
        {
            $target.addClass( "active" );
        }

        // Add active data attribute to parent container.
        $( `.nop-project[data-project-id="${ $target.data( "project-id" ) }"]` ).attr( "data-selected", isNowActive ? "false" : "true" );

        // Bad code.
        if ( $target.data( "project-id" ) == 10 )
        {
            if ( isNowActive )
            {
                $( "#nop-setup--project-school-meals-wrap" ).addClass( "hidden" );
            }
            else
            {
                $( "#nop-setup--project-school-meals-wrap" ).removeClass( "hidden" );
            }
        }

        // Bad code.
        else if ( $target.data( "project-id" ) == 12 )
        {
            if ( isNowActive )
            {
                $( "#nop-setup--project-quran-programme-wrap" ).addClass( "hidden" );
            }
            else
            {
                $( "#nop-setup--project-quran-programme-wrap" ).removeClass( "hidden" );
            }
        }

        if ( $( ".nop-project-btn.active" ).length > 0 )
        {
            this.enableForward();
        }
        else
        {
            this.disableForward();
        }
        
        // Recalculate the totals.
        this.recalculateTotal();
    }

    /**
     * Adds a hook that determines if
     * 
     * @param {String} from 
     * @param {String} to 
     * @param {Function} cb 
     * @return {NightOfPowerController} Self
     */
    addTransitionHook( from, to, cb, onFail = () => {}, onSuccess = () => {} )
    {
        this.pageHooks.push(
        {
            from: from,
            to: to,
            cb: cb,
            onFail: onFail,
            onSuccess: onSuccess
        } );

        return this;
    }

    /**
     * Fire all transition hooks and determine if we can move forward.
     * 
     * @param {String} from 
     * @param {String} to 
     * @return {Boolean} Can we go forwards?
     */
    fireHook( from, to )
    {
        var results = [];

        this.pageHooks.forEach( ( v, i ) => 
        {
            if ( ( v.from === from ) && (v.to === to ) )
            {
                const b = v?.cb();

                if ( ( ! b ) && v?.onFail )
                {
                    v.onFail();
                }
                else if ( v?.onSuccess )
                {
                    v.onSuccess();
                }

                results.push( b );
            }
        } );

        var canMove = true;

        results.forEach( v => 
        {
            if ( !v )
            {
                canMove = false;
            }
        } );

        return canMove;
    }

    /**
     * Sets the current page.
     * 
     * @param {String} pageName 
     */
    setPage( pageName )
    {
        this.currentPage = pageName;

        $( ".nop-card" ).addClass( "hidden" );
        $( `.nop-card[data-page-name="${ this.currentPage }"` ).removeClass( "hidden" );
    }

    /**
     * Collects all of the required fields for the network request / create / update a session.
     * 
     * @returns {Object}
     */
    collectData()
    {
        // Return the structure as the AJAX request will expect it.
        var data =  {
            amounts:            this.recalculateTotal(),
            currency:           "GBP",
            admin_contribution: this.adminContributionPercent(),
            split_type_id:      this.splitTypeId(),
            is_zakat:           $( "#nop-setup--donate_cr" ).prop( "checked" ) ? "true" : "false",
            is_anonymous:       $( "#is_anonymous" ).prop( "checked" ) ? "true" : "false"
        };

        // If this is a campaign donation then add the campaign ID.
        if ( this.isCampaign() )
        {
            data.campaign_id = this.campaignId();
        }

        return data;
    }

    /**
     * Collects the final information for gift aid etc.
     * 
     * @returns {Object}
     */
    collectFinalData()
    {
        // The company information.
        var company_data = null;
        
        // Is this a company donation?
        var is_company = $( "#company_checkbox" ).prop( "checked" ) ? "1" : "0";

        // Attach company data when we're on a company donation.
        if ( is_company )
        {
            company_data =  {
                name:           $( "#business_name" )               .val(),
                address:        $( "#business_address" )            .val(),
                st_num:         $( "#business_address_st_num" )     .val(),
                street:         $( "#business_address_street" )     .val(),
                town:           $( "#business_address_town" )       .val(),
                county:         $( "#business_address_county" )     .val(),
                postal_code:    $( "#business_address_postal_code" ).val(),
                country:        $( "#business_address_country" )    .val()
            };
        }

        return {
            is_company:     is_company,
            company_data:   company_data,
            is_gift_aid:    $( "#gift_aid_yes" )        .prop( "checked" ) ? "1" : "0",
            is_late_start:  $( "#is_late_start" )       .prop( "checked" ) ? "1" : "0",
            is_zakat:       $( "#nop-setup--donate_cr" ).prop( "checked" ) ? "1" : "0"
        };
    }

    /**
     * Recalculates totals and updates visible string.
     */
    recalculateTotal()
    {
        // Calculate the totals.
        var { total, projectTotals } = this.calcTotalNoAdmin();

        // Factor in the admin contribution value.
        if ( this.hasAdminContribution() )
        {
            total += total * ( this.adminContributionPercent() / 100.0 );
        }

        // Formatted amounts.
        const moneyFormatted = donation.amounts.format_money( total );

        // Format the money and place in the display string(s).
        $( "#nop-setup--donation-total-str" ).html( moneyFormatted );
        $( ".dv2-total-total" ).html( moneyFormatted );

        // Update all other visual things related to monies.
        this.updateProjectsStrings();

        // Return the totals.
        return projectTotals;
    }

    /**
     * Calculates the total.
     * 
     * @return {Number}
     */
    calcTotalNoAdmin()
    {
        // The total.
        var total = 0.0;
        var projectTotals = {};
        var totalMappings = {
            10: ".dv2-total-school-meals",
            12: ".dv2-total-quran-programme",
            99999: ".dv2-total-quran-programme"
        };

        // iterate on selected projects.
        $( `.nop-project.active` ).each( ( i, e ) => 
        {
            // Get project container so we can traverse tree to find values.
            const $container = $( e );

            // Get the project ID.
            const projectId = $container.data( "project-id" );

            // Get the project amount.
            // const $projectAmount = $container.find( ".nop-amount-input" );
            const $projectAmount = $( `.nop-amount-input[data-project-id="${ projectId }"]` );
    
            // Ensure amount is available.
            if ( ! $projectAmount.length )
            {
                return;
            }

            // Get this single projects total
            const thisProjectTotal = Number( $projectAmount.val() );

            // Ensure amount is acceptable.
            if ( ( thisProjectTotal === undefined ) || ( thisProjectTotal === NaN ) )
            {
                return;
            }

            // Add to absolute total.
            total += thisProjectTotal;

            // Add to projects list.
            projectTotals[ projectId ] = thisProjectTotal;

            // If there is a total mapping, set the total string for this project.
            if ( totalMappings[ projectId ] !== undefined ) 
            {
                $( totalMappings[ projectId ] ).html( donation.amounts.format_money( thisProjectTotal ) );
            }
        } );

        // Hooked here cos it's the most convenient place.
        $( "#nop-setup--subtotal-app" ).html( donation.amounts.format_money( total ) );

        // Calculations for the NOP strings.
        const totalIncAdminAmount = total + ( ( $( "#nop-setup--donate_cr" ).prop( "checked" ) ) ? ( total * ( $( "#nop-setup--donate_cr_amount" ).val() / 100 ) ) : 0.0 );
        const percentage_odd = .666666666;
        const percentage_even = 1.333333333;
        const _27thAmount = totalIncAdminAmount / 5;
        const non27thAmount = ( totalIncAdminAmount - _27thAmount ) / 9;

        // NOP equal amounts string.
        $( "#nop_amount_equal" ).html( donation.amounts.format_money( totalIncAdminAmount / 10.0 ) );
        $( "#nop_amount_odds" ).html( donation.amounts.format_money( ( totalIncAdminAmount / 10.0 ) * percentage_even ) );
        $( "#nop_amount_odd_rest" ).html( donation.amounts.format_money( ( totalIncAdminAmount / 10.0 ) * percentage_odd ) );
        $( "#nop_amount_27th" ).html( donation.amounts.format_money( _27thAmount ) ); // one fifth the total.
        $( "#nop_amount_27th_rest" ).html( donation.amounts.format_money( non27thAmount ) ); // one fifth the total.

        return {
            total: total,
            projectTotals: projectTotals
        };
    }

    /**
     * Does this donation include an admin contribution?
     * 
     * @return {Boolean}
     */
    hasAdminContribution()
    {
        return $( "#nop-setup--donate_cr:checked" ).length > 0;
    }

    /**
     * Get this donation's admin contribution percent.
     * 
     * @return {Number}
     */
    adminContributionPercent()
    {
        // If we don't have a contribution nothing is to be done.
        if ( ! this.hasAdminContribution() )
        {
            return 0.0;
        }

        // Convert the percent string to number.
        const thePercent = Number( $( "#nop-setup--donate_cr_amount" ).val() );

        // Ensure the value is ok.
        if ( ( ! thePercent ) || ( thePercent === NaN ) )
        {
            return 0.0
        }

        return thePercent;
    }

    /**
     * Gathers the current split type ID.
     * 
     * @return {Number}
     */
    splitTypeId()
    {
        // Get the split type Id.
        const splitType = Number( $( `input[name="nop-setup--split-type"]:checked` ).val() );

        // Ensure it's actually a number.
        if ( ( ! splitType ) || ( splitType === NaN ) )
        {
            return 1;
        } 

        // Return it.
        return splitType;
    }

    /**
     * Are we on the donate flow?
     * 
     * @returns {Boolean}
     */
    onDonateFlow()
    {
        return $( `input[name="onDonateFlow"]` ).val() === "true";
    }

    /**
     * Grab the current reference code if applicable.
     * 
     * @return {String|null}
     */
    getReferenceCode()
    {
        // If not on donate flow we cannot know the reference code.
        if ( ! this.onDonateFlow() )
        {
            return null;
        }

        if ( this.isCampaign() )
        {
            return $( "html" ).data( "seg-4" );
        }
        else
        {
            return $( "html" ).data( "seg-2" );
        }
    }

    /**
     * Is this a Zakat donation?
     * 
     * @return {Boolean}
     */
    isZakat()
    {
        return $( `[name="nop-zakat-donation"]` ).prop( "checked" );
    }

    /**
     * Is this a company donation?
     * 
     * @return {Boolean}
     */
    isCompany()
    {
        if ( ! $( `#company_checkbox` ) )
        {
            return false;
        }

        return $( `#company_checkbox` ).prop( "checked" );
    }

    /**
     * Is this a campaign project donation?
     * 
     * @return {Boolean}
     */
    isCampaign()
    {
        return !! this.campaignId();
    }

    /**
     * Campaign ID.
     * 
     * @returns {String}
     */
    campaignId() 
    {
        return Number( $( `input[name="campaignId"]` ).val() );
    }
}