import { VL_Modal } from "../general/modal-factory";
import $                    from "jquery";
import jQuery               from "jquery";

/**
 * Represents functionality for our popup HHH video (on click only).
 */
export class VL_Modal_Schoolsfundraising extends VL_Modal
{
    elements = 
    {
        dismiss:              null,
    };

    /**
     * Constructor
     */
    constructor ()
    {
        super( "schoolsfundraising-form" );

        // Args which will be passed to the bound function.
        const args = 
        { 
            _this_ref: this 
        };

        // Get the input fields.
        this.elements.dismiss = $( "#hhh-video-dismiss" );
        this.elements.dismiss.on( "click", args, this.onClickDismiss );
    }
}