import { payment_flow } from "../donations/payment-flow";
import { vl_util } from "../general/util";
import { cards }  from "../donations/card-toggles";
import $                    from "jquery";
import jQuery               from "jquery";

/**
 * Food fridays controller class.
 */
export default class FoodFridaysController
{
    /**
     * Automatically select GC, unless the country is set to UK.
     * 
     * @var {Boolena} usingGoCardless
     */
    usingGoCardless = true;

    /**
     * PayPal plan details, from the setup paypal call result.
     * 
     * @var {Object} payPalPlanDetails
     */
    payPalPlanDetails = null;

    /**
     * (Current) PayPal buttons object.
     * 
     * @var {Object} ogButtons
     */
    ogButtons = null;

    /**
     * Call constructor on document ready.
     */
    constructor() 
    {
        // On input "has uk bank" checkbox.
        $( "#have_uk_bank_account" ).on( "input", this.onInputHasUKBank.bind( this ));

        $( "#donor_address_country" ).on( "change", this.onAddressCountryChange.bind( this ));

        cards.addHook( "step_2_complete", this.onCompleteStep2.bind( this ) );   
    }

    /**
     * Called on completion to step2.
     */
    onCompleteStep2()
    {
        this.setUsingGoCardless( $( "#have_uk_bank_account" ).prop( "checked" ) );
    }

    /**
     * On address country change.
     * 
     * @param {Object} event 
     */
    onAddressCountryChange( event )
    {
        $( "#have_uk_bank_account" ).prop( "checked", ( event.target.value.toLowerCase() === "gb" ) );
    }

    /**
     * Set is using gocardless to complete payment.
     * 
     * @param {Boolean} isGoCardless 
     */
    setUsingGoCardless( isGoCardless )
    {
        this.usingGoCardless = isGoCardless;

        if ( this.usingGoCardless )
        {
            $( "#payment_paypal" ).addClass( "hidden" );
            $( "#payment_gocardless" ).removeClass( "hidden" );
        }
        else
        {
            $( "#payment_paypal" ).removeClass( "hidden" );
            $( "#payment_gocardless" ).addClass( "hidden" );

            this.setupPayPal();
        }
    }

    /**
     * Set up paypal plan, which will then show the payment cards.
     */
    setupPayPal()
    {
        // Get donation amount.
        const amount = $( "#enter_amount" ).val();

        // Generate additional data.
        const additional_data = payment_flow.generate_additional_data();

        FoodFridaysController.setPayPalLoading( true );

        this
            .setupPayPalPlan( amount, additional_data )
            .then( this.onPayPalPlanSetup.bind( this ) )
            .catch( this.onPayPalPlanSetupFailure.bind( this ) );
    }

    /**
     * Called on PayPal plan set up.
     * 
     * @param {Object} response
     */
    onPayPalPlanSetup( response )
    {
        if ( ! response.success )
        {
            // Show error alert.
            ( async () => 
            {
                return alert( "Error during PayPal subscription plan setup: " + ( response.error ? response.error : "Unknown. Please try again." ) );
            } )();

            // Display the donor details.
            cards.toggle_donor_details();

            // Exit.
            return;
        }

        this.payPalPlanDetails = response;

        // If there's already buttons, close them.
        if ( this.ogButtons )
        {
            if ( this.ogButtons.close )
            {
                this.ogButtons.close();
            }

            this.ogButtons = null;
        }
        
        // Remove previous instances of subscription setup.
        $( "#paypal-setup-subscription" ).html( "" );

        // Simple anon func bound to this so we can use this class within paypal setup calls.
        const onApprove = ( function ( data, actions ) 
        {
            FoodFridaysController.setPayPalLoading( false );

            this
                .storePayPalSubscription( data.subscriptionID ?? null, this.payPalPlanDetails.sub_uuid )
                .then( this.onSubscriptionVerified.bind( this ) );
        } ).bind( this );

        const inData = this.payPalPlanDetails;

        // Load the buttons and bind the functionality.
        this.ogButtons = window.paypal.Buttons(
        {
            createSubscription: function ( data, actions ) 
            {
                return actions.subscription.create(
                {
                    "plan_id":      inData.plan_id,
                    "start_time":   inData.next_friday
                } );
            },

            onCancel: function ( data )
            {
                FoodFridaysController.setPayPalLoading( false );
            },

            onInit: function () 
            {
                FoodFridaysController.setPayPalLoading( false );
            },

            onApprove: onApprove
        } ).render( "#paypal-setup-subscription" ); // Renders the PayPal button
    }

    /**
     * Called when subscription verification is returned from server.
     * 
     * @param {Object} response 
     */
    onSubscriptionVerified( response )
    {
        const baseUrl = vl_util.site_url();

        window.location.href = `${baseUrl}/foodfridays/donate/completed/${response.uuid}`;
    }

    /**
     * On Paypal plan setup [js] error.
     * 
     * @param {Object} error 
     */
    onPayPalPlanSetupFailure( error )
    {
        console.log( error );
        alert( "There was an error while setting up this PayPal subscription plan. Please try again." );
    }

    /**
     * Setup the first part of the subscription 
     */
    async setupPayPalPlan( amount, additional_data )
    {
        const response = await $.ajax(
        {
            url:        vl_util.site_url() + "/api/v1/foodfridays/paypal-setup-plan",
            dataType:   "JSON",
            method:     "POST",
            data: 
            {
                _token: vl_util.csrf_token(),
                amount: amount,
                extra:  additional_data
            }
        } );

        return response;
    }

    /**
     * Store the PayPal subscription.
     * 
     * @param {String} subscription_id 
     */
    async storePayPalSubscription( subscription_id, uuid )
    {
        const response = await $.ajax(
        {
            url:            vl_util.site_url() + "/api/v1/foodfridays/paypal-set-subscription",
            dataType:       "JSON",
            method:         "POST",
            data: 
            {
                _token:             vl_util.csrf_token(),
                subscriptionId:     subscription_id,
                subscriptionUUID:   uuid
            }
        } );

        return response;
    }

    /**
     * On input to the "have uk bank" checkbox, we update whether we're using GC.
     */
    onInputHasUKBank( event )
    {
        this.setUsingGoCardless( event.target.checked );
    }

    /**
     * 
     */
    static setPayPalLoading( isLoading )
    {
        if ( isLoading)
        {
            $( "#paypal-cards-loading" ).removeClass( "hidden" );
            $( "#paypal-setup-subscription" ).addClass( "hidden" );
        }
        else
        {
            $( "#paypal-cards-loading" ).addClass( "hidden" );
            $( "#paypal-setup-subscription" ).removeClass( "hidden" );
        }
    }
}