import $, { merge }                    from "jquery";
import { App } from "../../main";
import { vl_util } from "../general/util";

/**
 * Controller for the page accessed once the donation flow is completed.
 */
export default class NightOfPowerCompletedController
{

    /**
     * Current selected contact type.
     */
    selected_contact_type = "BOTH";

    /**
     * International telephone input object.
     */
    completed_form_phone  = null;

    /**
     * Has the contact form been validated fully?
     */
    form_validated = false;

    /**
     * Controller Constructor.
     */
    constructor () 
    {
        // If we're not even on the right page do nothing.
        if ( ! $( "html[data-dv2-nop-complete]" ).length )
        {
            return;
        }

        $( "#contact-email, #contact-phone, #contact-both" ).on( "click", ( ( function ( event ) 
        {
            this.selected_contact_type = $( event.target ).val();

            switch ( $( event.target ).val() )
            {
            case "BOTH":
                this.onClickContactBoth();
                break;

            case "PHONE":
                this.onClickContactPhone();
                break;
            
            case "EMAIL":
            default:
                this.onClickContactEmail();
                break;
            }
        } ) ).bind( this )  );  

        $( "#contact-subscribe" ).on( "click", this.onClickSubscribe.bind( this ) );
    }

    /**
     * Called on click to subscribe. 
     */
    onClickSubscribe()
    {
        var mergedata = {};

        switch ( this.selected_contact_type.toUpperCase() )
        {
        case "EMAIL":
            mergedata.contact_email = $( "#completed_email" ).val();
            break;
            
        case "PHONE":
            mergedata.contact_phone = $( "#completed_phone" ).val();
            break;
    
        default:
        case "BOTH":
            mergedata.contact_email = $( "#completed_email" ).val();
            mergedata.contact_phone = $( "#completed_phone" ).val();
            break;
        }   

        // Add the CSRF token.
        mergedata._token = $( `meta[name="csrf-token"]` ).prop( "content" );
        const nopId = $( `[name="nop-ref-id"]` ).val()
        
        // AJAX request to update the details.
        $.ajax(
        {
            url: vl_util.site_url() + `/api/v1/front/night-of-power/${ nopId }/store-contact`,
            dataType: "JSON",
            method: "POST",
            data: mergedata,
            success: ( function ( res )
            {
                $( "#contact-details-saved" ).removeClass( "hidden" );

                this.disableSubscribeButton();

                if ( ! res.status && res.error )
                {
                    $( "#contact-details-saved > span" ).attr( "data-og-text", $( "#contact-details-saved" ).text() );
                    $( "#contact-details-saved > span" ).text( res.error );
                    $( "#contact-details-saved > i" ).addClass( "hidden" );
                }
            } ).bind( this ),
            error: function ()
            {
                $( "#contact-details-saved > span" ).attr( "data-og-text", $( "#contact-details-saved" ).text() );
                $( "#contact-details-saved > span" ).text( "Failed to save your details!" );
                $( "#contact-details-saved > i" ).addClass( "hidden" );
            }
        } );
    }

    /**
     * Disables our subscribe button.
     */
    disableSubscribeButton()
    {
        this.form_validated = false;

        $( "#contact-subscribe" ).removeClass( "btn-active" ).addClass( "btn-disabled" );
    }

    /**
     * When user clicks contact with both types.
     */
    onClickContactBoth()
    {
        $( "#completed_phone" ).prop( "required", true );
        $( "#completed_email").prop( "required", true );

        $( "#phone-number-field" ).removeClass( "hidden" );
        $( "#email-field" ).removeClass( "hidden" );
    }

    /**
     * On click email address only contact.
     */
    onClickContactEmail()
    {
        $( "#completed_phone" ).removeAttr( "required" );
        $( "#completed_email" ).prop( "required", true );

        $( "#phone-number-field" ).addClass( "hidden" );
        $( "#email-field" ).removeClass( "hidden" );
    }
    
    /**
     * On click the phone number only contact.
     */
    onClickContactPhone()
    {
        $( "#completed_phone" ).prop( "required", true );
        $( "#completed_email" ).removeAttr( "required" );

        $( "#email-field" ).addClass( "hidden" );
        $( "#phone-number-field" ).removeClass( "hidden" );
    }
}