import $                    from "jquery";
import jQuery               from "jquery";

/**
 * Controller for the get involved page.
 */
export default class GetInvolvedController
{
    /**
     * The volunteer form.
     * 
     * @var {JQuery<HTMLFormElement>} volunteer_form
     */
    volunteer_form = $();

    /**
     * Class constructor.
     */
    constructor () 
    {
        // Get the volunteer form.
        this.volunteer_form = $( "#volunteer-form" );        

        // Bind the form submit action.
        this.volunteer_form.on( "submit", this.onVolunteerSubmit.bind( this ) );
    }

    /**
     * Called on volunteer form submit.
     * 
     * @param {Event} event 
     */
    onVolunteerSubmit( event )
    {
    }
}