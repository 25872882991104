import $                    from "jquery";
import jQuery               from "jquery";

/**
 * Represents a single modal in the HTML code.
 */
export class VL_Modal
{
    /**
     * @var {String} modal_tagf The modals prefix.
     */
    modal_tag = null;

    /**
     * @var {Boolean} is_showing Is this modal showing?
     */
    is_showing = false;

    /**
     * @var {Boolean} in_transition Is this modal currently playing an animation?
     */
    in_transition = false;

    /**
     * Constructor, also does all the necessary bindings.
     * 
     * @param {String} modal_tag Set the modal tag.
     */
    constructor( modal_tag )
    {
        this.modal_tag = modal_tag;

        // When the button to hide the modal is clicked, hide the modal.
        $( `#${this.modal_tag}-modal-hide` ).on( "click", 
        { 
            _this_ref: this // Add data to the event to reference this object when calling func.
        }, function ( event )
        {
            // This instance of VL_Modal
            const _this = event.data._this_ref;

            // Hide the modal.
            _this.showHideModal();
        } );

        // Doesn't work: trying to get the modal to hide when we click around it, but some
        // margin or something is getting in the way for now.
        $( `#${this.modal_tag}-modal-overlay` ).on( "click", 
        { 
            _this_ref: this // Add data to the event to reference this object when calling func.
        }, function ( event )
        {   
            // This instance of VL_Modal
            const _this = event.data._this_ref;

            // If the modal isn't showing, try hide it.
            if ( _this.is_showing )
            {
                _this.showHideModal();
            }
        } );
    }

    /**
     * Toggles classes needed to show the modal.
     */
    toggleBody () 
    {
        $( "body" ).toggleClass( "h-full overflow-hidden relative" );

        if ( this.is_showing )
        {
            this.in_transition = false;
        }
    }
    
    /**
     * Toggles the overlay opacity for our modal.
     */
    toggleOverlayOpacity () 
    {
        $( `#${this.modal_tag}-modal-overlay` ).toggleClass( "opacity-0 opacity-80" );
    }

    /**
     * Shows / hides the modal overlay.
     */
    toggleOverlayVisibility () 
    {
        $( `#${this.modal_tag}-modal-overlay` ).toggleClass( "invisible visible" );
    }
    
    /**
     * Shows / hides the modals wrapper.
     */
    toggleModalWrapper () 
    {
        $( `#${this.modal_tag}-modal-content-wrapper` ).toggleClass( "hidden flex" );
    }
    
    /**
     * Shows / hides the modals inner content.
     */
    toggleModalInner ()
    {
        $( `#${this.modal_tag}-modal-content` ).toggleClass( "opacity-0 opacity-100 translate-y-12 translate-y-0 scale-75 scale-100" );

        if ( ! this.is_showing )
        {
            this.in_transition = false;
        }
    }

    /**
     * Shows / hides the modal (toggles it).
     */
    showHideModal ()
    {
        if ( ! this.is_showing )
        {
            this.showModal();
        } 
        else 
        {
            this.hideModal();
        }
    }

    /**
     * Shows this modal.
     */
    showModal ()
    {
        // Do not try to run the animations etc if they're already running.
        if ( this.in_transition )
        {
            return;
        }

        // We are now transitioning.
        this.in_transition = true;

        setTimeout( this.toggleBody.bind( this ) );
        setTimeout( this.toggleOverlayVisibility.bind( this ) );
        setTimeout( this.toggleModalWrapper.bind( this ) );
        setTimeout( this.toggleOverlayOpacity.bind( this ), 300 );
        setTimeout( this.toggleModalInner.bind( this ), 500 );      // Last

        this.is_showing = true;
    }

    /**
     * Hides this modal.
     */
    hideModal ()
    {
        // Do not try to run the animations etc if they're already running.
        if ( this.in_transition )
        {
            return;
        }

        // We are now transitioning.
        this.in_transition = true;

        setTimeout( this.toggleBody.bind( this ), 400 );            // Last
        setTimeout( this.toggleOverlayOpacity.bind( this ) );
        setTimeout( this.toggleOverlayVisibility.bind( this ), 200 );
        setTimeout( this.toggleModalWrapper.bind( this ) );
        setTimeout( this.toggleModalInner.bind( this ) );

        this.is_showing = false;
    }
}