import { vl_util } from "../general/util";
import $                    from "jquery";
import jQuery               from "jquery";

export default class CartController
{
    /**
     * JQuery add to cart buttons.
     * 
     * @var {JQuery<HTMLButtonElement>} add_to_cart_buttons
     */
    add_to_cart_buttons = $();

    /**
     * JQuery add to cart quantities.
     * 
     * @var {JQuery<HTMLInputElement>} add_to_cart_quantities
     */
    add_to_cart_quantities = $();

    /**
     * JQuery increment quantity button
     * 
     * @var {JQuery<HTMLButtonElement>} inc_qty_buttons
     */
    inc_qty_buttons = $();

    /**
     * JQuery decrement quantity button.
     * 
     * @var {JQuery<HTMLButtonElement>} dec_qty_buttons
     */
    dec_qty_buttons = $();

    /**
     * JQuery cart quantity inputs
     * 
     * @var {JQuery<HTMLButtonElement>} qty_inputs
     */
    qty_inputs = $();

    /**
     * JQuery set for the cart container.
     * 
     * @var {JQuery<HTMLDivElement>} cart_container
     */
    cart_container = $();
    
    /**
     * Constructor, document ready must be called before this constructor.
     */
    constructor ()
    {
        this.bind();
    }

    bind()
    {
        // Get the cart container.
        this.cart_container = $( "#vl-cart" );

        // Get the add to cart buttons.
        this.add_to_cart_buttons = $( ".add-to-cart" );

        // Get the add to cart quantities.
        this.add_to_cart_quantities = $( ".add-to-cart-qty" );

        // .. cart item quantity increment button.
        this.inc_qty_buttons = $( ".qty-inc" );

        // .. cart item quantity decrement button.
        this.dec_qty_buttons = $( ".qty-dec" );

        // .. cart item quantity inputs.
        this.qty_inputs = $( ".cart-item-qty" );

        // Unbind any possible previous bindings.
        this.inc_qty_buttons.off( "click" );
        this.dec_qty_buttons.off( "click" );
        this.qty_inputs.off( "focusout" );
        this.add_to_cart_buttons.off( "click" );
        this.add_to_cart_quantities.off( "input" );

        // Bind all the inputs / buttons.
        this.add_to_cart_buttons    .on( "click",       this.onAddToCartClicked.bind( this ) );
        this.qty_inputs             .on( "focusout",    this.onModifyQuantity.bind( this ) );
        this.add_to_cart_quantities .on( "input",       this.onModifyQuantityAddtocart.bind( this ) )

        this.qty_inputs.each( () => 
        {
            // Store the elements default value.
            this.defaultValue = this.value;
        } );
    }

    /**
     * On modify quantity add to cart.
     * 
     * @param {Event} event 
     */
    onModifyQuantityAddtocart( event ) 
    {
        const newQuantity = $( event.target ).val();

        $( ".add-to-cart-subtotal[data-product-id=\"" + $(event.target).data("product-id") + "\"]").val( ( new Number( newQuantity ) ) * $( event.target ).data( "product-price" ) )
    }

    /**
     * On add to cart clicked.
     * 
     * @param {MouseEvent} event 
     */
    onAddToCartClicked( event ) 
    {
        // Get button element set.
        const $button = $( event.target );

        // Check button element set.
        if ( ! $button.length )
        {
            return;
        }

        // Get product ID from button data.
        const productID = $button.data( "product-id" );

        // Must be a product ID to add a product.
        if ( ! productID )
        {
            return;
        }

        const qty = $( ".add-to-cart-qty[data-product-id=\"" + productID + "\"]" ).val();

        // AJAX to add to cart.
        $.ajax(
        {
            url:        vl_util.site_url() + "/api/v1/cart/add/" + productID,
            method:     "POST",
            dataType:   "JSON",
            data: 
            {
                _token:     vl_util.csrf_token(),
                quantity:   qty ? qty : 1
            },
            success: function ( response )
            {
                if ( response.success )
                {
                    window.location.href = window.location.href + "?cart=show";
                }
                else
                {
                    if ( response.out_of_stock )
                    {
                        alert( "These " + qty + " product(s) could not be added to your cart (out of stock, or your desired quantity is too high)" );
                    }
                    else
                    {
                        // ..
                    }
                }
            }
        } );
    }

    /**
     * On quantity input focusout.
     * 
     * @param {Event} event 
     */
    onModifyQuantity( event )
    {
        // Get the quantity input.
        const $input = $( event.target );
        
        // Check the quantity input.
        if ( ! $input.length )
        {
            return;
        }

        // Get the new quantity.
        const desiredQuantity = $input.val();

        // Ensure the quantity has updated locally before we update over network.
        if ( desiredQuantity === $input.get( 0 ).defaultValue )
        {
            return;
        }

        // Get the cart item ID.
        const cartItemID = $input.data( "item-id" );

        // Check the cart item ID.
        if ( ! cartItemID )
        {
            return;
        }

        CartController.updateCartItemQuantity( cartItemID, desiredQuantity );
    }

    /**
     * On increment cart item quantity clicked.
     * 
     * @param {Event} event 
     */
    onIncrementQtyClicked( event ) 
    {
        // Get button element set.
        const $button = $( event.target ).parent( "button" );

        // Check button element set.
        if ( ! $button.length )
        {
            return;
        }

        const cartItemID = $button.data( "item-id" );

        const newQuantity = ( new Number( this.getCartItemQuantity( cartItemID ) ) ) + ( new Number( 1 ) );

        $( "input.cart-item-qty[data-item-id=\"" + cartItemID + "\"]" ).val( newQuantity );

        CartController.updateCartItemQuantity( cartItemID, newQuantity );
    }

    /**
     * On decrement cart item quantity clicked.
     * 
     * @param {Event} event 
     */
    onDecrementQtyClicked( event ) 
    {
        // Get button element set.
        const $button = $( event.target ).parent( "button" );

        // Check button element set.
        if ( ! $button.length )
        {
            return;
        }

        const cartItemID = $button.data( "item-id" );

        const newQuantity = ( new Number( this.getCartItemQuantity( cartItemID ) ) ) - ( new Number( 1 ) );
        
        $( "input.cart-item-qty[data-item-id=\"" + cartItemID + "\"]" ).val( newQuantity );

        CartController.updateCartItemQuantity( cartItemID, newQuantity );
    }

    /**
     * Updates a cart items quantity, by ID.
     * 
     * @param {Number|String} cartItemID Cart item ID.
     * @param {Number} newQuantity New quantity
     */
    static updateCartItemQuantity( cartItemID, newQuantity )
    {
        $.ajax(
        {
            url: vl_util.site_url() + "/api/v1/cart/update-quantity/" + cartItemID,
            data: 
            {
                _token:     vl_util.csrf_token(),
                quantity:   new Number( newQuantity )
            },
            dataType: "JSON",
            method: "POST",

            // Success should send back success response + new inner HTML for cart contents.
            success: ( function ( response )
            {
                if ( response.success )
                {
                    $( "#vl-cart" ).html( response.cart );
                    this.bind();
                }
                else
                {
                    alert( "Failed to update cart quantities." );
                }
            } ).bind( this )
        } );
    }

    /**
     * Gets a cart item quantity.
     * 
     * @param {Number|String} cartItemID 
     * @returns The quantity.
     */
    getCartItemQuantity( cartItemID )
    {
        return $( "input.cart-item-qty[data-item-id=\"" + cartItemID + "\"]" ).val();
    }
}