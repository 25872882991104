import { Countdown } from "../lib/countdown";
import $                    from "jquery";
import jQuery               from "jquery";

/**
 * Events archive controller class.
 */
export default class EventsArchiveController
{
    /**
     * @var {Countdown} countdowns
     */
    countdowns = [];

    /**
     * Constructor.
     */
    constructor () 
    {
        // Countdown for currently pinned event.
        this.countdowns.push( new Countdown( "div.countdown[data-event-start-ts]" ) );
    }
}