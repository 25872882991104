import $ from "jquery";
import jQuery from "jquery";
import { Select2 } from "select2";
import intlTelInput from "intl-tel-input";
import { inputs } from "../donations/inputs";
import StepOverHungerController from "../step-over-hunger/StepOverHungerController";

/**
 * Mountains of Makkah controller class.
 */
export default class MountainsOfMakkahController
{
    /**
     * Constructor, called no matter whether we're on this page or not.
     */
    constructor () 
    {
        // 
        if ( ! $( `html[data-seg-0="events"][data-seg-1="mountains-of-makkah"]` ).length ) 
        {
            return;
        }

        // Now we know we're on the correct page, start initialising things.
        this.initialise();
    }

    contactNumberTelInput = null;

    /**
     * Only called when we're on the right page for this.
     */
    initialise()
    {
        // Initialise this so we have the functionality for the phone number stuff.
        new StepOverHungerController();

        // Individual telephone input element
        const contactNoElem = document.querySelector( "#event_phone_number" );

        if ( contactNoElem )
        {
            this.contactNumberTelInput = intlTelInput( contactNoElem,
            {
                // Util script for extra features.
                utilsScript: "/app/assets/js/vendor/intl-tel-input/utils.js",

                // Placeholder should force override always.
                autoPlaceholder: "aggressive",

                // Automatically format.
                formatOnDisplay: true,

                // We want the country to be automated based on location.
                initialCountry: "auto",

                // Fixes the phone number strings.
                nationalMode: false,

                // Geoip lookup func.
                geoIpLookup: inputs.geoip_lookup,

                // Hidden input for actual full phone number.
                hiddenInput: contactNoElem.id + "-hidden",

                // Initial country to UK even if geoIP lookup doesn't work.
                initialCountry: "GB"
            } );

            this.contactNumberTelInput.rawElement = contactNoElem;

            $( contactNoElem ).on( "focusout", StepOverHungerController.formatIntlTelInput.bind( this.contactNumberTelInput ) );
        }
    }
}