import $ from "jquery";

/**
 * Form validation system.
 */
export class ValidatableForm
{
    /**
     * Form validation options.
     * 
     * @var {Object} options
     * @var {Number} options.rumbleTime The number of milliseconds to rumble for when validation fails. Set to zero to never rumble.
     */
    options = 
    {
        rumbleTime: 150
    };
    
    /**
     * Validatable forms ID.
     * 
     * @var {String} id
     */
    id = null;

    /**
     * The JQuery collection of len 1 for this form.
     * 
     * @var {JQueryStatic<HTMLFormElement>} $form
     */
    $form = $();

    /**
     * Object of inputs. 
     * 
     * Key = input ID.
     * Value = JQuery collection of said input.
     * 
     * @var {Object.<String, JQueryStatic<HTMLInputElement>>} inputs
     */
    inputs = {};

    /**
     * Object of submit buttons for this form. All buttons must contain [type="submit"].
     * 
     * Key = Button ID
     * Value = JQuery collection of said button.
     * 
     * @var {Object.<String, JQueryStatic<HTMLButtonElement>>} submitButtons
     */
    submitButtons = {};

    /**
     * Object of select inputs for this form.
     * 
     * Key = Element ID.
     * Value = JQuery collection containing that button.
     */
    selects = {};

    /**
     * Function to call when we've validated this form.
     * 
     * @var {Function} onValidated
     */
    fnOnValidated = null;

    /**
     * Function to call when the validation fails on call to full validate (this.validate()).
     * 
     * @var {Function} onValidationFailure
     */
    fnOnValidationFailure = null;

    /**
     * Constructor for ValidatableForm.
     * 
     * @param {String} formId The ID of the form to validate.
     */
    constructor ( formId, options = {} )
    {
        // Merge the options.
        this.options = { ...this.options, ...options };

        this.id = `#${formId}`;
        this.$form = $( this.id );

        // Ensure the given ID is actually on this page.
        if ( ! this.$form.add.length )
        {
            throw `Form ID ${this.id} does not exist.`;
        }

        // Locate the forms inputs and initialise jrumble.
        for ( let id in this.findInputs() )
        {
            // Get the jq collection.
            const $elem = this.inputs[ id ];

            // Make the element's have rumble events for invalid state.
            this.getValidator( $elem ).jrumble();

            // On element focusout, we run the validation.
            $elem.off( "focusout" ).on( "focusout", this.onElementFocusout.bind( this ) );
        }

        /**
         * @TODO Add multiselects, sliders(?).
         */

        // Locate the forms submit buttons and initialise our form submission.
        for ( let id in this.findSubmitButtons() )
        {
            // Get the jq collection.
            const $elem = this.submitButtons[ id ];

            // On click to the submit buttons, run our custom submit.
            $elem.off( "click" ).on( "click", this.onFormSubmit.bind( this ) );
        }

        for ( let id in this.findSelects() )
        {
            const $elem = this.selects[ id ];

            $elem.off( "change" ).on( "change", this.onElementFocusout.bind( this ) );
        }
    }

    /**
     * Set validated callback function.
     * 
     * @param {Function} fn
     * @param {Object|null} context The `this` argument.
     */
    onValidated( fn, context = null )
    {
        // Assume context is already assigned.
        if ( ! context )
        {
            this.fnOnValidated = fn;
        }
        else
        {
            // Otherwise bind the context ( [[this]] ).
            this.fnOnValidated = fn.bind( context );
        }

        return this;
    }

    /**
     * Set validated failure callback function.
     * 
     * @param {Function} fn
     * @param {Object|null} context The `this` argument.
     */
    onValidateError( fn, context = null )
    {
        // Assume context is already assigned.
        if ( ! context )
        {
            this.fnOnValidationFailure
        }
        else
        {
            // Otherwise bind the context ( [[this]] ).
            this.fnOnValidationFailure = fn.bind( context );
        }

        return this;
    }

    /**
     * Called on form submission by clicking submit button.
     * 
     * @param {MouseEvent} event 
     */
    onFormSubmit( event )
    {
        // Prevent button from automatically posting etc.
        event.preventDefault();

        // Validate the entire form, if all fields are sound, then we can run the onValidated function, if given.
        if ( this.validate( true, true ) )
        {
            if ( this.fnOnValidated )
            {
                // This should be bound by the programmer.
                this.fnOnValidated.call();
            }
        }
        else
        {
            if ( this.fnOnValidationFailure )
            {
                this.fnOnValidationFailure.call();
            }
        }
    }

    /**
     * Called on focus out to validatable element.
     * 
     * @param {FocusEvent} event 
     */
    onElementFocusout( event )
    {
        // Get the element as JQ set.
        const $elem = this.inputs[ event.target.id ]

        // Check length of element.
        if ( $elem.length !== 1 )
        {
            return;
        }

        // Validate the input
        this.validateInput( event.target.id, true );
    }

    /**
     * Validate text input.
     * 
     * @param {String} inputId
     * @param {Boolean} visual 
     * @param {Boolean} validateEmpty Should we validate empty inputs as false?
     */
    validateInput( inputId, visual = true, validateEmpty = false )
    {
        // Get the element.
        const $elem = this.inputs[ inputId ] ?? $();

        // Check it.
        if ( ! $elem.length )
        {
            throw "Attempted to validate element which is not in our form: " + inputId;
        }

        // Get the element type from the prop.
        const elemType = ( $elem.get( 0 ) instanceof HTMLSelectElement ) ? "select" : $elem.prop( "type" );

        // Get the raw element value.
        const elemValue = $elem.val();

        // The output validation state.
        var validationState = null;

        // Depending on the input type, we validate differently.
        switch ( elemType )
        {
        case "number":
            if ( $elem.attr( "min" ) )
            {
                validationState = ( ( elemValue.length === 0 ) && ( ! validateEmpty ) ) ? null : ( ( elemValue.length > 0 ) && ( Number( elemValue ) >= $elem.attr( "min" ) ) );    
            }
            else
            {
                console.log( elemValue );
                validationState = ( ( elemValue.length === 0 ) && ( ! validateEmpty ) ) ? null : ( elemValue.length > 0 );
            }
            break;
        case "tel":
        case "text": 
        case "select":
            // Validation state can never be false with this input type.
            validationState = ( ( elemValue.length === 0 ) && ( ! validateEmpty ) ) ? null : ( elemValue.length > 0 );
            break;
        case "email":
            // Validation state is nothing when no value, otherwise its the result of checking if the input value is actually an email.
            validationState = ( ( elemValue.length === 0 ) & ( ! validateEmpty ) ) ? null : ValidatableForm.isEmailValid( elemValue )
            break;
        default:
            break;
        }

        // If visual update is requested, do it.
        if ( visual )
        {
            this.setValidState( inputId, validationState );
        }

        // Always return the actual state.
        return validationState;
    }

    /**
     * Will validate the entire form.
     * 
     * @var {Boolean} visual
     * @var {Boolean} validateEmpty Should we validate the fields which haven't been filled? this will show bad validation state on empty.
     */
    validate( visual = true, validateEmpty = false )
    {
        var results = [];

        // Iterate through the validatable inputs.
        for ( let id in this.inputs )
        {
            // Ignore disabled or hidden inputs.
            if ( $( "#" + id ).hasClass( "hidden" ) || $( "#" + id ).prop( "disabled" ) )
            {
                continue;
            }

            // Validate the ID.
            results.push( this.validateInput( id, visual, validateEmpty ) );
        }

        // Return the actual validation result boolean / null.
        return this.getResultsFromArray( results );
    }

    /**
     * Clear the entire form and it's validation states.
     * 
     * @returns {void}
     * @todo
     */
    clear()
    {}

    /**
     * Looks through an array of validation results and returns false if any items are false or null.
     * 
     * @param {Array<Boolean>} results 
     * @returns {Boolean} The result of the array
     */
    getResultsFromArray( results )
    {
        // No results == no validation ??
        if ( ! results.length )
        {
            return false;
        }

        // Iterate through entries.
        for ( let i in results )
        {
            // Check the entry.
            if ( ( results[ i ] === false ) || ( results[ i ] === null ) )
            {
                // Return false on the first instance of something we don't want to see.
                return false;
            }
        }

        // If nothing bad was found, then we're good.
        return true;
    }

    /**
     * Will retrieve the values of each of the inputs and return as an object.
     * 
     * @return {Object.<Object,Object>}
     */
    values()
    {
        let output = {};

        for ( let id in this.inputs )
        {
            output[ id ] = this.inputs[ id ].val();
        }

        // Add checkboxes.. hack.
        $( `${this.id} input[type="checkbox"]:checked` ).each( ( i, e ) => 
        {
            output[ e.id ] = "1";
        } );

        return output;
    }

    /**
     * Apply validation state to a field.
     * 
     * @param {String} inputId 
     * @param {Boolean|null} state Null = nothing, false = invalid, true = valid.
     * @returns {void}
     */
    setValidState( inputId, state = null )
    {
        // Find the input.
        const input = this.inputs[ inputId ];

        // Ensure the ID exists.
        if ( ! input )
        {
            return;
        }

        // Get the elements validator element.
        const $validator = this.getValidator( input );

        // Don't trigger events on something that doesn't exist (or set timeouts, saves resources.)
        if ( ! $validator.length )
        {
            return;
        }

        // When the state is null.
        if ( state === null )
        {
            // Stop error rumble, remove all validation classes.
            $validator.trigger( "stopRumble" ).removeClass( "valid not-valid" );
        }

        // When the state is true
        else if ( state === true )
        {
            // Stop error rumble, remove the error state, add valid.
            $validator.trigger( "stopRumble" ).removeClass( "not-valid" ).addClass( "valid" );
        }

        // When the state is false.
        else if ( state === false )
        {
            // Stop the rumble (in case it's already running) and start it again, add invalid class and remove valid.
            $validator.trigger( "stopRumble" ).trigger( "startRumble" ).addClass( "not-valid" ).removeClass( "valid" );

            // If the rumble is enabled
            if ( this.options.rumbleTime > 0 )
            {
                // Set timeout to stop the rumble after the preferred time.
                setTimeout( ( () => 
                {
                    $validator.trigger( "stopRumble" );
                } ).bind( this ), this.options.rumbleTime );
            }
        }
    }

    /**
     * Finds the validator for an input.
     * 
     * @param {JQueryStatic<HTMLElement>} $input 
     */
    getValidator( $input )
    {
        if ( $input.get( 0 ) instanceof HTMLSelectElement )
        {
            return $input;
        }
        else
        {
            return $input.parents( ".validator" );
        }
    }

    /**
     * Locates all inputs in this form which are not hidden.
     * 
     * @return {Object.<String, JQueryStatic<HTMLInputElement>>}
    */
    findInputs()
    {
        $( `${this.id} input[type!="hidden"][type!="checkbox"]` ).each( ( ( idx, element ) => 
        {
            // Add the input, using the ID as the object key.
            this.inputs[ element.id ] = $( element );
        } ).bind( this ) );

        return this.inputs;
    }
 
    /**
     * Locates all submit buttons in this form, which are not hidden.
     * 
     * @returns {Object.<String, JQueryStatic<HTMLButtonElement>>}
     */
    findSubmitButtons()
    {
        $( `${this.id} button[type="submit"]` ).each( ( ( idx, element ) => 
        {
            // If the button has an ID, it will be used as the object key, otherwise we create one based on the index of the button in the foreach loop we're in.
            this.submitButtons[ element.id.length ? element.id : ( "SubmitButton_" + idx ) ] = $( element );
        } ).bind( this ) );

        return this.submitButtons;
    }

    /**
     * Locates all the select inputs in this form, which are not hidden.
     */
    findSelects()
    {
        $( `${this.id} select` ).each ( ( ( idx, element ) => 
        {
            this.selects[ element.id.length ? element.id : ( "Select_" + idx ) ] = $( element );
            this.inputs[ element.id.length ? element.id : ( "Select_" + idx ) ] = $( element );
        } ).bind( this ) );

        return this.selects;
    }

    /**
     * Determines if a given email address is valid.
     * 
     * @param {String} email 
     * @returns {Boolean} Is the email valid?
     */
    static isEmailValid( email ) 
    {
        return !!( String( email ).toLowerCase()
            .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) );
    }
}