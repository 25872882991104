import Flickity                 from "flickity";
import intlTelInput             from "intl-tel-input";
import { inputs }               from "../donations/inputs";
import { ValidatableForm }      from "../forms/ValidatableForm";
import { vl_util }              from "../general/util";
import StepOverHungerController from "../step-over-hunger/StepOverHungerController";
import $                        from "jquery";
import jQuery                   from "jquery";
import "flickity-imagesloaded";
import LazyLoad from "vanilla-lazyload";
import { VL_Modal_Schoolsfundraising } from "../modals/schoolsfundraising";

var __schools_fundraising_controller = null;

/**
 * Schools fundraising page controller.
 */
export default class SchoolsFundraisingController
{
    form = null;

    modal = null;

    /**
     * Constructor
     */
    constructor () 
    {
        if ( ! $( `html[data-route="schoolsfundraising.index"]` ).length )
        {
            return;
        }

        this.initialise();

        new StepOverHungerController().initialisePage();

        __schools_fundraising_controller = this;
    }   

    contactNumberTelInput = null;

    /**
     * Initialise this controller.
     */
    initialise()
    {
        this.form = new ValidatableForm( "schoolsfundraisingpost" );

        this.form.onValidated( this.onFormValidated.bind( this ), this );

        this.form.onValidateError( () => 
        {
            $( "#org_manual_address" ).trigger( "click" );
        }, this );

        this.modal = new VL_Modal_Schoolsfundraising();

        $( ".show-sfr-modal" ).on( "click", ( function () 
        {
            this.modal.showHideModal();
        } ).bind( this ) );

        // Individual telephone input element
        const contactNoElem = document.querySelector( "#contact_number" );

        if ( contactNoElem )
        {
            this.contactNumberTelInput = intlTelInput( contactNoElem,
            {
                // Util script for extra features.
                utilsScript: "/app/assets/js/vendor/intl-tel-input/utils.js",

                // Placeholder should force override always.
                autoPlaceholder: "aggressive",

                // Automatically format.
                formatOnDisplay: true,

                // We want the country to be automated based on location.
                initialCountry: "auto",

                // Fixes the phone number strings.
                nationalMode: false,

                // Geoip lookup func.
                geoIpLookup: inputs.geoip_lookup,

                // Hidden input for actual full phone number.
                hiddenInput: contactNoElem.id + "-hidden",

                // Initial country to UK even if geoIP lookup doesn't work.
                initialCountry: "GB"
            } );

            this.contactNumberTelInput.rawElement = contactNoElem;

            $( contactNoElem ).on( "focusout", StepOverHungerController.formatIntlTelInput.bind( this.contactNumberTelInput ) );
        }
    
        $( "#org_manual_address" ).on( "click", function ()
        {
            $( "#organisation_address_auto" ).addClass( "hidden" );
            $( "#organisation_address_manual" ).removeClass( "hidden" );
        } );

        $( "#org_auto_address" ).on( "click", function ()
        {
            $( "#organisation_address_auto" ).removeClass( "hidden" );
            $( "#organisation_address_manual" ).addClass( "hidden" );
        } );

        $( "#who_fundraising_with" ).on( "input", function ()
        {
            if ( this.value === "Other" )
            {
                $( "#who_fundraising_other" ).removeClass( "hidden" ).parent( "div" ).parent( "div" ).removeClass( "hidden" );
            }
            else
            {
                $( "#who_fundraising_other" ).addClass( "hidden" ).parent( "div" ).parent( "div" ).addClass( "hidden" );
            }
        } );

        $( "#how_heard" ).on( "input", function ()
        {
            if ( this.value === "Other" )
            {
                $( "#how_heard_other" ).removeClass( "hidden" ).parent( "div" ).parent( "div" ).removeClass( "hidden" );
            }
            else
            {
                $( "#how_heard_other" ).addClass( "hidden" ).parent( "div" ).parent( "div" ).addClass( "hidden" );
            }
        } );

        const schoolsHeroElem = document.querySelector( "#schools-hero-flkty" );

        // If it's found, initialise it's flickity.
        if ( schoolsHeroElem )
        {
            this.schoolsHeroFlickity = new Flickity( schoolsHeroElem, 
            {
                cellAlign:          "left",
                contain:            true,
                pageDots:           false,
                imagesLoaded:       true,
                prevNextButtons:    false,
                lazyLoad:           true,
                adaptiveHeight:     true,
            } );
        }

        const schoolsResources = document.querySelector( "#schools-resources-flkty" );

        // If it's found, initialise it's flickity.
        if ( schoolsResources )
        {
            this.schoolsResourcesFlickity = new Flickity( schoolsResources, 
            {
                cellAlign:          "left",
                contain:            true,
                pageDots:           false,
                imagesLoaded:       true,
                prevNextButtons:    true,
                lazyLoad: true,
            } );

            $( "#btn-left-schools-resources" ).on( "click", function ()
            {
                this.Flickity.previous();
            } );
    
            $( "#btn-right-schools-resources" ).on( "click", function ()
            {
                this.Flickity.next();
            } );
        }

        var lazy = new LazyLoad();
    }
    
    /**
     * Called when our form is fully validated.
     */
    onFormValidated()
    {
        $.ajax(
        {
            url:        vl_util.site_url() + "/api/v1/schoolsfundraising/post-form",
            type:       "POST",
            dataType:   "JSON",
            data:
            { 
                ...this.form.values(), 
                _token: vl_util.csrf_token() 
            }
        } )
        .done( ( ( response ) => 
        {
            if ( response.status )
            {
                $( "#schools-fundraising-form" ).addClass( "hidden" );
                $( "#schools-fundraising-form-submitted" ).removeClass( "hidden" );
            }
        } ).bind( this ) )
        .fail( ( error, jqxhr ) => 
        {
            alert( "Failed to upload your information." );
        } );
    }

    /**
     * 
     * @param {Object} place 
     */
    onRetrievePlace( place )
    {
        if ( ! place )
        {
            return;
        }

        place.address_components.forEach( element => 
        {
            switch ( element.types[ 0 ] )
            {
            // Street number / house name
            case "street_number":
                $( "#organisation_address-house_no" ).val( element.long_name );
                this.form.onElementFocusout( { target: { id: "organisation_address-house_no" } } );
                
                break;

            // Street
            case "route":
                $( "#organisation_address-street" ).val( element.long_name );
                this.form.onElementFocusout( { target: { id: "organisation_address-street" } } );

                break;

            // Town
            case "postal_town":
                $( "#organisation_address-town" ).val( element.long_name );
                this.form.onElementFocusout( { target: { id: "organisation_address-town" } } );

                break;
            
            // County
            case "administrative_area_level_2":
                $( "#organisation_address-county" ).val( element.long_name );
                this.form.onElementFocusout( { target: { id: "organisation_address-county" } } );

                break;

            // Country
            case "country":
                $( "#organisation_address-country" ).val( element.short_name.toUpperCase() );
                this.form.onElementFocusout( { target: { id: "organisation_address-country" } } );

                break;

            // Postal code
            case "postal_code":
                $( "#organisation_address-postal_code" ).val( element.long_name );
                this.form.onElementFocusout( { target: { id: "organisation_address-postal_code" } } );

                break;
            } 
        } );
    }

    /**
     * Post form data to server.
     * 
     * @param {Object} data 
     * @returns 
     */
    static async postFormData( data )
    {
        return $.ajax(
        {
            url: vl_util.site_url() + "/api/v1/schoolsfundraising/post-form",
            dataType: "JSON",
            data: 
            {
                _token: $( `meta[name="csrf-token"]` ).attr( "content" ),
                ...data
            }
        } );
    }

    /**
     * Binds the google places stuff.
     */
    static initGoogle()
    {
        function _init()
        {
            var google = window.google;

            if ( ! google )
            {
                setTimeout( _init, 250 );
                return;
            }

            var elem = $( "#organisation_address" ).get( 0 );

            // If the elements aren't loaded but google is, set a timeout for 250 ms and wait it out, this will call this function 
            // after 250 ms.
            if ( ! elem )
            {
                setTimeout( _init, 250 );
                return;
            }

            var autocomplete = new google.maps.places.Autocomplete( elem, { location: "53.79508524429051,-1.754992069056253" } );

            // Add listener for entry #1 - the user address input.
            autocomplete.addListener( "place_changed", function ()
            {
                __schools_fundraising_controller.onRetrievePlace( autocomplete.getPlace() );
            } );
        }

        _init();
    }
}