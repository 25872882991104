import $                    from "jquery";
import jQuery               from "jquery";

function stripe_style_appearance()
{
    return {
        theme: 'stripe',
      
        variables: {
          colorPrimary: '#0570de',
          colorBackground: '#ffffff',
          colorText: '#30313d',
          colorDanger: '#e42123',
          fontFamily: 'Epilogue, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
          spacingUnit: '6px',
          borderRadius: '12px',
        },

        rules : {
            '.Label':{
                fontSize : '1rem',
                color : 'black',
                marginBottom : '8px',
            },
            '.Input:focus' : {
                borderColor : '#e42381',
            },
            '.Input--empty' : {
                borderColor : '#dae0e5',
            },
            '.Input--invalid' : {
                borderColor : '#e42123',
            },
            '.Input' : {
                borderColor : '#25c968',
            }
        }
        
    };  
}

export var stripe_style = {
    appearance: stripe_style_appearance
}