import $                    from "jquery";
import jQuery               from "jquery";

/**
 * Fundraiser levels controller.
 */
export default class FundraiserLevelsController
{
    /**
     * Constructor.
     */
    constructor () 
    {
    }
} 