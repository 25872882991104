import $                    from "jquery";
import jQuery               from "jquery";
import { vl_util } from "../general/util";

/**
 * Module export, just `import { cards }`.
 */
export var cards = 
{
    hooks: [],

    /**
     * Hooks a cards event.
     * 
     * Hooks list:
     * - on_show_donation_details 
     * - on_show_donor_details
     * - on_show_payment_details
     * 
     * @param {String} name 
     * @param {CallableFunction} callback 
     */
    addHook: function ( name, callback )
    {
        this.hooks.push( [ name, callback ] );
    },

    wipeHook: function ( name )
    {
        var newHooks = [];

        this.hooks.forEach( ( value ) => 
        {
            if ( value[ 0 ] == name )
            {
                return;
            }

            newHooks.push( value );
        } );

        this.hooks = newHooks;
        console.log(this.hooks);
    },

    /**
     * Calls a hook, by name.
     * 
     * @param {String} name 
     * @return {*} The value from the first executed function 
     */
    fireHook: function ( name )
    {
        // :)
        const findme = "<<<<<<<<<<<<Don't Return This Value>>>>>>>>>>>>";

        var output = findme;

        this.hooks.forEach( function ( value, index, array )
        {
            if ( name == value[ 0 ] )
            {
                let retval = value[ 1 ]();

                if ( retval !== findme )
                {
                    output = retval;
                }
            }
        } );

        return output;
    },

    initialise_cards:           initialise_cards,
    toggle_donation_details:    toggle_donation_details,
    toggle_donor_details:       toggle_donor_details,
    toggle_payment_details:     toggle_payment_details,
    finalise_step_2:            finalise_step_2
};

function set_step( step )
{
    $( "#ind-step-current" ).text( step );

    $( ".ind-step" ).removeClass( "active") ;

    $( ".ind-step" ).each( function () 
    {
        if ( $( this ).data( "step" ) <= step )
        {
            $( this ).addClass( "active" );
        }
    } )
}

/**
 * Toggles the donation details showing.
 */
function toggle_donation_details() 
{
    jQuery('#donation_details_inner').slideDown(); // slide this down

    jQuery('#donor_details_inner').slideUp(); // and the other two up
    jQuery('#payment_details_inner').slideUp();

    cards.fireHook( "on_show_donation_details" );

    set_donor_details_all_good( false );

    set_step( 1 );
}

/**
 * Toggles the donor details showing.
 */
function toggle_donor_details() 
{
    jQuery('#donor_details_inner').slideDown(); // slide this down

    jQuery('#donation_details_inner').slideUp(); // and the other two up
    jQuery('#payment_details_inner').slideUp();

    cards.fireHook( "on_show_donor_details" );

    set_step( 2 );
}

/**
 * Toggles the payment details showing.
 */
function toggle_payment_details() 
{
    jQuery('#payment_details_inner').slideDown(); // slide this down

    jQuery('#donation_details_inner').slideUp(); // and the other two up
    jQuery('#donor_details_inner').slideUp();

    cards.fireHook( "on_show_payment_details" );
    
    $( "#payment_details_loading" ).toggleClass( "hidden flex" );

    set_step( 3 );
}

/**
 * Initialise our cards validation system.
 * 
 * @param {JQuery} $ JQuery
 */
function initialise_cards( $ )
{
    // Donation Details -> Donor Details
    jQuery( "#step_1_complete" ).on( "click", on_click_step_1_complete );
    
    // Donor Details -> Payment Details
    jQuery( "#step_2_complete" ).on( "click", on_click_step_2_complete );
    
    // When the edit button for step 1 is clicked, 
    jQuery( "#edit_step_1").on( "click", on_click_step_1_edit );
    
    // When the edit button for step 2 is clicked,
    jQuery( "#edit_step_2" ).on( "click", on_click_step_2_edit );
}

/**
 * On click donation details complete.
 */
function on_click_step_1_complete( e = null )
{
    if ( e )
    {
        e.preventDefault();
    }

    if ( ! window.is_event && ! ( $( `html[data-seg-0="hifzsponsorship"][data-seg-1="donate"]` ).length ) )
    {
        // Fire the validation hook to validate the card.
        if ( cards.fireHook( "validate_step_1" ) === false )
        {
            set_donation_details_all_good( false );

            return;
        }
    }

    // Show the donor details.
    setTimeout(toggle_donor_details);

    // Toggle "okay" state.
    set_donation_details_all_good( true );

    // Fire a hook.
    cards.fireHook( "step_1_complete" );
}

/**
 * On click donor details complete.
 */
function on_click_step_2_complete( e = null )
{
    if ( e )
    {
        e.preventDefault();
    }

    // Fire the validation hook to validate the card
    if ( cards.fireHook( "validate_step_2" ) === false )
    {
        set_donor_details_all_good( false );

        return;
    }

    set_donor_details_all_good( true );
}

function finalise_step_2()
{
    // Show the payment details.
    setTimeout(toggle_payment_details);

    // Toggle "okay" state.
    set_donor_details_all_good( true );

    // Fire a hook.
    cards.fireHook( "step_2_complete" );
}

/**
 * On click of donation details edit.
 */
function on_click_step_1_edit()
{
    // Show the donation details card
    setTimeout( toggle_donation_details );

    // Remove the "okay" state.
    set_donation_details_all_good( false );
}

/**
 * On click of donor details edit.
 */
function on_click_step_2_edit()
{
    // Show the donor details card.
    setTimeout( toggle_donor_details );

    // Remove the "okay" state.
    set_donor_details_all_good( false );
}

/**
 * Sets state for all good (true / false) for the donation details.
 * 
 * @param {Boolean} all_good 
 */
 function set_donation_details_all_good( all_good )
 {
    const $donation_details = jQuery( "#donation_details" );
 
    if ( $donation_details.hasClass( "state-good" ) && ( ! all_good ) )
    {
        $donation_details.removeClass( "state-good" );
    }

    if ( ( ! $donation_details.hasClass( "state-good" ) ) && all_good )
    {
        $donation_details.addClass( "state-good" );
    }
 }

cards.set_donation_details_all_good = set_donation_details_all_good;
 
 /**
  * Sets state for all good (true / false) for the donor details.
  * 
  * @param {Boolean} all_good 
  */
 function set_donor_details_all_good( all_good )
 {
    const $donor_details = jQuery( "#donor_details" );

    if ( $donor_details.hasClass( "state-good" ) && ( ! all_good ) )
    {
        $donor_details.removeClass( "state-good" );
    }

    if ( ( ! $donor_details.hasClass( "state-good" ) ) && all_good )
    {
        $donor_details.addClass( "state-good" );
    }

    if ( vl_util.site_config().isMalaysia )
    {
        if ( $( ".active#type-recurring" ).length )
        {
            $( "#save_card_wrapper" ).removeClass( "hidden" );
        }
        else
        {
            $( "#save_card_wrapper" ).addClass( "hidden" );
        }
    }
 }