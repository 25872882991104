import { VL_Modal } from "../general/modal-factory";
import $                    from "jquery";
import jQuery               from "jquery";

/**
 * Represents functionality for our pop-up login modal.
 */
export class VL_PayPal_Cancel_Modal extends VL_Modal
{
    elements = 
    {
        dismiss:              null,
    };

    /**
     * Constructor
     */
    constructor ()
    {
        super( "pp-cancel" );

        // Args which will be passed to the bound function.
        const args = 
        { 
            _this_ref: this 
        };

        // Get the input fields.
        this.elements.dismiss = $( "#pp-cancel-dismiss" );
        this.elements.dismiss.on( "click", args, this.onClickDismiss );

        if ( window.location.hash === "#paypal-canceled" )
        {
            this.showHideModal();
        }
    }

    onClickDismiss( event )
    {
        const _this = event.data._this_ref;

        window.location.hash = "#dismissed";

        _this.showHideModal();
    }
}