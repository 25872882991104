import { inputs } from "../donations/inputs";
import { VL_Modal } from "../general/modal-factory";
import { vl_util } from "../general/util";
import $                    from "jquery";
import jQuery               from "jquery";

/**
 * Represents functionality for our pop-up registration modal.
 */
export class VL_Register_Modal extends VL_Modal
{
    /**
     * Can we click the register button and run the function?
     * 
     * @var {Boolean} can_go_forward
     */
    can_go_forward = false;

    /**
     * Is the main password field showing as text?
     * 
     * @var {Boolean} is_password_showing
     */
    is_password_showing = false;

    /**
     * Is the confirm password field showing as text?
     * 
     * @var {Boolean} is_confirm_password_showing
     */
    is_confirm_password_showing = false;

    /**
     * All the elements for our form.
     * 
     * @var {Object} elements
     */
    elements = 
    {
        // Users basic details.
        title:              $(),
        first_name:         $(),
        last_name:          $(),

        // Users email address.
        email:              $(),

        // Password & confirmation of password.
        password:           $(),
        password_confirm:   $(),

        // The actual register button.
        action:             $(),
        continue_guest:     $(),

        // Password indicators
        password_indicator_1: $(),
        password_indicator_2: $()
    };

    /**
     * Validators for the elements.
     * 
     * @var {Object} validator
     */
    validators = 
    {
        title:              $(),
        first_name:         $(),
        last_name:          $(),
        email:              $(),
        password:           $(),
        password_confirm:   $()
    };

    /**
     * Constructor, sorts out our elements and their validators, then 
     * applies bindings where necessary.
     */
    constructor()
    {
        // Call parent constructor.
        super( "register" );

        // Locate the elements
        this.elements.title                 = $( "#register_title"                  );
        this.elements.first_name            = $( "#register_first_name"             );
        this.elements.last_name             = $( "#register_last_name"              );
        this.elements.email                 = $( "#register_email"                  );
        this.elements.password              = $( "#register_password_new"           );
        this.elements.password_confirm      = $( "#register_password_new_confirm"   );
        this.elements.password_indicator_1  = $( "#register_pwd_indicator_1"        );
        this.elements.password_indicator_2  = $( "#register_pwd_indicator_2"        );
        this.elements.action                = $( "#register-modal-ajax"             );
        this.elements.continue_guest        = $( "#register-continue-as-guest"      );

        // Locate the validators for the elements.
        if ( this.elements.title.length )
        {
            this.validators.title               = this.elements.title;                                      // No validator for selects atm.
        }
        
        if ( this.elements.first_name.length )
        {
            this.validators.first_name          = this.elements.first_name      .parents( ".validator" );
        }

        if ( this.elements.last_name.length )
        {
            this.validators.last_name           = this.elements.last_name       .parents( ".validator" );
        }
        
        if ( this.elements.email.length )
        {
            this.validators.email               = this.elements.email           .parents( ".validator" )
        }
        
        if ( this.elements.password.length )
        {
            this.validators.password            = this.elements.password        .parents( ".validator" );
        }
        
        if ( this.elements.password_confirm.length )
        {
            this.validators.password_confirm    = this.elements.password_confirm.parents( ".validator" );
        }

        // Add the JRumble effect to the validators (which are essentially the containers for the inputs.)
        if ( this.validators.title.length )
        {
            this.validators.title               .jrumble();
        }
        
        if ( this.validators.first_name.length )
        {
            this.validators.first_name          .jrumble();
        }
        
        if ( this.validators.last_name.length )
        {
            this.validators.last_name           .jrumble();
        }

        if ( this.validators.password.length )
        {
            this.validators.password            .jrumble();
        }
        
        if ( this.validators.password_confirm.length )
        {
            this.validators.password_confirm    .jrumble();
        }

        // Arguments which will be applied to all bindings, so we can access this class.
        const binding_args = 
        {
            _this_ref: this
        };

        // Input events.
        if ( this.elements.title.length )
        {
            this.elements.title                 .on( "change", binding_args, this.onInputTitle );
        }
        
        if ( this.elements.first_name.length )
        {
            this.elements.first_name            .on( "input", binding_args, this.onInputFirstName );
        }
        
        if ( this.elements.last_name.length )
        {
            this.elements.last_name             .on( "input", binding_args, this.onInputLastName );        
        }

        if ( this.elements.email.length )
        {
            this.elements.email                 .on( "input", binding_args, this.onInputEmail );
        }

        if ( this.elements.password.length )
        {
            this.elements.password              .on( "input", binding_args, this.onInputPassword );
        }

        if ( this.elements.password_confirm.length )
        {
            this.elements.password_confirm      .on( "input", binding_args, this.onInputPasswordConfirm );
        }
        
        // Click events
        if ( this.elements.action.length )
        {
            this.elements.action                .on( "click", binding_args, this.attemptRegister );
        }

        if ( this.elements.password_indicator_1.length )
        {
            this.elements.password_indicator_1  .on( "click", binding_args, this.onClickPasswordIndicator );
        }

        if ( this.elements.password_indicator_2.length )
        {
            this.elements.password_indicator_2  .on( "click", binding_args, this.onClickPasswordIndicator );
        }

        if ( this.elements.continue_guest.length )
        {
            this.elements.continue_guest        .on( "click", binding_args, this.onClickContinueAsGuest );
        }
        
        this.elements.password_confirm.on( "keypress", function ( event )
        {
            if ( event.key === "Enter" )
            {
                binding_args._this_ref.attemptRegister();
            }
        } );
        
        $( "#register_first_name, #register_last_name, #register_email, #register_password_new, #register_password_new_confirm" )
            .on( "focusout", binding_args, this.onInputLoseFocus )

        this.setCanGoForward( false );
    }

    /**
     * Attempts to register the user using our data.
     */
    attemptRegister( event = null )
    {
        var _this;

        if ( event )
        {
            _this = event.data._this_ref;
        }
        else
        {
            _this = this;
        }

        _this.can_go_forward = _this.validateAllFields( true, true, true );

        // Don't try if we haven't fully validated yet.
        if ( ! _this.can_go_forward )
        {
            return;
        }

        _this.setCanGoForward( false );

        // Grab the field values since we can assume they've been validated already.
        const title             = _this.elements.title.val();
        const email             = _this.elements.email.val();
        const password          = _this.elements.password.val();
        const password_confirm  = _this.elements.password_confirm.val();
        const first_name        = _this.elements.first_name.val();
        const last_name         = _this.elements.last_name.val();

        // Use AJAX to attempt the login.
        $.ajax(
        {
            url:        vl_util.site_url() + "/api/v1/front/ajax-register-v2",
            method:     "POST",
            dataType:   "JSON",
            data: 
            {
                email:              email,
                password:           password,
                password_confirm:   password_confirm,

                title:              title,
                first_name:         first_name,
                last_name:          last_name,

                _token:             vl_util.csrf_token()
            },
            success: function ( response )
            {
                // If the status is true, we loop back the selected donation info, we're logged in now.
                if ( response.status )
                {
                    // If we're on the ramadan challenge page, we want to refresh but go to the anchor, and indicate setting the next step.
                    if ( vl_util.site_config().ramadanChallengeSlug === "ramadanchallenge" )
                    {
                        // Get the unhashed location.
                        var loc = window.location.href.split( "#" )[ 0 ];

                        // Unset the hash string.
                        window.location.hash = null;

                        window.location.href = vl_util.add_query_params( loc,
                        {
                            "type": $( "#soh-individual-reg" ).hasClass( "hidden" ) ? "team" : "individual"
                        } ) + "#app-container";
                    }
                    else
                    {
                        inputs.loop_query_params();
                    }
                }
                else
                {
                    _this.setCanGoForward( true );

                    VL_Register_Modal.setRegisterError( response.error );

                    // Bodge - won't work in other languages lmao. (TODO: Error codes from server)
                    if ( response.error.includes( "Email is taken." ) )
                    {
                        inputs.not_valid( _this.validators.email );
                    }
                }
            },
            error: function ( jqXHR, error )
            {

                _this.setCanGoForward( true );
            }
        } );
    }

    /**
     * Validates all fields.
     * 
     * @return {Boolean} The validation result.
     */
    validateAllFields( update_visually = true, force = false )
    {
        // Assume everything is okay, until it isn't.
        var validation_result = true;

        // Iterate through our list of validators.
        for ( let idx in this.validators )
        {
            // From the validator index we can get the actual input from this.elements
            const validator = this.validators[ idx ];

            if ( ! validator || ! validator.length )
            {
                continue;
            }

            // Get this validators input (or select..)
            const input = this.elements[ idx ];

            if ( ! this.validateID( input.get( 0 ).id, input, validator, update_visually, force ) )
            {
                validation_result = false;
            }
        }

        return validation_result;
    }

    /**
     * Called when the password indicator is clicked.
     * 
     * @param {*} event 
     */
    onClickPasswordIndicator( event )
    {
        const _this = event.data._this_ref;

        const $elem = $( this ).prev( "input" );

        $( this ).toggleClass("fa-eye fa-eye-slash");

        if ( $elem.attr( "type" ) === "text" )
        {
            $elem.attr( "type", "password" );
        }
        else
        {
            $elem.attr( "type", "text" );
        }
    }

    /**
     * Called when any of our inputs lose focus.
     * 
     * @param {*} event 
     */
    onInputLoseFocus( event )
    {
        const _this = event.data._this_ref;

        const input = $( this );
        const validator = VL_Register_Modal.findInputValidator( input );

        // Fully validate everything
        _this.setCanGoForward( _this.validateAllFields( false ) );

        if ( input.val().replace( /\s/g, "" ).length === 0 )
        {
            inputs.unknown_valid( validator );
        }
        else
        {
            _this.validateID( this.id, input, validator, true );
        }
    }

    /**
     * Validates a single element ID, and the element. bad func.
     * 
     * @param {*} id 
     * @param {*} input 
     * @param {*} validator 
     * @param {*} update_visually 
     * @returns 
     */
    validateID( id, input, validator, update_visually = true, force = false, rumble = true )
    {
        var validation_result = true;
        var strong_enough = false;

        switch ( id )
        {
        case "register_email":

            if ( ! vl_util.validate_email_address( input.val() ) )
            {
                if ( update_visually )
                {
                    inputs.not_valid( validator, rumble );
                }

                validation_result = false;
            }
            else
            {
                inputs.is_valid( validator );
            }

            break;
        case "register_title":
        case "register_first_name":
        case "register_last_name":
 
            if ( input.val().replace( /\s+/g, "" ).length === 0 )
            {
                if ( update_visually )
                {
                    inputs.not_valid( validator, rumble );
                }

                validation_result = false;
            }
            else
            {
                if ( update_visually )
                {
                    inputs.is_valid( validator );
                }
            }

            break;

        case "register_password_new":
            strong_enough = VL_Register_Modal.passwordStrength( this.elements.password.get( 0 ) );

        case "register_password_new_confirm":

            if ( input.val().length )
            {
                const password_valid = VL_Register_Modal.validatePassword( input.val() );

                if ( ! password_valid )
                {   
                    if ( update_visually )
                    {
                        inputs.not_valid( validator, rumble );
                    }
    
                    validation_result = false;
                }
                else
                {
                    if ( update_visually ) 
                    {
                        inputs.is_valid( validator );
                    }
                }
            }
            else
            {
                if ( update_visually )
                {
                    if ( force )
                    {
                        inputs.not_valid( validator, rumble );
                    }
                    else
                    {
                        inputs.unknown_valid( validator );   
                    }   
                }
            }
           

            break
        }

        return validation_result;
    }

    /**
     * Called when user clicks the register button
     * 
     * @param {*} event 
     */
    onClickRegister( event )
    {
        const _this = event.data._this_ref;

        if ( ! _this.can_go_forward )
        {
            return;
        }

        const validationResult = true;

        if ( validationResult )
        {
            _this.attemptRegister();
        }
        else
        {
            _this.setCanGoForward( false );
        }
    }

    /**
     * Called on input of the title element.
     * 
     * @param {*} event 
     */
    onInputTitle( event )
    {
        const _this = event.data._this_ref;

        const input = $( this );
        const validator = $( this );

        if ( _this.validateID( this.id, input, validator, false ) )
        {
            input.addClass( "valid" ).removeClass( "not-valid" );
        }
        else
        {
            input.addClass( "not-valid" ).removeClass( "valid" );
        }
    }

    /**
     * Called on input of the first name element.
     * 
     * @param {*} event 
     */
    onInputFirstName( event )
    {
        const _this = event.data._this_ref;
        const validator = VL_Register_Modal.findInputValidator( $( this ) );

        inputs.unknown_valid( validator );
    }

    /**
     * Called on input of last name element.
     * 
     * @param {*} event 
     */
    onInputLastName( event )
    {
        const _this = event.data._this_ref;
        const validator = VL_Register_Modal.findInputValidator( $( this ) );

        inputs.unknown_valid( validator );
    }

    /**
     * Called on input of email element.
     * 
     * @param {*} event 
     */
    onInputEmail( event )
    {
        const _this = event.data._this_ref;
        const validator = VL_Register_Modal.findInputValidator( $( this ) );

        inputs.unknown_valid( validator );
    }

    /**
     * Called on input of the password element.
     * 
     * @param {*} event 
     */
    onInputPassword( event )
    {
        const _this = event.data._this_ref;
        const validator = VL_Register_Modal.findInputValidator( $( this ) );

        inputs.unknown_valid( validator );
        
        VL_Register_Modal.passwordStrength( _this.elements.password.get( 0 ) );
    }

    /**
     * Called on input of the password confirm element.
     * 
     * @param {*} event 
     */
    onInputPasswordConfirm( event )
    {
        const _this = event.data._this_ref;
        const validator = VL_Register_Modal.findInputValidator( $( this ) );

        inputs.unknown_valid( validator );
    }

    /**
     * Enables / disables the register button, also does its classes.
     * 
     * @param {Boolean} new_val 
     */
    setCanGoForward( new_val )
    {
        this.can_go_forward = new_val;

        if ( ! new_val )
        {
            this.elements.action.addClass( "btn-disabled" ).removeClass( "btn-main" );
        }
        else
        {
            this.elements.action.removeClass( "btn-disabled" ).addClass( "btn-main" );
        }
    }

    /**
     * Continue as a guest.
     */
    onClickContinueAsGuest( event )
    {
        const _this = event.data._this_ref;

        _this.showHideModal();

        $( "#create_account_checkbox" ).prop( "checked", false );
    }

    /**
     * Simple, repeatable, password validation.
     * 
     * @var {String} password_string
     */
    static validatePassword( password_string )
    {
        if ( ! password_string.length )
        {
            return false;
        }

        // Ensure password is at least 8 chars long.
        if ( password_string.length < 8 )
        {
            return false;
        }

        // Ensure the password contains at least one upper and one lower.
        if ( password_string.search( /[A-Z]/g ) === -1 ||
            password_string.search( /[A-Z]/g ) === -1 )
        {
            return false;
        }

        return true;
    }

    /**
     * Finds an inputs validator container.
     * 
     * @param {JQuery} $input 
     * @returns {JQuery}
     */
    static findInputValidator( $input )
    {
        return $input.parents( ".validator" );
    }

    /**
     * Updates password strength.
     */
    static passwordStrength( elem = null ) 
    {
        if ( ! elem )
        {
            elem = this;
        }

        const donor_password = $( elem ).val();

        for ( let i = 0; i < 4; i ++ )
        {
            $( `.pwd-indicator[data-pwd-level="${i}"]` ).removeClass( "active" );
        }

        if ( ! donor_password )
        {
            return true;
        }

        var levels = 0;

        if ( donor_password.length >= 6 )
        {
            levels += 1;
        }

        // Has a lowercase
        if ( donor_password.search( /[a-z]/g ) !== -1 )
        {
            levels += 1;
        }

        // Has an uppercase
        if ( donor_password.search( /[A-Z]/g ) !== -1 )
        {
            levels += 1;
        }

        // Has a number
        if ( donor_password.search( /[0-9]/g ) !== -1 )
        {
            levels += 1;
        }

        for ( let i = 0; i < levels; i ++ )
        {
            $( `.pwd-indicator[data-pwd-level="${i}"]` ).addClass( "active" ).attr( "data-current-level", levels );
        }

        return true;
    }

    /**
     * Sets the registration error.
     * 
     * @param {String|Boolean|null} str Use a value of false, or null, to hide the error.
     */
    static setRegisterError( str )
    {
        $( "#register_error" ).text( str );

        if ( ! str || ! str.length )
        {
            $( "#wrap_register_error" ).addClass( "hidden" );
        }   
        else
        {
            $( "#wrap_register_error" ).removeClass( "hidden" );
        }
    }
}