import { donate_stripe }    from "../general/stripe";
import { vl_util }          from "../general/util";
import { cards }            from "./card-toggles";
import { donation }         from "./current-donation";
import { App }              from "../../main"; // Circular import..
import intlTelInput         from "intl-tel-input";
import tippy                from "tippy.js";
import { payment_flow }     from "./payment-flow";
import b64                  from "../general/b64";
import $                    from "jquery";
import jQuery               from "jquery";
import { jrumble } from "../../vendor/jrumble/jrumble";

const VALIDATE_RUMBLE_MS = 250;

const VALIDATE_EVENT = "focusout";

const VALIDATE_RESET_EVENT = "keydown";

const VALIDATE_ADDRESS_MAP = 
{
    "street_number":                "#donor_address_st_num",
    "route":                        "#donor_address_street",
    "postal_town":                  "#donor_address_town",
    "administrative_area_level_2":  "#donor_address_county",
    "country":                      "#donor_address_country",
    "postal_code":                  "#donor_address_postal_code",
    "postal_code_prefix":           "#donor_address_postal_code",
};

const VALIDATE_ADDRESS_MAP_BUSINESS = 
{
    "street_number":                "#business_address_st_num",
    "route":                        "#business_address_street",
    "postal_town":                  "#business_address_town",
    "administrative_area_level_2":  "#business_address_county",
    "country":                      "#business_address_country",
    "postal_code":                  "#business_address_postal_code",
};

const VALIDATE_REQUIRED_FIELDS = 
[
    "#donor_email",
    "#donor_title",
    "#donor_first_name",
    "#donor_last_name",
    // "#donor_phone",
    // "#donor_address_st_num",
    // "#donor_address_street",
    // "#donor_address_town",
    // "#donor_address_county",
    // "#donor_address_postal_code",
    // "#donor_address_country",
    "_company:#business_name",
    "_company:#business_address"
];

const VALIDATE_ELEMENTS = 
[
    "#donor_first_name",
    "#donor_last_name", 
    // "#donor_address_st_num", 
    // "#donor_address_street", 
    // "#donor_address_town", 
    // "#donor_address_postal_code",
    "#business_name",
    "#business_address",
    "#donor_title"
];

const VALIDATE_RESETS = 
[
    "#donor_email",
    "#donor_first_name",
    "#donor_last_name", 
    // "#donor_address", 
    // "#donor_address_st_num", 
    // "#donor_address_street", 
    // "#donor_address_town",
    // "#donor_address_postal_code", 
    "#donor_password_new",
    "#donor_password_new_confirm",
    "#business_name", 
    "#business_address"
];

export var inputs = 
{
    telephone: null,

    last_tippy: null,

    is_checkout: false,

    /**
     * 
     * @param {JQuery} $ 
     */
    initialise_inputs: function ( $, isCheckout = false )
    {
        this.is_checkout = isCheckout;

        jrumble($);
        jrumble(jQuery);

        this.bind_inputs( $ ); 
        this.bind_validators( $ );
        this.bind_card_hooks( $ );
        this.initialise_phone( $ );

        // After we've bound everything, iterate through all validators and trigger the validation bindings for each.
        $( ".validator" ).each( function ()
        {
            const $element = $( this );

            $element.find( "input" ).trigger( "input" );
            $element.find( "input" ).trigger( VALIDATE_EVENT );

            // Initialise jrumble on this validator.
            $element.jrumble();

            inputs.full_donor_details_validation();
        } );

        $( ".cr-field-select" ).each( function ()
        {
            $( this ).jrumble();

            $( this ).on( "input", function ()
            {
                inputs.full_donor_details_validation();
            } );
        } );

        // Initialise jrumble on the gift aid wrapper.
        $( "#giftaid_wrap" ).jrumble();
        
        // Initialis jrumble on the phone input.
        $( "#donor_phone" ).jrumble();

        // If the query params make the donation recurring, then we shall recur the donation.
        if ( window._vl_donation_is_recurring !== undefined && window._vl_donation_is_recurring )
        {
            // .. after 150 milliseconds because doing it instantly won't work.
            setTimeout( () => 
            {
                $( "#type-recurring" ).trigger( "click" );
            }, 150 );
        }

        // On companies checkbox clicked, click no on gift aid.
        $( "#company_checkbox" ).on( "input", function ( e )
        {
            console.log("clicked");
            if ( $( "#company_checkbox" ).prop( "checked" ) )
            {
                console.log("changed");
                $( "#gift_aid_no" ).prop( "checked", true );   
            }
        } );

        inputs.refresh_donation_level( true );
    },

    recaptchaToken: null,
    recaptchaReady: false,

    /**
     * Initialise recaptcha.
     */
    initialiseRecaptcha()
    {
        vl_util.dbgout( "Initialising reCAPTCHA enterprise" );

        if ( ! window?.grecaptcha || ! window?.grecaptcha?.enterprise )
        {
            return;
        }
        
        window.grecaptcha.enterprise.ready( ( function() {
            vl_util.dbgout( "reCAPTCHA enterprise is active." );
            this.recaptchaReady = true;
        } ).bind( this ) );
    },

    /**
     * Gets a new reCAPTCHA token, per actionable event.
     * 
     * @param {String} action 
     */
    getNewRecaptchaToken( action = "unknown", cb = null )
    {
        if ( ! this.recaptchaReady )
        {
            return;
        }

        // reCAPTCHA publishable key.
        const recaptchaPublishable = $( `[name="reCAPTCHA-public-key"]` ).prop( "content" );

        // Check the key is valid.
        if ( ( ! recaptchaPublishable ) || ( ! recaptchaPublishable.length ) )
        {
            return;
        }

        // Run the recaptcha process.
        window.grecaptcha.enterprise.execute( $( `[name="reCAPTCHA-public-key"]` ).prop( "content" ), 
        {
            action: action
        } ).then( cb ).catch( () => 
        {
            // Generic alert when the reCAPTCHA fails.
            alert( "There has been an issue while verifying your device identity for your donation's security.                         Please re-load this page and attempt your payment again." );
        } );
    },

    /**
     * Called when we rx a recaptcha login token.
     * 
     * @param {String|null} token 
     */
    onReceiveRecaptchaToken( token = null )
    {
        this.recaptchaToken = token;
    },

    /**
     * Initialises the international telephone input.
     * 
     * @param {JQuery} $ 
     */
    initialise_phone: function ( $ )
    {
        // Get donor phone element.
        const donor_phone = $( "#donor_phone" ).get( 0 );

        if ( ! donor_phone )
        {
            return;
        }

        // If the element is valid, initialise International Telephone Input
        inputs.telephone = intlTelInput( donor_phone, 
        {
            // Util script for extra features.
            utilsScript: "/app/assets/js/vendor/intl-tel-input/utils.js",

            // Placeholder should force override always.
            autoPlaceholder: "aggressive",

            // Automatically format.
            formatOnDisplay: true,

            // We want the country to be MY
            initialCountry: "MY",

            // Fixes the phone number strings.
            nationalMode: false,

            // Geoip lookup func.
            geoIpLookup: inputs.geoip_lookup,

            // Hidden input for actual full phone number.
            hiddenInput: "donor_phone_full",
        } );

        // Adds an event for when the phone is fully initialised, 
        // it will click on the element then focusoff to trigger the format event, 
        // so the validation autocompletes.
        inputs.telephone.promise.then( function ()
        {
            $( "#donor_phone" ).trigger( "click" ).trigger( "focusout" );

            // Retrieve the query vars.
            // https://stackoverflow.com/a/901144
            const params = new Proxy( new URLSearchParams( window.location.search ), 
            {
                get: ( searchParams, prop ) => searchParams.get( prop ),
            } );
    
            if ( params.skip_step1 && params.skip_step1 === "true" )
            {
                $( "#step_1_complete" ).trigger( "click" );
            }

            if ( ! params.amount )
            {
                $( $( ".donate-select-val" ).get( 0 ) ).trigger( "click" );
            }

            // Automatically update the selected projects name since the value can be set through query var.
            $( "#project_selected" ).html( $( "#project_name > :selected" ).text() );
        } );

        $( "#donor_phone" ).on( "focusout", formatIntlTelInput );

        function formatIntlTelInput() {
            if (typeof intlTelInputUtils !== 'undefined') { // utils are lazy loaded, so must check
                var currentText = inputs.telephone.getNumber(intlTelInputUtils.numberFormat.E164);
                if (typeof currentText === 'string') { // sometimes the currentText is an object :)
                    inputs.telephone.setNumber(currentText); // will autoformat because of formatOnDisplay=true
                }
            }
        }
    },

    /**
     * Binds the google places stuff.
     */
    initialise_google: function ( $=jQuery )
    {
        function _init()
        {
            var google = window.google;

            if ( ! google )
            {
                setTimeout( _init, 250 );
                return;
            }

            var elem = $( "#donor_address" ).get( 0 );
            var elem2;

            if ( ! inputs.is_checkout && ! ( $( `html[data-seg-0="hifzsponsorship"][data-seg-1="donate"]` ).length ) )
            {
                elem2 = $( "#business_address" ).get( 0 );
            }

            // If the elements aren't loaded but google is, set a timeout for 250 ms and wait it out, this will call this function 
            // after 250 ms.
            if ( ! elem || ( ! elem2 && !inputs.is_checkout && ! ( $( `html[data-seg-0="hifzsponsorship"][data-seg-1="donate"]` ).length ) ) || !( elem instanceof HTMLInputElement ) || ( ! ( elem2 instanceof HTMLInputElement )  && !inputs.is_checkout && ! ( $( `html[data-seg-0="hifzsponsorship"][data-seg-1="donate"]` ).length ) ) )
            {
                setTimeout( _init, 250 );
                return;
            }

            var autocomplete = new google.maps.places.Autocomplete( elem, { location: "53.79508524429051,-1.754992069056253" } );

            // Add listener for entry #1 - the user address input.
            autocomplete.addListener( "place_changed", function ()
            {
                const place = autocomplete.getPlace();
                const $self_validator = $( $( "#donor_address" ).parents( ".validator" ).get( 0 ) );

                if ( ! place )
                {
                    return;
                }

                inputs.on_get_location( place );
                inputs.is_valid( $self_validator );
                inputs.full_donor_details_validation();
                $( "#company_checkbox" ).trigger( 'input' );
            } );

            if ( ! inputs.is_checkout )
            {
                var autocomplete2 = new google.maps.places.Autocomplete( elem2, { location: "53.79508524429051,-1.754992069056253" } );
                // Add listener for entry #2 - the company address input.
                autocomplete2.addListener( "place_changed", function ()
                {
                    const place = autocomplete2.getPlace();
                    const $self_validator = $( $( "#business_address" ).parents( ".validator" ).get( 0 ) );

                    if ( ! place )
                    {
                        return;
                    }

                    inputs.on_get_location_business( place );
                    inputs.is_valid( $self_validator );
                    inputs.full_donor_details_validation();
                } );
            }
        }

        _init();        
    },

    /**
     * Function to be called when doing geoip lookup on load.
     * 
     * @param {CallableFunction} cb Pass arg of country code here.
     */
    geoip_lookup: function ( cb )
    {
        if ( $( "#donor_address_country" ).length )
        {
            // Skip if the country is already set.
            if ( $( "#donor_address_country > option:selected" ).val().length )
            {
                cb( $( "#donor_address_country" ).val().toLowerCase() );
                return;
            }
        }

        $( "#donor_address_country" ).val( "GB" );

        $.get( "http://ip-api.com/json/", function() {}, "json" ).always( function( resp )
        {
            // If the response status isn't success, just use "gb".
            if ( resp.status !== "success" )
            {
                cb( "gb" );
                return;
            }

            // Get country code from response.
            var countryCode = ( resp && resp.countryCode ) ? resp.countryCode : "gb";

            // Automatically set the country on the address.
            $( "#donor_address_country" ).val( countryCode );
            $( "#company_checkbox" ).trigger( "input" );

            // Callback with country code.
            cb( countryCode.toLowerCase() );

            return
        }).fail( function () 
        {
        } );
    },

    update_step1_complete: function ()
    {
        let val = parseFloat( $( "#enter_amount" ).val() );

        if ( val )
        {
            $( "#step_1_complete" ).addClass( "btn-active" ).removeClass( "btn-disabled" );
        }
        else
        {
            $( "#step_1_complete" ).removeClass( "btn-active" ).addClass( "btn-disabled" );
        }
    },

    bind_inputs: function ( $ )
    {
        this.bind_amount_selection( $ );
        this.enable_complete_button();

        // When the donation currency is changed..
        $( "#donation_currency" ).on( "input", function ()
        {
            inputs.update_totals();
        } );

        // On focusout - if the number isn't blank / invalid, do a .toFixed on it so we have a good decimal place we can work with.
        $( "#enter_amount" ).on( "focusout", function ( e )
        {
            e.preventDefault();

            if ( ! $( this ).val().length )
            {
                return;
            }

            let val = parseFloat( $( this ).val() );

            $( this ).val( val.toFixed( 2 ) );

            inputs.update_step1_complete();
        } );

        // Abandon previous PI when not on quran flow.
        if ( ! $( `html[data-seg-0="hifzsponsorship"][data-seg-1="donate"]` ).length )
        {
            $( "#edit_step_2" ).on( "click", function ()
            {
                const last_pi = donate_stripe.get_last_payment_intent();

                if ( ! last_pi  )
                {
                    return;
                }

                donate_stripe.abandon_payment_intent( last_pi );
            } );
        }

        $( "#donor_email" ).on( "focusout", function ()
        {
            if ( $( `input[name="_user_logged_in"]` ).length )
            {
                return;
            }

            vl_util.does_user_email_exist( $( "#donor_email" ).val(), () => 
            {
                $( "#create_an_account" ).addClass( "hidden" );
                $( "#you_have_account" ).removeClass( "hidden" );
            }, () => 
            {
                $( "#create_an_account" ).removeClass( "hidden" );
                $( "#you_have_account" ).addClass( "hidden" );
            } );
        } );

        $( "#checkout_as_guest" ).on( "click", function ()
        {
            $( "#you_have_account" ).addClass( "hidden" );
        } );

        $( "#company_checkbox" ).on( "input", function ()
        {
            const checked = $( this ).prop( "checked" );

            if ( checked )
            {
                $( "#company_details" ).removeClass( "hidden" );
            }
            else
            {
                $( "#company_details" ).addClass( "hidden" );
            }
            
            if ( $( "#company_checkbox:checked" ).length === 0 && $( "#donor_address_country" ).val().toLowerCase() === "gb" )
            {
                $( "#giftaid_wrap" ).removeClass( "hidden" );
            }
            else
            {
                $( "#giftaid_wrap" ).addClass( "hidden" );
                inputs.on_gift_aid_hide();
            }

            $( "#gift_aid_yes" ).prop( "checked", false );
            $( "#gift_aid_no" ).prop( "checked", false );
        } );

        $( "#donor_address_country" ).on( "input", function ()
        {
            if ( $( "#company_checkbox:checked" ).length === 0 && $( "#donor_address_country" ).val().toLowerCase() === "gb" )
            {
                $( "#giftaid_wrap" ).removeClass( "hidden" );
                inputs.on_gift_aid_hide();
            }
            else
            {
                $( "#giftaid_wrap" ).addClass( "hidden" );
                inputs.on_gift_aid_hide();
            }
        } );

        $( "#enter_amount" ).on( "focusout", function ( e )
        {
            e.preventDefault();

            var val;
            
            try
            {
                val = parseFloat( $( this ).val() ).toFixed( 2 );
            }
            catch ( error )
            {
                $( $( ".donate-select-val" ).get( 0 ) ).trigger( "click" )
                return;
            }

            if ( val === null || val === undefined || val === NaN || val == "NaN" )
            {
                $( $( ".donate-select-val" ).get( 0 ) ).trigger( "click" );
            }
        } );

        $( "#donor_details_inner .validator > input" ).on( "focusout", inputs.full_donor_details_validation );
        $( "#gift_aid_yes, #gift_aid_no" ).on( "click", inputs.full_donor_details_validation );

        $( "#donate_cr" ).on( "click", function ()
        {
            const is_checked = $( this ).prop( "checked" );
            
            if ( is_checked )
            {
                $( "#fees-wrap" ).toggleClass( "flex hidden" );
            }
            else
            {
                $( "#fees-wrap" ).toggleClass( "flex hidden" );
            }

            inputs.update_totals();

            if ( ! payment_flow.is_one_off() )
            {
                return;
            }

            inputs.disable_complete_button();
            inputs.update_cr_percent_serverside();
        } );

        $( `#donate_cr_amount, input[type="radio"][name="contact_preference"]` ).on( "input", function ()
        {
            inputs.update_totals();
            
            if ( ! payment_flow.is_one_off() )
            {
                return;
            }

            inputs.disable_complete_button();   
            inputs.update_cr_percent_serverside();
        } );

        $( "#gift_aid_yes" ).on( "click", function ()
        {
            $( "#giftaid_tag" ).addClass( "inline-block" ).removeClass( "hidden" );
        } );

        $( "#gift_aid_no" ).on( "click", function ()
        {
            $( "#giftaid_tag" ).removeClass( "inline-block" ).addClass( "hidden" );
        } );

        $( "#company_checkbox" ).on( "input", function ()
        {
            inputs.full_donor_details_validation();
        } );
        
        $( "#donor_title" ).on( "input", function ()
        {
            if ( $( "#donor_title" ).val().length )
            {
                $( this ).addClass( "valid" );
            }
            else
            {
                $( this ).removeClass( "valid" );
            }

            inputs.donor_details_validation( true );
        } );

        $( "#manual_address" ).on( "click", function ()
        {
            inputs.address_mode( true );
        } );

        $( "#manual_address_business" ).on( "click", function ()
        {
            inputs.address_mode_company( true );
        } );

        $( "#auto_address" ).on( "click", function ()
        {
            inputs.address_mode( false );
        } );

        $( "#auto_address_business" ).on( "click", function ()
        {
            inputs.address_mode_company( false );
        } );
        
        var card_options_state = ( $( "#has-cards" ).val() === "true" );

        $( "#card-options" ).on( "click", function ()
        {
            if ( card_options_state )
            {
                $( "#state-add-new" ).addClass( "hidden" );
                $( "#state-use-existing" ).removeClass( "hidden" );

                payment_flow.initialise_new_card();
                payment_flow.set_known_unknown( false );
            }
            else
            {
                $( "#state-add-new" ).removeClass( "hidden" );
                $( "#state-use-existing" ).addClass( "hidden" );
                payment_flow.set_known_unknown( true );
            }

            $( "#payment-form" ).slideToggle( 150 );
            $( "#known-cards-wrap" ).slideToggle( 150 );

            card_options_state = ! card_options_state;
        } );

        $( "#save_card" ).on( "click", function ()
        {
            inputs.disable_complete_button();
            inputs.update_totals();
            inputs.update_save_card();
        } );

        $( "#fundraiser_message" ).on( "input", function ()
        {
            let len = $( "#fundraiser_message" ).val().length;

            if ( len > 500 )
            {
                $( "#fundraiser_message" ).val( $( "#fundraiser_message" ).val().substring( 0, 499 ) );
                len = 500;
            }

            $( "#n-chars-msg" ).text( len );
        } );
    },

    address_mode: function ( manual_automatic )
    {
        if ( manual_automatic )
        {
            $( "#address_entry_manual" ).removeClass( "hidden" );
            $( "#address_entry_automatic" ).addClass( "hidden" );
        }
        else
        {
            $( "#address_entry_manual" ).addClass( "hidden" );
            $( "#address_entry_automatic" ).removeClass( "hidden" );

            var donor_address = "";
            var copied_fields = 0;

            for ( let i in VALIDATE_ADDRESS_MAP )
            {
                let v = VALIDATE_ADDRESS_MAP[ i ];
                let vv = $( v ).val();

                if ( i == "country" )
                {
                    vv = $( v ).find( ":selected" ).text();
                }

                if ( vv )
                {
                    donor_address += vv + ", "
                    copied_fields += 1;
                }
            }

            donor_address = donor_address.trim();

            if ( donor_address.endsWith( "," ) )
            {
                donor_address = donor_address.substring( 0, donor_address.length - 1 );
            }

            if ( copied_fields >= 3 )
            {
                $( "#donor_address" ).val( donor_address );
            }
        }
    },

    address_mode_company: function ( manual_automatic )
    {
        if ( manual_automatic )
        {
            $( "#business_address_entry_manual" ).removeClass( "hidden" );
            $( "#business_address_entry_automatic" ).addClass( "hidden" );
        }
        else
        {
            $( "#business_address_entry_manual" ).addClass( "hidden" );
            $( "#business_address_entry_automatic" ).removeClass( "hidden" );

            var donor_address = "";
            var copied_fields = 0;

            for ( let i in VALIDATE_ADDRESS_MAP_BUSINESS )
            {
                let v = VALIDATE_ADDRESS_MAP_BUSINESS[ i ];
                let vv = $( v ).val();

                if ( i == "country" )
                {
                    vv = $( v ).find( ":selected" ).text();
                }

                if ( vv )
                {
                    donor_address += vv + ", "
                    copied_fields += 1;
                }
            }

            donor_address = donor_address.trim();

            if ( donor_address.endsWith( "," ) )
            {
                donor_address = donor_address.substring( 0, donor_address.length - 1 );
            }

            if ( copied_fields >= 3 )
            {
                $( "#business_address" ).val( donor_address );    
            }
        }
    },

    trigger_parent_form: function ()
    {
        $( this ).parents( "form" ).trigger( "submit" );
    },

    enable_complete_button: function ()
    {
        $( "#donate_cr_amount, #donate_cr, #save_card" ).prop( "disabled", false );
        $( "#complete-donation" ).removeClass( "btn-disabled" ).addClass( "btn-active" );
    },

    disable_complete_button: function ()
    {
        $( "#donate_cr_amount, #donate_cr, #save_card" ).prop( "disabled", true );
        $( "#complete-donation" ).removeClass( "btn-active" ).addClass( "btn-disabled" );
    },

    update_cr_percent_serverside: function ()
    {
        if ( ! payment_flow.is_one_off() )
        {
            return;
        }

        // Update using AJAX now.
        jQuery.ajax(
        {
            url: vl_util.site_url() + "/api/v1/front/set-admin-fee",
            method: "POST",
            dataType: "JSON",
            data:
            {
                total_amount:                   donation.amounts.get_final_amount(),
                amount_percent:                 donation.amounts.get_fee_percent(),
                subtotal:                       donation.amounts.get_sub_total(),
                payment_intent:                 donate_stripe.get_last_payment_intent(), // Latest payment intent.    
                marketing_consent_identifer:    donation.get_marketing_consent()
            },

            success: function ( res )
            {
                if ( res.status )
                {
                    inputs.enable_complete_button();
                }
                else
                {
                    // Case/Switch on the error message.
                    switch ( res.error )
                    {
                    case "donation_too_high":
                        $( "#edit_step_1" ).trigger( "click" );
                        break;
                    }
                }

                
            },

            error: function ()
            {

            }
        } );
    },

    /**
     * Update your saved cards.
     */
    update_save_card: function ()
    {
        jQuery.ajax(
        {
            url: vl_util.site_url() + "/api/v1/front/update-save-card",
            method: "POST",
            dataType: "JSON",
            data:
            {
                save_card:      $( "#save_card" ).prop( "checked" ),
                payment_intent: donate_stripe.get_last_payment_intent() // Is actually the current payment intent, despite the func name.
            },

            success: function ()
            {
                inputs.enable_complete_button();
            },

            error: function ()
            {

            }
        } );
    },

    on_gift_aid_hide: function ()
    {
        $( "#gift_aid_yes" ).prop( "checked", false );
        $( "#gift_aid_no" ).prop( "checked", false );
    },

    attempt_login: function ()
    {
        // First, run the validation on the email address and password.
        const $donor_email = $( "#donor_email" );
        const $donor_password = $( "#donor_password" );

        if ( ! $donor_email.length || ! $donor_password.length )
        {
            return;
        }

        const $email_validator      = $( $donor_email.parents( ".validator" ).get( 0 ) );
        const $password_validator   = $( $donor_password.parents( ".validator" ).get( 0 ) );

        if ( ! $email_validator.length || ! $password_validator.length )
        {
            return;
        }

        var valid = true;

        if ( ! vl_util.validate_email_address( $donor_email.val() ) )
        {
            inputs.not_valid( $email_validator, true );

            valid = false;
        }

        if ( $donor_password.val().replace( /\s+/g, "" ).length < 8 )
        {
            inputs.not_valid( $password_validator, true );

            valid = false;
        }

        if ( ! valid )
        {
            return;
        }

        // Then, send the email and password to the server via ajax and attempt authentication.
        // https://readerstacks.com/laravel-ajax-login-and-registration-with-example/
        // If authenticated, the page will refresh with a query var and bring us back to the current state, but now logged in so email will be filled etc.

        $( "#donor_password" ).prop( "disabled", true );

        jQuery.ajax(
        {
            url: vl_util.site_url() + "/api/v1/front/ajax-login",
            method: "POST",
            data:
            {
                email: $donor_email.val(),
                password: $donor_password.val()
            },
            dataType: "JSON",
            success: function ( res )
            {
                if ( res.status )
                {
                    // If we're on the ramadan challenge page, we want to refresh but go to the anchor, and indicate setting the next step.
                    if ( vl_util.site_config().ramadanChallengeSlug === "ramadanchallenge" )
                    {
                        // Get the unhashed location.
                        var loc = window.location.href.split( "#" )[ 0 ];

                        // Unset the hash string.
                        window.location.hash = null;

                        window.location.href = vl_util.add_query_params( loc,
                        {
                            "type": $( "#soh-individual-reg" ).hasClass( "hidden" ) ? "team" : "individual"
                        } ) + "#app-container";
                    }
                    else
                    {
                        // We are logged in, refresh the state.
                        inputs.loop_query_params(); 
                    }
                }
                else
                {
                    inputs.set_login_error( "Email address or password incorrect" );
                    inputs.not_valid( $password_validator, true );
                    $( "#donor_password" ).prop( "disabled", false );
                }
            },
            error: function ()
            {
                inputs.set_login_error( "Email address or password incorrect" );   
                inputs.not_valid( $password_validator, true );
                $( "#donor_password" ).prop( "disabled", false );
            }
        } );
    },

    attempt_register: function ()
    {
        // First, run the validation on the email address and password.
        const $donor_email = $( "#donor_email" );
        const $donor_password_new = $( "#donor_password_new" );
        const $donor_password_new_confirm = $( "#donor_password_new_confirm" );

        if ( ! $donor_email.length || ! $donor_password_new.length || ! $donor_password_new_confirm.length )
        {
            return;
        }

        const $email_validator      = $( $donor_email.parents( ".validator" ).get( 0 ) );
        const $password_validator   = $( $donor_password_new.parents( ".validator" ).get( 0 ) );
        const $password_validator_confirm = $( $donor_password_new_confirm.parents( ".validator" ).get( 0 ) );

        if ( ! $email_validator.length || ! $password_validator.length || ! $password_validator_confirm.length )
        {
            return;
        }

        var valid = true;

        // Validate the email address
        if ( ! vl_util.validate_email_address( $donor_email.val() ) )
        {
            inputs.not_valid( $email_validator );

            valid = false;
        }

        // Validate the first password input
        if ( ! $donor_password_new.val().replace( /\s+/g, "" ).length )
        {
            inputs.not_valid( $password_validator );

            valid = false;
        }

        // Validate the second password (confirmation)
        if ( ! $donor_password_new_confirm.val().replace( /\s+/g, "" ).length )
        {
            inputs.not_valid( $password_validator_confirm );

            valid = false;
        }

        if ( $donor_password_new.val() !== $donor_password_new_confirm.val() )
        {
            inputs.not_valid( $password_validator_confirm );
            inputs.create_password_mismatch_tippy();

            valid = false;
        }

        if ( ! valid )
        {
            return;
        }

        jQuery.ajax(
        {
            url: vl_util.site_url() + "/api/v1/front/ajax-register",
            method: "POST",
            data:
            {
                email:              $donor_email.val(),
                password:           $donor_password_new.val(),
                password_confirm:   $donor_password_new_confirm.val()
            },
            dataType: "JSON",
            success: function ( res )
            {
                if ( res.status )
                {
                    // We are logged in, refresh the state.
                    inputs.loop_query_params();
                }
                else
                {
                    inputs.set_register_error( "Your account could not be registered: " + res.error );
                    inputs.not_valid( $password_validator );
                    inputs.not_valid( $password_validator_confirm );
                }
            }
        } );
    },

    loop_query_params: function ()
    {
        var params = {
            "amount":           $( "#enter_amount" ).val(),
            "project_id":       $( "#project_name" ).val(),
            "country_id":       $( "#country_supported" ).val(),
            "is_zakat":         ( $( "#zakat_checkbox:checked" ).length > 0 ) ? "true" : "false",
            "is_anonymous":     ( $( "#anonymous_checkbox:checked" ).length > 0 ) ? "true" : "false",
            "skip_step1":       $( ".ind-step.active" ).length > 1 ? "true" : "false",
            "donation_type":    $( "#type-recurring.active" ).length ? "recurring" : "one-off"
        };

        // If there's a Hifz ID set, and a length on it (UUID).
        if ( window.hifz_id && window.hifz_id.length )
        {
            params = 
            {
                "donation_type": $( "[for=qc-payment-type-one-off].qc-payment-type.active" ).length ? "one-off" : "reocurring"
            };
        }

        if ( $( "#fundraiser_message" ).length > 0 )
        {
            params.message = b64.encode( $( "#fundraiser_message" ).val() );
        }

        var loc = window.location.href.split( "#" )[ 0 ];
        window.location.href = vl_util.add_query_params( loc, params );
    },

    full_donor_details_validation: function ()
    {
        if ( inputs.donor_details_validation( true ) )
        {
            $( "#step_2_complete" ).addClass( "btn-active" ).removeClass( "btn-disabled" );
        }
        else
        {
            $( "#step_2_complete" ).addClass( "btn-disabled" ).removeClass( "btn-active" );
        }
    },

    /**
     * Sets the login error, leave null to remove said error.
     * 
     * @param {String|null} message 
     */
    set_login_error: function ( message = null )
    {
        const $elem = $( "#dv2-login-ajax-error" );

        if ( ! message )
        {
            $elem.addClass( "hidden" );
            $elem.text( "" );

            return;
        }

        $elem.removeClass( "hidden" );
        $elem.text( message );
    },

    /**
     * Sets the error string which shows on the registration form on the donation flow.
     * 
     * @param {String|null} message 
     */
    set_register_error: function ( message )
    {
        const $elem = $( "#dv2-register-ajax-error" );

        if ( ! message )
        {
            $elem.addClass( "hidden" );
            $elem.text( "" );

            return;
        }

        $elem.removeClass( "hidden" );
        $elem.text( message );
    },

    /**
     * Flow for whether email exist.
     */
    email_exists_flow: function ()
    {
        // Get the donor email string.
        const donor_email = $( "#donor_email" ).val();

        // Ensure the email address is valid before
        if ( ! vl_util.validate_email_address( donor_email ) ) 
        {
            $( "#create_an_account" ).addClass( "hidden" );
            return;
        }

        $( "#create_an_account" ).removeClass( "hidden" );

        // Does the email address exist? Simple DB query on server.
        vl_util.does_user_email_exist( donor_email, 
        function ()
        {
            // On the event the email address already exists, we hide the create account box.
            $( "#create_an_account" ).addClass( "hidden" );
            $( "#you_have_account" ).removeClass( "hidden" );

            $( "#login_email" ).val( $( "#donor_email" ).val() );
            
            App.instance.modal_login.setTitleStringDonate( true );

            App.instance.modal_login.showHideModal();
        },
        function ()
        {
            // Otherwise we show the create account box.
            $( "#create_an_account" ).removeClass( "hidden" );
            $( "#you_have_account" ).addClass( "hidden" );
        } );
    },

    bind_amount_selection: function ( $=jQuery )
    {
        // When a donation amount is clicked, we want to update the actual input.
        $( ".donate-select-val" ).on( "click", function ()
        {
            $( ".donate-select-val" ).removeClass( "active" );
    
            $( this ).addClass( "active" );
    
            $( `#enter_amount` ).val( $( this ).data( "amount" ) );

            inputs.update_totals( this );

            inputs.update_step1_complete();
        } );

        // When the input is changed..
        $( "#enter_amount" ).on( "input", function ( e )
        {
            e.preventDefault();
            
            let vals = [];
            
            $( ".donate-select-val" ).each( function ()
            {
                vals.push( $( this ).data( "amount" ) );
            } );

            let val = $( this ).val();

            // If the value can't be found - we remove all active states from buttons.
            if ( ! vals.find( element => element == val ) )
            {
                $( ".donate-select-val" ).removeClass( "active" );
            }
            else // Otherwise we locate the one which matches and set the active state.
            {
                $( ".donate-select-val" ).removeClass( "active" ).each( function ()
                {
                    if ( $( this ).data( "amount" ) == val.split( "." )[0] )
                    {
                        $( this ).addClass( "active" );
                    }
                } );
            }

            if ( $( ".donate-select-val.active" ).length )
            {  
                inputs.update_totals( $( ".donate-select-val.active" ).get( 0 ) );
            }
            else
            {
                $( ".dv2-donation-level-desc" ).text( "" );
                payment_flow.click_but_not_click( null, true );
                inputs.update_totals( null );
            }

            inputs.update_step1_complete();
        } );

        // When we focus out on the amount entering 
        $( "#enter_amount" ).on( "focusout", function ()
        {
            var minValue = Number( $( "#enter_amount" ).prop( "min" ) );

            if ( ! minValue )
            {
                minValue = 1.0;
            }

            if ( donation.amounts.get_sub_total(false) < minValue )
            {
                $( "#enter_amount" ).val( minValue );
                setTimeout( () => {
                    $( "#enter_amount" ).trigger( "input" );
                }, 50 );
            }
        } );

        $( "#enter_amount" ).val( $( "#enter_amount" ).val() );
        $( "#enter_amount" ).trigger( "input" );
    },

    /**
     * Updates donation totals.
     */
    update_totals: function ( elem=null )
    {
        // Update subtotal.
        $( ".dv2-total-subtotal" ).text( donation.amounts.get_sub_total( true ) );

        // Update full total.
        $( ".dv2-total-total" ).text( donation.amounts.get_final_amount( true ) );

        // Update possible giftaid value.
        $( ".dv2-total-giftaid" ).text( donation.amounts.get_gift_aid_amount( true ) );

        if ( elem )
        {
            // Donation level description.
            $( ".dv2-donation-level-desc" ).text( $( elem ).data( "description" ) );
        }

        // $( "#type-one-off, #type-recurring" ).trigger( "click" );

        // Update fees total
        const fee_amount = donation.amounts.get_fee_percent( false, true );

        $( ".dv2-total-fees" ).text( donation.amounts.get_fees( true ) );
        $( ".dv2-total-fees-percent" ).text( fee_amount );
    },

    bind_validators: function ( $=jQuery )
    {
        $( "#donor_email" ).on( VALIDATE_EVENT, function ()
        {
            const $self_validator = $( $( this ).parents( ".validator" ).get( 0 ) );
            const $element = $( this );

            if ( ! $self_validator.length )
            {
                return;
            }

            if ( $element.val().replace( /\s+/g, "" ).length === 0 )
            {
                inputs.unknown_valid( $self_validator );
                return;
            }

            if ( vl_util.validate_email_address( $( this ).val() ) )
            {
                inputs.is_valid( $self_validator );
            }
            else
            {
                inputs.not_valid( $self_validator );
            }
        } );

        $( VALIDATE_ELEMENTS.join( ", " ) ).on( VALIDATE_EVENT, function () 
        {
            const $self_validator = $( $( this ).parents( ".validator" ).get( 0 ) );
            const $self = $( this );

            if ( ! $self_validator.length )
            {
                return;
            }

            if ( $self.val().replace( /\s+/g, "" ).length !== 0 )
            {
                inputs.is_valid( $self_validator );
            }
            else
            {
                // inputs.not_valid( $self_validator );
                inputs.unknown_valid( $self_validator );
            }
        } );

        $( "#donor_phone" ).on( VALIDATE_EVENT, function ()
        {
            const $self_validator = $( $( this ).parents( ".validator" ).get( 0 ) );

            if ( $( this ).val().replace( /\s+/g, "" ).length === 0 )
            {
                inputs.unknown_valid( $self_validator );
                return;
            }

            var error = inputs.telephone.getValidationError();

            // IS_POSSIBLE || IS_POSSIBLE_LOCAL_ONLY
            if ( error === 0 || error === 4 )
            {
                inputs.is_valid( $self_validator );
            }
            else
            {
                inputs.not_valid( $self_validator );
            }
        } );

        /**
         * When the donor address is updated, we want to clear all the hidden fields.
         */
        $( "#donor_address" ).on( VALIDATE_RESET_EVENT, function ()
        {
            for ( let i in VALIDATE_ADDRESS_MAP )
            {
                let value = VALIDATE_ADDRESS_MAP[ i ];

                if ( ! value ) continue;

                const $self_validator = $( $( value ).parents( ".validator" ).get( 0 ) );

                $( value ).val( "" );
                inputs.unknown_valid( $self_validator );
            }
        } );

        /**
         * When the donor address is updated, we want to clear all the hidden fields.
         */
         $( "#business_address" ).on( VALIDATE_RESET_EVENT, function ()
         {
            for ( let i in VALIDATE_ADDRESS_MAP_BUSINESS )
            {
                let value = VALIDATE_ADDRESS_MAP_BUSINESS[ i ];

                if ( ! value ) continue;

                const $self_validator = $( $( value ).parents( ".validator" ).get( 0 ) );

                $( value ).val( "" );
                inputs.unknown_valid( $self_validator );

            }
        } );

        $( VALIDATE_RESETS.join( ", " ) ).on( VALIDATE_RESET_EVENT, function ()
        {
            const $self_validator = $( $( this ).parents( ".validator" ).get( 0 ) );

            inputs.unknown_valid( $self_validator );
        } );

        /**
         * When gift aid yes or no is clicked, we need to remove the not-valid class, if it exists, and add the valid one.
         */
        $( "#gift_aid_yes, #gift_aid_no" ).on( "input", function ()
        {
            $( "#giftaid_wrap" ).removeClass( "not-valid" ).addClass( "valid" );
        } );

        /**
         * When we update these, we need to remove the last tippy.
         */
        $( "#enter_amount, #donor_password_new_confirm" ).on( "input", function ()
        {
            if ( inputs.last_tippy )
            {
                inputs.last_tippy.destroy();

                inputs.last_tippy = null;
            }
        } );
    },

    /**
     * Event for when the location is chosen by the user on the google places autocomplete.
     * 
     * @param {Object} place 
     */
    on_get_location: function ( place )
    {
        const components = place.address_components;

        if ( ! components || ! components.length )
        {
            return;
        }

        for ( let i in VALIDATE_ADDRESS_MAP )
        {
            const value = VALIDATE_ADDRESS_MAP[ i ];
            const $elem = $( value );

            inputs.unknown_valid( $elem );
        }

        // Check for missing expected components.
        const REQUIRED_COMPONENTS = 
        [
            "street_number",
            "route",
            "postal_town",
            "administrative_area_level_2",
            "country",
            "postal_code",
        ];

        // iterate through components and look at their type lists.
        let anyMissing = false;

        for ( let x in REQUIRED_COMPONENTS )
        {
            const componentName = REQUIRED_COMPONENTS[ x ];
            let matches = 0;

            components.forEach( component => 
            {
                if ( component.types.includes( componentName ) )
                {
                    matches += 1;
                }
            } );

            if ( matches === 0 )
            {
                anyMissing = true;
                break;
            }
        }

        // If there's any fields which could not be filled, show the manual address entry
        if ( anyMissing )
        {
            inputs.address_mode( true );
        }

        components.forEach( ( value, idx, array ) => 
        {
            if ( value.types.length == 0 )
            {
                return;
            }

            const type = value.types[ 0 ];
            const selector = VALIDATE_ADDRESS_MAP[ type ] !== undefined ? VALIDATE_ADDRESS_MAP[ type ] : null;

            if ( selector )
            {
                const $elem = $( selector );

                $elem.val( selector === "country" ? value.long_name : value.short_name );
                
                if ( selector === "#donor_address_country" )
                {
                    $( "#have_uk_bank_account" ).prop( "checked", value.short_name === "GB" );
                }

                inputs.is_valid( $elem );
            }
        } );
    },

    /**
     * Event for when the location is chosen by the user on the google places autocomplete.
     * 
     * @param {Object} place 
     */
    on_get_location_business: function ( place )
    {
        const components = place.address_components;

        for ( let i in VALIDATE_ADDRESS_MAP_BUSINESS )
        {
            const value = VALIDATE_ADDRESS_MAP_BUSINESS[ i ];
            const $elem = $( value );

            inputs.unknown_valid( $elem );
        }

        if ( components && components.length )
        {
            components.forEach( ( value, idx, array ) => 
            {
                if ( value.types.length == 0 )
                {
                    return;
                }

                const type = value.types[ 0 ];
                const selector = VALIDATE_ADDRESS_MAP_BUSINESS[ type ] !== undefined ? VALIDATE_ADDRESS_MAP_BUSINESS[ type ] : null;

                if ( selector )
                {
                    const $elem = $( selector );

                    $elem.val( selector === "country" ?  value.long_name : value.short_name );

                    inputs.is_valid( $elem );
                }
            } );
        }
    },

    /**
     * Binds the hooks for the cards.
     */
    bind_card_hooks: function ( $=jQuery )
    {
        /**
         * Validation for step 1 -> step 2
         */
        cards.addHook( "validate_step_1", () => 
        {
            // Get the raw value.
            let val_raw = $( "#enter_amount" ).val();

            // If thats not available somethings gone terribly wrong.
            if ( ! val_raw || ! val_raw.length )
            {
                return false;
            }

            // Parse the value, if there's more than one dot in the string we convert to <int + .00>.
            if ( val_raw.split( "." ).length !== 2 )
            {
                val_raw = val_raw.split( "." )[ 0 ] + ".00";
            }

            // Try to parse a float out of our value.
            let val;
            
            try 
            { 
                val = parseFloat( val_raw );
            }
            catch ( error )
            {
                return false;
            }

            // If the parse failed we return false.
            if ( ! val || ! val.toFixed( 2 ) )
            {
                inputs.create_amount_tippy();

                return false;
            }

            // Make sure the value is within bounds.
            if ( val < 1 || val > 9999999 )
            {
                inputs.create_amount_tippy();

                return false;
            }

            return true;
        } );

        /**
         * Validation for step 2 -> payment, we store the users information here, where we can.
         */
        cards.addHook( "validate_step_2", () => 
        {
            // Ensure the inputs are validated
            var validation_result = inputs.donor_details_validation();

            if ( ! window.is_event && ! inputs.is_checkout && ! vl_util.site_config().isMalaysia && ! ( $( `html[data-seg-0="hifzsponsorship"][data-seg-1="donate"]` ).length ) )
            {
                // If the company checkbox is not checked, and we're in the UK - giftaid is available.
                if ( $( "#company_checkbox:checked" ).length === 0 /*&& ( $( "#donor_address_country" ).val() )*/ && $( "#donor_address_country" ).val().toLowerCase() === "gb" )
                {
                    // If the giftaid checkboxes are both false and we're here, invalid.
                    if ( $( "#gift_aid_yes:checked" ).length === 0 && $( "#gift_aid_no:checked" ).length === 0 )
                    {
                        $( "#giftaid_wrap" ).addClass( "not-valid" );
                        $( "#giftaid_wrap" ).trigger( "startRumble" );

                        setTimeout( () => 
                        {
                            $( "#giftaid_wrap" ).trigger( "stopRumble" );
                        }, VALIDATE_RUMBLE_MS )

                        validation_result = false;
                    }
                }
            }

            // If the validation returned true, we need to send the data to the server.
            if ( validation_result )
            {
                inputs.upload_user_data();
            }

            return validation_result;
        } );
    },

    create_amount_tippy: function ()
    {
        inputs.last_tippy = tippy( $( "#enter_amount" ).get( 0 ),
        {
            content: "Please enter a value between 1 and 999999",
            showOnCreate: true,
            hideOnClick: true,
        } );

        $( "#enter_amount-wrap" ).jrumble();

        $( "#enter_amount-wrap" ).trigger( "startRumble" );

        setTimeout( () => 
        {
            $( "#enter_amount-wrap" ).trigger( "stopRumble" );
        }, VALIDATE_RUMBLE_MS );
    },

    create_password_mismatch_tippy: function ()
    {
        inputs.last_tippy = tippy( $( "#donor_password_new_confirm" ).get( 0 ),
        {
            content: "Passwords do not match",
            showOnCreate: true,
            hideOnClick: true,
        } );
    },

    donor_details_validation: function ( just_validate = false )
    {
        var validation_result = true;

        for ( let i in VALIDATE_REQUIRED_FIELDS )
        {
            const val = VALIDATE_REQUIRED_FIELDS[ i ];
            const splt = val.split( ":" );

            if ( splt.length == 2 )
            {
                const type = splt[ 0 ];
                const selector = splt[ 1 ];

                const onBusinessAddressManual = ! $( "#business_address_entry_manual" ).hasClass( "hidden" );

                switch ( type )
                {
                case "_company":
                    if ( ! this.is_checkout && ! ( $( `html[data-seg-0="hifzsponsorship"][data-seg-1="donate"]` ).length ) )
                    {
                        if ( ! $( "#company_checkbox" ).prop( "checked" ) )
                        {
                            continue;
                        }

                        const $self = $( selector );
                        const $self_validator = $( $self.parents( ".validator" ).get( 0 ) );

                        if ( $self.val().replace( /\s+/g, "" ).length !== 0 )
                        {
                            if ( ! just_validate ) inputs.is_valid( $self_validator );
                        }
                        else
                        {
                            if ( ! just_validate ) inputs.not_valid( $self_validator, true );

                            // Test fix.
                            if ( ( selector === "#business_address" ) && onBusinessAddressManual )
                            {
                                continue;
                            }

                            validation_result = false;

                            if ( ! just_validate )
                            {
                                // Failure to validate the company address should result in showing the full company form.
                                $( "#manual_address_business" ).trigger( "click" );
                            }
                        }
                    }

                    break;

                default: continue;
                }
            }
            else
            {
                var $self = $( val );
                var $self_validator = $( $self.parents( ".validator" ).get( 0 ) );
                var vvvvv = $self.val();
                var was_updated = false;

                if ( ! $self_validator.length && $self.hasClass( "cr-field-select" ) )
                {
                    $self_validator = $self;
                }

                var email_validate = val.includes("donor_email") ? vl_util.validate_email_address( $self.val() ) : true;

                if ( vvvvv && vvvvv.replace( /\s+/g, "" ).length !== 0 && email_validate )
                {
                    if ( ! just_validate )
                    {
                        inputs.is_valid( $self_validator );
                    }
                }
                else
                {
                    if ( ! just_validate ){
                        inputs.not_valid( $self_validator, true );
                    } 
                    validation_result = false;
                    was_updated = true;
                }

                if ( val.startsWith( "#donor_address_" ) && ! just_validate && was_updated && ! validation_result )
                {
                    inputs.address_mode( true );
                }
            }
        }

        const donor_address_country = $( "#donor_address_country" ).val();

        if ( ! inputs.is_checkout && ! vl_util.site_config().isMalaysia && ! ( $( `html[data-seg-0="hifzsponsorship"][data-seg-1="donate"]` ).length ) )
        {
            if ( $( "#company_checkbox:checked" ).length === 0 && donor_address_country && donor_address_country.toLowerCase() === "gb" )
            {
                if ( ! window.is_event )
                {
                    if ( ! $( "#gift_aid_yes:checked" ).length && ! $( "#gift_aid_no:checked" ).length )
                    {
                        validation_result = false;
                    }
                }
            }
        }

        // Only validate the phone number if there's a value, otherwise we don't care since it's an optional field.
        if ( $( "#donor_phone" ).val().replace( /\s+/g, "" ).length )
        {
            const phone_error = inputs.telephone.getValidationError();

            // IS_POSSIBLE || IS_POSSIBLE_LOCAL_ONLY
            if ( phone_error === 0 || phone_error === 4 )
            {
                if ( ! just_validate ) inputs.is_valid( $( "#donor_phone" ).parents( ".validator" ) );
            }
            else
            {
                if ( ! just_validate ) inputs.not_valid( $( "#donor_phone" ).parents( ".validator" ), true );
                validation_result = false;
            }
        }

        return validation_result;
    },

    /**
     * Sorts out the user data and uploads it all.
     */
    upload_user_data: function ()
    {
        const title =           $( "#donor_title" ).val();
        const first_name =      $( "#donor_first_name" ).val();
        const last_name =       $( "#donor_last_name" ).val();
        const email =           $( "#donor_email" ).val();
        const mobile =          inputs.telephone.getNumber();
        const st_num =          $( "#donor_address_st_num" ).val();
        const street =          $( "#donor_address_street" ).val();
        const town =            $( "#donor_address_town" ).val();
        const county =          $( "#donor_address_county" ).val();
        const postal_code =     $( "#donor_address_postal_code" ).val();
        const country_code =    $( "#donor_address_country" ).val();
        const gift_aid_status = $( "#gift_aid_yes:checked" ).length > 0;

        const user_data = 
        {
            title:              title,
            first_name:         first_name,
            last_name:          last_name,
            email:              email,
            mobile:             mobile,
            address: 
            {
                st_num:         st_num,
                street:         street,
                town:           town,
                county:         county,
                postal_code:    postal_code,
                
            },
            country_code:       country_code,
            currency_code:      donation.get_currency_code(),
            gift_aid_status:    gift_aid_status ? "1" : "0"
        };

        if ( $( `html[data-seg-0="hifzsponsorship"][data-seg-1="donate"]` ).length )
        {
            user_data.is_zakat = $( "#zakat_checkbox" ).prop( "checked" ) ? "true" : "false";
            user_data.hifz_uuid = window.hifz_id;
        }

        // Send the validated data to server.
        donate_stripe.save_user_data( user_data );
    },

    is_valid: function ( $element )
    {
        $element.addClass( "valid" ).removeClass( "not-valid" );
        $element.find( "fa-check" ).removeClass( "hidden" );
        $element.find( "fa-times" ).addClass( "hidden" );  
    },

    not_valid: function ( $element, doRumble = false )
    {
        $element.removeClass( "valid" ).addClass( "not-valid" );
        $element.find( "fa-check" ).addClass( "hidden" );
        $element.find( "fa-times" ).removeClass( "hidden" );

        if ( doRumble )
        {
            $element.trigger( "startRumble" );

            setTimeout( () => 
            {
                $element.trigger( "stopRumble" );
            }, VALIDATE_RUMBLE_MS );
        }
    },

    unknown_valid: function( $element )
    {
        $element.removeClass( "valid not-valid" );
        $element.find( "fa-check" ).addClass( "hidden" );
        $element.find( "fa-times" ).addClass( "hidden" );
    },

    refresh_donation_level( ignoreDefault = false )
    {
        var selected = $( "#project_name" ).find( ":selected" );
        var value = selected.text();
        $( "#project_selected" ).html( value );

        const donationMinimum   = Number( selected.data( "minimum-donation" ) );
        const donationDefault   = Number( selected.data( "default-donation" ) );
        const donationStep      = Number( selected.data( "step" ) );
        const allow_one_off     = selected.data( "allow_one_off" );
        const allow_reoccurring = selected.data( "allow_reoccurring" );
        const allow_zakat       = selected.data( "allow_zakat" );

        if ( ( donationMinimum !== NaN ) && ( donationMinimum !== null ) && ( donationMinimum !== undefined ) )
        {
            // Sets up the donation minimum amount.
            $( "#enter_amount" ).prop( "min", donationMinimum );

            if ( Number( $( "#enter_amount" ).val() ) < donationMinimum )
            {
                // Set the amount.
                $( "#enter_amount" ).val( donationMinimum );

                // Run all the hooks.
                $( "#enter_amount" ).trigger( "input" );
            }
        }

        // Set donation step amount.
        if ( ( donationMinimum !== NaN ) && ( donationMinimum !== null ) && ( donationMinimum !== undefined ) )
        {
            $( "#enter_amount" ).prop( "min", donationMinimum );
        }
        else
        {
            $( "#enter_amount" ).prop( "min", 1 );
        }

        // Set donation step amount.
        if ( ( donationStep !== NaN ) && ( donationStep !== null ) && ( donationStep !== undefined ) )
        {
            $( "#enter_amount" ).prop( "step", donationStep );
        }
        else
        {
            $( "#enter_amount" ).prop( "step", 1 );
        }

        // If project ID (donation type ID) is 17, then we MUST force the donation amount to be a multiple of 75.
        if ( $( "#project_name" ).val() == "17" )
        {
            try
            {
                let val = parseFloat( $( "#enter_amount" ).val() );

                if ( ( ( val % 75 ) != 0 ) || ( val < 37.5 ) )
                {
                    let newVal = Math.round( val / 75 ) * 75;
                    
                    if ( newVal < 75 )
                    {
                        newVal = 75;
                    }

                    $( this ).val( newVal );
                    inputs.update_totals();
                }
            }
            catch(_){}
        }

        // When we are allowed Zakat..
        if ( allow_zakat )
        {
            // Unhide the parent checkbox if it's not already.
            $( "#zakat_wrap" ).removeClass( "hidden" );
        }
        else // otherwise...
        {
            // Hide the parent checkbox
            $( "#zakat_wrap" ).addClass( "hidden" );

            // Uncheck the zakat box by force.
            $( "#zakat_checkbox" ).prop( "checked", false );
        }

        $( "#type-one-off" ).removeClass( "hidden" );
        $( "#type-recurring" ).removeClass( "hidden" );

        // When we are allowed one-off donations..
        if ( allow_one_off )
        {
            // Unhide the one-off box.
            $( "#type-one-off" ).removeClass( "hidden" );
        }
        else // otherwise
        {
            // Ensure the other type is clicked
            $( "#type-recurring" ).trigger( "click" ).addClass( "active" );

            // Hide this type.
            $( "#type-one-off" ).addClass( "hidden" );
        }

        // When we are allowed reoccurring donations..
        if ( allow_reoccurring ) 
        {
            // Un-hide the reoccurring box.
            $( "#type-recurring" ).removeClass( "hidden" );
        }
        else
        {
            // Ensure the other type is clicked
            $( "#type-one-off" ).trigger( "click" ).addClass( "active" );

            // Hide this type.
            $( "#type-recurring" ).addClass( "hidden" );

            // .. And hide the other.
            $( "#type-one-off" ).addClass( "hidden" );
        }

        if ( ! ignoreDefault )
        {
            if ( ! Number.isNaN( donationDefault ) && ( !! donationDefault ) )
            {
                $( "#enter_amount" ).val( donationDefault );
            }
        }
    }
}

