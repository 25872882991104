import $                    from "jquery";
import jQuery               from "jquery";

function toggleBody() {
    jQuery('body').toggleClass( "h-full overflow-hidden relative");
}

function rotateChevron() {
  jQuery('.donation_total_toggle i').toggleClass( "fa-chevron-down fa-chevron-up");
  jQuery('#donation_title_price').toggleClass('opacity-0 opacity-100'); // same time just hide the title price
  jQuery('#partial_donations_overlay').toggleClass('opacity-0 opacity-100'); // same time just hide partial donations overlay

  $( "#popup-donation-down" ).toggleClass( "hidden" );
  $( "#popup-donation-up" ).toggleClass( "hidden" );
}


function toggleOverlayOpacity() {
  jQuery('#donations_overlay').toggleClass( "opacity-0 opacity-80");
}
function toggleOverlayVisibility() {
  jQuery('#donations_overlay').toggleClass( "invisible visible");
}

function toggleDonationsBoxOn() {
  jQuery('#donations_total_box_inner').slideDown();
}

function toggleDonationsBoxOff() {
  jQuery('#donations_total_box_inner').slideUp();
}

// open the nav button
var clicks = 0;

jQuery( function ( $ )
{
    jQuery(".donation_total_toggle").on("click", function () {
        if (clicks == 0) {
          setTimeout(toggleBody);
          setTimeout(rotateChevron);
          setTimeout(toggleOverlayVisibility);
          setTimeout(toggleOverlayOpacity, 200);
          setTimeout(toggleDonationsBoxOn);
      
          clicks++;
        } else {
          setTimeout(toggleBody, 400);
          setTimeout(rotateChevron);
          setTimeout(toggleOverlayOpacity);
          setTimeout(toggleOverlayVisibility, 200);
          setTimeout(toggleDonationsBoxOff);
      
          clicks--;
        }
      });
} );