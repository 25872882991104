import { vl_util } from "../general/util";
import QuranStudent from "./quran-student";
import lightGallery from "lightgallery";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgVideo from "lightgallery/plugins/video";
import lgFullscreen from "lightgallery/plugins/fullscreen";
import lgAutoplay from "lightgallery/plugins/autoplay";
import Flickity from "flickity";
import "flickity-imagesloaded";
import jrumble from "../../vendor/jrumble/jrumble";
import LazyLoad from "vanilla-lazyload";
import { VL_HHH_Video } from "../modals/hhh-video";
import $ from "jquery";
import jQuery from "jquery";

/**
 * The quran challenge controller.
 */
export default class QuranChallengeController
{
    /**
     * App container for quran challenge.
     * 
     * @var {JQuery<HTMLDivElement>} appContainer
     */
    appContainer = $();

    /**
     * Buttons to go forwards in the flow.
     * 
     * @var {JQuery<HTMLButtonElement>} backButtons
     */
    forwardButtons = $();

    /**
     * Buttons to go backwards in the flow.
     * 
     * @var {JQuery<HTMLButtonElement>} backButtons
     */
    backButtons = $();

    /**
     * For displaying the students to the user.
     * 
     * @var {JQuery<HTMLDivElement>} studentsListView
     */
    studentsListView = $();

    /**
     * For displaying the schools to the user.
     * 
     * @var {JQuery<HTMLDivElement>} schoolsListView
     */
    schoolsListView = $();

    /**
     * Display of students.
     * 
     * @var {JQuery} studentCards
     */
    studentCards = $();

    /**
     * Current quran challenge step.
     * 
     * @var {String} currentPage
     */
    currentPage = "choose-country";

    /**
     * Step containers, loaded on ready.
     * @var {Object<String,JQuery<HTMLDivElement>>} stepContainers
     */
    stepContainers = {};

    /**
     * List of quran challenge students (HTML code + data attributes)
     * 
     * @var {String} loadedStudents
     */
    loadedStudents = {};

    /**
     * The youtube video popup.
     * 
     * @var {VL_HHH_Video} videoPopup
     */
    videoPopup = null;

    selectedCountryId   = null;
    selectedSchoolId    = null;
    selectedStudents    = [];

    /**
     * Defines the step flow.
     */
    stepFlow = [
        "choose-country",
        "choose-school",
        "choose-children",
        "choose-payment"
    ];

    /**
     * Object containing arrays of hooks.
     * 
     * @var {Object} flowHooks
     */
    flowHooks = {};

    /**
     * Will contain the most recent redirect link for the quran programme flow.
     * 
     * @var {String} flowRedirect
     */
    flowRedirect = null;

    /**
     * Are we on the donate flow?
     * 
     * @var {Boolean} isDonateFlow
     */
    isDonateFlow = false;
    
    /**
     * Quran challenge controller constructor
     */
    constructor ( isDonateFlow = false )
    {
        jrumble.jrumble( $ );
        jrumble.jrumble( jQuery );

        this.isDonateFlow = isDonateFlow;

        // Get the elements.
        this.appContainer       = $( "#quran-challenge-container" );
        this.studentsListView   = $( "#quran-students-list" );
        this.schoolsListView    = $( "#quran-schools-list" );
        this.forwardButtons     = $( ".quran-challenge-forward" );
        this.backButtons        = $( ".quran-challenge-back" );
        
        // Forward buttons.
        this.forwardButtons     .on( "click", this.onClickForward.bind( this ) );
        
        // Back buttons
        this.backButtons        .on( "click", this.onClickBack.bind( this ) );

        // Get the containers for each of the steps.
        this.stepContainers[ this.currentPage ]     = $( "#choose-country" );
        this.stepContainers[ "choose-school" ]      = $( "#choose-school" );
        this.stepContainers[ "choose-children" ]    = $( "#choose-children" );
        this.stepContainers[ "choose-payment" ]     = $( "#choose-payment" );

        this.videoPopup = new VL_HHH_Video();

        if ( isDonateFlow )
        {
            // Need to load pre-set data.
            this.selectedCountryId  = $( ".qc-country-card.active" ).data( "country-id" );
            this.selectedSchoolId   = $( ".qc-school-card.active" ).data( "school-id" );

            // The auto-select the payment step.
            this.setStep( "choose-payment" );
        }
        else
        {
            this.setStep( "choose-country" );
        }

        if ( this.isDonateFlow )
        {
            this.addFlowHook( "choose-children", "choose-school", function ()
            {
                if ( this.selectedCountryId == "-1" )
                {
                    this.setStep( "choose-country" );
                }
            } );
        }

        if ( $( ".qc-payment-type.active[for=\"qc-payment-type-reocurring\"]" ).length )
        {
            $( ".quran-challenge-total-recurring" ).removeClass( "hidden" );
            $( ".quran-challenge-total-oneoff" ).addClass( "hidden" );
        }
        else
        {
            $( ".quran-challenge-total-recurring" ).addClass( "hidden" );
            $( ".quran-challenge-total-oneoff" ).removeClass( "hidden" );
        }

        this.addFlowHook( "choose-country", "choose-school", function ()
        {
            $( ".child-string-non-selected" ).removeClass( "hidden" );
            $( ".child-string-selected" ).addClass( "hidden" );

            // Set on loading screen.
            this.setLoading( true );
            
            // Clear schools list.
            this.schoolsListView.html( "" );

            this.selectedCountryId = this.getSelectedCountry();

            // Apply the country name to the cards title.
            $( "#qc-country-name" ).text( this.getSelectedCountryName() );

            // Country ID of -1 means where most needed, which means we want a random selection of students and don't care about the school.
            if ( this.selectedCountryId == "-1" )
            {
                // Force school ID to -1.
                this.selectedSchoolId = -1;

                this.loadStudents( -1, this.resolveLoadedStudentIds() ).then( ( response ) => 
                {
                    if ( ! response.success )
                    {
                        alert( response.error );
                        return;
                    }

                    var newHtml = "";

                    for ( let idx in response.students )
                    {
                        newHtml += response.students[ idx ];
                    }

                    this.studentsListView.html( newHtml );
                    this.bindStudents();
                    this.setLoading( false );

                    this.setStep( "choose-children", true );
                } );
            }
            else
            {
                // Load schools over network asynchronously.
                this.loadSchools( this.selectedCountryId ).then( ( response ) => 
                {
                    if ( ! response.success )
                    {
                        alert( response.error );
                        return;
                    }

                    var newHtml = "";

                    for ( let idx in response.schools )
                    {
                        newHtml += response.schools[ idx ];
                    }

                    this.schoolsListView.html( newHtml );
                    this.bindSchools();
                    $( ".qc-school-card[data-school-id=-1]" ).trigger( "click" )
                    this.setLoading( false );
                } );
            }
        } );

        // Add a hook for loading children.
        this.addFlowHook( "choose-school", "choose-children", function ()
        {
            // Set loading state.
            this.setLoading( true );   

            // Clear anything previous.
            this.studentsListView.html( "" );

            this.selectedSchoolId = this.getSelectedSchool();

            this.loadStudents( this.getSelectedSchool() ).then( ( response ) => 
            {
                if ( ! response.success )
                {
                    alert( response.error );
                    return;
                }

                var newHtml = "";

                for ( let idx in response.students )
                {
                    newHtml += response.students[ idx ];
                }

                this.studentsListView.html( newHtml );
                this.bindStudents();
                this.setLoading( false );
            } );
        } );

        // Hook for moving between the children choosing and choosing a payment method, generates payment stuff in this step.
        this.addFlowHook( "choose-children", "choose-payment", function ()
        {
            this.setLoading( true );
            
            this.selectedStudents = this.getSelectedStudents();

            this.storeQuranChallengeData( this.selectedCountryId, this.selectedSchoolId, this.selectedStudents ).then( ( ( response ) => 
            {
                if ( ! response.success )
                {
                    alert( response.error );

                    return;
                }

                if ( response.totals )
                {
                    $( "#qc-price-one-off" ).html( response.totals.oneoff );
                    $( "#qc-price-reocurring" ).html( response.totals.reocurring );
                }

                if ( response.n_students )
                {
                    $( ".qc-child-count" ).html( response.n_students ).prop( "data-n-children", response.n_students );

                    if ( response.n_students == 1 )
                    {
                        $( ".children-ren" ).addClass( "hidden" );
                    }
                    else
                    {
                        $( ".children-ren" ).removeClass( "hidden" );
                    }
                }

                if ( response.cart_html && $( "#qc-cart-inner" ).length )
                {
                    $( "#qc-cart-inner" ).html( response.cart_html );
                }

                if ( response.giftaid_total_rc || response.giftaid_total_oo )
                {
                    $( ".dv2-total-giftaid.quran-challenge-total-oneoff" ).text( response.giftaid_total_oo );
                    $( ".dv2-total-giftaid.quran-challenge-total-recurring" ).text( response.giftaid_total_rc + "/mo" );
                }

                // Store the redirect URL.
                this.flowRedirect = response.redirect_url;

                // Set loading false.
                this.setLoading( false );

                // If one is already selected, click on it to keep the payment type.
                $( ".qc-payment-type.active" ).trigger( "click" )
            } ).bind( this ) );
        } );

        this.addFlowHook( "choose-school", "choose-children", function ()
        {
            const nSelectedStudents = $( ".quran-student-card-select:checked" ).length;

            if ( nSelectedStudents )
            {
                $( ".child-string-non-selected" ).addClass( "hidden" );
                $( ".child-string-selected" ).removeClass( "hidden" );
            }
            else
            {
                $( ".child-string-non-selected" ).removeClass( "hidden" );
                $( ".child-string-selected" ).addClass( "hidden" );
            }
        } );

        $( "html[data-seg-0=\"hifzsponsorship\"][data-route=\"dv2.quran-challenge\"] #complete-donation" ).on( "click", ( function ()
        {
            const type = $( `[name=qc-payment-type]:checked` ).val();
            
            window.location.href = this.flowRedirect + "?donation_type=" + type;
        } ).bind( this ) );

        // hifz country active state.
        $( "[name=hifz_country]" ).on( "input", function () 
        {
            $( ".qc-country-card" ).removeClass( "active" );
            $( ".qc-country-card[data-country-id=\"" + $( this ).val() + "\"]" ).addClass( "active" );
        } );

        $( "html[data-seg-0=\"hifzsponsorship\"] .qc-payment-type" ).on( "click", function () 
        {
            $( "#complete-donation" ).prop( "disabled", false );    
            $( ".qc-payment-type" ).removeClass( "active" );
            $( this ).addClass( "active" );  
        } );

        $( ".child-string-non-selected" ).jrumble();

        $( "#children-to-payment" ).on( "click", function ( e ) 
        {
            const nSelectedStudents = $( ".quran-student-card-select:checked" ).length;

            if ( ! nSelectedStudents )
            {
                $( ".child-string-non-selected" ).trigger( "startRumble" );

                setTimeout( () => 
                {
                    $( ".child-string-non-selected" ).trigger( "stopRumble" );
                }, 150 );
            }
        } );

        const $quranChallengeFlickity = $( ".quran-challenge-flkty" );
        const $successStoriesFlickity = $( ".success-stories-flkty");
        const $quranChallengeLightGal = $( "#lightgallery" );

        if ( $quranChallengeFlickity.length ) 
        {
            const quran_challenge_flkty = new Flickity( $quranChallengeFlickity.get( 0 ), {
                cellAlign: "left",
                contain: true,
                pageDots: false,
                imagesLoaded: true,
                prevNextButtons: false,
                lazyLoad: true,
            } );

            $( ".qc-flick-what" ).on( "click", () => quran_challenge_flkty.select( 0 ) );
            $( ".qc-flick-why" ).on( "click", () => quran_challenge_flkty.select( 1 ) );
            $( ".qc-flick-how" ).on( "click", () => quran_challenge_flkty.select( 2 ) );

            $("#prev-slide-btn").on("click", () => quran_challenge_flkty.previous());
            $("#next-slide-btn").on("click", () => quran_challenge_flkty.next());
        }

        if($successStoriesFlickity.length)
        {
            const success_stories_flkty = new Flickity($successStoriesFlickity.get(0), {
                cellAlign: "left",
                contain: true,
                pageDots: false,
                imagesLoaded: true,
                prevNextButtons: false
            });
            if(success_stories_flkty.slides.length > 1) {
                $("#ss-btns").toggleClass("hidden flex");
                
                $("#ss-prev-slide-btn").on("click", () => success_stories_flkty.previous());
                $("#ss-next-slide-btn").on("click", () => success_stories_flkty.next());
            };

            
        }

        if ( $quranChallengeLightGal.length ) 
        {
            const lg = lightGallery( $quranChallengeLightGal.get( 0 ), 
            {
                plugins:        [ lgThumbnail, lgVideo, lgFullscreen, lgAutoplay ],
                mobileSettings: { controls: true },
                pager:          false,
                zoomFromOrigin: true,
                animateThumb:   true,
                thumbnail:      true,
                download:       false,
                selector:       ".lightgallery-item",
                licenseKey:     "09AFC435-3656490E-B49C0C78-31D9789B"
            } );

            $( "#openGallery" ).on( "click", function () 
            {
                lg.openGallery();
            } );
        }

        $(document).on('click', 'a[href^="#"]', function (event) {
            event.preventDefault();
        
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - 100
            }, 500);
        });

        var lazy = new LazyLoad();
        //lazy.update();

        $("#more-faq-toggle").on('click', function() {
            $("#more-faq-section").toggleClass("h-0");
            const text = $("#more-faq-toggle").text();
            $("#more-faq-toggle").text(text == "More FAQ's" ? "Hide FAQ's" : "More FAQ's");
            console.log("working");
        });


        this.bindCountries();
    }

    bindCountries()
    {
        $( `[name="hifz_country"]` ).on( "click", function ()
        {
            $( "#country-to-school" ).removeClass( "btn-disabled" ).addClass( "btn-active" ).prop( "disabled", false );
        } );
    }

    bindSchools()
    {
        $( `[name="hifz_school"]` ).on( "click", function ()
        {
            $( "#school-to-children" ).removeClass( "btn-disabled" ).addClass( "btn-active" ).prop( "disabled", false );
        } );

        // hifz school active state.
        $( "[name=hifz_school]" ).on( "input", function ()
        {
            $( ".qc-school-card" ).removeClass( "active" );
            $( ".qc-school-card[data-school-id=\"" + $( this ).val() + "\"]" ).addClass( "active" );
        } );
    }

    /**
     * Bind functionality which only exists as it has been downloaded from the server.
     */
    bindStudents()
    {
        // Disable the button, so we can't click continue with no students selected.
        // $( "#children-to-payment" ).prop( "disabled", true );

        $( ".quran-student-card-select" ).off( "input" ).on( "input", ( function ( e )
        {
            const nSelectedStudents = $( ".quran-student-card-select:checked" ).length;

            if ( nSelectedStudents )
            {
                // $( "#children-to-payment" ).removeClass( "btn-disabled" ).addClass( "btn-active" ).prop( "disabled", false );
                $( ".child-string-non-selected" ).addClass( "hidden" );
                $( ".child-string-selected" ).removeClass( "hidden" );
            }
            else
            {
                // $( "#children-to-payment" ).removeClass( "btn-active" ).addClass( "btn-disabled" ).prop( "disabled", true );
                $( ".child-string-non-selected" ).removeClass( "hidden" );
                $( ".child-string-selected" ).addClass( "hidden" );
            }

            $( ".children-count" ).text( nSelectedStudents === 1 ? "1 child" : `${nSelectedStudents} children` );

            if ( $( e.target ).prop( "checked" ) ) 
            {
                $( ".qc-student[data-student-id=\"" + $( e.target ).data( "student-id")  + "\"]" ).addClass( "active" );
            }
            else
            {
                $( ".qc-student[data-student-id=\"" + $( e.target ).data( "student-id")  + "\"]" ).removeClass( "active" );
            }
        } ).bind( this ) );

        $( "#children-load-more" ).off( "click" ).on( "click", this.onClickLoadMoreStudents.bind( this ) );
    }

    /**
     * Called when user is attempting to load more students.
     */
    onClickLoadMoreStudents()
    {
        this.loadStudents( this.selectedSchoolId ?? -1, this.resolveLoadedStudentIds() ).then( ( response ) => 
        {
            if ( ! response.success )
            {
                alert( response.error );
                return;
            }

            var newHtml = this.studentsListView.html();

            for ( let idx in response.students )
            {
                newHtml += response.students[ idx ];
            }

            this.studentsListView.html( newHtml );
            this.bindStudents();
        } );
    }

    /**
     * Attempts to find the array of student IDs which are already loaded, by looking at the page.
     * 
     * @return {Number[]} The IDs.
     */
    resolveLoadedStudentIds()
    {
        var studentIds = [];

        $( ".qc-student[data-student-id]" ).each( (i,e) => 
        {
            studentIds.push( $( e ).data( "student-id" ) );
        } );

        return studentIds;
    }

    /**
     * Adds a new hook for when the state of the flow changes.
     * 
     * @param {String}      from    The state we're coming from.
     * @param {String}      to      The state we're going to.
     * @param {Function}    fn      The callback to call when it happens.
     */
    addFlowHook( from, to, fn )
    {
        // Generate the known hook name from the froms and tos.
        const hookName = `${from}_${to}`;

        // If the hook hasn't been used before it needs an entry in the hooks list.
        if ( ! this.flowHooks[ hookName] )
        {
            this.flowHooks[ hookName ] = [];
        }

        // Add the hook to the hook list.
        this.flowHooks[ hookName ].push( fn );
    }

    /**
     * Called when a forward button is clicked.
     * 
     * @var {MouseEvent} event
     */
    onClickForward( event )
    {
        event.preventDefault();

        const nextPage = this.nextStep();

        if ( this.currentPage === "choose-children" && nextPage === "choose-payment" )
        {
            const nSelectedStudents = $( ".quran-student-card-select:checked" ).length;
            
            if ( nSelectedStudents === 0 )
            {
                return this;
            }
        }

        this.setStep( nextPage );
    }

    /**
     * Called when a backward button is clicked.
     * 
     * @var {MouseEvent} event
     */
    onClickBack( event )
    {
        event.preventDefault();

        const previousPage = this.previousStep();

        if ( this.selectedCountryId == -1 && previousPage == "choose-school" )
        {
            this.setStep( "choose-country" );
        }
        else
        {
            this.setStep( previousPage );
        }
    }

    /**
     * Sets the current step.
     * 
     * @param {String} stepName 
     * @param {Boolean} disableHooks Disable executing hooks?
     * @return {QuranChallengeController} Instance of this.
     */
    setStep( stepName, disableHooks = false )
    {
        if ( stepName === this.currentPage )
        {
            return this;
        }

        // Hide all of the cards.
        $( ".quran-challenge-card" ).addClass( "hidden" );

        // Unhide the one we're targeting.
        $( `.quran-challenge-card[data-step-name="${stepName}"]` ).removeClass( "hidden" );

        if ( ! disableHooks )
        {
            const hookedActionName = `${this.currentPage}_${stepName}`;

            if ( this.flowHooks[ hookedActionName ] )
            {
                this.flowHooks[ hookedActionName ].forEach( ( ( fn ) => { fn.bind( this )(); } ).bind( this ) );
            }
        }

        this.currentPage = stepName;

        return this;
    }
    
    /**
     * Gets the name of the next step.
     * 
     * @return {String}
     */
    nextStep()
    {
        for ( let idx in this.stepFlow )
        {
            const idxN = new Number( idx );

            if ( this.currentPage === this.stepFlow[ idx ] )
            {
                return this.stepFlow[ ( ( idxN + 1 ) > this.stepFlow.length ) ? idxN : ( idxN + 1 ) ];
            }
        }

        return this.currentPage;
    }

    /**
     * Gets the name of the previous step.
     * 
     * @return {String}
     */
    previousStep()
    {
        for ( let idx in this.stepFlow )
        {
            const idxN = new Number( idx );

            if ( this.currentPage === this.stepFlow[ idx ] )
            {
                return this.stepFlow[ ( idxN - 1 ) === -1 ? idxN : ( idxN - 1 ) ];
            }
        }

        return this.currentPage;
    }

    /**
     * Asynchronously loads students from a specified school
     * 
     * @param {Number} schoolId
     * @param {Number[]|null} alreadyLoaded List of already loaded student IDs, to be excluded from the query.
     */
    async loadStudents( schoolId = null, alreadyLoaded = null )
    {
        var data = 
        {
            _token:         vl_util.csrf_token(),
            schoolId:       schoolId,
            alreadyLoaded:  alreadyLoaded
        };

        const result = await $.ajax(
        {
            url:        vl_util.site_url() + "/api/v1/quran-challenge/students",
            type:       "post",
            dataType:   "json",
            data: data
        } );

        return result;
    }

    /**
     * Loads schools asynchronously.
     * 
     * @param {Number} countryId 
     */
    async loadSchools( countryId = null )
    {
        var data = 
        {
            _token:     vl_util.csrf_token(),
            countryId:   countryId ?? -1
        };

        const result = await $.ajax(
        {
            url:        vl_util.site_url() + "/api/v1/quran-challenge/schools",
            type:       "post",
            dataType:   "json",
            data: data
        } );

        return result;
    }

    /**
     * 
     * @param {*} countryId 
     * @param {*} schoolId 
     * @param {*} students 
     * @returns 
     */
    async storeQuranChallengeData( countryId, schoolId, students )
    {
        var data = 
        {
            _token:     vl_util.csrf_token(),
            countryId:  countryId ?? -1,
            schoolId:   schoolId ?? -1,
            students:   students ?? [],
            modify:     window.hifz_id ? window.hifz_id : "create"
        };

        const result = await $.ajax(
        {
            url:        vl_util.site_url() + "/api/v1/quran-challenge/store",
            type:       "post",
            dataType:   "json",
            data: data
        } );

        return result;
    }

    /**
     * Push (a/the) list of quran students to the screen.
     * 
     * @param {QuranStudent[]|null} inStudents 
     */
    pushStudentsToView( inStudents = null )
    {
        // Wipe the current HTML data.
        this.studentsListView.html( "" );

        // List students.
        const students = inStudents ?? this.loadedStudents;

        // Iterate through students and show.
        students.forEach( ( ( student ) => 
        {
            this.studentsListView.append( student.html() );
        } ).bind( this ) );
    }

    /**
     * Loads the students which are currently selected on the choose student view.
     * 
     * @return {QuranStudent[]}
     */
    selectedStudents()
    {
        const $students = $( ".quran-student-card-select:checked" );

        var outStudentsList = [];

        $students.each( ( ( idx, element ) =>
        {
            outStudentsList.push( this.loadedStudents[ $( element ).data( "student-id" ) ] );  
        } ).bind( this ) )

        return outStudentsList;
    }

    /**
     * Sets if the loading mask is showing.
     * 
     * @param {Boolean} isLoading 
     */
    setLoading( isLoading )
    {
        if ( isLoading )
        {
            $( ".quran-challenge-card" ).addClass( "hidden" );
            $( ".quran-challenge-card#quran-challenge-loading" ).removeClass( "hidden" );
        }
        else
        {
            $( ".quran-challenge-card" ).addClass( "hidden" );
            $( `.quran-challenge-card[data-step-name="${this.currentPage}"]` ).removeClass( "hidden" );
        }
    }

    /**
     * Gets the currently selected country ID.
     * 
     * @return {String} Country ID
     */
    getSelectedCountry()
    {
        return $( `[name="hifz_country"]:checked` ).val();
    }

    /**
     * Gets the currently selected countries name from it's data.
     * 
     * @return {String}
     */
    getSelectedCountryName()
    {
        return $( `[name="hifz_country"]:checked` ).data( "country-name" );
    }

    /**
     * Gets the currently selected school ID.
     * 
     * @return {String} School ID.
     */
    getSelectedSchool()
    {
        return $( `[name="hifz_school"]:checked` ).val();
    }

    /**
     * Returns list of currently selected student IDs.
     * 
     * @return {Number[]}
     */
    getSelectedStudents()
    {
        const $students = $( ".quran-student-card-select:checked" );

        var outStudentsList = [];

        $students.each( ( ( idx, element ) =>
        {
            outStudentsList.push( $( element ).data( "student-id" ) );  
        } ).bind( this ) );

        return outStudentsList;   
    }
}