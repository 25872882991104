import intlTelInput from "intl-tel-input";
import { inputs } from "../donations/inputs";
import StepOverHungerController from "../step-over-hunger/StepOverHungerController";
import LazyLoad from "vanilla-lazyload";
import { FFPartnerModal } from "../modals/ff-partner";

export default class FoodFridaysIndexController
{
    telephone = null;
    
    constructor() 
    {
        const contact_no = $( "#contact_number" ).get( 0 );

        if ( contact_no )
        {
            // If the element is valid, initialise International Telephone Input
            this.telephone = intlTelInput( contact_no, 
            {
                // Util script for extra features.
                utilsScript: "/app/assets/js/vendor/intl-tel-input/utils.js",

                // Placeholder should force override always.
                autoPlaceholder: "aggressive",

                // Automatically format.
                formatOnDisplay: true,

                // We want the country to be automated based on location.
                initialCountry: "auto",

                // Fixes the phone number strings.
                nationalMode: false,

                // Geoip lookup func.
                geoIpLookup: inputs.geoip_lookup,

                // Hidden input for actual full phone number.
                hiddenInput: "donor_phone_full",

                // Initial country to UK even if geoIP lookup doesn't work.
                initialCountry: "GB"
            } );

            this.telephone.rawElement = contact_no;

            $( contact_no ).on( "focusout", StepOverHungerController.formatIntlTelInput.bind( this.telephone ), {passive: true});
        }

        var lazy = new LazyLoad();
        
        const partnerModal = new FFPartnerModal();

        $( `[data-target="#partnerModal"]` ).on( "click", partnerModal.showHideModal.bind( partnerModal ) );
    }
}