import $ from "jquery";
import { App } from "../../main";

/**
 * Quick donate frontend controller class.
 */
export class QuickDonateController
{
    /**
     * Constructor
     * 
     * @param {App} app
     */
    constructor ( app )
    {
        // Make sure the quick donate block actually appears on the page.
        if ( ! $( ".quick-donate" ).length )
        {
            return;
        }

        this.initialise();
    }   

    /**
     * Initialisation method, only called once verified we need this functionality.
     * 
     * @returns {undefined}
     */
    initialise()
    {
        // On donation level selected.
        $( ".qd-donation-level" ).on( "click", this.onClickDonationLevel.bind( this ) );

        // On modify the amounts.
        $( ".qd-amount" ).on( "input", this.onInputAmount.bind( this ) );

        // On select donation level.
        $( ".qd-donation-type" ).on( "click", this.onDonationTypeClicked.bind( this ) );
    }

    /**
     * Called on click to one of the donation level buttons.
     * 
     * @param {MouseEvent} event 
     */
    onClickDonationLevel( event )
    {
        // Check the event target.
        if ( ! event.target )
        {
            return;
        }

        var $target = $();

        // If the target is a button then we use that for data.
        if ( event.target instanceof HTMLButtonElement )
        {
            $target = $( event.target );
        }
        else
        {
            // Else we find it's parent (JS bug mitigation).
            $target = $( event.target ).parents( "button" );
        }

        // Get it's quick donate instance ID.
        const qdId = $target.data( "qd-id" );

        // Remove active class from all other buttons.
        $( `button.qd-donation-level[data-qd-id="${ qdId }"]` ).removeClass( "active" );

        // Add active class to this one.
        $target.addClass( "active" );

        // Set the value of the donation amount.
        var amount = $target.data( "amount" );

        // Not amazing code but better than NaN.
        if ( ( ! amount ) || ( ! Number( amount ) ) )
        {
            amount = 13;
        }

        // Set the donation amount.
        $( `#${ qdId }_amount` ).val( amount );        
    }

    /**
     * Called on input to the quick donate amount box.
     * 
     * @param {InputEvent} event 
     */
    onInputAmount( event )
    {
        // Check the event target.
        if ( ! event.target || ! ( event.target instanceof HTMLInputElement ) )
        {
            return;
        }
        
        // Get the quick donate ID.
        const qdId = $( event.target ).data( "qd-id" );
        const currentAmount = Number( $( event.target ).val() );

        $( ".qd-donation-level" ).removeClass( "active" );

        // Iterate through the quick donate buttons and click on the one which the value matches.
        $( `.qd-donation-level[data-qd-id="${ qdId }"]` ).each( function ( idx, element )
        {
            const amount = Number( $( element ).data( "amount" ) );

            if ( amount === currentAmount )
            {
                $( element ).trigger( "click" );
            }
        } );
    }

    /**
     * Called on click to the donation type "radio".
     * 
     * @param {MouseEvent} event 
     */
    onDonationTypeClicked( event )
    {
        // Check the event target.
        if ( ! event.target )
        {
            return null;
        }

        $( `.qd-donation-type-val[data-qd-id="${ $(event.target).data("qd-id") }"]` ).val( $( event.target ).data( "val" ) );

        $( ".qd-donation-type" ).removeClass( "active" );
        $( event.target ).addClass( "active" );
    }
}