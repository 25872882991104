import { vl_util } from "../general/util";
import { payment_flow } from "../donations/payment-flow";
import { inputs } from "../donations/inputs";
import { cards } from "../donations/card-toggles";
import { donate_stripe } from "../general/stripe";
import jrumble from "../../vendor/jrumble/jrumble";
import $                    from "jquery";
import jQuery               from "jquery";

/**
 * Controller for quran challenge donation flow.
 */
export default class QuranChallengeDonateController
{
    /**
     * Determines if this is a oneoff Hifz programme donation.
     * 
     * @var {Boolean} is_oneoff Oneoff?
     */
    is_oneoff = true;

    /**
     * Quran challenge controller
     * 
     * @var {QuranChallengeController|null} quranChallengeFlow
     */
    quranChallengeFlow = null;

    /**
     * Constructor
     * 
     * @var {QuranChallengeController|null} quranChallengeFlow
     */
    constructor ( quranChallengeFlow = null )
    {
        this.quranChallengeFlow = quranChallengeFlow;
        window.hhhDonate = this;

        // Be certain we're on the quran challenge payment flow.
        if ( ! $( `html[data-seg-0="hifzsponsorship"][data-seg-1="donate"]` ).length )
        {
            return;
        }

        this.is_oneoff = new URLSearchParams( window.location.search ).get( "donation_type" ) !== "reocurring";

        if ( this.is_oneoff )
        {
            $( "[for=qc-payment-type-one-off].qc-payment-type" ).trigger( "click" );
        }

        // Initialise the payment flow with base data.
        payment_flow.init();
        
        // Override the stripe redirect URL.
        payment_flow.overrideRedirectUrl( vl_util.site_url() + `/api/v1/quran-challenge/complete/stripe/{hifzDonationUUID}?payment_intent={paymentIntent}&payment_intent_client_secret={clientSecret}&redirect_status=succeeded` );

        payment_flow.set_known_unknown( $( "#known-cards-wrap" ).length > 0 );

        // Initialise the input validation.
        inputs.initialise_inputs( $, true );
        
        // Initialise the card-based flow.
        cards.initialise_cards( $ );

        // Add card hook for when the payment details are requested to be shown.
        cards.addHook( "on_show_payment_details", this.onShowPaymentDetails.bind( this ) );

        // Initialise the stripe system.
        donate_stripe.initialise_stripe();

        cards.addHook( "stripe_initialised", function ()
        {
            payment_flow.set_donation_processing( false );
            
            $( "#payment-details-waiting" ).addClass( "hidden" );
            $( "#payment-details-loaded" ).removeClass( "hidden" );
        } );

        if ( $( ".donation-type-selector.active#type-recurring" ).length > 0 )
        {
            $( "#type-recurring" ).trigger( "click" );   
        }

        donate_stripe.set_last_payment_intent( window.stripe_pi_id );
        payment_flow.force_stripe_data( window.stripe_cl_secret, window.stripe_pi_id, ( window?.subscription_id ) ?? null );
        payment_flow.set_known_unknown( $( "#known-cards-wrap" ).length > 0 )

        $( "#step_1_complete" ).trigger( "click" );

        if ( this.quranChallengeFlow )
        {
            this.quranChallengeFlow.bindStudents();
            this.quranChallengeFlow.bindSchools();
            this.quranChallengeFlow.bindCountries();
        }

        jrumble.jrumble( $ );
        jrumble.jrumble( jQuery );

        $( ".child-string-non-selected" ).jrumble();

        // Switch between one-off and recurring.
        $( "[for=qc-payment-type-one-off], [for=qc-payment-type-reocurring]" ).on( "click", function ()
        {
            $( "[for=qc-payment-type-one-off], [for=qc-payment-type-reocurring]" ).removeClass( "active" );
            $( this ).addClass( "active" );
            switch ( $( this ).prop( "for" ) )
            {
            case "qc-payment-type-one-off":
                $( ".quran-challenge-total-recurring" ).addClass( "hidden" );
                $( ".quran-challenge-total-oneoff" ).removeClass( "hidden" );
                break;

            case "qc-payment-type-reocurring":
                $( ".quran-challenge-total-oneoff" ).addClass( "hidden" );
                $( ".quran-challenge-total-recurring" ).removeClass( "hidden" );
                break;
            }
            
            $( this ).addClass( "active" );
        } );
    }
    /**
     * 
     */
    onShowPaymentDetails()
    {   
        this.is_oneoff = $( "[for=qc-payment-type-one-off].qc-payment-type.active" ).length > 0;

        $( "#paypal-step2-continue" ).removeClass( "hidden" );
        $( "#paypal-step2-loading" ).addClass( "hidden" );
        $( "#complete-state-inactive" ).text( `Complete ${ this.is_oneoff ? "Payment" : "Subscription" }` );

        payment_flow.set_card_initialised( false );
        payment_flow.set_is_checkout( true );
        payment_flow.set_is_one_off( true );
        
        if ( this.is_oneoff )
        {
            payment_flow.force_stripe_data( window.stripe_cl_secret, window.stripe_pi_id, ( window?.subscription_id ) ?? null );

            $( "#complete-donation" ).off( "click" ).on( "click", payment_flow.do_payment );
        }
        else
        {
            $( "#payment-details-waiting" ).removeClass( "hidden" );
            $( "#payment-details-loaded" ).addClass( "hidden" );
            this.createSubscription().then( this.onCreateSubscriptionSuccess.bind( this ) ).catch( this.onCreateSubscriptionFailed.bind( this ) );
        }
    }

    /**
     * Called when successfully set-up subscription for a user.
     * 
     * @param {Object} data 
     */
    onCreateSubscriptionSuccess( data )
    {
        payment_flow.force_stripe_data( data.paymentIntentSecret, data.paymentIntentId, data.subscriptionId );
        $( "#complete-donation" ).off( "click" ).on( "click", payment_flow.do_payment );
    }

    /**
     * Called on failure to create subscription.
     * 
     * @param {Object} data 
     */
    onCreateSubscriptionFailed( data )
    {

    }

    /**
     * Begin process of creating subscription / updating if already created.
     * 
     * @returns
     */
    async createSubscription()
    {
        return $.ajax(
        {
            url: `${ vl_util.site_url() }/api/v1/quran-challenge/stripe/${ this.getCurrentHifzUUID() }/create-subscription`,
            method: "POST",
            dataType: "JSON",
            data: 
            {
                _token: $( `meta[name="csrf-token"]` ).attr( "content" ),
            }
        } );
    }

    /**
     * Unpauses the subscription so the automatic payments begin.
     * 
     * @returns
     */
    async finaliseSubscription()
    {
        return $.ajax(
        {
            url: `${ vl_util.site_url() }/api/v1/quran-challenge/stripe/${ this.getCurrentHifzUUID() }/finalise-subscription`,
            method: "POST",
            dataType: "JSON",
            data: 
            {
                _token: $( `meta[name="csrf-token"]` ).attr( "content" ),
            }
        } );
    }

    /**
     * Gets the current Hifz donation instance UUID.
     * 
     * @returns {String} The current Hifz Donation instance UUID.
     */
    getCurrentHifzUUID()
    {
        return window.hifz_id ? window.hifz_id : null;
    }
}
