import $                    from "jquery";
import jQuery               from "jquery";

/**
 * JS countdown.
 */
export class Countdown
{
    /**
     * The parent element.
     * 
     * @var {JQuery<HTMLDivElement>} parentElement
     */
    parentElement = $();

    /**
     * Days counter.
     * 
     * @var {JQuery<HTMLHeadingElement>} days
     */
    days = $();

    /**
     * Hours counter.
     * 
     * @var {JQuery<HTMLHeadingElement>} hours
     */
    hours = $();

    /**
     * Minutes counter.
     * 
     * @var {JQuery<HTMLHeadingElement>} minutes
     */
    minutes = $();

    /**
     * Seconds counter.
     * 
     * @var {JQuery<HTMLHeadingElement>} seconds
     */
    seconds = $();

    /**
     * Initial timestamp.
     * 
     * @var {Number} initialTimestamp
     */
    initialTimestamp = 0;

    /**
     * Constructor.
     * 
     * @param {String} selector 
     */
    constructor ( selector, initialTimestamp = null ) 
    {
        // Get the parent.
        this.parentElement = $( selector );

        if ( ! this.parentElement.length )
        {
            throw `Parent element with selector ${selector} could not be found.`;
        }

        this.days       = this.parentElement.find( ".days" );
        this.hours      = this.parentElement.find( ".hours" );
        this.minutes    = this.parentElement.find( ".minutes" );
        this.seconds    = this.parentElement.find( ".seconds" );

        // Check the children elements exist.
        if ( ! this.days.length || ! this.hours.length || ! this.minutes.length || ! this.seconds.length )
        {
            throw `Child element could not be found`;
        }

        // If no initial timestamp is given, it is derived from the parent element.
        if ( ! initialTimestamp )
        {
            this.initialTimestamp = this.parentElement.data( "event-start-ts" );
        }
        else
        {
            this.initialTimestamp = initialTimestamp;
        }

        // Set timeout.
        setInterval( this.tick.bind( this ), 1000 );
    }

    /**
     * Second tick method, for updating on-screen elements.
     */
    tick()
    {
        // get total seconds between the times
        var delta = Math.abs( this.initialTimestamp - Date.now() ) / 1000;

        // calculate (and subtract) whole days
        var days = Math.floor( delta / 86400 );
        delta -= days * 86400;

        // calculate (and subtract) whole hours
        var hours = Math.floor( delta / 3600 ) % 24;
        delta -= hours * 3600;

        // calculate (and subtract) whole minutes
        var minutes = Math.floor( delta / 60 ) % 60;
        delta -= minutes * 60;

        // what's left is seconds
        var seconds = delta % 60;  // in theory the modulus is not required

        this.days.text( Math.round( days ) );
        this.hours.text( Math.round( hours ) );
        this.minutes.text( Math.round( minutes ) );
        this.seconds.text( Math.round( seconds ) );
    }
}