import { jrumble } from "../../vendor/jrumble/jrumble";
import { vl_util } from "../general/util";
import { donate_stripe } from "../general/stripe";
import { stripe_style } from "./style-stripe-elements";
import intlTelInput from 'intl-tel-input';
import { inputs } from "./inputs";
import $                    from "jquery";
import jQuery               from "jquery";

/**
 * Controller for the user profile.
 */
export default class UserProfileController
{
    /**
     * JQuery sets which are required to be referenced often.
     * 
     * @var {Object} elements
     */
    elements = 
    {
            /**
             *  == == == CONTAINERS == == == 
             */

        // Container for user viewing their full name.
        edit_full_name_view:        $(),

        // Container for user viewing their address.
        edit_address_view:          $(),

        // Container for user editing their full name.
        edit_full_name:             $(),
        
        // Container for user editing their address.
        edit_address:               $(),

        // Container for address automatic finding.
        address_automatic:          $(),

        // Container for address manual entry.
        address_manual:             $(),

        // Container for displaying current email.
        edit_email_view:            $(),

        // Container for editing the email address.
        edit_email:                 $(),

        // Container for viewing the password edit box, just has the icon to flip the frames really.
        edit_password_view:         $(),

        // Container for actually settings / updating the password.
        edit_password:              $(),

        // Container for showing the phone number.
        edit_phone_view:            $(),

        // Container for editing the phone number.
        edit_phone:                 $(),

            /**
             *  == == == BUTTONS == == == 
             */

        // Button to show the edit form.
        btn_show_edit_full_name:    $(),

        // Button to save the users changes.
        btn_edit_user_name_save:    $(),

        // Button to show the address edit form.
        btn_show_edit_address:      $(),

        // Button to save the address changes.
        btn_edit_address_save:      $(),

        // Button to show email edit form,
        btn_show_edit_email:        $(),

        // Button to save the users email address.
        btn_save_email:             $(),

        // Button to cancel updating the email address.
        btn_cancel_email:           $(),

        // Button to show the password setting form.
        btn_show_edit_password:     $(),

        // Button to (attempt to) save the password data.
        btn_save_password:          $(),

        // Button to cancel saving the password.
        btn_cancel_password:        $(),

        // Button to open file selection for profile image.
        btn_edit_profile_image:     $(),

        // Button to add new card.
        btn_add_new_card:           $(),

        // Button to delete a card from the users account & stripe.
        btn_delete_card:            $(),

        // Button to cancel adding a new card.
        btn_cancel_pm_add:          $(),

        // Button to save payment method.
        btn_save_pm:                $(),

        // Button to show phone editing.
        btn_show_edit_phone:        $(),

        // Button to save the edited phone number.
        btn_save_phone:             $(),

        // Button to cancel updating the phone number.
        btn_cancel_phone:           $(),

        // Button to cancel mandate.
        btn_cancel_mandate:         $(),

            /**
             *  == == == STRINGS == == == 
             */

        // Current email address, is masked to user.
        view_email:                 $(),

        // Title string.
        view_title:                 $(),

        // First name string.
        view_first:                 $(),

        // Last name string.
        view_last:                  $(),

        // Email response error.
        email_error_message:        $(),

        // Password response error.
        password_error_message:     $(),

        // Card setup error message (stripe errors).
        cards_error_message:        $(),

        // Phone number update error message.
        phone_error_message:        $(),

            /**
             *  == == == INPUTS == == == 
             */
        
        // Users title
        title:                      $(),

        // First name
        first_name:                 $(),
        
        // Last name
        last_name:                  $(),

        // Address street number
        address_st_num:             $(),
        
        // Address street
        address_street:             $(),

        // Address town
        address_town:               $(),

        // Address county
        address_county:             $(),

        // Address postal code
        address_postal_code:        $(),

        // Address country
        address_country:            $(),

        // Users current email address (required as it is masked on normal view)
        user_current_email:         $(),
        
        // Users desired new email address.
        user_new_email:             $(),
        
        // Users desired new email address (re-typed.)
        user_new_email_confirm:     $(),

        // Users current password for updating email address.
        user_email_current_password:$(),

        // Users current password for updating to new password.
        user_password_old:          $(),

        // Users desired new password.
        user_password_new:          $(),
        
        // Users desired new password, repeat.
        user_password_new_repeat:   $(),

        // Users profile image, the actual file input which is hidden.
        edit_profile_image_file:    $(),

        // Users phone number input (intlTelInput-ified)
        user_phone:                 $(),

            /**
             *  IMAGES
             */

        // Profile image update preview.
        edit_profile_image_preview: $(),
    }

    /**
     * Validator elements (usually also contain the base element, which is also in `this.elements`)
     * 
     * @var {Object} validators
     */
    validators = 
    {}

    /**
     * Contains anything relating to stripe variable-wise.
     * 
     * @var {Object} stripe
     */
    stripe = 
    {
        /**
         * Holds the main stripe object from stripe.js.
         * 
         * @var {Object} stripe
         */
        stripe: null,
        
        /**
         * Holds the (current) stripe elements object for card detail entry.
         */
        elements: null
    }

    /**
     * Contains the intlTelInput object for the phone editing input.
     * 
     * @var {intlTelInput.Plugin} intlTelInput
     */
    intlTelInput = null;

    /**
     * Are we currently editing the users email address.
     * 
     * @var {Boolean} isEditingEmail
     */
    isEditingEmail = false;

    /**
     * Are we currently editing the users name?
     * 
     * @var {Boolean} isEditingName
     */
    isEditingName = false;

    /**
     * Are we currently editing the address.
     * 
     * @var {Boolean} isEditingAddress
     */
    isEditingAddress = false;

    /**
     * Are we currently editing the password?
     * 
     * @var {Boolean} isEditingPassword
     */
    isEditingPassword = false;

    /**
     * Are we currently entering payment method information?
     * 
     * @var {Boolean} isAddingPaymentMethod
     */
    isAddingPaymentMethod = false;

    /**
     * Are we currently editing the phone number?
     * 
     * @var {Boolean} isEditingPhone
     */
    isEditingPhone = false;

    /**
     * Are all fields in the users email edit validated?
     * 
     * @var {Boolean} isEmailValidated
     */
    isEmailValidated = false;

    /**
     * Are all fields in the users full name validated?
     * 
     * @var {Boolean} isFullNameValidated
     */
    isFullNameValidated = false;

    /**
     * Are all the fields in the address validated?
     * 
     * @var {Boolean} isAddressValidated
     */
    isAddressValidated = false;

    /**
     * Are all the fields in the password update validated?
     * 
     * @var {Boolean} isPasswordValidated
     */
    isPasswordValidated = false;

    /**
     * Are we finding the address automatically at the moment?
     * 
     * @var {Boolean} isAutomaticAddress
     */
    isAutomaticAddress = true;

    /**
     * Class constructor.
     */
    constructor()
    {
        $( this.onReady.bind( this ) );
    }

    /**
     * JQuery / Document ready event will call this. Bind things in here.
     * 
     * @param {JQueryStatic} $ 
     */
    onReady( $ )
    {
        // Initialise JRumble on this instance of jQuery.
        jrumble( $ );
        
        // Email address containers.
        let edit_email = this.initialiseElement( "edit_email" );
        let edit_email_view = this.initialiseElement( "edit_email_view" );

        // Full name containers.
        let edit_full_name = this.initialiseElement( "edit_full_name" );
        let edit_full_name_view =this.initialiseElement( "edit_full_name_view" );

        // Address containers.
        let edit_address = this.initialiseElement( "edit_address" );
        let edit_address_view = this.initialiseElement( "edit_address_view" );

        let edit_password = this.initialiseElement( "edit_password" );
        let edit_password_view = this.initialiseElement( "edit_password_view" );

        let edit_phone = this.initialiseElement( "edit_phone" );
        let edit_phone_view = this.initialiseElement( "edit_phone_view" );

        // Do not try to initialise if our main elements aren't present.
        if ( 
            ! edit_email        || ! edit_email_view        ||
            ! edit_full_name    || ! edit_full_name_view    ||
            ! edit_address      || ! edit_address_view      ||
            ! edit_password     || ! edit_password_view     ||
            ! edit_phone        || ! edit_phone_view )
        {
            // TODO: user feedback when somethings broken?
            return;
        }

        // Bind the email editing flow.
        this.bindEmail();
        
        // Bind the full name editing flow.
        this.bindFullName();

        // Bind the address editing flow.
        this.bindAddress();

        // Bind the password editing flow.
        this.bindPassword();

        // Bind the profile image editing flow.
        this.bindProfileImage();

        // Bind the payment method adding / deleting flow.
        this.bindPaymentMethods();

        // Bind the functionality for phone number updating.
        this.bindPhoneNumber();
    }

    /**
     * Binds all elementa etc relating to the users email address editing.
     * Keeping code tidy - grouping bindings.
     */
    bindEmail()
    {
        // Initialise button elements.
        this.initialiseElement( "btn_show_edit_email" )
        this.initialiseElement( "btn_save_email" );
        this.initialiseElement( "btn_cancel_email" );

        // Initialise the string elements (i.e. showing something to user).
        this.initialiseElement( "view_email" );
        this.initialiseElement( "email_error_message" );

        // Initialise the input elements (usually mapped to a validator).
        this.initialiseElement( "user_current_email" );
        this.initialiseElement( "user_new_email" );
        this.initialiseElement( "user_new_email_confirm" );
        this.initialiseElement( "user_email_current_password" );

        // Initialise validators for the inputs.
        this.initialiseValidator( "user_current_email" );
        this.initialiseValidator( "user_new_email" );
        this.initialiseValidator( "user_new_email_confirm" );
        this.initialiseValidator( "user_email_current_password" );

        [
            this.getElement( "user_current_email" ),
            this.getElement( "user_new_email" ),
            this.getElement( "user_new_email_confirm" )
        ].forEach( ( ( value, idx, array ) =>
        {   
            value.on( "input",      this.onInputEmailField.bind( this ) );
            value.on( "focusout",   this.onFocusoutEmailField.bind( this ) );
        } ).bind( this ) );

        this.getElement( "user_email_current_password" ).on( "input", this.onInputPasswordField.bind( this ) );
        this.getElement( "user_email_current_password" ).on( "focusout", this.onFocusoutPasswordField.bind( this ) );

        if ( this.isElementAvailable( "btn_show_edit_email" ) )
        {
            this.getElement( "btn_show_edit_email" ).on( "click", this.onClickShowEditEmail.bind( this ) );
        }

        if ( this.isElementAvailable( "btn_save_email" ) )
        {
            this.getElement( "btn_save_email" ).on( "click", this.onClickSaveEmail.bind( this ) );
        }

        if ( this.isElementAvailable( "btn_cancel_email" ) )
        {
            this.getElement( "btn_cancel_email" ).on( "click", this.onClickEmailCancel.bind( this ) );
        }

        if ( this.isValidatorAvailable( "user_current_email" ) )
        {
            this.getValidator( "user_current_email" ).jrumble();
        }

        if ( this.isValidatorAvailable( "user_new_email" ) )
        {
            this.getValidator( "user_new_email" ).jrumble();
        }

        if ( this.isValidatorAvailable( "user_new_email_confirm" ) )
        {
            this.getValidator( "user_new_email_confirm" ).jrumble();
        }

        if ( this.isValidatorAvailable( "user_email_current_password" ) )
        {
            this.getValidator( "user_email_current_password" ).jrumble();
        }
    }

    /**
     * Binds all elements etc relating to full name + title editing.
     * Keeping code tidy - grouping bindings.
     */
    bindFullName()
    {
        // Initialise the button elements.
        this.initialiseElement( "btn_show_edit_full_name", "show-edit-full-name" );
        this.initialiseElement( "btn_edit_user_name_save", "edit-user-name-save" );
        this.initialiseElement( "btn_show_edit_address", "show-edit-address" );

        // Initialise the string elements (i.e. showing something to user).
        this.initialiseElement( "view_title" );
        this.initialiseElement( "view_first" );
        this.initialiseElement( "view_last" );

        // Initialise the input elements (usually mapped to a validator).
        this.initialiseElement( "title", "user-title" );
        this.initialiseElement( "first_name", "user-first-name" );
        this.initialiseElement( "last_name", "user-last-name" );

        // Initialise validators for the inputs.
        this.initialiseValidator( "title", true );
        this.initialiseValidator( "first_name" );
        this.initialiseValidator( "last_name" );

        // If the element is available,
        if ( this.isElementAvailable( "btn_show_edit_full_name" ) )
        {
            // .. bind the show button.
            this.getElement( "btn_show_edit_full_name" ).on( "click", this.onClickShowFullName.bind( this ) );
        }

        // If the element is available, 
        if ( this.isElementAvailable( "btn_edit_user_name_save" ) )
        {
            // .. bind the save button.
            this.getElement( "btn_edit_user_name_save" ).on( "click", this.onClickSaveFullName.bind( this ) );
        }

        // If we have the first name input.
        if ( this.isElementAvailable( "first_name" ) )
        {
            // Bind the input event.
            this.getElement( "first_name" ).on( "input", this.onInputFirstName.bind( this ) );
        }

        // If we have the last name input.
        if ( this.isElementAvailable( "last_name" ) )
        {
            // Bind the input event.
            this.getElement( "last_name" ).on( "input", this.onInputLastName.bind( this ) );
        }

        // If the title validator is available,
        if ( this.isValidatorAvailable( "title" ) )
        {
            // initialise jrumble on it.
            this.getValidator( "title" ).jrumble();
        }

        // If the first name validator is available,
        if ( this.isValidatorAvailable( "first_name" ) )
        {
            // initialise jrumble on it.
            this.getValidator( "first_name" ).jrumble();
        }

        // If the last name validator is available,
        if ( this.isValidatorAvailable( "last_name" ) )
        {
            // initialise jrumble on it.
            this.getValidator( "last_name" ).jrumble();
        }
    }

    /**
     * Binds all elements etc relating to address editing.
     * Keeping code tidy - grouping bindings.
     */
    bindAddress()
    {
        // Containers.
        this.initialiseElement( "address_automatic" );
        this.initialiseElement( "address_manual" );
        
        // Inputs.
        this.initialiseElement( "address_auto_input" );
        this.initialiseElement( "address_st_num" );
        this.initialiseElement( "address_street" );
        this.initialiseElement( "address_town" );
        this.initialiseElement( "address_county" );
        this.initialiseElement( "address_postal_code" );
        this.initialiseElement( "address_country" );

        this.initialiseValidator( "address_auto_input" );
        this.initialiseValidator( "address_st_num" );
        this.initialiseValidator( "address_street" );
        this.initialiseValidator( "address_town" );
        this.initialiseValidator( "address_county" );
        this.initialiseValidator( "address_postal_code" );
        this.initialiseValidator( "address_country", true );

        if ( this.isValidatorAvailable( "address_auto_input" ) )
        {
            this.getValidator( "address_auto_input" ).jrumble();
        }

        if ( this.isValidatorAvailable( "address_st_num" ) )
        {
            this.getValidator( "address_st_num" ).jrumble();
        }

        if ( this.isValidatorAvailable( "address_street" ) )
        {
            this.getValidator( "address_street" ).jrumble();
        }

        if ( this.isValidatorAvailable( "address_town" ) )
        {
            this.getValidator( "address_town" ).jrumble();
        }
        
        if ( this.isValidatorAvailable( "address_county" ) )
        {
            this.getValidator( "address_county" ).jrumble();
        }

        if ( this.isValidatorAvailable( "address_postal_code" ) )
        {
            this.getValidator( "address_postal_code" ).jrumble();
        }

        if ( this.isValidatorAvailable( "address_country" ) )
        {
            this.getValidator( "address_country" ).jrumble();
        }

        // Buttons.
        this.initialiseElement( "btn_manual_address" );
        this.initialiseElement( "btn_auto_address" );
        this.initialiseElement( "show_edit_address" );
        this.initialiseElement( "btn_address_save" ); 

        // Bind the show button.
        this.getElement( "show_edit_address" ).on( "click", this.onClickShowEditAddress.bind( this ) );

        // Bind the manual / auto buttons.
        this.getElement( "btn_manual_address" ).on( "click", this.onClickManualAddress.bind( this ) );
        this.getElement( "btn_auto_address" ).on( "click", this.onClickAutoAddress.bind( this ) );

        // Bind the save button.
        this.getElement( "btn_address_save" ).on( "click", this.onClickSaveAddress.bind( this ) );

        // Bind inputs.
        this.getElement( "address_auto_input" ).on( "input", this.onInputAutoAddress.bind( this ) );

        [
            this.getElement( "address_st_num" ), 
            this.getElement( "address_street" ), 
            this.getElement( "address_town" ), 
            this.getElement( "address_county" ),
            this.getElement( "address_postal_code" ),
            this.getElement( "address_country" )
        ].forEach( ( ( element ) => 
        {
            element.on( "input", this.onInputAddressField.bind( this ) );
            element.on( "focusout", this.onFocusoutAddressField.bind( this ) );
        } ).bind( this ) );
    }

    /**
     * Binds all elements etc relating to password editing.
     * Keeping code tidy - grouping bindings.
     */
    bindPassword()
    {
        // Strings
        this.initialiseElement( "password_error_message" );

        // If we have old password input
        if ( this.initialiseElement( "user_password_old" ) )
        {
            // Init validator.
            if ( this.initialiseValidator( "user_password_old" ) )
            {
                // Initialise jrumble.
                this.getValidator( "user_password_old" ).jrumble();
            }
        }

        // If we have new password input
        if ( this.initialiseElement( "user_password_new" ) )
        {
            // Init validator
            if ( this.initialiseValidator( "user_password_new" ) )
            {
                // Initialise jRumble
                this.getValidator( "user_password_new" ).jrumble();
            }
        }
        
        // If we have repeat pass
        if ( this.initialiseElement( "user_password_new_repeat" ) )
        {
            // Init validator
            if ( this.initialiseValidator( "user_password_new_repeat" ) )
            {
                // Initialise jrumble.
                this.getValidator( "user_password_new_repeat" ).jrumble();
            }
        }

        // Initialise buttons
        this.initialiseElement( "btn_show_edit_password" );
        this.initialiseElement( "btn_save_password" );
        this.initialiseElement( "btn_cancel_password" );

        // Bindings
        this.getElement( "btn_show_edit_password" ) .on( "click", this.onClickShowPassword.bind( this ) );
        this.getElement( "btn_save_password" )      .on( "click", this.onClickSavePassword.bind( this ) );
        this.getElement( "btn_cancel_password" )    .on( "click", this.onClickPasswordCancel.bind( this ) );

        [
            this.getElement( "user_password_old" ),
            this.getElement( "user_password_new" ),
            this.getElement( "user_password_new_repeat" )
        ].forEach( ( value, index, array ) => 
        {
            value.on( "input",      this.onInputGenericPasswordField.bind( this ) );
            value.on( "focusout",   this.onFocusoutGenericPasswordField.bind( this ) );
        } );
    }

    /**
     * Binds all elements etc relating to profile image editing.
     * Keeping code tidy - grouping bindings.
     */
    bindProfileImage()
    {
        // Profile image preview <img>.
        if ( this.initialiseElement( "edit_profile_image_preview" ) )
        {
            // ..
        }

        // Hidden input for file.
        if ( this.initialiseElement( "edit_profile_image_file" ) )
        {
            this.getElement( "edit_profile_image_file" ).on( "change", this.onChangeProfileImageFile.bind( this ) );
        }

        // Button for displaying file picking
        if ( this.initialiseElement( "btn_edit_profile_image" ) )
        {
            this.getElement( "btn_edit_profile_image" ).on( "click", this.onClickShowEditProfileImage.bind( this ) );
        }
    }

    /**
     * Binds all elements etc relating to payment method management.
     * Keeping code tidy - grouping binds.
     */
    bindPaymentMethods()
    {
        // Initialise the add new card element
        if ( this.initialiseElement( "btn_add_new_card" ) )
        {
            this.getElement( "btn_add_new_card" ).on( "click", this.onClickAddNewCard.bind( this ) );
        }

        // Initialise the delete card element
        if ( this.initialiseElementClass( "btn_delete_card" ) )
        {
            this.getElement( "btn_delete_card" ).on( "click", this.onClickDeleteCard.bind( this ) );
        }

        if ( this.initialiseElementClass( "btn_cancel_mandate" ) )
        {
            this.getElement( "btn_cancel_mandate" ).on( "click", this.onClickCancelMandate.bind( this ) );
        }

        // Payment method add cancel.
        if ( this.initialiseElement( "btn_cancel_pm_add" ) )
        {
            this.getElement( "btn_cancel_pm_add" ).on( "click", this.onClickCancelAddCard.bind( this ) );
        }

        if ( this.initialiseElement( "btn_save_pm" ) )
        {
            this.getElement( "btn_save_pm" ).on( "click", this.onClickSavePaymentMethod.bind( this ) );
        }

        if ( this.initialiseElement( "cards_error_message" ) )
        {
        }
    }

    /**
     * Binds all elements etc relating to phone number management.
     * Keeping code tidy - grouping labels.
     */
    bindPhoneNumber()
    {
        if ( this.initialiseElement( "phone_error_message" ) )
        {}

        if ( this.initialiseElement( "btn_show_edit_phone" ) )
        {
            this.getElement( "btn_show_edit_phone" ).on( "click", this.onClickShowEditPhone.bind( this ) );
        }

        if ( this.initialiseElement( "btn_save_phone" ) )
        {
            this.getElement( "btn_save_phone" ).on( "click", this.onClickSavePhone.bind( this ) );
        }

        if ( this.initialiseElement( "btn_cancel_phone" ) )
        {
            this.getElement( "btn_cancel_phone" ).on( "click", this.onClickCancelPhone.bind( this ) );
        }

        // Initialise the custom phone input.
        if ( this.initialiseElement( "user_phone" ) )
        {
            // Get donor phone element.
            const donor_phone = this.getElement( "user_phone" ).get( 0 );

            // Fail here if the phone element isn't loaded.
            if ( ! donor_phone )
            {
                return;
            }

            // If the element is valid, initialise International Telephone Input
            this.intlTelInput = intlTelInput( donor_phone, 
            {
                // Util script for extra features.
                utilsScript: "/app/assets/js/vendor/intl-tel-input/utils.js",

                // Placeholder should force override always.
                autoPlaceholder: "aggressive",

                // Automatically format.
                formatOnDisplay: true,

                // We want the country to be automated based on location.
                initialCountry: "auto",

                // Fixes the phone number strings.
                nationalMode: false,

                // Geoip lookup func.
                geoIpLookup: inputs.geoip_lookup,

                // Hidden input for actual full phone number.
                hiddenInput: "user_phone",

                // Initial country to UK even if geoIP lookup doesn't work.
                initialCountry: "GB"
            } );
        }
    }

    /**
     * Initialises google maps for the address entry.
     */
    initialiseGoogle()
    {
        const init = ( () => 
        {
            var google = vl_util.google();

            if ( ! google )
            {
                setTimeout( init, 250 );
                return;
            }

            var elem = $( "#address-auto-input" ).get( 0 );

            // If the elements aren't loaded but google is, set a timeout for 250 ms and wait it out, this will call this function 
            // after 250 ms.
            if ( ! elem || ! ( elem instanceof HTMLInputElement ) )
            {
                setTimeout( init, 250 );
                return;
            }

            var autocomplete = new google.maps.places.Autocomplete( elem, { location: "53.79508524429051,-1.754992069056253" } );

            autocomplete.addListener( "place_changed", ( function () 
            {
                this.onPlaceRetrieved( autocomplete.getPlace() );
            } ).bind( this ) );
        } ).bind( this );

        init();
    }

    /**
     * Called when the place is retrieved from the 
     * 
     * @param {*} place 
     */
    onPlaceRetrieved( place )
    {
        const addressMapping = 
        {
            "street_number":                "address_st_num",
            "route":                        "address_street",
            "postal_town":                  "address_town",
            "administrative_area_level_2":  "address_county",
            "postal_code":                  "address_postal_code",
            "country":                      "address_country"
        };

        const getMappedItem = function ( types )
        {
            for ( let i in addressMapping )
            {
                if ( types.includes( i ) )
                {
                    return addressMapping[ i ];
                }
            }

            return null;
        };

        // Iterate through the address components and 
        for ( let idx in place.address_components )
        {
            let component = place.address_components[ idx ];
            let mappedItem = getMappedItem( component.types );

            if ( ! mappedItem )
            {
                continue;
            }

            const elem = this.getElement( mappedItem );

            if ( elem )
            {
                if ( mappedItem === "address_country" )
                {
                    elem.val( component.short_name );
                }
                else
                {
                    elem.val( component.long_name ? component.long_name : component.short_name );     
                }
            }
        }

        this.fullValidateAddress( true );
    }

    /**
     * On input address field.
     *
     * @var {InputEvent} event
     */
    onInputAddressField( event )
    {
        // Get target.
        const target = $( event.target );

        // Check target.
        if ( ! target || ! target.length )
        {
            return;
        }

        // Get the (custom) element name (NOT NAME ATTRIBUTE) from the ID
        const elementName = event.target.id.replace( /\s/g, "" ).replace( /\-/g, "_" );

        const element = this.getElement( elementName );
        const validator = this.getValidator( elementName );

        if ( ! element || ! validator )
        {
            console.error( "Failed to find element + validator for : " + elementName );
            return;
        }

        validator.removeClass( "not-valid valid" );
    }

    /**
     * Called when the user clicks off a field for the address.
     * 
     * @param {FocusEvent} event 
     * @returns 
     */
    onFocusoutAddressField( event )
    {
        // Get target.
        const target = $( event.target );

        // Check target.
        if ( ! target || ! target.length )
        {
            return;
        }

        // Get the (custom) element name (NOT NAME ATTRIBUTE) from the ID
        const elementName = event.target.id.replace( /\s/g, "" ).replace( /\-/g, "_" );

        const element = this.getElement( elementName );
        const validator = this.getValidator( elementName );

        if ( ! element || ! validator )
        {
            console.error( "Failed to find element + validator for : " + elementName );
            return;
        }

        validator.removeClass( "not-valid valid" );
        
        if ( this.validateAddressField( elementName ) )
        {
            validator.addClass( "valid" );

        }
        else
        {
            validator.addClass( "not-valid" );

            this.rumbleTimed( elementName );
        }

        this.fullValidateAddress( false );
    }

    /**
     * Called when user clicks off an email field for the email update.
     * 
     * @param {FocusEvent} event 
     */
    onFocusoutEmailField( event )
    {
        // Get target.
        const target = $( event.target );

        // Check target.
        if ( ! target || ! target.length )
        {
            return;
        }

        // Get the (custom) element name (NOT NAME ATTRIBUTE) from the ID
        const elementName = event.target.id.replace( /\s/g, "" ).replace( /\-/g, "_" );

        const element = this.getElement( elementName );
        const validator = this.getValidator( elementName );

        if ( ! element || ! validator )
        {
            console.error( "Failed to find element + validator for : " + elementName );
            return;
        }

        // Validate everything to update buttons state.
        this.fullValidateEmail( false );

        // Is this email field valid?
        const isValid = this.validateEmailField( elementName );
        const value = this.getElementValue( elementName );

        if ( value && value.length )
        {
            // If valid, valid. otheriwse not valid.
            if ( isValid )
            {
                validator.addClass( "valid" ).removeClass( "not-valid" );
            }
            else
            {
                validator.addClass( "not-valid" ).removeClass( "valid" );

                this.rumbleTimed( elementName );
            }
        }
    }
    
    /**
     * Called when user clicks off the password field for the email update.
     * 
     * @param {FocusEvent} event
     */
    onFocusoutPasswordField( event )
    {
        const elementName = "user_email_current_password";

        const element = this.getElement( elementName );
        const validator = this.getValidator( elementName );

        if ( ! element || ! validator )
        {
            console.error( "Failed to find element + validator for : " + elementName );
            return;
        }

        // Validate everything to update save buttons state.
        this.fullValidateEmail( false );

        // Is this email field valid?
        const isValid = this.validatePasswordField( elementName );
        const value = this.getElementValue( elementName );

        if ( value && value.length )
        {
            // If valid, valid. otheriwse not valid.
            if ( isValid )
            {
                validator.addClass( "valid" ).removeClass( "not-valid" );
            }
            else
            {
                validator.addClass( "not-valid" ).removeClass( "valid" );

                this.rumbleTimed( elementName );
            }
        }
    }

    /**
     * Called when user clicks off the password field for the email update.
     * 
     * @param {FocusEvent} event
     */
    onFocusoutGenericPasswordField( event )
    {
        // Get target.
        const target = $( event.target );

        // Check target.
        if ( ! target || ! target.length )
        {
            return;
        }

        // Get the (custom) element name (NOT NAME ATTRIBUTE) from the ID
        const elementName = event.target.id.replace( /\s/g, "" ).replace( /\-/g, "_" );

        const element = this.getElement( elementName );
        const validator = this.getValidator( elementName );

        if ( ! element || ! validator )
        {
            console.error( "Failed to find element + validator for : " + elementName );
            return;
        }

        // Validate everything to update save buttons state.
        this.fullValidatePassword( true );

        // Is this email field valid?
        const isValid = this.validatePasswordField( elementName );
        const value = this.getElementValue( elementName );

        if ( value && value.length )
        {
            // If valid, valid. otheriwse not valid.
            if ( isValid )
            {
                validator.addClass( "valid" ).removeClass( "not-valid" );
            }
            else
            {
                validator.addClass( "not-valid" ).removeClass( "valid" );

                this.rumbleTimed( elementName );
            }
        }
    }

    /**
     * On input automatic address field.
     * 
     * @param {InputEvent} event 
     */
    onInputAutoAddress( event )
    {
        this.getValidator( "address_auto_input" ).removeClass( "valid" ).removeClass( "not-valid" );

        this.isAddressValidated = false;
        this.setSaveAddressEnabled( this.isAddressValidated );
    }

    

    /**
     * When the manual address is clicked.
     */
    onClickManualAddress()
    {
        this.isAutomaticAddress = false;

        this.getElement( "address_automatic" ).addClass( "hidden" );
        this.getElement( "address_manual" ).removeClass( "hidden" );
    }

    /**
     * On click the auto address.
     */
    onClickAutoAddress()
    {
        this.isAutomaticAddress = true;

        this.getElement( "address_automatic" ).removeClass( "hidden" );
        this.getElement( "address_manual" ).addClass( "hidden" );
    }

    /**
     * When the show edit address is clicked.
     */
    onClickShowEditAddress()
    {
        this.setEditingAddress( true );

        // But not editing any other fields.
        this.setEditingPassword( false );
        this.setEditingName( false );
        this.setEditingEmail( false );
        this.setAddingPaymentMethod( false );
    }
 
     /**
      * Called when user is requesting to show the email edit.
      * 
      * @param {MouseEvent} event 
      */
    onClickShowEditEmail( event )
    {
        if ( event )
        {
            event.preventDefault();
        }

        // Editing email
        this.setEditingEmail( true );

        // But not editing any other fields.
        this.setEditingPassword( false ); 
        this.setEditingName( false );
        this.setEditingAddress( false );
        this.setAddingPaymentMethod( false );
    }
  
      /**
       * Called when user clicks "Update" for updating their password.
       * 
       * @param {MouseEvent} event
       */
    onClickShowPassword( event )
    {
        // Prevent default in case item has some default function.
        if ( event )
        {
            event.preventDefault();
        }

        // Editing password
        this.setEditingPassword( true );

        // But not editing any other fields:
        this.setEditingEmail( false );
        this.setEditingName( false );
        this.setEditingAddress( false );
        this.setAddingPaymentMethod( false );
    }

    /**
     * Called when the show / edit button is clicked.
     * 
     * @param {MouseEvent|null} event The event.
     */
    onClickShowFullName( event = null )
    {
        // If the event isn't null, prevent it from doing it's default things.
        if ( event )
        {
            event.preventDefault();
        }

        // Editing name
        this.setEditingName( true );

        // But not editing any other fields:
        this.setEditingEmail( false );
        this.setEditingPassword( false );
        this.setEditingAddress( false );
        this.setAddingPaymentMethod( false );
    }

    /**
     * Called when the save button is clicked.
     * 
     * @param {MouseEvent|null} event The event.
     */
    onClickSaveFullName( event = null )
    {
        // If the event isn't null, prevent it from doing it's default things.
        if ( event )
        {
            event.preventDefault();
        }

        if ( ! this.isFullNameValidated )
        {
            this.isFullNameValidated = this.fullValidate( true );
        }

        if ( ! this.isFullNameValidated )
        {
            return;
        }

        // Title value.
        const titleVal  = this.getElement( "title" ).val();

        // First name value.
        const firstVal  = this.getElement( "first_name" ).val();

        // Last name value.
        const lastVal   = this.getElement( "last_name" ).val();

        this.updateFullName( titleVal, firstVal, lastVal, ( json ) => 
        {
            // TODO: Error handling here. Important stuff!
        } );
    }

    /**
     * Called when the save button for address is clicked.
     * 
     * @param {MouseEvent|null} event 
     */
    onClickSaveAddress( event = null )
    {
        // Prevent default, this likely has no effect.
        if ( event )
        {
            event.preventDefault();
        }

        // Update validation.
        this.isAddressValidated = this.fullValidateAddress( true );

        // Get container for auto input.
        const autoInputValidator = this.getValidator( "address_auto_input" );

        // If the address is not properly validated
        if ( ! this.isAddressValidated )
        {
            // Ensure user knows somethings up.
            autoInputValidator.addClass( "not-valid" ).removeClass( "valid" );

            // Dont proceed.
            return;
        }
        else
        {
            // Otherwise add valid state to auto input validator.
            autoInputValidator.removeClass( "not-valid" ).addClass( "valid" );
        }

        // Get input for automatic address finding.
        const autoInput = this.getElement( "address_auto_input" );

        // Check the input, if it has no value then we set the value forcefully based off the other fields values.
        if ( autoInput && ! ( autoInput.val().replace( /\s/g, "" ).length ) )
        {
            autoInput.text( this.getAddressOneline() );
        }

        if ( this.isAddressValidated )
        {
            // Body data for AJAX request.
            const ajaxData = 
            {
                // Add CSRF token to request.
                _token:         vl_util.csrf_token(),

                // Address components.
                st_num:         this.getElementValue( "address_st_num" ),
                street:         this.getElementValue( "address_street" ),
                town:           this.getElementValue( "address_town" ),
                county:         this.getElementValue( "address_county" ),
                postal_code:    this.getElementValue( "address_postal_code" ),
                country:        this.getElementValue( "address_country" )
            };

            // Start the AJAX.
            $.ajax(
            {
                url:        vl_util.site_url() + "/api/v1/front/user-details/address",
                dataType:   "json",
                method:     "post",
                data:       ajaxData,
                async:      true,

                success: function ( json )
                {
                    // No response - no action.
                    if ( ! json )
                    {
                        return;
                    }

                    // If there's a status value, and it's true
                    if ( json.status )
                    {
                        // Refresh the page to ensure new changes are reflected on client.
                        window.location.href = window.location.href;

                        return;
                    }
                    else
                    {
                        // TODO: More error handling.
                    }
                },
                error: function ( jqXHR, error )
                {

                }
            } );
        }
    }

    /**
     * Called when user clicks on save password button.
     * 
     * @param {MouseEvent} event
     */
    onClickSavePassword( event )
    {
        // Prevent default, this likely has no effect.
        if ( event )
        {
            event.preventDefault();
        }

        // Remove password error.
        this.setPasswordError( false );

        // Validate the password update form.
        const valid = this.fullValidatePassword( true );

        // If form validated.
        if ( valid )
        {
            const ajaxData = 
            {
                _token: vl_util.csrf_token(),

                old_password:       this.getElementValue( "user_password_old" ),
                new_password:       this.getElementValue( "user_password_new" ),
                repeat_password:    this.getElementValue( "user_password_new_repeat" )
            };

            // Bind the error message method to the instance of this and store constantly.
            const setError = this.setPasswordError.bind( this );
            
            // Start the AJAX.
            $.ajax( 
            {
                url:        vl_util.site_url() + "/api/v1/front/user-details/password",
                dataType:   "json",
                method:     "post",
                data:       ajaxData,
                async:      true,

                success: function ( json )
                {
                    // If status is available and true.
                    if ( json.status )
                    {
                        // Refresh page, a status of true means we've been logged out.
                        window.location.href = vl_util.site_url() + "/login?reauth=true";
                    }
                    else
                    {
                        setError( json.error );
                    }
                },

                error: function ( jqXHR, error )
                {
                    setError( "Sorry, we could not process your information. Please enter your details carefully and try again." );
                }
            } );
        }
    }

    /**
     * Called when user is requesting to show the email edit.
     * 
     * @param {MouseEvent} event 
     */
    onClickSaveEmail( event )
    {
        // Prevent default in case the element becomes something like an <a> or button[type="submit"].
        if ( event )
        {
            event.preventDefault();
        }

        // Check email validation state.
        this.isEmailValidated = this.fullValidateEmail( true );

        // Don't update serverside if not validated.
        if ( ! this.isEmailValidated )
        {
            return;
        }

        // Data to be passed to server through AJAX.
        const ajaxData = 
        {
            // CSRF token.
            _token:             vl_util.csrf_token(),

            // Required email fields.
            current_email:      this.getElementValue( "user_current_email" ),
            current_password:   this.getElementValue( "user_email_current_password" ),
            new_email:          this.getElementValue( "user_new_email" ),
            new_email_confirm:  this.getElementValue( "user_new_email_confirm" )
        };

        const setError = this.setEmailError.bind( this );

        // Update on the server through ajax.
        $.ajax(
        {
            url:        vl_util.site_url() + "/api/v1/front/user-details/email",
            dataType:   "json",
            method:     "post",
            data:       ajaxData,

            success: function ( json )
            {
                if ( json.status )
                {
                    window.location.href = window.location.href;
                }
                else
                {
                    setError( json.error );
                }
            },

            error: function ( jqXHR, error )
            {
                setError( "Your details could not be processed. Please enter your details carefully and try again." );
            }
        } );
    }

    /**
     * Called when user is requesting to show the email edit.
     * 
     * @param {MouseEvent} event 
     */
    onClickEmailCancel( event )
    {
        if ( event )
        {
            event.preventDefault();
        }

        // Clear the values of the inputs.
        this.getElement( "user_current_email" )         .val( "" );
        this.getElement( "user_new_email" )             .val( "" );
        this.getElement( "user_new_email_confirm" )     .val( "" );
        this.getElement( "user_email_current_password" ).val( "" );

        // Clear the validation states.
        this.getValidator( "user_current_email" )           .removeClass( "valid not-valid" );
        this.getValidator( "user_new_email" )               .removeClass( "valid not-valid" );
        this.getValidator( "user_new_email_confirm" )       .removeClass( "valid not-valid" );
        this.getValidator( "user_email_current_password" )  .removeClass( "valid not-valid" );

        // Prevent clicking save after user comes back to this view.
        this.setSaveEmailEnabled( false );

        // Show the original box.
        this.setEditingEmail( false );
    }

    

    /**
     * Called when user clicks "Cancel" on the password updating flow.
     * 
     * @param {MouseEvent} event
     */
    onClickPasswordCancel( event )
    {
        // Prevent default in case item has some default function.
        if ( event )
        {
            event.preventDefault();
        }

        // Clear the values of the inputs
        this.getElement( "user_password_old" )              .val( "" );
        this.getElement( "user_new_email" )                 .val( "" );
        this.getElement( "user_new_email_confirm" )         .val( "" );
        this.getElement( "user_email_current_password" )    .val( "" );

        // Clear the validation states.
        this.getValidator( "user_password_old" )            .removeClass( "valid not-valid" );
        this.getValidator( "user_new_email" )               .removeClass( "valid not-valid" );
        this.getValidator( "user_new_email_confirm" )       .removeClass( "valid not-valid" );
        this.getValidator( "user_email_current_password" )  .removeClass( "valid not-valid" );

        // Prevent clicking save after user comes back to this view.
        this.setSavePasswordEnabled( false );

        // Show the original box.
        this.setEditingPassword( false );
    }

    /**
     * Called when user clicks to edit their profile image.
     * 
     * @param {MouseEvent} event The event.
     */
    onClickShowEditProfileImage( event )
    {
        if ( event )
        {
            event.preventDefault();
        }

        this.getElement( "edit_profile_image_file" ).trigger( "click" );
    }

    /**
     * Called when user clicks to edit their phone number.
     * 
     * @param {MouseEvent} event 
     */
    onClickShowEditPhone( event )
    {
        // Prevent default behaviour
        if ( event )
        {
            event.preventDefault();
        }

        this.setEditingPhone( true );
    }

    /**
     * On click add new card.
     * 
     * @param {MouseEvent} event 
     */
    onClickAddNewCard( event )
    {
        // Prevent default behaviour
        if ( event )
        {
            event.preventDefault();
        }

        // Editing name
        this.setAddingPaymentMethod( true );

        // But not editing any other fields:
        this.setEditingEmail( false );
        this.setEditingPassword( false );
        this.setEditingAddress( false );
        this.setEditingName( false );

        const userProfileInstance = this;

        this.createSetupIntent().then( ( data ) => 
        {
            const clientSecret  = data.client_secret;
            const customerId    = data.customer_id;

            // Get stripe from donate code.
            let stripe = donate_stripe.get_stripe();

            // If stripe not loaded, load it.
            if ( ! stripe )
            {
                donate_stripe.initialise_stripe();
                
                stripe = donate_stripe.get_stripe();
            }

            // Add stripe instance to controller class.
            userProfileInstance.stripe.stripe = stripe;

            // Stripe cards options.
            const options = 
            {
                clientSecret: clientSecret,

                // Fully customizable with appearance API.
                appearance: stripe_style.appearance(),
            };
            
            // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
            userProfileInstance.stripe.elements = stripe.elements( options );
            
            // Create and mount the Payment Element
            const paymentElement = userProfileInstance.stripe.elements.create( "payment" );

            // Mount the card entry to a blank element.
            paymentElement.mount( "#payment-element" );

            // When ready, show the card entry form.
            paymentElement.on( "ready", function ()
            {
                userProfileInstance.getElement( "btn_add_new_card" ).slideUp( 150 );
                $( "#payment-wrapper" ).slideDown( 150 );
            } );
            
        } );
    }

    /**
     * Called when user clicks to delete their card.
     * 
     * @param {MouseEvent} event 
     */
    onClickDeleteCard( event )
    {
        // Prevent default in case it's an anchor etc.
        if ( event )
        {
            event.preventDefault();
        }

        // Get target in JQuery.
        let $target = $( event.target );

        // Validate target.
        if ( ! $target || ! $target.length )
        {
            return;
        }

        $target = $target.parent( "a" );

        // Get payment method ID.
        const paymentMethodId = $target.data( "card-id" );

        // We have to have a payment method.
        if ( ! paymentMethodId )
        {
            return;
        }

        // Ask for user confirmation.
        if ( confirm( "Are you sure you would like to remove this card?" ) )
        {
            $.ajax(
            {
                url: vl_util.site_url() + "/api/v1/front/delete-card",
                data:
                {
                    // Payment method identifier.
                    payment_method_id:  paymentMethodId,

                    // CSRF token.
                    _token:             vl_util.csrf_token()
                },
                dataType: "json",
                method: "POST",
                success: function ( res )
                {
                    if ( res.success )
                    {
                        window.location.href = window.location.href;
                    }
                    else
                    {
                    }
                }
            } );
        }
    }

    /**
     * Called on click to cancel mandate.
     * 
     * @param {MouseEvent} event 
     */
    onClickCancelMandate( event )
    {
        const mandateId = $( event.target ).data( "mandate-id" ) ?? $( event.target ).parent( "a" ).data( "mandate-id" );

        if ( confirm( "Are you sure you would like to cancel this Direct Debit mandate? All subscriptions will be cancelled." ) )
        {
            window.location.href = vl_util.site_url() + "/api/v1/front/user-details/mandates/" + mandateId + "/destroy";
        }
    }

    /**
     * Called when user is clicking to cancel adding a card.
     * 
     * @param {MouseEvent} event 
     */
    onClickCancelAddCard( event )
    {
        // Prevent any default behaviour.
        if ( event )
        {
            event.preventDefault();
        }

        this.setAddingPaymentMethod( false );
    }

    /**
     * Called when user is clicking to save payment method.
     * 
     * @param {MouseEvent} event 
     */
    async onClickSavePaymentMethod( event )
    {
        // Prevent any default behaviour.
        if ( event )
        {
            event.preventDefault();
        }
        
        // Stripe object.
        const stripe = this.stripe.stripe;

        // Stripe elements object.
        const elements = this.stripe.elements;

        // Ensure both these objects aren't null.
        if ( ! stripe || ! elements )
        {
            return;
        }

        // Confirm the setup intent using the entered details.
        const { error } = await stripe.confirmSetup(
        {
            elements, 
            confirmParams: 
            {
                return_url: vl_util.site_url() + "/account-settings#payment-details-header"
            }
        } );

        if ( error )
        {
            this.getElement( "cards_error_message" ).html( error.message ? error.message : "Something has gone wrong while setting up your payment method. Please try again." );
        }
        else
        {
            // Send ping to refresh payment methods.
        }
    }

    /**
     * Called when user clicks to save their phone number.
     * 
     * @param {MouseEvent} event 
     */
    onClickSavePhone( event )
    {
        // Prevent default behaviour
        if ( event )
        {
            event.preventDefault();
        }

        const newPhone = this.getElement( "user_phone" ).val();

        $.ajax(
        {
            url:        vl_util.site_url() + "/api/v1/front/user-details/phone",
            dataType:   "json",
            method:     "post",
            data: 
            {
                // CSRF token.
                _token: vl_util.csrf_token(),

                // The new phone number value.
                phone: newPhone
            },

            success: function ( json )
            {
                if ( json.status )
                {
                    window.location.href = window.location.href;
                }
                else
                {

                }
            },

            error: function ( jqXHR, error )
            {

            }
        } );
    }

    onClickCancelPhone( event )
    {
        // Prevent default behaviour
        if ( event )
        {
            event.preventDefault();
        }

        this.setEditingPhone( false );
    }

    /**
     * Called when a file is selected for upload on profile image changer.
     * 
     * @param {Event} event The event.
     */
    onChangeProfileImageFile( event )
    {
        // Get the input which has been changed.
        const input = event.target;

        // Check the element, make sure it has files.
        if ( ! input || ! input.files || ! input.files.length )
        {
            return;
        }

        var reader = new FileReader();

        reader.onload = ( ( event ) => 
        {
            if ( ! event || ! event.target )
            {
                return;
            }

            const result = event.target.result;

            if ( ! result )
            {
                return;
            }

            this.getElement( "edit_profile_image_preview" ).attr( "src", result ).css( "visibility", "visible" );
        } ).bind( this );

        // Read as data URL
        reader.readAsDataURL( input.files[ 0 ] );

        var formData = new FormData();
        var files = input.files[ 0 ];

        // Add photo to form data.
        formData.append( "photo", files );

        // Add CSRF token to form data.
        formData.append( "_token", vl_util.csrf_token() );

        $.ajax(
        {
            url: vl_util.site_url() + "/api/v1/front/user-details/profile-image",
            type: "post",
            data: formData,
            contentType: false,
            processData: false,

            success: function ( response )
            {
                // window.location.href = window.location.href;
            }
        } );
    }

    /**
     * Input event on first name input.
     * 
     * @param {InputEvent} event 
     */
    onInputFirstName( event )
    {
        this.getValidator( "first_name" ).removeClass( "active not-active" );
        this.fullValidate( false );
    }
 
     /**
      * Input event on first name input.
      * 
      * @param {InputEvent} event 
      */
    onInputLastName( event )
    {
        this.getValidator( "last_name" ).removeClass( "active not-active" );
        this.fullValidate( false );
    }

    /**
     * Called when input event is fired on the email address (edit) fields.
     * Password for same form is below.
     * 
     * @param {InputEvent} event 
     */
    onInputEmailField( event )
    {
        // Get target.
        const target = $( event.target );

        // Check target.
        if ( ! target || ! target.length )
        {
            return;
        }

        // Get the (custom) element name (NOT NAME ATTRIBUTE) from the ID
        const elementName = event.target.id.replace( /\s/g, "" ).replace( /\-/g, "_" );

        // If no element name, no element..?
        if ( ! elementName )
        {
            return;
        }

        // Get the validator from the element name.
        const validator = this.getValidator( elementName );

        // If no validator, no validation..?
        if ( ! validator )
        {
            return;
        }

        // Remove active & not active classes.
        validator.removeClass( "active not-active" );
    }

    /**
     * Called when input event is fired on the password field for the email updating.
     * 
     * @param {InputEvent} event 
     */
    onInputPasswordField( event )
    {
        this.getValidator( "user_email_current_password" ).removeClass( "active not-active" );
    }

    /**
     * Called when input event is fired on a generic password input, for the password updating.
     * 
     * @param {InputEvent} event 
     */
    onInputGenericPasswordField( event )
    {
        // Get target.
        const target = $( event.target );

        // Check target.
        if ( ! target || ! target.length )
        {
            return;
        }

        // Get the (custom) element name (NOT NAME ATTRIBUTE) from the ID
        const elementName = event.target.id.replace( /\s/g, "" ).replace( /\-/g, "_" );

        // If no element name, no element..?
        if ( ! elementName )
        {
            return;
        }

        // Get the validator from the element name.
        const validator = this.getValidator( elementName );

        // If no validator, no validation..?
        if ( ! validator )
        {
            return;
        }

        // Remove active & not active classes.
        validator.removeClass( "active not-active" );
    }

    /**
     * Sets whether we're editing or not, will also update containers.
     * 
     * @param {Boolean} newIsEditingName
     */
    setEditingName( newIsEditingName )
    {
        this.isEditingName = newIsEditingName;

        if ( this.isEditingName )
        {
            this.elements.edit_full_name_view.addClass( "hidden" );
            this.elements.edit_full_name.removeClass( "hidden" );
        }
        else
        {
            this.elements.edit_full_name_view.removeClass( "hidden" );
            this.elements.edit_full_name.addClass( "hidden" );
        }
    }

    /**
     * Sets whether we're currently editing the email address or not, will also update containers.
     * 
     * @param {Boolean} newIsEditingEmail 
     */
    setEditingEmail( newIsEditingEmail )
    {
        this.isEditingEmail = newIsEditingEmail;

        if ( this.isEditingEmail )
        {
            this.getElement( "edit_email_view" ).addClass( "hidden" );
            this.getElement( "edit_email" ).removeClass( "hidden" );
        }
        else
        {
            this.getElement( "edit_email_view" ).removeClass( "hidden" );
            this.getElement( "edit_email" ).addClass( "hidden" );
        }
    }

    /**
     * Sets whether we're currently editing the email address or not, will also update containers.
     * 
     * @param {Boolean} newIsEditingPassword 
     */
    setEditingPassword( newIsEditingPassword )
    {
        this.isEditingPassword = newIsEditingPassword;

        if ( this.isEditingPassword )
        {
            this.getElement( "edit_password_view" ).addClass( "hidden" );
            this.getElement( "edit_password" ).removeClass( "hidden" );
        }
        else
        {
            this.getElement( "edit_password_view" ).removeClass( "hidden" );
            this.getElement( "edit_password" ).addClass( "hidden" );
        }
    }

    /**
     * Sets state for if we're currently editing the address.
     * 
     * @param {Boolean} isEditing 
     */
    setEditingAddress( isEditing )
    {
        this.isEditingAddress = isEditing;

        if ( this.isEditingAddress )
        {
            this.getElement( "edit_address_view" ).addClass( "hidden" );
            this.getElement( "edit_address" ).removeClass( "hidden" );   
        }
        else
        {
            this.getElement( "edit_address_view" ).removeClass( "hidden" );
            this.getElement( "edit_address" ).addClass( "hidden" );
        }
    }

    /**
     * Sets state for if we're currently adding a payment method.
     * 
     * @param {Boolean} isEditing 
     */
    setAddingPaymentMethod( isAdding )
    {
        this.isAddingPaymentMethod = isAdding;

        if ( this.isAddingPaymentMethod )
        {
            
        }
        else
        {
            $( "#payment-wrapper" ).slideUp( 150 );
            $( "#payment-element" ).html( "" );
            this.getElement( "btn_add_new_card" ).slideDown( 150 );
        }
    }

    /**
     * Sets state for if we're currently editing the phone number.
     * 
     * @param {Boolean} isEditing 
     */
    setEditingPhone( isEditing )
    {
        this.isEditingPhone = isEditing;

        if ( this.isEditingPhone )
        {
            this.getElement( "edit_phone_view" ).addClass( "hidden" );
            this.getElement( "edit_phone" ).removeClass( "hidden" );
        }
        else
        {
            this.getElement( "edit_phone_view" ).removeClass( "hidden" );
            this.getElement( "edit_phone" ).addClass( "hidden" );
        }
    }

    /**
     * Sets whether we can save the users name right now.
     * 
     * @param {Booean} isEnabled 
     */
    setSaveEnabled( isEnabled )
    {
        // Check the element.
        if ( ! this.isElementAvailable( "btn_edit_user_name_save" ) )
        {
            return;
        }

        // Get the element.
        const btn = this.getElement( "btn_edit_user_name_save" );

        // Modify classes.
        if ( isEnabled )
        {
            btn.addClass( "btn-active" ).removeClass( "btn-disabled" );
        }
        else
        {
            btn.removeClass( "btn-active" ).addClass( "btn-disabled" );
        }
    }

    /**
     * Sets whether we can save the users address.
     * 
     * @param {Boolean} isEnabled 
     */
    setSaveAddressEnabled( isEnabled )
    {
        // Check the element.
        if ( ! this.isElementAvailable( "btn_address_save" ) )
        {
            return;
        }

        // Get the element.
        const btn = this.getElement( "btn_address_save" );

        // Modify classes.
        if ( isEnabled )
        {
            btn.addClass( "btn-active" ).removeClass( "btn-disabled" );
        }
        else
        {
            btn.removeClass( "btn-active" ).addClass( "btn-disabled" );
        }
    }

    /**
     * Sets whether we can update users email address.
     * 
     * @param {Boolean} isEnabled 
     * @returns 
     */
    setSaveEmailEnabled( isEnabled )
    {
        // Check the element.
        if ( ! this.isElementAvailable( "btn_save_email" ) )
        {
            return;
        }

        // Get the element.
        const btn = this.getElement( "btn_save_email" );

        // Modify classes.
        if ( isEnabled )
        {
            btn.addClass( "btn-active" ).removeClass( "btn-disabled" );
        }
        else
        {
            btn.removeClass( "btn-active" ).addClass( "btn-disabled" );
        }
    }

    /**
     * Sets whether we can update the users password right now.
     * @param {Boolean} isEnabled 
     */
    setSavePasswordEnabled( isEnabled )
    {
        if ( ! this.isElementAvailable( "btn_save_password" ) )
        {
            return;
        }

        const btn = this.getElement( "btn_save_password" );

        if ( isEnabled )
        {
            btn.addClass( "btn-active" ).removeClass( "btn-disabled" );
        }
        else
        {
            btn.removeClass( "btn-active" ).addClass( "btn-disabled" );
        }
    }

    /**
     * Initialises an element.
     * 
     * @param {String} elementName The internal name of this element, in format `some_string_no_spaces_no_caps_no_funky_biz`.
     * @param {String|null} elementId Set to null to resolve using elementName, otherwise pass the ID of an element WITHOUT the #.
     * @returns {JQuery|null} The element entry.
     */
    initialiseElement( elementName, elementId = null )
    {
        // If the element is already initialised then we just want to return the already initialised element, let console know this has been used wrong.
        if ( this.isElementAvailable( elementName ) )
        {
            // Debug output to let programmer know they've tried to initialise the element more than once.
            vl_util.dbgout( "UserProfileController", `Attempt to re-initialise ${elementName} blocked - previous result given.` );

            // Return the old element.
            return this.getElement( elementName );
        }

        // If null is passed for the element ID, replace all underscores with dashes (as this is my format for IDs).
        if ( ! elementId )
        {
            elementId = elementName.replace( /\_/g, "-" );
        }

        // Ensure ID has hash symbol in correct place, removes all hashes and adds one to the front.
        elementId = `#${ elementId.replace( /\#/g, "" ) }`;

        // Retrieve the element.
        this.elements[ elementName ] = $( elementId );

        // If the retrieval is unsuccessful, this will return null.
        const createdElement = this.getElement( elementName );

        // Return the element set.
        return createdElement;
    }

    /**
     * Initialises an element, by class name instead of ID, so we can hold multiple elements.
     * 
     * @param {String} elementName 
     */
    initialiseElementClass( elementName, elementClass = null )
    {
        // If the element is already initialised then we just want to return the already initialised element, let console know this has been used wrong.
        if ( this.isElementAvailable( elementName ) )
        {
            // Debug output to let programmer know they've tried to initialise the element more than once.
            vl_util.dbgout( "UserProfileController", `Attempt to re-initialise ${elementName} blocked - previous result given.` );

            // Return the old element.
            return this.getElement( elementName );
        }

        // If null is passed for the element ID, replace all underscores with dashes (as this is my format for IDs).
        if ( ! elementClass )
        {
            elementClass = elementName.replace( /\_/g, "-" );
        }

        // Ensure ID has hash symbol in correct place, removes all hashes and adds one to the front.
        elementClass = `.${ elementClass.replace( /\./g, "" ) }`;

        // Retrieve the element.
        this.elements[ elementName ] = $( elementClass );

        // If the retrieval is unsuccessful, this will return null.
        const createdElement = this.getElement( elementName );

        // Return the element set.
        return createdElement;
    }

    /**
     * Checks if an element in the `this.elements` object is available.
     * 
     * @param {String} elementName 
     * @returns {Boolean} Whether the given element exists and is on the page.
     */
    isElementAvailable( elementName )
    {
        return  ( this.elements[ elementName ] !== undefined ) && 
                ( this.elements[ elementName ] !== null ) && 
                ( this.elements[ elementName ] !== $() ) && 
                ( this.elements[ elementName ].length !== 0 );
    }

    /**
     * Attempts to retrieve an element from the `this.elements` object.
     * 
     * @param {String} elementName 
     * @returns {JQuery|null} The element JQuery set.
     */
    getElement( elementName )
    {
        if ( ! this.isElementAvailable( elementName ) )
        {
            return null;
        }

        return this.elements[ elementName ];
    }

    /**
     * Gets an elements value attribute value.
     * 
     * @param {?} elementName 
     */
    getElementValue( elementName )
    {
        const element = this.getElement( elementName );

        if ( ! element )
        {
            return null;
        }

        const val = element.val();

        if ( ! val )
        {
            return null;
        }

        return val;
    }   

    /**
     * 
     * @param {String} elementName 
     * @param {Boolean} isSelect If the element is a select, special attention must be given.
     * @return {JQuery|null} The validator element JQuery set.
     */
    initialiseValidator( elementName, isSelect = false )
    {
        if ( isSelect )
        {
            this.validators[ elementName ] = this.getElement( elementName );
        }
        else
        {
            this.validators[ elementName ] = this.getElement( elementName ).parents( ".validator" );
        }

        return this.validators[ elementName ];
    }

    /**
     * Checks whether a validator is available.
     * 
     * @param {String} elementName The name of the element in the validators object.
     * @return {Boolean} Whether the given validator is available.
     */
    isValidatorAvailable( elementName )
    {
        return this.validators[ elementName ] !== undefined && this.validators[ elementName ].length;
    }

    /**
     * Gets the validator for an element.
     * 
     * @param {String} elementName The name of the element.
     * @return {JQuery|null} The validator set.
     */
    getValidator( elementName )
    {
        if ( ! this.isValidatorAvailable( elementName ) )
        {
            return null;
        }

        return this.validators[ elementName ];
    }

    /**
     * Updates the users full name using ajax.
     * 
     * @param {String} title 
     * @param {String} first_name 
     * @param {String} last_name 
     */
    updateFullName( title, first_name, last_name, error = ( json ) => {} )
    {
        $.ajax(
        {
            url:        vl_util.site_url() + "/api/v1/front/user-details/full-name",
            dataType:   "JSON",
            method:     "POST",
            data:       
            {
                first_name: first_name,
                last_name:  last_name,
                title:      title,
                _token:     vl_util.site_config().csrf_token
            },
            success: function ( json )
            {
                if ( json.status )
                {
                    window.location.href = window.location.href;
                }
                else
                {
                    if ( error )
                    {
                        error.bind( this )( json );
                    }
                }
            },
            error: function ( res )
            {}
        } );
    }

    /**
     * Performs a full validation on all fields.
     * 
     * @param {Boolean} isVisual Should we visually reflect the validation state on each element?
     * @return {Boolean} The validation state.
     */
    fullValidate( isVisual = true )
    {
        // Have to be editing it to validate it.
        if ( ! this.isEditingName )
        {
            return false;
        }

        // Remove the valid from the validators.
        this.getValidator( "first_name" ).removeClass( "valid not-valid" );
        this.getValidator( "last_name" ).removeClass( "valid not-valid" );

        // First name validation state.
        const firstNameValid    = this.validateFirstName();

        // Last name validation state.
        const lastNameValid     = this.validateLastName();

        // Title validation state.
        const titleValid        = this.validateTitle();

        // Get full validation state.
        this.isFullNameValidated = firstNameValid && lastNameValid && titleValid;

        if ( ! this.isFullNameValidated && isVisual )
        {
            if ( ! firstNameValid )
            {
                this.getElement( "first_name" ).trigger( "startRumble" );
                this.getValidator( "first_name" ).addClass( "not-valid" ).removeClass( "valid" );

                setTimeout( ( () => 
                {
                    this.getElement( "first_name" ).trigger( "stopRumble" );
                } ).bind( this ), 150 );
            }

            if ( ! lastNameValid )
            {
                this.getElement( "last_name" ).trigger( "startRumble" );
                this.getValidator( "last_name" ).addClass( "not-valid" ).removeClass( "valid" );

                setTimeout( ( () => 
                {
                    this.getElement( "last_name" ).trigger( "stopRumble" );
                } ).bind( this ), 150 );
            }

            if ( ! titleValid )
            {
                this.getValidator( "title" ).addClass( "not-valid" ).removeClass( "valid" ).trigger( "startRumble" );

                setTimeout( ( () => 
                {
                    this.getValidator( "title" ).trigger( "stopRumble" );
                } ).bind( this ), 150 );
            }
        }

        if ( firstNameValid )
        {
            this.getValidator( "first_name" ).addClass( "valid" ).removeClass( "not-valid" );
        }

        if ( lastNameValid )
        {
            this.getValidator( "last_name" ).addClass( "valid" ).removeClass( "not-valid" );            
        }

        if ( titleValid )
        {
            this.getValidator( "title" ).addClass( "valid" ).removeClass( "not-valid" );
        }

        this.setSaveEnabled( this.isFullNameValidated );

        return this.isFullNameValidated;
    }

    /**
     * 
     * @param {Boolean} isVisual 
     */
    fullValidateAddress( isVisual = true )
    {
        // Have to be editing it to validate it.
        if ( ! this.isEditingAddress )
        {
            return false;
        }

        // Remove the valid from the validators.
        this.getValidator( "address_st_num" )       .removeClass( "valid not-valid" );
        this.getValidator( "address_street" )       .removeClass( "valid not-valid" );
        this.getValidator( "address_town" )         .removeClass( "valid not-valid" );
        this.getValidator( "address_county" )       .removeClass( "valid not-valid" );
        this.getValidator( "address_postal_code" )  .removeClass( "valid not-valid" );
        this.getValidator( "address_country" )      .removeClass( "valid not-valid" );

        // Street num / name status.
        const stNumValid        = this.validateAddressField( "address_st_num" );

        // Street validation status.
        const streetValid       = true;

        // Town validation status.
        const townValid         = true;

        // County validation status.
        const countyValid       = this.validateAddressField( "address_county" );

        // Postal code validation status.
        const postalCodeValid   = this.validateAddressField( "address_postal_code" );
        
        // Country validation status.
        const countryValid      = this.validateAddressField( "address_country" );

        // Get full validation state.
        this.isAddressValidated = stNumValid && streetValid && townValid && countyValid && postalCodeValid && countryValid;

        if ( ! this.isAddressValidated && isVisual )
        {
            // Street number
            if ( ! stNumValid )
            {
                this.getElement( "address_st_num" ).trigger( "startRumble" );
                this.getValidator( "address_st_num" ).addClass( "not-valid" ).removeClass( "valid" );

                this.rumbleTimed( "address_st_num" );
            }
            
            // Street
            if ( ! streetValid )
            {
                this.getElement( "address_street" ).trigger( "startRumble" );
                this.getValidator( "address_street" ).addClass( "not-valid" ).removeClass( "valid" );

                this.rumbleTimed( "address_street" );
            }
            
            // Town
            if ( ! townValid )
            {
                this.getElement( "address_town" ).trigger( "startRumble" );
                this.getValidator( "address_town" ).addClass( "not-valid" ).removeClass( "valid" );

                this.rumbleTimed( "address_town" );
            }

            // County
            if ( ! countyValid )
            {
                this.getElement( "address_county" ).trigger( "startRumble" );
                this.getValidator( "address_county" ).addClass( "not-valid" ).removeClass( "valid" );

                this.rumbleTimed( "address_county" );
            }

            // Postal Code
            if ( ! postalCodeValid )
            {
                this.getElement( "address_postal_code" ).trigger( "startRumble" );
                this.getValidator( "address_postal_code" ).addClass( "not-valid" ).removeClass( "valid" );

                setTimeout( ( () => 
                {
                    this.getElement( "address_postal_code" ).trigger( "stopRumble" );
                } ).bind( this ), 150 );
            }

            // Country
            if ( ! countryValid )
            {
                this.getElement( "address_country" ).trigger( "startRumble" );
                this.getValidator( "address_country" ).addClass( "not-valid" ).removeClass( "valid" );

                setTimeout( ( () => 
                {
                    this.getElement( "address_country" ).trigger( "stopRumble" );
                } ).bind( this ), 150 );
            }
        }

        if ( stNumValid )
        {
            this.getValidator( "address_st_num" ).addClass( "valid" ).removeClass( "not-valid" );
        }

        if ( streetValid )
        {
            this.getValidator( "address_street" ).addClass( "valid" ).removeClass( "not-valid" );            
        }

        if ( townValid )
        {
            this.getValidator( "address_town" ).addClass( "valid" ).removeClass( "not-valid" );
        }

        if ( countyValid )
        {
            this.getValidator( "address_county" ).addClass( "valid" ).removeClass( "not-valid" );
        }

        if ( postalCodeValid )
        {
            this.getValidator( "address_postal_code" ).addClass( "valid" ).removeClass( "not-valid" );
        }

        if ( countryValid )
        {
            this.getValidator( "address_country" ).addClass( "valid" ).removeClass( "not-valid" );
        }

        this.setSaveAddressEnabled( this.isAddressValidated );

        if ( this.isAddressValidated )
        {
            this.getValidator( "address_auto_input" ).removeClass( "not-valid" ).addClass( "valid" );
        }

        return this.isAddressValidated;
    }

    /**
     * Performs the full validation on the email editing form.
     */
    fullValidateEmail( isVisual = true )
    {
        // Have to be editing it to validate it.
        if ( ! this.isEditingEmail )
        {
            return false;
        }
        
        // Remove visual validator states.
        this.getValidator( "user_current_email" )           .removeClass( "valid not-valid" );
        this.getValidator( "user_new_email" )               .removeClass( "valid not-valid" );
        this.getValidator( "user_new_email_confirm" )       .removeClass( "valid not-valid" );
        this.getValidator( "user_email_current_password" )  .removeClass( "valid not-valid" );

        // Validate each of the fields
        const currentEmailValid = this.validateEmailField( "user_current_email" );
        const newEmailValid     = this.validateEmailField( "user_new_email" );
        const confirmEmailValid = this.validateEmailField( "user_new_email_confirm" );
        const currentPassValid  = this.validatePasswordField( "user_email_current_password" );

        // Collect full validation state.
        this.isEmailValidated = currentEmailValid && newEmailValid && confirmEmailValid && currentPassValid;

        // If invalid, and we're wanting to update visually.
        if ( ! this.isEmailValidated && isVisual )
        {
            // Street number valid?
            if ( ! currentEmailValid )
            {
                this.getElement( "user_current_email" ).trigger( "startRumble" );
                this.getValidator( "user_current_email" ).addClass( "not-valid" ).removeClass( "valid" );

                this.rumbleTimed( "user_current_email" );
            }

            // New email valid?
            if ( ! newEmailValid )
            {
                this.getElement( "user_new_email" ).trigger( "startRumble" );
                this.getValidator( "user_new_email" ).addClass( "not-valid" ).removeClass( "valid" );

                this.rumbleTimed( "user_new_email" );
            }

            // New email valid?
            if ( ! confirmEmailValid )
            {
                this.getElement( "user_new_email_confirm" ).trigger( "startRumble" );
                this.getValidator( "user_new_email_confirm" ).addClass( "not-valid" ).removeClass( "valid" );

                this.rumbleTimed( "user_new_email_confirm" );
            }
            
            // New email valid?
            if ( ! currentPassValid )
            {
                this.getElement( "user_email_current_password" ).trigger( "startRumble" );
                this.getValidator( "user_email_current_password" ).addClass( "not-valid" ).removeClass( "valid" );

                this.rumbleTimed( "user_email_current_password" );
            }
        }

        // Add valid state to current email
        if ( currentEmailValid )
        {
            this.getValidator( "user_current_email" ).addClass( "valid" );
        }

        // Add valid state to new email
        if ( newEmailValid )
        {
            this.getValidator( "user_new_email" ).addClass( "valid" );
        }

        // Add valid state to new email confirmation.
        if ( confirmEmailValid )
        {
            this.getValidator( "user_new_email_confirm" ).addClass( "valid" );
        }

        // Add valid state to current password
        if ( currentPassValid )
        {
            this.getValidator( "user_email_current_password" ).addClass( "valid" );
        }

        // Set the email saving status.
        this.setSaveEmailEnabled( this.isEmailValidated );

        // Is validated?
        return this.isEmailValidated;
    }

    /**
     * Performs the full validation for the password modification
     * 
     * @param {Boolean} isVisual Will this validation update things visually?
     * @return {Boolean} Full validation state.
     */
    fullValidatePassword( isVisual = true )
    {
        // Have to be editing it to validate it.
        if ( ! this.isEditingPassword )
        {
            return false;
        }
        
        // Remove visual validator states.
        this.getValidator( "user_password_old" )            .removeClass( "valid not-valid" );
        this.getValidator( "user_password_new" )            .removeClass( "valid not-valid" );
        this.getValidator( "user_password_new_repeat" )     .removeClass( "valid not-valid" );

        // Validate each of the fields
        const oldPasswordValid      = this.validatePasswordField( "user_password_old" );
        const newPasswordValid      = this.validatePasswordField( "user_password_new" );
        const repeatPasswordValid   = this.validatePasswordField( "user_password_new_repeat" );

        // Collect full validation state.
        this.isPasswordValidated = oldPasswordValid && newPasswordValid && repeatPasswordValid;

        // If invalid, and we're wanting to update visually.
        if ( ! this.isPasswordValidated && isVisual )
        {
            // Old password valid?
            if ( ! oldPasswordValid )
            {
                this.getElement( "user_password_old" ).trigger( "startRumble" );
                this.getValidator( "user_password_old" ).addClass( "not-valid" ).removeClass( "valid" );

                this.rumbleTimed( "user_password_old" );
            }

            // New password valid?
            if ( ! newPasswordValid )
            {
                this.getElement( "user_password_new" ).trigger( "startRumble" );
                this.getValidator( "user_password_new" ).addClass( "not-valid" ).removeClass( "valid" );

                this.rumbleTimed( "user_password_new" );
            }

            // Repeated new password valid?
            if ( ! repeatPasswordValid )
            {
                this.getElement( "user_password_new_repeat" ).trigger( "startRumble" );
                this.getValidator( "user_password_new_repeat" ).addClass( "not-valid" ).removeClass( "valid" );

                this.rumbleTimed( "user_password_new_repeat" );
            }
        }

        // Add valid state to current email
        if ( oldPasswordValid )
        {
            this.getValidator( "user_password_old" ).addClass( "valid" );
        }

        // Add valid state to new email
        if ( newPasswordValid )
        {
            this.getValidator( "user_password_new" ).addClass( "valid" );
        }

        // Add valid state to new email confirmation.
        if ( repeatPasswordValid )
        {
            this.getValidator( "user_password_new_repeat" ).addClass( "valid" );
        }

        // Sets the save buttons status based on validation status.
        this.setSavePasswordEnabled( this.isPasswordValidated );

        // Return the new validation status.
        return this.isPasswordValidated;
    }

    /**
     * Validates an address field.
     * 
     * @param {String} fieldName 
     */
    validateAddressField( fieldName )
    {
        const elem = this.getElement( fieldName );

        if ( ! elem || ! elem.length )
        {
            return false;
        }

        if ( fieldName === "address_street" || fieldName === "address_town" )
        {
            return true;
        }

        // Get value.
        const val = elem.val();

        // Check value validation.
        const valid = val && val.length && val.replace( /\s/g, "" ).length;

        // If not valid, switch screen to manual forcefully so user sees validation errors.
        if ( ! valid )
        {
            this.getElement( "btn_manual_address" ).trigger( "click" );
        }

        return valid;
    }
    
    /**
     * Validates an email field.
     * 
     * @param {String} fieldName The field name
     * @return {Boolean} The validation result. 
     */
    validateEmailField( fieldName )
    {
        // Get the actual element (jq).
        const element = this.getElement( fieldName );

        // Ensure it's on the page.
        if ( ! element )
        {
            return false;
        }

        // Get the element value.
        const value = this.getElementValue( fieldName );

        // If no value, then not valid.
        if ( ! value )
        {
            return false;
        }

        // Validate our email address.
        return vl_util.validate_email_address( value );
    }

    /**
     * Validates a password field.
     * 
     * @param {String} fieldName The field name
     * @return {Boolean} The validation result.
     */
    validatePasswordField( fieldName )
    {
        // Get the actual element (jq).
        const element = this.getElement( fieldName );

        // Ensure it's on the page.
        if ( ! element )
        {
            return false;
        }

        // Get the element value.
        const value = this.getElementValue( fieldName );

        // If no value, then not valid.
        if ( ! value )
        {
            return false;
        }

        // For now - only password validation required is knowing the string length is 8 or above.
        return value.length >= 8;
    }

    /**
     * Validates the value of the first name field.
     * 
     * @return {Boolean} Validation status.
     */
    validateFirstName()
    {
        if ( ! this.isElementAvailable( "first_name" ) )
        {
            return false;
        }

        const firstNameVal = this.getElement( "first_name" ).val();

        if ( ! firstNameVal || ! firstNameVal.length )
        {
            return false;
        }
        
        return true;
    }
 
     /**
      * Validates the value of the last name field.
      * 
      * @return {Boolean} Validation status.
      */
    validateLastName()
    {
        if ( ! this.isElementAvailable( "last_name" ) )
        {
            return false;
        }

        const lastNameVal = this.getElement( "last_name" ).val();

        if ( ! lastNameVal || ! lastNameVal.length )
        {
            return false;
        }
        
        return true;
    }
    
    /**
     * Validates the title input.
     * 
     * @returns {Boolean} Validation status.
     */
    validateTitle()
    {
        if ( ! this.isElementAvailable( "title" ) )
        {
            return false;
        }

        const titleVal = this.getElement( "title" ).val();

        if ( ! titleVal || ! titleVal.length )
        {
            return false;
        }
        
        return true;
    }

    /**
     * Gets the address in one line.
     * 
     * @return {String|null}
     */
    getAddressOneline()
    {
        // Collect the address components, in order.
        const addressComponenets = 
        [
            this.getElementValue( "address_st_num" ),
            this.getElementValue( "address_street" ),
            this.getElementValue( "address_town" ),
            this.getElementValue( "address_county" ),
            this.getElementValue( "address_postal_code" ),
            this.getElementValue( "address_coutry" )
        ];

        // Final output string.
        let out = "";
        
        for ( let idx in addressComponenets )
        {
            // Get address component.
            const component = addressComponenets[ idx ];

            // Check value.
            if ( ! component || ! component.length )
            {
                continue;
            }

            out += `${component}, `;
        }

        // Remove trailing whitespace.
        out = out.trim();

        // If there's a comma at the end, remove it.
        if ( out.charAt( out.length - 1 ) === "," )
        {
            out = out.substring( 0, out.length - 1 );
        }

        return out;
    }

    /**
     * Starts an elements validator rumble for a given amount of time.
     * 
     * @param {String} elementName The name of the element in this controller to target.
     * @param {Number} time The number of milliseconds to rumble for.
     */
    rumbleTimed( elementName, time = 150.0 )
    {
        // Get the validator
        const validator = this.getValidator( elementName );

        // Check the validator
        if ( ! validator )
        {
            return;
        }

        // Start rumbling
        validator.trigger( "startRumble" );

        // Create a new timeout, with the thisArgs set as this controller.
        setTimeout( ( () => 
        {
            // Trigger stop rumble after the desired amount of time.
            validator.trigger( "stopRumble" );
        } ).bind( this ), time );
    }

    /**
     * Sets the user-visible error string for the email updating.
     * 
     * @param {String|null|Boolean} str 
     */
    setEmailError( str = false )
    {
        if ( ! str )
        {
            this.getElement( "email_error_message" ).addClass( "hidden" ).text( "" );
        }
        else
        {
            this.getElement( "email_error_message" ).removeClass( "hidden" ).text( str );
        }
    }

    /**
     * Sets the user-visible error string for the password updating.
     * 
     * @param {String|null|Boolean} str
     */
    setPasswordError( str = false )
    {
        if ( ! str )
        {
            this.getElement( "password_error_message" ).addClass( "hidden" ).text( "" );
        }
        else
        {
            this.getElement( "password_error_message" ).removeClass( "hidden" ).text( str );
        }
    }

    setPhoneError( str = false )
    {
        if ( ! str )
        {
            this.getElement( "phone_error_message" ).addClass( "hidden" ).text( "" );
        }
        else
        {
            this.getElement( "phone_error_message" ).removeClass( "hidden" ).text( "" );
        }
    }

    /**
     * Creates setup intent for adding card on stripe.
     */
    async createSetupIntent()
    {
        let response = await fetch( vl_util.site_url() + "/api/v1/front/user-details/setup-intent",
        {
            method: "POST",
            headers: 
            { 
                "Content-Type": "application/json",
                'X-CSRF-TOKEN': vl_util.csrf_token()
            }
        } );

        return response.json();
    }
}