// International telephone input.
import intlTelInput from 'intl-tel-input';
import $                    from "jquery";
import jQuery               from "jquery";
import { jrumble } from '../../vendor/jrumble/jrumble';
import { vl_util } from '../general/util';

// Inputs
import { inputs } from "./inputs";

/**
 * Handles all functionality etc to do with the donation completion page.
 */
export class DonationCompleted
{
    /**
     * Current selected contact type.
     */
    selected_contact_type = "BOTH";

    /**
     * International telephone input object.
     */
    completed_form_phone  = null;

    /**
     * Has the contact form been validated fully?
     */
    form_validated = false;

    // Class Constructor
    constructor()
    {
        // Do not run on NOP page.
        if ( $( "html[data-dv2-nop-complete]" ).length )
        {
            return;
        }

        $( this.onReady.bind( this ) );
    }

    /**
     * Handles document ready event.
     */
    onReady()
    {
        this.bindClickContactMeRadio();
        this.bindTelephoneInput();
        this.bindClickContactMeSubscribe();
        this.bindValidationEvents();

        jrumble( $ );

        $( "#completed_email" ).parent( ".validator" ).jrumble();
        $( "#completed_phone" ).parent( ".validator" ).jrumble();

        window.completed = this;
    }

    /**
     * Bindings for the radio for contact me etc.
     */
    bindClickContactMeRadio()
    {
        $( "#contact-email, #contact-phone, #contact-both" ).on( "click", { _this_ref : this }, ( function ( event ) 
        {
            const _this = event.data._this_ref;

            _this.selected_contact_type = $( this ).val();

            switch ( $( this ).val() )
            {
            case "BOTH":
                _this.onClickContactBoth();
                break;

            case "PHONE":
                _this.onClickContactPhone();
                break;
            
            case "EMAIL":
            default:
                _this.onClickContactEmail();
                break;
            }
        } ) );
    }

    /**
     * Sets up the telephone input.
     */
    bindTelephoneInput()
    {
        const $completed_phone = $( "#completed_phone" );

        if ( $completed_phone.get( 0 ) )
        {
            this.completed_form_phone = intlTelInput( $completed_phone.get( 0 ), 
            {
                // Util script for extra features.
                utilsScript: "/app/assets/js/vendor/intl-tel-input/utils.js",

                // Placeholder should force override always.
                autoPlaceholder: "aggressive",

                // Automatically format.
                formatOnDisplay: true,

                // We want the country to be automated based on location.
                initialCountry: "auto",

                // Fixes the phone number strings.
                nationalMode: false,

                // Geoip lookup func.
                geoIpLookup: inputs.geoip_lookup,

                // Hidden input for actual full phone number.
                hiddenInput: "completed_phone_full",

                // Initial country to UK even if geoIP lookup doesn't work.
                initialCountry: "GB"
            } );

            $completed_phone.on( "focusout", { _this_ref : this }, this.onFormatTelephone );
        }
    }
    
    /**
     * Binds the subscribe button, properly.
     */
    bindClickContactMeSubscribe()
    {
        if ( $( "#contact-subscribe" ).length )
        {
            $( "#contact-subscribe" ).on( "click", { _this_ref : this }, this.onClickSubscribe );
        }
    }

    /**
     * Binds validation events for the completion form.
     */
    bindValidationEvents()
    {
        jrumble( $ );

        $( "#completed_email" ).jrumble();
        $( "#completed_phone" ).jrumble();
    }

    /**
     * When user clicks contact with both types.
     */
    onClickContactBoth()
    {
        $( "#completed_phone" ).prop( "required", true );
        $( "#completed_email").prop( "required", true );

        $( "#phone-number-field" ).removeClass( "hidden" );
        $( "#email-field" ).removeClass( "hidden" );
    }

    /**
     * On click email address only contact.
     */
    onClickContactEmail()
    {
        $( "#completed_phone" ).removeAttr( "required" );
        $( "#completed_email" ).prop( "required", true );

        $( "#phone-number-field" ).addClass( "hidden" );
        $( "#email-field" ).removeClass( "hidden" );
    }
    
    /**
     * On click the phone number only contact.
     */
    onClickContactPhone()
    {
        $( "#completed_phone" ).prop( "required", true );
        $( "#completed_email" ).removeAttr( "required" );

        $( "#email-field" ).addClass( "hidden" );
        $( "#phone-number-field" ).removeClass( "hidden" );
    }

    onClickSubscribe( event )
    {
        const _this = event.data._this_ref;
        const $donation_type = $( "#donation_type" );

        if ( ! $donation_type.length )
        {
            return false;
        }

        if ( ! $donation_type.val() || ! $donation_type.val().length )
        {
            return false;
        }

        _this.form_validated = true;

        switch ( $donation_type.val().toLowerCase() )
        {
        case "stripe":
            _this.saveContactDetailsStripe();
            break;
        
        case "paypal":
            _this.saveContactDetailsPayPal();
            break;
        
        case "recurring":
            _this.saveContactDetailsRecurring();
            break;
        
        case "hifz":
            _this.saveContactDetailsHifz();
            break;

        default:
            return;
        }   

        return true;
    }

    /**
     * Fired on events which require formatting our telephone input.
     */
    onFormatTelephone( event )
    {
        const _this_ref = event.data._this_ref;

        // Utils are lazy loaded, so must check
        if ( typeof intlTelInputUtils === "undefined" )
        {
            return;
        }

        // Gets number from input.
        var currentText = _this_ref.completed_form_phone.getNumber( intlTelInputUtils.numberFormat.E164 );
        
        // Then sets the number, which fires the format event.
        if ( typeof currentText === "string" ) 
        { 
            _this_ref.completed_form_phone.setNumber( currentText );
        }
    }

    /**
     * Disables our subscribe button.
     */
    disableSubscribeButton()
    {
        this.form_validated = false;

        $( "#contact-subscribe" ).removeClass( "btn-active" ).addClass( "btn-disabled" );
    }

    /**
     * Enables our subscribe button.
     */
    enableSubscribeButton()
    {
        this.form_validated = true;

        $( "#contact-subscribe" ).addClass( "btn-active" ).removeClass( "btn-disabled" );
    }

    /**
     * Attempts to save the new contact details on the server.
     */
    saveContactDetailsStripe()
    {
        // Don't go forth if the validation isn't done.
        if ( ! this.form_validated )
        {
            return;
        }

        // Disable the subscribe button.
        this.disableSubscribeButton();

        // Hide this indicator, if it isn't already.
        $( "#contact-details-saved" ).addClass( "hidden" );

        // Unhide the icon, if it's hidden.
        $( "#contact-details-saved > i" ).removeClass( "hidden" );

        // If this is the second attempt at this, fix the strings.
        if ( $( "#contact-details-saved > span" ).data( "og-text" ) )
        {   
            $( "#contact-details-saved > span" ).text( $( "#contact-details-saved > span" ).data( "og-text" ) );
        }
        
        var mergedata = {};

        switch ( this.selected_contact_type.toUpperCase() )
        {
        case "EMAIL":
            mergedata.contact_email = $( "#completed_email" ).val();
            break;
            
        case "PHONE":
            mergedata.contact_phone = $( "#completed_phone" ).val();
            break;
    
        default:
        case "BOTH":
            mergedata.contact_email = $( "#completed_email" ).val();
            mergedata.contact_phone = $( "#completed_phone" ).val();
            break;
        }

        const _this = this;

        // AJAX request to update the details.
        $.ajax(
        {
            url: vl_util.site_url() + "/api/v1/front/contact-details/stripe",
            dataType: "JSON",
            method: "POST",
            data:
            {
                donation_id: $( "#donation_id" ).val(),
                pi_secret_id: $( "#pi_secret_id" ).val(),
                ...mergedata   
            },
            success: function ( res )
            {
                $( "#contact-details-saved" ).removeClass( "hidden" );

                _this.disableSubscribeButton();

                if ( ! res.status && res.error )
                {
                    $( "#contact-details-saved > span" ).attr( "data-og-text", $( "#contact-details-saved" ).text() );
                    $( "#contact-details-saved > span" ).text( res.error );
                    $( "#contact-details-saved > i" ).addClass( "hidden" );
                }
            },
            error: function ()
            {
                $( "#contact-details-saved > span" ).attr( "data-og-text", $( "#contact-details-saved" ).text() );
                $( "#contact-details-saved > span" ).text( "Failed to save your details!" );
                $( "#contact-details-saved > i" ).addClass( "hidden" );
            }
        } );
    }

    /**
     * Saves contact details for paypal.
     */
    saveContactDetailsPayPal()
    {
        // Don't go forth if the validation isn't done.
        if ( ! this.form_validated )
        {
            return;
        }

        this.disableSubscribeButton();

        // Hide this indicator, if it isn't already.
        $( "#contact-details-saved" ).addClass( "hidden" );

        // Unhide the icon, if it's hidden.
        $( "#contact-details-saved > i" ).removeClass( "hidden" );

        // If this is the second attempt at this, fix the strings.
        if ( $( "#contact-details-saved > span" ).data( "og-text" ) )
        {   
            $( "#contact-details-saved > span" ).text( $( "#contact-details-saved > span" ).data( "og-text" ) );
        }
        
        var mergedata = 
        {
        };

        switch ( this.selected_contact_type )
        {
        case "EMAIL":
            mergedata.contact_email = $( "#completed_email" ).val();
            break;
            
        case "PHONE":
            mergedata.contact_phone = $( "#completed_phone" ).val();
            break;
    
        default:
        case "BOTH":
            mergedata.contact_email = $( "#completed_email" ).val();
            mergedata.contact_phone = $( "#completed_phone" ).val();
            break;
        }

        // Get transaction ID hidden input field.
        const $transaction_id = $( "#tx_id" );

        // If the transaction ID elemt is not valid, don't try to do nuthin.
        if ( ! $transaction_id.length || ! $transaction_id.val().length )
        {
            return;
        }

        const _this = this;

        // AJAX request to update the details.
        $.ajax(
        {
            url: vl_util.site_url() + "/api/v1/front/contact-details/paypal",
            dataType: "JSON",
            method: "POST",
            data:
            {
                transaction_id: $( "#tx_id" ).val(),
                ...mergedata   
            },
            success: function ( res )
            {
                $( "#contact-details-saved" ).removeClass( "hidden" );

                _this.disableSubscribeButton();

                if ( ! res.status && res.error )
                {
                    $( "#contact-details-saved > span" ).attr( "data-og-text", $( "#contact-details-saved" ).text() );
                    $( "#contact-details-saved > span" ).text( res.error );
                    $( "#contact-details-saved > i" ).addClass( "hidden" );
                }
            },
            error: function ()
            {
                $( "#contact-details-saved > span" ).attr( "data-og-text", $( "#contact-details-saved" ).text() );
                $( "#contact-details-saved > span" ).text( "Failed to save your details!" );
                $( "#contact-details-saved > i" ).addClass( "hidden" );
            }
        } );
    }

    saveContactDetailsRecurring()
    {
        // Don't go forth if the validation isn't done.
        if ( ! this.form_validated )
        {
            return;
        }

        this.disableSubscribeButton();

        // Hide this indicator, if it isn't already.
        $( "#contact-details-saved" ).addClass( "hidden" );

        // Unhide the icon, if it's hidden.
        $( "#contact-details-saved > i" ).removeClass( "hidden" );

        // If this is the second attempt at this, fix the strings.
        if ( $( "#contact-details-saved > span" ).data( "og-text" ) )
        {   
            $( "#contact-details-saved > span" ).text( $( "#contact-details-saved > span" ).data( "og-text" ) );
        }
        
        var mergedata = 
        {
        };

        switch ( this.selected_contact_type )
        {
        case "EMAIL":
            mergedata.contact_email = $( "#completed_email" ).val();
            break;
            
        case "PHONE":
            mergedata.contact_phone = $( "#completed_phone" ).val();
            break;
    
        default:
        case "BOTH":
            mergedata.contact_email = $( "#completed_email" ).val();
            mergedata.contact_phone = $( "#completed_phone" ).val();
            break;
        }

        // Get transaction ID hidden input field.
        const $transaction_id = $( "#tx_id" );

        // If the transaction ID elemt is not valid, don't try to do nuthin.
        if ( ! $transaction_id.length || ! $transaction_id.val().length )
        {
            return;
        }

        const _this = this;

        // AJAX request to update the details.
        $.ajax(
        {
            url: vl_util.site_url() + "/api/v1/front/contact-details/gocardless",
            dataType: "JSON",
            method: "POST",
            data:
            {
                transaction_id: $( "#tx_id" ).val(),
                processor:      $( "#tx_processor" ).val(),
                ...mergedata   
            },
            success: function ( res )
            {
                $( "#contact-details-saved" ).removeClass( "hidden" );

                _this.disableSubscribeButton();

                if ( ! res.status && res.error )
                {
                    $( "#contact-details-saved > span" ).attr( "data-og-text", $( "#contact-details-saved" ).text() );
                    $( "#contact-details-saved > span" ).text( res.error );
                    $( "#contact-details-saved > i" ).addClass( "hidden" );
                }
            },
            error: function ()
            {
                $( "#contact-details-saved > span" ).attr( "data-og-text", $( "#contact-details-saved" ).text() );
                $( "#contact-details-saved > span" ).text( "Failed to save your details!" );
                $( "#contact-details-saved > i" ).addClass( "hidden" );
            }
        } );
    }

    /**
     * Saves contact details for Hifz donation on completion.
     */
    saveContactDetailsHifz()
    {
        // Don't go forth if the validation isn't done.
        if ( ! this.form_validated )
        {
            return;
        }

        this.disableSubscribeButton();

        // Hide this indicator, if it isn't already.
        $( "#contact-details-saved" ).addClass( "hidden" );

        // Unhide the icon, if it's hidden.
        $( "#contact-details-saved > i" ).removeClass( "hidden" );

        // If this is the second attempt at this, fix the strings.
        if ( $( "#contact-details-saved > span" ).data( "og-text" ) )
        {   
            $( "#contact-details-saved > span" ).text( $( "#contact-details-saved > span" ).data( "og-text" ) );
        }
        
        var mergedata = 
        {
        };

        switch ( this.selected_contact_type )
        {
        case "EMAIL":
            mergedata.contact_email = $( "#completed_email" ).val();
            break;
            
        case "PHONE":
            mergedata.contact_phone = $( "#completed_phone" ).val();
            break;
    
        default:
        case "BOTH":
            mergedata.contact_email = $( "#completed_email" ).val();
            mergedata.contact_phone = $( "#completed_phone" ).val();
            break;
        }

        // Get transaction ID hidden input field.
        const $transaction_id = $( "#tx_id" );

        // If the transaction ID elemt is not valid, don't try to do nuthin.
        if ( ! $transaction_id.length || ! $transaction_id.val().length )
        {
            return;
        }

        const _this = this;

        // AJAX request to update the details.
        $.ajax(
        {
            url: vl_util.site_url() + "/api/v1/front/contact-details/quran-challenge",
            dataType: "JSON",
            method: "POST",
            data:
            {
                transaction_id: $( "#tx_id" ).val(),
                ...mergedata   
            },
            success: function ( res )
            {
                $( "#contact-details-saved" ).removeClass( "hidden" );

                _this.disableSubscribeButton();

                if ( ! res.status && res.error )
                {
                    $( "#contact-details-saved > span" ).attr( "data-og-text", $( "#contact-details-saved" ).text() );
                    $( "#contact-details-saved > span" ).text( res.error );
                    $( "#contact-details-saved > i" ).addClass( "hidden" );
                }
            },
            error: function ()
            {
                $( "#contact-details-saved > span" ).attr( "data-og-text", $( "#contact-details-saved" ).text() );
                $( "#contact-details-saved > span" ).text( "Failed to save your details!" );
                $( "#contact-details-saved > i" ).addClass( "hidden" );
            }
        } );
    }

    /**
     * Sets whether the email address is valid (visually)
     * 
     * @param {Boolean|null} state 
     */
    setEmailValid( state, rumble = false )
    {
        const $completed_email = $( "#completed_email");
        const $validator = $completed_email.parents( ".validator" );

        if ( state === true )
        {
            inputs.is_valid( $validator );
        }
        else if ( state === false )
        {
            inputs.not_valid( $validator, rumble );
        }
        else
        {
            inputs.unknown_valid( $validator );
        }
    }

    /**
     * Sets whether the phone address is valid (visually)
     * 
     * @param {Boolean|null} state 
     */
    setPhoneValid( state, rumble = false )
    {
        const $completed_phone = $( "#completed_phone");
        const $validator = $completed_phone.parents( ".validator" );

        if ( state === true )
        {
            inputs.is_valid( $validator );   
        }
        else if ( state === false )
        {
            inputs.not_valid( $validator, rumble );
        }
        else
        {
            inputs.unknown_valid( $validator );
        }
    }
}
