import { VL_Modal } from "../general/modal-factory";
import $                    from "jquery";
import jQuery               from "jquery";

/**
 * Represents functionality for our popup HHH video (on click only).
 */
export class VL_HHH_Video extends VL_Modal
{
    elements = 
    {
        dismiss:              null,
    };

    /**
     * Constructor
     */
    constructor ()
    {
        super( "hhh-video" );

        if ( !$( "#hhh-video-modal-overlay" ).length )
        {
            return;
        }

        // Args which will be passed to the bound function.
        const args = 
        { 
            _this_ref: this 
        };

        // Get the input fields.
        this.elements.dismiss = $( "#hhh-video-dismiss" );
        this.elements.dismiss.on( "click", args, this.onClickDismiss );

        $( "#show-video" ).on( "click", ( function () 
        {
            this.showHideModal();
        } ).bind( this ) );

        $( "#hhh-video-modal-content-wrapper, #hhh-video-modal-hide" ).on( "click", ( function ( )
        {
            this.showHideModal();
        } ).bind( this ) );
    }
}