import { cards } from "../donations/card-toggles";
import { inputs } from "../donations/inputs";
import { payment_flow } from "../donations/payment-flow";
import { donate_stripe } from "../general/stripe";
import { vl_util } from "../general/util";
import $                    from "jquery";
import jQuery               from "jquery";

export default class CheckoutController
{
    /**
     * Checkout item quantity inputs.
     * 
     * @var {JQuery<HTMLInputElement>} item_qtys
     */
    item_qtys = $();

    /**
     * Checkout cart purchase identification number.
     * 
     * @var {Number|null} cart_purchase_id
     */
    cart_purchase_id = null;

    /**
     * Checkout controller constructor.
     */
    constructor() 
    {
        if ( ! $( "html[data-checkout-flow]" ).length )
        {
            return;
        }

        this.bind();

        // Initialise the payment flow with base data.
        payment_flow.init();
        
        // Override the stripe redirect URL.
        payment_flow.overrideRedirectUrl( vl_util.site_url() + `/checkout/completed?payment_intent={paymentIntent}&payment_intent_client_secret={clientSecret}&redirect_status=succeeded` );

        // Initialise the input validation.
        inputs.initialise_inputs( $, true );

        // Initialise stripe
        donate_stripe.initialise_stripe( $ );

        // Initialise the payment flow (requires stripe)
        payment_flow.initialise_payment_flow( $ );
        
        // Initialise the card-based flow.
        cards.initialise_cards( $ );

        // Add card hook for when the payment details are requested to be shown.
        cards.addHook( "on_show_payment_details", this.onShowPaymentDetails.bind( this ) );

        if ( vl_util.site_config().cartPurchaseID )
        {
            this.cart_purchase_id = ( new Number( vl_util.site_config().cartPurchaseID ) );
        }
    }

    /**
     * 
     */
    onShowPaymentDetails()
    {
        payment_flow.set_is_checkout( true );
        payment_flow.hk_on_show_payment_details( true );
        payment_flow.set_is_one_off( true );
        payment_flow.force_stripe_data( window.stripe_cl_secret, window.stripe_pi_id, ( window?.subscription_id ) ?? null );
        donate_stripe.set_last_payment_intent( window.stripe_pi_id );
        
        $( "#payment_one_off" ).removeClass( "hidden" );

        // Bind the stripe / paypal switcher.
        $( "#use_stripe" ).off( "click" );
        $( "#use_paypal" ).off( "click" );

        $( "#use_stripe" ).on( "click", function ()
        {
            $( "#payment_stripe" ).removeClass( "hidden" );
            $( "#payment_paypal" ).addClass( "hidden" );

            payment_flow.set_using_paypal( false );
        } );

        $( "#use_paypal" ).on( "click", function ()
        {
            $( "#payment_stripe" ).addClass( "hidden" );
            $( "#payment_paypal" ).removeClass( "hidden" );

            payment_flow.set_using_paypal( true );
        } );

        $( "#complete-donation" ).on( "click", ( function ( e = null )
        {
            if ( e )
            {
                e.preventDefault();
            }

            // Overriding paypal stuff here.
            if ( ! payment_flow.is_using_paypal() )
            {
                payment_flow.do_payment(null);

                return;
            }

            this.createPayPalPayment( window.stripe_pi_id, window.stripe_cl_secret, ( approvalUrl ) => 
            {   
                window.location.href = approvalUrl;
            }, 
            ( reason ) => 
            {
                // Not good enough.
                alert( reason );
            } );
        } ).bind( this ) );
    }

    /**
     * Creates a paypal payment through the current checkout system state.
     * 
     * @param {String} stripePIID 
     * @param {String} stripeCLSecret 
     */
    createPayPalPayment( stripePIID, stripeCLSecret, paymentCreated = ( approvalUrl ) => {}, paymentFailed = ( reason ) => {} )
    {
        $.ajax(
        {
            url: vl_util.site_url() + "/api/v1/paypal/create-payment/checkout",
            type: "POST",
            dataType: "JSON",
            data: 
            {
                // Add CSRF token.
                _token:             vl_util.csrf_token(),
                cartPurchaseID:     this.cart_purchase_id,
                stripe: 
                {
                    payment_intent_id:  stripePIID,
                    client_secret:      stripeCLSecret
                }
            },

            success: function ( json ) 
            {
                if ( json.status )
                {
                    paymentCreated( json.url );
                }
                else
                {
                    paymentFailed( json.error );
                }
            },

            error: function ( jqXHR, errorMessage )
            {
                paymentFailed( errorMessage );
            }
        } );
    }

    /**
     * Bind JQuery stuff.
     */
    bind()
    {
        // Get the item quantities.
        this.item_qtys = $( ".checkout-item-qty" );

        // Focus out.
        this.item_qtys.off( "focusout" );

        // Focus out event.
        this.item_qtys.on( "focusout", this.onUpdateItemQuantity.bind( this ) );
    }

    /**
     * Called when item quantity is updated.
     * 
     * @param {Event} event 
     */
    onUpdateItemQuantity( event ) 
    {
        // Get the target input element set.
        const $target = $( event.target );

        // Check the target set.
        if ( ! $target.length )
        {
            return;
        }

        const desiredQuantity = $target.val();
        const cartItemID = $target.data( "item-id" );

        // Payment intent ID for tying this data to the already stored cart info.
        const paymentIntentID = window.stripe_pi_id;

        // Disable the quantity updater until it's updated.
        $( ".checkout-item-qty" ).prop( "disabled", true );

        $.ajax(
        {
            url: vl_util.site_url() + "/api/v1/checkout/update-quantity/" + paymentIntentID + "/" + cartItemID,
            data: 
            {
                _token:     vl_util.csrf_token(),
                quantity:   new Number( desiredQuantity )
            },
            dataType: "JSON",
            method: "POST",

            // Success should send back success response + new inner HTML for cart contents.
            success: ( function ( response )
            {
                if ( response.success )
                {
                    $( "#checkout-total-wrapper" ).fadeOut( 150, ( () => 
                    {
                        $( "#checkout-total-wrapper" ).html( response.checkout_cart );
                        $( "#checkout-total-wrapper" ).fadeIn( 150 );
                        $( ".checkout-item-qty" ).prop( "disabled", false );

                        this.bind();                        
                    } ).bind( this ) );
                }
                else
                {
                    alert( "Failed to update cart quantities." );
                }
            } ).bind( this )
        } );
    }   
}