import { inputs } from "../donations/inputs";
import { VL_Modal } from "../general/modal-factory";
import { vl_util } from "../general/util";
import $                    from "jquery";
import jQuery               from "jquery";

/**
 * Represents functionality for our pop-up login modal.
 */
export class VL_Login_Modal extends VL_Modal
{
    elements = 
    {
        // Fields required for a login.
        email:              $(),
        password:           $(),

        // Buttons
        action:             $(),
        continue_guest:     $(),
        password_vis:       $(), // Password visibility indicator.

        // Field validation
        email_validator:    $(),
        password_validator: $(),

        // The title string element.
        title:              $(),
    };

    can_go_forward = false;

    /**
     * Constructor
     */
    constructor ()
    {
        super( "login" );

        // Args which will be passed to the bound function.
        const args = 
        { 
            _this_ref: this 
        };

        // Get the input fields.
        this.elements.email                 = $( "#login_email" );
        this.elements.password              = $( "#login_password" );
        
        // Get the validators,
        if ( this.elements.email.length )
        {
            this.elements.email_validator       = this.elements.email.parents( ".validator" );
        }
        
        // Get the password element.
        if ( this.elements.password.length )
        {
            this.elements.password_validator    = this.elements.password.parents( ".validator" );
        }

        // Get the buttons.
        this.elements.action                = $( "#login-modal-ajax" );
        this.elements.continue_guest        = $( "#login-continue-as-guest" );
        this.elements.password_vis          = $( "#toggle-login-password" );

        // H1 Title.
        this.elements.title                 = $( "#sign-in-title" );

        // Add JRumble to the fields for validation.
        if ( this.elements.email.length )
        {
            this.elements.email.jrumble();
        }

        if ( this.elements.password.length )
        {
            this.elements.password.jrumble();
        }

        // Bind event handlers.
        if ( this.elements.password_vis.length )
        {
            this.elements.password_vis          .on( "click",       args, this.onClickPasswordIndicator );
        }

        // Bind the login click.
        if ( this.elements.action.length )
        {
            this.elements.action                .on( "click",       args, this.onClickLogin );
        }
        
        // Bind the email input
        if ( this.elements.email.length )
        {
            this.elements.email                 .on( "input",       args, this.onEmailInput );
        }
        
        // Bind the password input.
        if ( this.elements.password.length )
        {
            this.elements.password              .on( "input",       args, this.onPasswordInput );

            // When we click enter on the password, try to log in.
            this.elements.password              .on( "keypress", args, function ( event )
            {
                if ( event.key === "Enter" )
                {
                    event.data._this_ref.attemptLogin();
                }
            } );
        }

        // Continue as guest button.
        if ( this.elements.continue_guest.length )
        {
            this.elements.continue_guest        .on( "click",       args, this.onClickContinueGuest );
        }
        
        // Focus out event uses same func for both elements.
        $( "#login_email, #login_password" ).on( "focusout",    args, this.onInputLoseFocus );
    }

    /**
     * Called when the user wants to show / hide the password.
     */
    onClickPasswordIndicator ( event )
    { 
        const _this = event.data._this_ref;

        $( this ).toggleClass("fa-eye fa-eye-slash");
        
        var type = $( this ).prev( "input" ).attr( "type" );
        
        if ( type === "text" ){
            
            if ( _this.elements.password.length > 0 )
            {
                _this.elements.password.attr('type',"password");
            }
            else
            {
                $( this ).prev( "input" ).attr( "type", "password" );
            }
        } 
        else{
            if ( _this.elements.password.length > 0 )
            {
                _this.elements.password.attr( "type", "text" );
            } 
            else
            {
                $( this ).prev( "input" ).attr( "type", "text" );
            }
        }
    }

    /**
     * Called when user attempts login.
     */
    onClickLogin ( event )
    {
        const _this = event.data._this_ref;

        var validationResult = _this.doEmailValidation( true );
        
        if ( 1 )
        {
            validationResult = _this.doPasswordValidation( true );
        }

        // Won't do anything if we're not validated.
        if ( ! _this.can_go_forward || ! validationResult )
        {
            return;
        }

        _this.attemptLogin();
    }

    /**
     * Called when the email field is updated.
     */
    onEmailInput ( event)
    {
        const _this = event.data._this_ref;

        var validationResult = _this.doBothValidations();

        _this.setCanGoForward( validationResult );
    }

    /**
     * Called when the password field is updated.
     */
    onPasswordInput ( event )
    {
        const _this = event.data._this_ref;

        var validationResult = _this.doBothValidations();

        _this.setCanGoForward( validationResult );
    }

    /**
     * Called when any of our inputs lose focus.
     */
    onInputLoseFocus ( event )
    {
        const _this = event.data._this_ref;

        var validationResult = false;

        switch ( this.id )
        {
        case "login_email":
            if ( _this.elements.email.val().replace( /\s+/g, "" ).length )
            {
                validationResult = _this.doEmailValidation( true );
            }

            break;
        case "login_password":
            if ( _this.elements.password.val().replace( /\s+/g, "" ).length )
            {
                validationResult = _this.doPasswordValidation( true );
            }

            break;
        default: break;
        }

        _this.setCanGoForward( validationResult );
    }

    setCanGoForward( new_val )
    {
        this.can_go_forward = new_val;

        if ( ! new_val )
        {
            this.elements.action.addClass( "btn-disabled" ).removeClass( "btn-main" );
        }
        else
        {
            this.elements.action.removeClass( "btn-disabled" ).addClass( "btn-main" );
        }
    }

    /**
     * Email field validation.
     * 
     * @return {Boolean}
     */
    doEmailValidation ( update_visually = false )
    {
        const val = this.elements.email.val();
        const strippedLen   = val.replace( /\s+/g, "" ).length;
        const realLen       = val.length;

        if ( update_visually )
        {
            this.setEmailValid( null );
        }

        if ( ! strippedLen || ! realLen || ! vl_util.validate_email_address( val ) || strippedLen < 3 )
        {
            if ( update_visually )
            {
                this.setEmailValid( false );
            }

            return false;
        }

        if ( update_visually )
        {
            this.setEmailValid( true );
        }

        return true;
    }

    /**
     * Password field validation.
     * 
     * @return {Boolean}
     */
    doPasswordValidation ( update_visually = false )
    {
        return true;
    }

    /**
     * Get lost optimisation. I SHOULD NOT HAVE TO DO THIS.
     */
    doBothValidations ()
    {
        const VALIDATOR_FUNCTIONS = [ this.doEmailValidation.bind(this), this.doPasswordValidation.bind(this) ];

        var validation_result = false;

        for ( let i in VALIDATOR_FUNCTIONS )
        {
            const func = VALIDATOR_FUNCTIONS[ i ];

            if ( func() )
            {
                validation_result = true;
            }
            else
            {
                return false;
            }
        }

        return validation_result;
    }

    /**
     * Sets the email validation state (visually).
     * 
     * @param {Boolean|null} is_valid 
     */
    setEmailValid ( is_valid, rumble = true )
    {
        if ( is_valid === true )
        {
            inputs.is_valid( this.elements.email_validator );
        }
        else if ( is_valid === false )
        {
            if ( rumble )
            {
                this.quickRumbleEmail();
            }

            inputs.not_valid( this.elements.email_validator );
        }
        else
        {
            inputs.unknown_valid( this.elements.email_validator );
        }
    }

    /**
     * Sets the password validation state (visually).
     * 
     * @param {Boolean|null} is_valid 
     */
    setPasswordValid( is_valid, rumble = true )
    {
        if ( is_valid === true )
        {
            inputs.is_valid( this.elements.password_validator );
        }
        else if ( is_valid === false )
        {
            if ( rumble )
            {
                this.quickRumblePassword();
            }

            inputs.not_valid( this.elements.password_validator );
        }
        else
        {
            inputs.unknown_valid( this.elements.password_validator );
        }
    }

    /**
     * Rumbles the email field for a set amount of time.
     * 
     * @param {Number} t The number of milliseconds to rumble for.
     */
    quickRumbleEmail ( t = 150 )
    {
        this.elements.email_validator.trigger( "startRumble" );

        setTimeout( ( () => 
        {
            this.elements.email_validator.trigger( "stopRumble" );
        } ).bind( this ), t );
    }

    /**
     * Rumbles the password field for a set amount of time.
     * 
     * @param {Number} t The number of milliseconds to rumble for.
     */
    quickRumblePassword ( t = 150 )
    {
        this.elements.password_validator.trigger( "startRumble" );

        setTimeout( ( () => 
        {
            this.elements.password_validator.trigger( "stopRumble" );
        } ).bind( this ), t );
    }

    /**
     * Calls AJAX to do the login.
     */
    attemptLogin()
    {
        $( "#wrap_login_error" ).addClass( "hidden" );

        var _this = this;

        jQuery.ajax(
        {
            url: vl_util.site_url() + "/api/v1/front/ajax-login",
            method: "POST",
            data:
            {
                email:      this.elements.email.val(),
                password:   this.elements.password.val(),
                _token:     vl_util.csrf_token()
            },
            dataType: "JSON",
            success: function ( res )
            {
                if ( res.status )
                {
                    // If we're on the ramadan challenge page, we want to refresh but go to the anchor, and indicate setting the next step.
                    if ( vl_util.site_config().ramadanChallengeSlug === "ramadanchallenge" )
                    {
                        // Get the unhashed location.
                        var loc = window.location.href.split( "#" )[ 0 ];

                        // Unset the hash string.
                        window.location.hash = null;

                        window.location.href = vl_util.add_query_params( loc,
                        {
                            "type": $( "#soh-individual-reg" ).hasClass( "hidden" ) ? "team" : "individual"
                        } ) + "#app-container";
                    }
                    else
                    {
                        // We are logged in, refresh the state.
                        inputs.loop_query_params();
                        $( "#wrap_login_error" ).addClass( "hidden" );
                    }
                }
                else
                {
                    $( "#wrap_login_error" ).removeClass( "hidden" );
                    _this.setPasswordValid( false );
                }
            },
            error: function ()
            {
                $( "#wrap_login_error" ).removeClass( "hidden" );
                _this.setPasswordValid( false );
            }
        } );
    }

    /**
     * Called when user wants to continue as a guest when on login modal.
     */
    onClickContinueGuest( event )
    {
        const _this = event.data._this_ref;

        _this.showHideModal();
    }

    /**
     * Sets whether the title string is for the donation page, or not.
     * 
     * @param {Boolean} b True when donate-related, false when otherwise.
     */
    setTitleStringDonate( b )
    {
        this.elements.title.text( b ? "You have an account! Sign in here." : "Sign in" );
    }
}