import { VL_Modal } from "../general/modal-factory";
import { vl_util } from "../general/util";
import $                    from "jquery";
import jQuery               from "jquery";

/**
 * Represents functionality for our pop-up login modal.
 */
export class VL_Sub_Cancel_Modal extends VL_Modal
{
    elements = 
    {
        confirm:    null,
        dismiss:    null
    };

    subscription_id = null;

    subscription_type = null;

    /**
     * Constructor
     */
    constructor ()
    {
        super( "subscription-cancel" );

        // Get the input fields.
        this.elements.confirm = $( "#subscription-cancel-confirm" );
        this.elements.dismiss = $( "#subscription-cancel-dismiss" );
        
        this.elements.confirm.on( "click", this.onClickConfirm.bind( this ) );
        this.elements.dismiss.on( "click", this.onClickDismiss.bind( this ) );
    }

    openWithID( subscription_id, subscription_type )
    {
        this.subscription_id    = subscription_id;
        this.subscription_type  = subscription_type;

        this.showHideModal();
    }

    /**
     * Called when user confirms this modal's action.
     * 
     * @param {*} event 
     */
    onClickConfirm( event )
    {        
        $.ajax(
        {
            url: vl_util.site_url() + "/api/v1/gocardless/cancel-subscription",
            method: "POST",
            dataType: "JSON",
            data:
            {
                _token: vl_util.csrf_token(),
                subscription_type: this.subscription_type,
                subscription_id: this.subscription_id
            },

            success: function ( res )
            {
                _this.showHideModal();
                
                if ( res.status )
                {
                    window.location.href = window.location.href;
                }
                else
                {
                    // ...?
                }
            }
        } );
    }

    /**
     * Called when user cancels this modal's action.
     * 
     * @param {*} event 
     */
    onClickDismiss( event )
    {
        const _this = event.data._this_ref;

        _this.showHideModal();
    }
}