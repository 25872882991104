import $                    from "jquery";
import jQuery               from "jquery";

/**
 * Represents a singular Quran student for the quran challenge.
 */
export default class QuranStudent
{
    /**
     * Students internal ID, for targetting.
     * 
     * @var {Number} id
     */
    id              = 0;

    /**
     * Students school ID, what would we use this for?
     * 
     * @var {Number} schoolId
     */
    schoolId        = 0;

    /**
     * Students actual name.
     * 
     * @var {String} name
     */
    name            = "";

    /**
     * Students age.
     * 
     * @var {Number} age
     */
    age             = 0;

    /**
     * Students Hifz progress (chapter count).
     * 
     * @var {Number} progress
     */
    progress        = 0;
    
    /**
     * Students join date to the Hifz programme.
     * 
     * @var {Number} joinDate
     */
    joinDate        = "";

    /**
     * The HTML code for this students info card.
     * 
     * @var {String} htmlCard
     */
    htmlCard        = "";

    /**
     * Constructor for our quran student, pass JSON data from server, or tell it to decode.
     * 
     * @param {String|Object} json 
     * @param {Boolean} decode 
     */
    constructor ( json, decode = false )
    {
        // Parse out the json.
        json = decode ? JSON.parse( json ) : json;

        // Apply the values.
        this.id         = json.id       ?? this.id;
        this.schoolId   = json.schoolId ?? this.schoolId;
        this.name       = json.name     ?? this.name;
        this.age        = json.age      ?? this.age;
        this.progress   = json.progress ?? this.progress;
        this.joinDate   = json.joinDate ?? this.joinDate;
        this.htmlCard   = json.htmlCard ?? this.htmlCard;
    }

    /**
     * Get the user card HTML.
     * 
     * @returns {String}
     */
    html()
    {
        return this.htmlCard;
    }

    /**
     * Loads a single quran student, by ID.
     * 
     * @param {Number} studentId 
     */
    static loadById( studentId )
    {

    }
}