import $                    from "jquery";
import jQuery               from "jquery";

export var vl_util =
{
    /**
     * Retrieves the full site config.
     * 
     * @returns {Object}
     */
    site_config: () => { return window._$$$_vl_config; },

    /**
     * Retrieves the main site URL.
     * 
     * @returns {String} The site main URL.
     */
    site_url: () => { return window._$$$_vl_config.site_url },

    /**
     * Checks if we're in laravel debug mode.
     * 
     * @returns {Boolean}
     */
    dbg_enabled: () => { return window._$$$_vl_config !== undefined; },

    /**
     * Retrieves the CSRF token for this request, used for making AJAX requests when CSRF protection is enabled.
     * 
     * @returns {String}
     */
    csrf_token: () => { return window._$$$_vl_config.csrf_token !== undefined ? window._$$$_vl_config.csrf_token : null },

    /**
     * Neatly formats and outputs messages for debug.
     * 
     * @param {String} component 
     * @param {String} message 
     * @returns 
     */
    dbgout: function ( component, message ) 
    {
        if ( ! this.site_config().is_debug )
        {
            return;
        }

        const now = new Date();
        const current = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;

        console.log( `[${ current }][${component}]: ${message}` );
    },

    /**
     * Determines whether a user with a given email address exists.
     * 
     * @param {String} email 
     * @param {CallableFunction|null} on_exists 
     * @param {CallableFunction|null} otherwise 
     */
    does_user_email_exist: function ( email, on_exists, otherwise )
    {
        jQuery.ajax(
        {
            url: vl_util.site_url() + "/api/v1/front/validate-email",
            data: 
            {
                user_email: email,
                _token:     $( `meta[name="csrf-token"]` ).attr( "content" )
            },
            method: "POST",
            dataType: "JSON",

            success: function ( res )
            {
                if ( res.status === undefined )
                {
                    if ( otherwise ) otherwise();
                    return;
                }

                if ( res.status !== "exists" )
                {
                    if ( otherwise ) otherwise();
                    return;
                }

                if ( on_exists ) on_exists();
            },

            error: function ( jqXHR, error )
            {
                if ( otherwise ) otherwise();
            }
        } );
    },

    /**
     * Validates an email address.
     * 
     * @param {String} email 
     * @returns {Boolean} Is the email valid?
     */
    validate_email_address: function ( email )
    {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    },

    /**
     * Get the global google object.
     * 
     * @returns {Object}
     */
    google: function ()
    {
        return window.google;
    },

    /**
     * Stack overflow wizardry, updates query string params.
     * 
     * https://stackoverflow.com/a/6021027
     * 
     * @param {*} uri 
     * @param {*} key 
     * @param {*} value 
     * @returns 
     */
    updateQueryStringParameter: function ( uri, key, value ) 
    {
        var re = new RegExp( "([?&])" + key + "=.*?(&|$)", "i" );
        var separator = uri.indexOf( '?' ) !== -1 ? "&" : "?";
        
        if ( uri.match( re ) ) 
        {
            return uri.replace( re, '$1' + key + "=" + value + '$2' );
        }
        else 
        {
            return uri + separator + key + "=" + value;
        }
    },

    add_query_params: function ( uri, params )
    {
        for ( let key in params )
        {
            let value = params[ key ];

            uri = this.updateQueryStringParameter( uri, key, value );
        }

        return uri;
    },
};

window.uuu = vl_util;