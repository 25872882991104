import { VL_Modal } from "../general/modal-factory";
import $                    from "jquery";
import jQuery               from "jquery";

/**
 * Represents functionality for our popup HHH advert.
 */
export class FFPartnerModal extends VL_Modal
{
    elements = 
    {
        dismiss:              null,
    };

    /**
     * Constructor
     */
    constructor ()
    {
        super( "ff-partner" );

        if ( !$( "#ff-partner-modal-overlay" ).length )
        {
            return;
        }

        // Args which will be passed to the bound function.
        const args = 
        { 
            _this_ref: this 
        };

        // Get the input fields.
        this.elements.dismiss = $( "#ff-partner-dismiss" );
        this.elements.dismiss.on( "click", args, this.onClickDismiss );

        if ( window.auto_open_modal )
        {
            this.showHideModal();
        }
    }

    onClickDismiss( event )
    {
        const _this = event.data._this_ref;

        window.location.hash = "#dismissed";

        _this.showHideModal();
    }
}