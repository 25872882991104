import jQuery from "jquery";
import $ from "jquery";
import { donation } from "./current-donation";
import { inputs } from "./inputs";


$( function() 
{
    // zakat donation
    $( "#zakat_checkbox, #nop-zakat-donation" ).on( "click", function () 
    {
        let $elem = $( this );
        
        if ( $elem.prop( "checked" ) )
        {
            $( "#zakat_tag" ).removeClass( "hidden" ).addClass( "inline-block" );
        }
        else
        {
            $( "#zakat_tag" ).addClass( "hidden" ).removeClass( "inline-block" );
        }
    } );

    /**
     * If the anonymous checkbox is pressed, update states of the tags.
     */
    $( "#anonymous_checkbox" ).on( "click", function ()
    {
        let $elem = $( this );
        
        if ( $elem.prop( "checked" ) )
        {
            $( "#anon_tag" ).removeClass( "hidden" ).addClass( "inline-block" );
        }
        else
        {
            $( "#anon_tag" ).addClass( "hidden" ).removeClass( "inline-block" );
        }
    } );

    // company donation
    $( "#company_checkbox" ).on( "click", function () 
    {
        let $elem = $( this );
        
        if ( $elem.prop( "checked" ) )
        {
            $( "#company_tag" ).removeClass( "hidden" ).addClass( "inline-block" );
            $( "#giftaid_tag" ).addClass( "hidden" ).removeClass( "inline-block" );
        }
        else
        {
            $( "#company_tag" ).addClass( "hidden" ).removeClass( "inline-block" );
        }
    } );

    // gift aid donation
    $( "input[name=gift_aid]:radio" ).on( "click", function () 
    {
        if ( $( "input[name=gift_aid]:checked" ).val() == "yes" ) 
        {
            $('#giftaid_tag').removeClass('hidden').addClass( "inline-block" );
        } 
        else if ( $( "input[name=gift_aid]:checked" ).val() == "no" ) 
        {
            $( "#giftaid_tag" ).addClass( 'inline-block' ).removeClass( "inline-block" );
        }
    } );

    // get the value of the select field for project supported
    $( "#project_name" ).on( "change", function()
    { 
        inputs.refresh_donation_level( true );
    } );

    // get the value of the select field for project supported
    $( "#country_supported" ).on( "change", function()
    { 
        const value = $( this ).find( ":selected" ).text();
        $( "#country_selected" ).html( value ); //puts that value into the summary box

        const isAllowed = ( value == "Bangladesh" || value == "Sudan" );
        $( `#project_name > [value="12"]` ).prop( "disabled", ! isAllowed ); // hifz meals only to show when on bangladesh or sudan.

        // if not allowed but we're on the hifz meals anyway, reset the value.
        if ( ( !isAllowed ) && ( $( "#project_name" ).find( ":selected" ).val() == 12 ) )
        {
            $( "#project_name > :selected" ).prop( "selected", false );
            $( "#project_name > option:first-of-type" ).prop( "selected", true );
        }
    } );

    // password toggle visibility states
    $('.toggle-new-passwords').on( "click", function () 
    {	
        $(".toggle-new-passwords").toggleClass("fa-eye fa-eye-slash");
        var type = $(this).prev('input').attr('type');
		if(type=="text"){
			
			if($('#donor_password_new').length > 0  && $('#donor_password_new_confirm').length > 0 ){
				$('#donor_password_new').attr('type',"password");
				$('#donor_password_new_confirm').attr('type',"password");
			} else{
				$(this).prev('input').attr('type',"password");
			}
		} else{
		   if($('#donor_password_new').length > 0  && $('#donor_password_new_confirm').length > 0 ){
				$('#donor_password_new').attr('type',"text");
				$('#donor_password_new_confirm').attr('type',"text");
			} else{
		   		$(this).prev('input').attr('type',"text");
			}
		   
		}
    });

     // password toggle visibility states
     $('.toggle-current-password').click(function() {	
        $(".toggle-current-password").toggleClass("fa-eye fa-eye-slash");
        var type = $(this).prev('input').attr('type');
		if(type=="text"){
			
			if($('#donor_password').length > 0 ){
				$('#donor_password').attr('type',"password");
			} else{
				$(this).prev('input').attr('type',"password");
			}
		} else{
		   if($('#donor_password').length > 0 ){
				$('#donor_password').attr('type',"text");
			} else{
		   		$(this).prev('input').attr('type',"text");
			}
		   
		}
    });
});