// JQuery
import $                    from "jquery";
import jQuery               from "jquery";
import { vl_util } from "../general/util";

/**
 * Does the work for waiting for a donations confirmation.
 */
export class ConfirmationWorker
{
    payment_intent_id = null;

    constructor( _payment_intent_id )
    {
        this.payment_intent_id = _payment_intent_id;
    }

    start()
    {
        setTimeout( this.onPing.bind( this ), 1000 );
    }

    onPing()
    {
        const payment_intent_id = this.payment_intent_id;

        $.ajax(
        {
            url: vl_util.site_url() + "/api/v1/front/ping-confirmation",
            method: "POST",
            dataType: "JSON",
            data: 
            {
                payment_intent_id: payment_intent_id
            },
            success: function ( res )
            {
                if ( res.status )
                {
                    $( "#lastest-confirm-status" ).text( "Payment detected! Reloading page." );

                    window.location.href = window.location.href;
                }
                else
                {
                    $( "#lastest-confirm-status" ).text( res.error );
                }
            },
            error: function ( jqXHR, error )
            {
                $( "#lastest-confirm-status" ).text( error );
            }
        } );
    }
}