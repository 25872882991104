import Flickity         from "flickity";
import "flickity-imagesloaded";
import { vl_util }      from "../general/util";
import intlTelInput     from "intl-tel-input";
import { inputs } from "../donations/inputs";
import LazyLoad from "vanilla-lazyload";
import $                    from "jquery";
import jQuery               from "jquery";

$.fn.scrollTo = function(elem, speed) { 
    $(this).animate({
        scrollTop:  $(this).scrollTop() - $(this).offset().top + $(elem).offset().top 
    }, speed == undefined ? 1000 : speed); 
    return this; 
};

// Hack. Don't do this.
var __soh__instance = null;

/**
 * Frontend js controller class for Step Over Hunger challenge / programme page & app.
 */
export default class StepOverHungerController
{
    /**
     * Success stories Flickity slider on S-O-H near footer.
     * 
     * @var {Object} successStoriesFlickity
     */
    successStoriesFlickity = null;

    /**
     * The ID of the container for the step we're currently on. Do not update manually. Use `<StepOverHungerController/this>.setStep( ... )`
     * 
     * 
     * ID definitions: 
     * 
     *  `soh-step-1`            - Initial screen.
     * 
     *  `soh-individual-reg`    - Individual registration page.
     * 
     *  `soh-team-name`         - Team first step, choosing the name.
     * 
     *  `soh-team-leader`       - Team leader detail collection.
     * 
     *  `soh-team-details`      - Team details, including participant collection.
     * 
     *  `soh-finished`          - Final page, after full completion.
     * 
     *  `soh-loading`           - Special :: Loading screen.
     * 
     * @var {String} currentStepId
     */
    currentStepId = "soh-step-1";

    /**
     * Step ID history, so we can go backwards in time.
     * 
     * @var {String[]} stepHistory
     */
    stepHistory = [];

    /**
     * Are we in a loading screen?
     * 
     * @var {Boolean} inLoadingScreen
     */
    inLoadingScreen = false;

    /**
     * Tel input for the individual registration.
     * 
     * @var {intlTelInput} individualTelInput
     */
    individualTelInput = null;

    /**
     * Team tel input.
     * 
     * @var {intlTelInput} teamTelInput
     */
    teamTelInput = null;

    /**
     * Array of participant data.
     * 
     * @var {Object[]}
     */
    collectedParticipants = [];

    /**
     * Class constructor. Call on document.ready event.
     */
    constructor ( bSkipInitialisation ) 
    {
        if ( ! bSkipInitialisation )
        {
            this
                .initialisePage()
                .initialiseApp();
        }
    }

    /**
     * Initialise all functionality etc relating to the actual frontend page (so things like the lightgallery etc).
     */
    initialisePage()
    {
        __soh__instance = this;

        // Find the success stories flickity.
        const successStoriesElem = document.querySelector( "#SOH-success-stories-slider" );

        // Individual telephone input element
        const individualTelInputElem = document.querySelector( "#soh-individual-reg_contact-no" );

        // Team telephone input element.
        const teamTelInputElem = document.querySelector( "#soh-team-leader_contact-no" );

        // If it's found, initialise it's flickity.
        if ( successStoriesElem )
        {
            this.successStoriesFlickity = new Flickity( successStoriesElem, 
            {
                cellAlign:          "left",
                contain:            true,
                pageDots:           false,
                imagesLoaded:       true,
                prevNextButtons:    false
            } );

            if ( this.successStoriesFlickity.slides.length > 1 ) 
            {
                $( "#ss-btns" ).toggleClass( "hidden flex" );
                
                $( "#ss-prev-slide-btn" ).on( "click", ( () => this.successStoriesFlickity.previous() ).bind( this ) );
                $( "#ss-next-slide-btn" ).on( "click", ( () => this.successStoriesFlickity.next() ).bind( this ) );
            };
        }

        if ( individualTelInputElem )
        {
            this.individualTelInput = intlTelInput( individualTelInputElem,
            {
                // Util script for extra features.
                utilsScript: "/app/assets/js/vendor/intl-tel-input/utils.js",

                // Placeholder should force override always.
                autoPlaceholder: "aggressive",

                // Automatically format.
                formatOnDisplay: true,

                // We want the country to be automated based on location.
                initialCountry: "auto",

                // Fixes the phone number strings.
                nationalMode: false,

                // Geoip lookup func.
                geoIpLookup: inputs.geoip_lookup,

                // Hidden input for actual full phone number.
                hiddenInput: individualTelInputElem.id + "-hidden",

                // Initial country to UK even if geoIP lookup doesn't work.
                initialCountry: "GB"
            } );

            this.individualTelInput.rawElement = individualTelInputElem;

            $( individualTelInputElem ).on( "focusout", StepOverHungerController.formatIntlTelInput.bind( this.individualTelInput ) );
        }
    
        if ( teamTelInputElem )
        {
            this.teamTelInput = intlTelInput( teamTelInputElem,
            {
                // Util script for extra features.
                utilsScript: "/app/assets/js/vendor/intl-tel-input/utils.js",

                // Placeholder should force override always.
                autoPlaceholder: "aggressive",

                // Automatically format.
                formatOnDisplay: true,

                // We want the country to be automated based on location.
                initialCountry: "auto",

                // Fixes the phone number strings.
                nationalMode: false,

                // Geoip lookup func.
                geoIpLookup: inputs.geoip_lookup,

                // Hidden input for actual full phone number.
                hiddenInput: teamTelInputElem.id + "-hidden",

                // Initial country to UK even if geoIP lookup doesn't work.
                initialCountry: "GB"
            } );

            this.teamTelInput.rawElement = teamTelInputElem;

            $( teamTelInputElem ).on( "focusout", StepOverHungerController.formatIntlTelInput.bind( this.teamTelInput ) );
        }

        $(document).on('click', 'a[href^="#"]', function (event) {
            event.preventDefault();
        
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - 50
            }, 500);
        });



        return this;
    }

    /**
     * Format telephone input on focusout, also validate.
     */
    static formatIntlTelInput() 
    {
        const numberFormat = intlTelInputUtils.numberFormat.E164;

        // utils are lazy loaded, so must check
        if ( typeof intlTelInputUtils !== 'undefined' )
        {
            var currentText = this.getNumber(  );

            // sometimes the currentText is an object :)
            if (typeof currentText === 'string') 
            {
                // will autoformat because of formatOnDisplay=true
                this.setNumber( currentText );
            }
        }

        if ( ! __soh__instance )
        {
            __soh__instance = new StepOverHungerController( true );
        }

        if ( this.getNumber( numberFormat ).length && __soh__instance.validateSingleField )
        {
            __soh__instance.validateSingleField( $( this.rawElement ) );
        }
    }

    /**
     * (Re-)Initialise the validation functionality.
     */
    initialiseValidators()
    {
        // Intialise all JRumble-enabled elements (validators).
        $( ".validator" ).jrumble();

        // On validator input focus out.
        $( ".validator > input" ).off( "focusout" ).on( "focusout", this.onFocusOutValidatable.bind( this ) );

        // On select change, validate.
        $( "select.validator" ).off( "change" ).on( "focusout", this.onFocusOutValidatable.bind( this ) );

        // Scroll.
        $( "#soh-team-participants" ).animate(
        {
            scrollTop: $( "#soh-team-participant-list" ).height()
        }, 250 );

        $( "#soh-individual-reg_how-heard, #soh-team_how-heard" ).off( "change" ).on( "change", ( ( event ) => 
        {
            if ( event.target.value === "other" )
            {
                $( ".how-heard-please-specify" ).removeClass( "hidden" );
            }
            else
            {
                $( ".how-heard-please-specify" ).addClass( "hidden" );
            }
        } ).bind( this ) );
    }

    /**
     * Initialise all functionality etc relating to the sign up form.
     */
    initialiseApp()
    {
        // Push the starting step ID to the history list.
        this.pushHistory( this.currentStepId );

        this.initialiseValidators();

        // Forward buttons.
        $( ".soh-next" ).on( "click", this.onClickNext.bind( this ) );

        // Back buttons.
        $( ".soh-back" ).on( "click", this.onClickBack.bind( this ) );

        $( "#soh-nav-individual-reg, #soh-nav-team-reg, #soh-nav-individual-reg *, #soh-nav-team-reg *" ).on( "click", ( (e) => 
        {
            $( "#soh-nav-individual-reg, #soh-nav-team-reg" ).removeClass( "active" );
            $( e.target ).parents( "#soh-nav-individual-reg, #soh-nav-team-reg" ).addClass( "active" );
            $( e.target ).addClass( "active" );

            $( ".soh-next" ).removeClass( "btn-disabled" );
            $( ".soh-next" ).addClass( "btn-active" );
            $( ".soh-next" ).prop( "disabled", false );
        } ).bind( this ) );

        // On click to add participant.
        $( "#soh-add-participant" ).on( "click", this.onClickAddParticipant.bind( this ) );

        const $sohFlickity = $( ".soh-flkty" );
        if ( $sohFlickity.length ) 
        {
            const soh_flkty = new Flickity( $sohFlickity.get( 0 ), {
                cellAlign: "left",
                contain: true,
                pageDots: false,
                imagesLoaded: true,
                prevNextButtons: false,
                lazyLoad: true,
            } );

            $( ".soh-flick-what" ).on( "click", () => soh_flkty.select( 0 ) );
            $( ".soh-flick-why" ).on( "click", () => soh_flkty.select( 1 ) );
            $( ".soh-flick-how" ).on( "click", () => soh_flkty.select( 2 ) );

            $("#prev-slide-btn").on("click", () => soh_flkty.previous());
            $("#next-slide-btn").on("click", () => soh_flkty.next());
        }

        var lazy = new LazyLoad();

        return this;
    }

    /**
     * Called when focusout is called on a validatable input.
     * 
     * @param {MouseEvent} event 
     */
    onFocusOutValidatable( event )
    {
        this.validateSingleField( $( event.target ), true );
    }

    /**
     * On click next button.
     * 
     * @param {MouseEvent} event 
     */
    onClickNext( event )
    {
        // Action is based on the current step. I.e. if we're on the individual registration and click next, we validate individual and then post etc etc.
        const mappedFunction = 
        {
            "soh-step-1":           this.onClickStepOneNext.bind( this ),
            "soh-individual-reg":   this.onClickIndividualNext.bind( this ),
            "soh-individual-goals": this.onClickIndividualTargetNext.bind( this ),
            "soh-team-leader":      this.onClickTeamLeaderNext.bind( this ),
            "soh-team-details":     this.onClickTeamDetailsNext.bind( this ),
            "soh-team-goals":       this.onClickTeamGoalsNext.bind( this )
        }[ this.currentStepId ];
        
        // If mapping is not defined, do nothing. Not good UX really..
        if ( ! mappedFunction )
        {
            return;
        }

        mappedFunction( event );
    }

    /**
     * 
     */
    onClickStepOneNext( event )
    {
        if ( $( "#soh-nav-individual-reg" ).hasClass( "active" ) )
        {
            this.onClickIndividualStart( event );
        }
        else
        {
            this.onClickTeamStart( event );
        }
    }

    /**
     * Called when user is on individual registration and clicks next button.
     * 
     * @param {MouseEvent} event 
     */
    onClickIndividualNext( event )
    {
        // Validate the fields.
        if ( this.validateIndividualFields() )
        {
            this.setStep( "soh-individual-goals" );
        }
        else
        {
            // Since the validation failed, we need to re-validate and jiggle the bad fields, which need to include the empty ones now.
            this.validateIndividualFields( true );
        }
    }

    /**
     * Called when individual target page next is clicked.
     * 
     * @param {MouseEvent} event 
     */
    onClickIndividualTargetNext( event )
    {
        var toValidate = [ "soh-individual-reg_fundraising-target", "soh-individual-reg_how-heard" ];

        // If the specification is showing, we need to add it to validation.
        if ( ! $( ".how-heard-please-specify" ).hasClass( "hidden" ) )
        {
            toValidate.push( "soh-individual-reg_how-heard-specified" );
        }

        // Validate the fields.
        if ( this.validateFieldsById( toValidate, false ) )
        {
            // Collect the users details as they have been validated.
            const details = this.collectIndividualDetails();

            // Set loadings screen to indicate something is happening (no hanging.)
            this.setLoading( true );

            // Send the details and capture the response.
            StepOverHungerController
                .API_sendIndividualDetails( details )
                .then( this.onIndividualDetailsSent.bind( this ) )
                .catch( this.onIndividualDetailsFailed.bind( this ) );
        }
        else
        {
            // Since the validation failed, we need to re-validate and jiggle the bad fields, which need to include the empty ones now.
            this.validateFieldsById( toValidate, true );
        }
    }

    /**
     * Called on click to next when on team leader details page.
     * 
     * @param {MouseEvent} event 
     */
    onClickTeamLeaderNext( event )
    {
        if ( this.validateTeamLeaderFields( false ) )
        {
            this.setStep( "soh-team-details" );
        }
        else
        {
            this.validateTeamLeaderFields( true );
        }
    }

    /**
     * Validates all the form fields for the team leader fields.
     * 
     * @param {String[]} fields         The fields.
     * @param {Boolean} validateEmpty   Validate the empty fields as bad when empty?
     * @returns {Boolean}               The validation result
     */
    validateFieldsById( fields, validateEmpty = false )
    {
        var validationResult = true;

        // Iterate through the fields.
        for ( let i in fields )
        {
            // get the field ID from array.
            const fieldId = fields[ i ];

            // Get the field as JQuery.
            const $field = $( `#${fieldId}` );
            
            if ( ! validateEmpty )
            {
                validationResult &&= this.validateSingleField( $field, true, validateEmpty );
            }
            else
            {
                // FORCE these fields to validate.
                this.validateSingleField( $field, true, validateEmpty );
            }
        }

        return validationResult;
    }

    /**
     * Validates all the form fields for the team leader fields.
     * 
     * @param {Boolean} validateEmpty Validate the empty fields as bad when empty?
     * @returns {Boolean} The validation result
     */
    validateTeamLeaderFields( validateEmpty = false )
    {
        // All of these just need values to be validated successfully. Nothing special really.
        const fields = 
        [
            "soh-team_name",
            "soh-team-leader_full-name",
            "soh-team-leader_email-address",
            "soh-team-leader_contact-no",
            "soh-team-leader_age"
        ];

        return this.validateTeamDetailsFields( fields, validateEmpty );        
    }

    /**
     * Called on click to next page when on team details. Will post etc.
     * 
     * @param {MouseEvent} event 
     */
    onClickTeamDetailsNext( event )
    {
        var requiresValidating = [];
        this.collectedParticipants = [];

        $( ".soh-participant:not(.soh-participant-factory)" ).each( ( ( i, e ) => 
        {
            const ageinput = $( e ).find( `input[type="number"]` );
            const nameinput = $( `[data-linked-age="${ageinput.get(0).id}"]` );

            // Populate participant data here. In case we add more fields.
            this.collectedParticipants.push( 
            { 
                age:    ageinput.val(), 
                name:   nameinput.val() 
            } );

            requiresValidating.push( ageinput.get( 0 ).id );
            requiresValidating.push( nameinput.get( 0 ).id );
            
        } ).bind( this ) );

        // Validate the inputs
        if ( this.validateTeamDetailsFields( requiresValidating, false ) )
        {
            this.setStep( "soh-team-goals" );
        }
        else
        {
            this.validateTeamDetailsFields( requiresValidating, true );
        }
    }

    /**
     * On click next when on the team goals page.
     * 
     * @param {MouseEvent} event 
     */
    onClickTeamGoalsNext( event )
    {
        let requiresValidating = [ "soh-team_fundraising-target", "soh-team_how-heard" ]

        // If the specification is showing, we need to add it to validation.
        if ( ! $( ".how-heard-please-specify" ).hasClass( "hidden" ) )
        {
            requiresValidating.push( "soh-team_how-heard-specified" );
        }

        // Validate the inputs
        if ( this.validateTeamDetailsFields( requiresValidating, false ) )
        {
            // On successful validation, we show the loading screen and upload.
            this.setLoading( true );

            // Collate the team information to be sent.
            const teamData = 
            {
                // The team name
                team_name:          $( "#soh-team_name" ).val(),

                // Fundraising target value.
                fundraising_target: $( "#soh-team_fundraising-target" ).val(),

                // How did the user hear about this page?
                how_heard:          $( "#soh-team_how-heard" ).val() === "other" ? $( "#soh-team_how-heard-specified" ).val() : $( "#soh-team_how-heard" ).val(),

                // The team leader details.
                leader: 
                {
                    full_name:      $( "#soh-team-leader_full-name" ).val(),
                    email_address:  $( "#soh-team-leader_email-address" ).val(),
                    contact_number: $( "#soh-team-leader_contact-no" ).val(),
                    age:            $( "#soh-team-leader_age" ).val()
                },
                
                // Add participants collection
                participants: this.collectedParticipants,
            }

            // Send to the server.
            StepOverHungerController
                .API_sendTeamDetails( teamData )
                .then( this.onTeamDetailsSent.bind( this ) )
                .catch( this.onTeamDetailsFailed.bind( this ) );
        }
        else
        {
            this.validateTeamDetailsFields( requiresValidating, true );
        }
    }

    /**
     * On team details sent.
     * 
     * @param {Object} response
     */
    onTeamDetailsSent( response )
    {
        this.setLoading( false );

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(
        {
            "event": "formSubmission",
            "formType": "Step Over Hunger"
        } );

        if ( response.status )
        {
            this.setStep( "soh-finished" );
        }
        else
        {
            alert( "An error ocurred on upload of your information: " + ( response.error ?? "[No error message!]" ) );
        }
    }

    /**
     * Failure to post data to backend.
     * 
     * @param {JQueryXHR} jqXHR 
     * @param {*} error 
     */
    onTeamDetailsFailed( jqXHR, error )
    {
        console.error( error );

        alert( "An error ocurred while uploading your information." );
    }

    /**
     * Validates all the form fields for the team leader fields.
     * 
     * @param {String[]} requiresValidating The field IDs to validate.
     * @param {Boolean} validateEmpty Validate the empty fields as bad when empty?
     * @returns {Boolean} The validation result
     */
    validateTeamDetailsFields( requiresValidating, validateEmpty = false )
    {
        var validationResult = true;

        // Iterate through the fields.
        for ( let i in requiresValidating )
        {
            // get the field ID from array.
            const fieldId = requiresValidating[ i ];

            // Get the field as JQuery.
            const $field = $( `#${fieldId}` );

            if ( ! validateEmpty )
            {
                validationResult &&= !!this.validateSingleField( $field, true, validateEmpty );
            }
            else
            {
                // FORCE these fields to validate.
                this.validateSingleField( $field, true, validateEmpty );
            }
        }

        return validationResult;
    }

    static idCounter = 2;

    /**
     * Scans the participants and updates the numbering when one is removed.
     */
    updateParticipantNumbers()
    {
        var i = 1;

        $( ".soh-participant:not(.soh-participant-factory)" ).each( ( idx, element ) => 
        {
            $( element ).find( ".team-member-id" ).text( i );

            i ++;
        } );
    }

    /**
     * Called on click to add participant to team listing.
     * 
     * @param {MouseEvent} event 
     */
    onClickAddParticipant( event )
    {
        // Dupe the element.
        var elem = document.querySelector( ".soh-participant-factory" ).cloneNode( true );

        // Remove the hidden class.
        elem.classList.remove( "hidden" );
        elem.classList.remove( "soh-participant-factory" );
        elem.classList.add( "soh-participant" );

        // Add ID to child input element.
        const ageId = "soh-team-member-" + StepOverHungerController.idCounter;

        $( elem ).find( `input[type="number"]` ).get( 0 ).id = ageId;
        $( elem ).find( `.team-member-id` ).text( StepOverHungerController.idCounter );
        $( elem ).find( `.team-member-name` )
            .addClass( "soh-team-member-name-" + StepOverHungerController.idCounter )
            .attr( "data-linked-age", ageId )
            .get( 0 ).id = "soh-team-member-name" + StepOverHungerController.idCounter;

        // Increment the ID counter.s
        StepOverHungerController.idCounter += 1;

        // Append it to our list.
        document.querySelector( "#soh-team-participant-list" ).appendChild( elem );

        // Bind the participant functionalities.
        $( ".soh-remove-participant, .soh-remove-participant > i" ).off( "click" ).on( "click", this.onClickRemoveParticipant.bind( this ) );

        this.initialiseValidators();
        this.updateParticipantNumbers();
    }

    /**
     * On click remove participant button.
     * 
     * @param {MouseEvent} event 
     */
    onClickRemoveParticipant( event )
    {
        const $target = $( event.target ).parents( ".soh-participant" );

        if ( $target.hasClass( ".soh-participant-factory" ) )
        {
            return;
        }

        $target.remove();

        this.updateParticipantNumbers();
    }

    /**
     * Once the details are sent and the server responds okay - deal with it.
     * 
     * @param {Object} response 
     */
    onIndividualDetailsSent( response )
    {
        this.setLoading( false );
        
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(
        {
            "event": "formSubmission",
            "formType": "Step Over Hunger"
        } );

        if ( response.status )
        {
            this.setStep( "soh-finished" );
        }
        else
        {
            alert( "An error ocurred on upload of your information: " + ( response.error ?? "[No error message!]" ) );
        }
    }

    /**
     * Called when individual details failed to upload.
     * 
     * @param {JQueryXHR} jqXHR
     * @param {Object} error 
     */
    onIndividualDetailsFailed( jqXHR, error )
    {
        console.error( error );

        alert( "An error ocurred while uploading your information." );
    }

    /**
     * Validates all the form fields for the simple individual upload functionality.
     * 
     * @param {Boolean} validateEmpty Validate the empty fields as bad when empty?
     * @returns {Boolean} The validation result
     */
    validateIndividualFields( validateEmpty = false )
    {
        // All of these just need values to be validated successfully. Nothing special really.
        const fields = 
        [
            "soh-individual-reg_full-name",
            "soh-individual-reg_email-address",
            "soh-individual-reg_age",
            "soh-individual-reg_contact-no",
        ];

        return this.validateFieldsById( fields, validateEmpty );
    }

    /**
     * Validates a single field.
     * 
     * @param {String} fieldId 
     * @param {JQuery} $field 
     * @param {Boolean} visual Visually update?
     */
    validateSingleField( $field, visual = true, validateEmpty = false )
    {
        // Get the field ID.
        const fieldId = ( $field.get( 0 ) ?? { id: "" } ).id;

        // Get the field value.
        const value = $field.val();

        // No validation state.
        var validationState = null;

        // If there's no value, we skip and remove validation states.
        if ( value && value.length )
        {
            // Special case - must be an email address.
            if ( fieldId.endsWith( "email-address" ) )
            {
                validationState = !!vl_util.validate_email_address( value );
            }
            // Special case - must be at least 150.00
            else if ( fieldId.endsWith( "fundraising-target" ) )
            {
                // Must be at least three letters.
                validationState = value.length >= 3;
                
                // If three letters, we convert to number and check it's "actual" value.
                if ( validationState )
                {
                    const realValue = Number( value );

                    // If the parsed value is a number
                    if ( realValue && realValue !== NaN )
                    {
                        validationState = realValue >= 150.0;
                    }
                    else
                    {
                        validationState = false;
                    }
                }
            }
            // Otherwise, just ensure there's at least some data in the string.
            else
            {
                validationState = value.length > 0;
            }
        }

        // Locate the validator element.
        const $validator = this.getValidatorElement( $field );

        // If the programmer requests visual update, do so.
        if ( visual )
        {
            if ( validationState === null && ! validateEmpty )
            {
                $validator.removeClass( "not-valid valid" );
            }
            else if ( validationState === true )
            {
                $validator.removeClass( "not-valid" ).addClass( "valid" );
            }
            else if ( validationState === false || ( validationState === null && validateEmpty ) )
            {
                $validator.addClass( "not-valid" ).removeClass( "valid" );

                // Bad validation result will do a little jiggle.
                $validator.trigger( "startRumble" );

                // Timeout end rumble.
                setTimeout( () => $validator.trigger( "stopRumble" ), 250 );
            }
        }

        return validationState;
    }

    /**
     * Get the validator element.
     * 
     * @param {JQuery<HTMLElement>} $field 
     */
    getValidatorElement( $field )
    {
        if ( $field.get( 0 ) instanceof HTMLSelectElement )
        {
            return $field;
        }
        else
        {
            return $field.parents( ".validator" );
        }
    }

    /**
     * On click back button.
     * 
     * @param {MouseEvent} event 
     */
    onClickBack( event )
    {
        this.setStep( this.popHistory(), false );
    }

    /**
     * Called on click to individual reg button.
     * 
     * @param {MouseEvent} event 
     */
    onClickIndividualStart( event )
    {
        this.setStep( "soh-individual-reg" );
    }

    /**
     * Called on click to individual reg button.
     * 
     * @param {MouseEvent} event 
     */
    onClickTeamStart( event )
    {
        this.setStep( "soh-team-leader" );
    }

    /**
     * Collects the full set of details for the individual sign up form.
     */
    collectIndividualDetails()
    {
        // Field mappings.
        const fieldMappings = 
        {
            full_name:              "soh-individual-reg_full-name",
            email_address:          "soh-individual-reg_email-address",
            age:                    "soh-individual-reg_age",
            contact_number:         "soh-individual-reg_contact-no",
            fundraising_target:     "soh-individual-reg_fundraising-target",
            // how_heard:              "soh-individual-reg_how-heard"
        };

        // Output data set.
        var output = {};

        // Foreach field in the mapping.
        for ( let fieldName in fieldMappings )
        {
            // Get the input ID
            const inputId = fieldMappings[ fieldName ];

            // Get the value of the input, by ID.
            const inputValue = $( `#${inputId}` ).val();

            // Move the value into the output object.
            output[ fieldName ] = inputValue;
        }

        if ( $( "#soh-individual-reg_how-heard" ).val() === "other" ) 
        {
            output.how_heard = $( "#soh-individual-reg_how-heard-specified" ).val();
        }
        else
        {
            output.how_heard = $( "#soh-individual-reg_how-heard" ).val();
        }

        return output;
    }
    
    /**
     * Sets the current step.
     * 
     * @param {String} stepId 
     * @param {Boolean} storeHistory Add this page to the history array?
     */
    setStep( stepId, storeHistory = true )
    {
        // Hide all steps that aren't the target.
        $( `.soh-registration-section:not(#${stepId})` ).addClass( "hidden" );

        // Unhide our target step.
        $( `.soh-registration-section#${stepId}` ).removeClass( "hidden" );

        // Add the current item to the history, if requested (soon will not be the current item, making it historical).
        if ( storeHistory ) this.pushHistory( this.currentStepId );

        // Set the current step ID value.
        this.currentStepId = stepId;
    }

    /**
     * Adds a step to the history list.
     * 
     * @param {String} stepId 
     */
    pushHistory( stepId )
    {
        this.stepHistory.push( stepId );
    }

    /**
     * Pops the most recent item off the history list.
     * 
     * @return {String} The historical item.
     */
    popHistory()
    {
        return this.stepHistory.pop();
    }

    /**
     * Enables / disables the loading screen.
     * 
     * @param {Boolean} isLoading 
     */
    setLoading( isLoading )
    {
        this.inLoadingScreen = isLoading;

        if ( this.inLoadingScreen )
        {
            $( `.soh-registration-section:not(#soh-loading)` ).addClass( "hidden" );
            $( `.soh-registration-section#soh-loading` ).removeClass( "hidden" );
        }
        else
        {
            $( `.soh-registration-section:not(#soh-loading)` ).removeClass( "hidden" );
            $( `.soh-registration-section#soh-loading` ).addClass( "hidden" );
        }
    }

    /**
     * Sends individuals data to server.
     * 
     * @param {Object} individualData The data on the individual.
     * @returns {Promise<JQueryXHR>} The jqXHR request.
     */
    static async API_sendIndividualDetails( individualData )
    {
        return await $.ajax( 
        {
            url:        vl_util.site_url() + "/api/v1/stepoverhunger/store-individual",
            dataType:   "JSON",
            method:     "POST",
            async:      true,
            data: 
            {
                _token: vl_util.csrf_token(),
                ...individualData
            }
        } );
    }

    /**
     * Sends the team details to the server, asynchronously.
     * 
     * @param {Object} teamData 
     * @returns {Promise<JQueryXHR>} The jqXHR request.
     */
    static async API_sendTeamDetails( teamData )
    {
        return await $.ajax(
        {
            url:        vl_util.site_url() + "/api/v1/stepoverhunger/store-team",
            dataType:   "JSON",
            method:     "POST",
            async:      true,
            data: 
            {
                _token: vl_util.csrf_token(),
                ...teamData
            }
        } );
    }
}