import $                    from "jquery";
import jQuery               from "jquery";

/**
 * Functions and variables which are to interface with the current state (i.e. calculating totals, current currency.)
 */
export var donation = 
{
    /**
     * Amounts
     * 
     * @var {Object}
     */
    amounts: 
    {
        /**
         * Gets the sub total for this donation.
         * 
         * @returns {Number}
         */
        get_sub_total: function ( format = false )
        {
            var valf;
            
            try 
            {
                valf = parseFloat( $( "#enter_amount" ).val() );
            }
            catch ( error )
            {
                valf = 0.0;
            }

            return format ? donation.amounts.format_money( valf ) : valf;
        },

        set_sub_total_quantity: function ( q )
        {
            var ogAmount = $( "#enter_amount" ).data( "og" );

            if ( ! ogAmount )
            {
                $( "#enter_amount" ).attr( "data-og", $( "#enter_amount" ).val() );

                ogAmount = $( "#enter_amount" ).val();
            }

            $( "#enter_amount" ).val( ogAmount * q );
        },

        /**
         * Gets the admin fee % the user has chosen.
         * 
         * @returns {Number} The fee %
         */
        get_fee_percent: function ( format = false, ignore_checkbox = false )
        {
            const $donate_cr = $( "#donate_cr" );
            const $donate_cr_amount = $( "#donate_cr_amount" );

            if ( ! $donate_cr.length || ! $donate_cr_amount.length )
            {
                return 0.0;
            }

            if ( ! ignore_checkbox )
            {
                if ( ! $donate_cr.prop( "checked" ) )
                {
                    return 0.0;
                }
            }
            
            var val = null;

            try
            {
                val = parseFloat( $donate_cr_amount.val() );
            }
            catch ( error )
            {
                return 0.0;
            }

            return format ? donation.amounts.format_money( val ) : val;
        },

        /**
         * Calculates the actual fees from the donation amount.
         * 
         * @returns {Number} The fee.
         */
        get_fees: function ( format = false )
        {
            const fee_percent = donation.amounts.get_fee_percent();
            const sub_total = donation.amounts.get_sub_total();
            const val = ( fee_percent / 100 ) * sub_total;

            return format ? donation.amounts.format_money( parseFloat( val ) ) : val;
        },

        /**
         * Gets the final amount to be paid.
         * 
         * @returns {Number}
         */
        get_final_amount: function ( format = false )
        {
            const sub_total = donation.amounts.get_sub_total();
            const fees = donation.amounts.get_fees();
            const val = ( sub_total + fees ).toFixed( 2 );

            return format ? donation.amounts.format_money( parseFloat( val ) ) : val;
        },

        /**
         * Gets the amount which will be donated if the user chooses to giftaid
         * their donation.
         * 
         * @returns {Number}
         */
        get_gift_aid_amount: function ( format = false )
        {
            const sub_total = donation.amounts.get_sub_total();
            const val = ( sub_total * 1.25 ).toFixed( 2 );

            return format ? donation.amounts.format_money( parseFloat( val ) ) : val;
        },

        /**
         * Formats a money string using the users selected currency code.
         * 
         * @param {Number} amount 
         */
        format_money: function ( amount )
        {
            return amount.toLocaleString( "en-MY", 
            {
                style:      "currency",
                currency:   "MYR",
            } );
        }
    },
    
    /**
     * Gets the currently selected currency's code.
     * 
     * @returns {String} The code, i.e. GBP, USD, XMR.
     */
    get_currency_code: function ()
    {
        return $( "#donation_currency" ).val();
    },

    /**
     * Gets the currently selected currency's symbol.
     * 
     * @returns {String} The symbol.
     */
    get_currency_symbol: function ()
    {
        return $( "#donation_currency > :selected" ).text();
    },

    /**
     * Gets the source identifier string from the donation inputs.
     * 
     * @returns {String}
     */
    get_source_identifier: function ()
    {
        const val = $( `input[name="source"]` ).val();

        return ( val && val.length ) ? val : "direct2donate";
    },

    /**
     * Gets the chosen marketing consent type string.
     * 
     * @return {String}
     */
    get_marketing_consent: function ()
    {
        return $( `[name="contact_preference"]:checked` ).val();
    }
};