import { donate_stripe } from "../general/stripe";
import { vl_util } from "../general/util";
import { cards } from "./card-toggles.js";
import { inputs } from "./inputs";
import { donation } from "./current-donation";
import $                    from "jquery";
import jQuery               from "jquery";

var client_secret           = null;
var payment_intent_id       = null;
var subscription_id         = null;

var using_known_card        = false;
var using_paypal            = false;
var in_payment              = false;
var has_card_initialised    = false;
var processing              = false;
var is_one_off              = true;
var is_checkout             = false;

/**
 * Easily the sloppiest code I've written for this yet.
 * 
 * See: app/assets-malaysia/ts/PaymentFlow.ts
 * 
 * @param {*} event 
 * @param {*} no_update 
 */
function click_but_not_click( event = null, no_update = false)
{
    is_one_off = this.id === "type-one-off";

    if ( ! no_update )
    {
        if ( is_one_off )
        {
            $( "#str-one-off" ).removeClass( "hidden" );
            $( "#str-recurring" ).addClass( "hidden" );

            $( "#type-one-off" ).addClass( "active" );
            $( "#type-recurring" ).removeClass( "active" );

            $( ".dv2-total-recur" ).addClass( "hidden" );
        }
        else
        {
            $( "#str-recurring" ).removeClass( "hidden" );
            $( "#str-one-off" ).addClass( "hidden" );

            $( "#type-one-off" ).removeClass( "active" );
            $( "#type-recurring" ).addClass( "active" );
            
            $( ".dv2-total-recur" ).removeClass( "hidden" );
        }
    }

    // If it is a recurring donation
    if ( ! is_one_off )
    {
        // If there's no length on 
        if ( $( ".dv2-donation-level-desc" ).text().length === 0 )
        {
            $( ".dv2-total-recur.dv2-each-month" ).addClass( "hidden" );
        }
        else
        {
            $( ".dv2-total-recur.dv2-each-month" ).removeClass( "hidden" );
        }
    }
}

/**
 * Initialise the payment flow, adds all our hooks.
 */
function initialise_payment_flow( $ )
{    
    // Hook the donation details showing, we need to clear states on this one since the amounts can change.
    cards.addHook( "on_show_donation_details", hk_on_show_donation_details );

    // Do not hook payment stuff on NOP.
    if ( $( `input[name="onDonateFlow"]` ).val() === "true" )
    {
        return;
    }

    // Hook the payment details showing.
    cards.addHook( "on_show_payment_details", hk_on_show_payment_details );

    cards.addHook( "on_show_donor_details", function ()
    {
        if ( ! is_one_off )
        {
            return;
        }

        has_card_initialised = false;
    } );

    $( "#type-one-off, #type-recurring" ).on( "click", function ( event )
    {
        click_but_not_click.bind( this )( event );
    } );

    using_known_card = $( "#known-cards-wrap" ).length > 0;
}

function hk_on_show_donation_details()
{
    vl_util.dbgout( "Payment Flow", "Showing donation details" );

    if ( ! is_one_off )
    {
        return;
    }

    // Clear anything currently in the (stripe) payment area.

    // Abandon previous PI, unless we're on the quran flow.
    if ( ! $( `html[data-seg-0="hifzsponsorship"][data-seg-1="donate"]` ).length )
    {
        const pi = donate_stripe.get_last_payment_intent();

        if ( pi )
        {
            donate_stripe.abandon_payment_intent( pi, () =>
            {
            }, null );
        }
    }

    has_card_initialised    = false;
    client_secret           = null;
    payment_intent_id       = null;
    subscription_id         = null;
}

function generate_additional_data()
{
    vl_util.dbgout( "Payment Flow", "Generating additional payment data." );

    // The additional data for this payment.
    var additional_data =
    {
        is_zakat:       $( "#zakat_checkbox:checked" ).length > 0,
        is_gift_aid:    $( "#gift_aid_yes:checked" ).length > 0,
        is_donate_cr:   $( "#donate_cr:checked" ).length > 0,
        is_company:     $( "#company_checkbox:checked" ).length > 0,
        is_event:       window.is_event !== undefined && window.is_event === true,
        // project_id:     $( "#project_name" ).val()
    };

    // Add the country ID.
    additional_data.country_id = $( "#country_supported" ).val();

    // Add the donation source ID.
    additional_data.source_id = donation.get_source_identifier();

    // Add the marketing consent identifier (internally just attaches the customer email + phone etc into the correct places.)
    additional_data.marketing_consent_type = donation.get_marketing_consent();

    if ( $( "#is_campaign_donation" ).length === 0 )
    {
        additional_data.project_id = $( "#project_name" ).val();
    }
    else
    {
        additional_data.campaign_id     = $( "#campaign_id" ).val();
        additional_data.is_anonymous    = $( "#anonymous_checkbox:checked" ).length > 0;
        additional_data.message         = $( "#fundraiser_message" ).val().replace( /\s+/g, "" ).length > 0 ? $( "#fundraiser_message" ).val() : null;
        additional_data.project_id      = $( "#project_name" ).val();
    }

    // If this is a company donation, we need to add some extra data for the business name and address.
    if ( additional_data.is_company )
    {
        additional_data.business = {};
        additional_data.business.name       = $( "#business_name" ).val();
        additional_data.business.address    = $( "#business_address" ).val();
        additional_data.business.address_full   =
        {
            st_num:         $( "#business_address_st_num" ).val(),
            street:         $( "#business_address_street" ).val(),
            town:           $( "#business_address_town" ).val(),
            county:         $( "#business_address_county" ).val(),
            postal_code:    $( "#business_address_postal_code" ).val(),
            country:        $( "#business_address_country" ).val()
        }
    }

    // If donate CR enabled, we need to know how much for PayPal integration. Server can deal with calcing the amounts here.
    if ( additional_data.is_donate_cr )
    {
        additional_data.donate_cr_percent = donation.amounts.get_fee_percent();
    }

    if ( window.is_food_fridays )
    {
        additional_data.is_food_fridays = true;
    }

    if ( additional_data.is_event ) 
    {
        additional_data.eventId     = $( "#event_id" ).val();
        additional_data.ticketId    = $( "#ticket_id" ).val();
        additional_data.quantity    = $( "#ticket_quantity" ).val();
    }

    return additional_data;
}

/**
 * Called when the payment details are shown, we create a payment intent at this step since we've validated the users input.
 * (Validation still needs to occur on the serverside, too.)
 */
function hk_on_show_payment_details( isCheckingOut = false, isNop = false )
{

    vl_util.dbgout( "Payment Flow", "Payment details are now showing." );

    is_one_off = ( $( "#type-one-off.active" ).length > 0 ) || vl_util.site_config().isMalaysia;

    $( "#complete-donation" ).on( "click", do_payment );

    console.log( "is_one_off: " + ( is_one_off ? "true" : "false" ) );

    if ( ! is_one_off )
    {
        show_payment_details_gocardless();

        return;
    }

    $( "#payment_one_off" ).removeClass( "hidden" );
    $( "#payment_recurring" ).addClass( "hidden" );

    clear_payment_area();

    // Can't use cards that don't exist.
    if ( $( ".single-known-card" ).length === 0 )
    {
        using_known_card = false;
    }

    $( "#use_stripe" ).on( "click", function ()
    {
        $( "#payment_stripe" ).removeClass( "hidden" );
        $( "#payment_paypal" ).addClass( "hidden" );
        using_paypal = false;
    } );

    $( "#use_paypal" ).on( "click", function ()
    {
        $( "#payment_stripe" ).addClass( "hidden" );
        $( "#payment_paypal" ).removeClass( "hidden" );
        using_paypal = true;
    } );

    const amount = $( "#enter_amount" ).val();

    const additional_data = generate_additional_data();

    $( "#payment_details_loading" ).removeClass( "hidden" );
    $( "#payment-details-waiting" ).removeClass( "hidden" );
    $( "#payment-details-loaded" ).addClass( "hidden" );

    if ( ! isCheckingOut )
    {
        set_donation_processing( true );

        // Create payment intent when the payment details show, gets the client secret, asynchronous.
        donate_stripe.create_payment_intent(
            amount,
            hk_on_create_payment_intent,
            hk_on_failed_create_payment_intent,
            additional_data
        );
    }
}

function force_stripe_data( clientSecret, paymentID, subscriptionId = null )
{
    client_secret       = clientSecret;
    payment_intent_id   = paymentID;
    subscription_id      = subscriptionId;

    $( "#payment-details-waiting" ).addClass( "hidden" );
    $( "#payment-details-loaded" ).removeClass( "hidden" );

    if ( $( "#has-cards" ).length && using_known_card )
    {
        on_using_known_cards();
    }
    else
    {
        initialise_new_card();
    }

    set_donation_processing( false );
}

/**
 * Hook for the event that a payment intent was successfully created for our flow.
 *
 * @param {Any} res Object (from JSON) returned from server.
 * @returns
 */
function hk_on_create_payment_intent( res )
{
    vl_util.dbgout( "Payment Flow", "Retrieved new Payment Intent" );

    if ( ! ( is_one_off || vl_util.site_config().isMalaysia )  )
    {
        return;
    }

    // Client secret, not to be shared about.
    client_secret               = res.client_secret;
    payment_intent_id           = res.payment_intent_id;
    window.stripe_pi_id         = res.payment_intent_id;
    window.stripe_cl_secret     = res.client_secret;

    if ( ! client_secret )
    {
        if ( res.user_error )
        {
            jQuery( "#error-message" ).removeClass( "hidden" ).text( res.user_error );    
        }
        else
        {
            jQuery( "#error-message" ).removeClass( "hidden" ).text( "Failed to create payment intent" );
        }
        
        return;
    }

    $( "#payment-details-waiting" ).addClass( "hidden" );
    $( "#payment-details-loaded" ).removeClass( "hidden" );

    if ( $( "#has-cards" ).length && using_known_card )
    {
        on_using_known_cards();
    }
    else
    {
        initialise_new_card();
    }

    set_donation_processing( false );
}

function get_chosen_card_id()
{
    return $( `input[name="chosen-card"]:checked` ).parents( ".single-known-card" ).data( "card-id" );
}

function on_using_known_cards()
{
    vl_util.dbgout( "Payment Flow", "User is using known cards" );

    if ( ! ( is_one_off || vl_util.site_config().isMalaysia )  )
    {
        return;
    }

    using_known_card = true;

    cards.fireHook( "stripe_initialised" );
}

function on_adding_new_card()
{
    vl_util.dbgout( "Payment Flow", "User is adding new card" );

    if ( ! ( is_one_off || vl_util.site_config().isMalaysia )  )
    {
        return;
    }
    using_known_card = false;
}

async function do_payment( event )
{
    if ( ! ( is_one_off || vl_util.site_config().isMalaysia )  )
    {
        do_payment_gocardless( event );

        return;
    }

    if ( in_payment || processing )
    {
        return;
    }

    vl_util.dbgout( "Payment Flow", "Attempting requested payment type (user clicked payment button)" );

    set_donation_processing( true );
    $( "#error-message" ).addClass( "hidden" ).text( "" );

    in_payment = true;

    if ( ! using_paypal )
    {
        if ( using_known_card )
        {
            fire_known_card_payment( event );
        }
        else
        {
            fire_form_payment( event );
        }
    }
    else
    {
        fire_paypal_payment( event );
    }

}

async function fire_paypal_payment( event )
{
    vl_util.dbgout( "Payment Flow", "Beginning PayPal payment" );

    if ( ! ( is_one_off || vl_util.site_config().isMalaysia )  )
    {
        return;
    }

    inputs.getNewRecaptchaToken( "create_paypal_payment", ( function ( token )
    {
        jQuery.ajax(
        {
            url: vl_util.site_url() + "/api/v1/paypal/create-payment",
            data:
            {
                // Core information for making a donation.
                donation:
                {
                    amount: donation.amounts.get_final_amount(),
                    currency: donation.get_currency_code(),
                    subtotal: donation.amounts.get_sub_total()
                },
    
                // Stripe information (so we can cancel that when we do the PayPal stuff)
                stripe:
                {
                    payment_intent_id: payment_intent_id,
                    client_secret: client_secret
                },
    
                // Extra fields for the donation, i.e. Zakat, Gift Aid..
                extra:              generate_additional_data(),
    
                _token:             $( `meta[name="csrf-token"]` ).attr( "content" ),
    
                _recaptchaToken:    token
            },
            method: "POST",
            dataType: "JSON",
            success: function ( res )
            {
                vl_util.dbgout( "Payment Flow", "PayPal Payment finished:" );
    
                if ( vl_util.site_config().is_debug )
                {
                }
    
                if ( res.status )
                {
                    window.location.href = res.url;
                }
                else
                {
                    $( "#error-message" ).removeClass( "hidden" ).text( res.error );
    
                    vl_util.dbgout( "Payment Flow", "Error in PayPal payment: " + res.error );
    
                    in_payment = false;
                }
            },
            error: function ( jqXHR, error )
            {
                $( "#error-message" ).removeClass( "hidden" ).text( error );
                vl_util.dbgout( "Payment Flow", "Server reported error in payment: " + error );
    
                in_payment = false;
            }
        } );
    } ).bind( this ) )
}

var redirectUrl;

async function fire_known_card_payment( event )
{
    vl_util.dbgout( "Payment Flow", "User is firing payment using known card." );
    
    if ( ! ( is_one_off || vl_util.site_config().isMalaysia )  )
    {
        return;
    }

    if ( ! client_secret )
    {
        vl_util.dbgout( "Payment Flow", "User attempted to fire card payment BEFORE client secret generation!" );

        set_donation_processing( false );

        return;
    }

    // Get our stripe instance.
    const stripe = donate_stripe.get_stripe();

    // Set donation as processing.
    set_donation_processing( true );

    // Confirm the card payment using the client secret and payment method.
    stripe.confirmCardPayment( client_secret,
    {
        payment_method: get_chosen_card_id()
    } ).then( function ( out )
    {
        // If there's an error, the user should know about it.
        if ( out.error )
        {
            vl_util.dbgout( "Payment Flow", "Error on Stripe card payment: " + out.error.message );

            $( "#error-message" ).removeClass( "hidden" ).text( out.error.message );

            in_payment = false;
        }
        else if ( out.paymentIntent ) // Otherwise check for a payment intent response.
        {
            if ( out.paymentIntent.status !== "succeeded" )
            {
                $( "#error-message" ).removeClass( "hidden" ).text( "Your payment could not be made." );

                vl_util.dbgout( "Payment Flow", "Payment intent did not return succeeded, returned: " + out.paymentIntent.status );
            }
            else
            {
                vl_util.dbgout( "Payment Flow", "Stripe card Payment successful" );

                // Redirect to the thank you page.
                window.location.href = redirectUrl
                    .replace( "{paymentIntent}", out.paymentIntent.id )
                    .replace( "{clientSecret}", out.paymentIntent.client_secret )
                    .replace( "{hifzDonationUUID}", window.hifz_id ? window.hifz_id : "" )
                    .replace( "{subscription}", subscription_id );

                // Exit here so the donate button doesn't get re-bound.
                return;
            }

            in_payment = false;
        }
        else
        {
            $( "#error-message" ).removeClass( "hidden" ).text( "No response from Stripe on payment, please contact an administrator." );

            vl_util.dbgout( "Payment Flow", "Stripe didn't report anything post payment confirmation." );

            in_payment = false;
        }

        set_donation_processing( false );
    } );
}

async function fire_form_payment( event )
{
    vl_util.dbgout( "Payment Flow", "Attempting payment with stripe elements form." );
    event.preventDefault();

    if ( ! ( is_one_off || vl_util.site_config().isMalaysia )  )
    {
        return;
    }

    let elements = donate_stripe.get_elements();

    const { error } = await donate_stripe.get_stripe().confirmPayment(
    {
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams:
        {
            return_url: redirectUrl
            .replace( "{paymentIntent}", payment_intent_id )
            .replace( "{clientSecret}", client_secret )
            .replace( "{hifzDonationUUID}", window.hifz_id ? window.hifz_id : "" )
            .replace( "{subscription}", subscription_id ? subscription_id : "" ),
        },
    } );

    if ( error )
    {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        $( "#error-message" ).removeClass( "hidden" ).text( error.message );

        vl_util.dbgout( "Payment Flow", "Stripe elements card payment error: " + error.message );

        inputs.enable_complete_button();
        set_donation_processing( false );

        in_payment = false;
    }
    else
    {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.

        vl_util.dbgout( "Payment Flow", "Payment was successful, user is being automatically redirected." );
    }
}

function initialise_new_card()
{
    if ( ! ( is_one_off || vl_util.site_config().isMalaysia )  )
    {
        return;
    }

    // Ensure cards have initialised.
    if ( has_card_initialised )
    {
        return;
    }

    // Ensure there's at least a client secret on the go.
    if ( ! client_secret || ! client_secret.length )
    {
        return;
    }

    vl_util.dbgout( "Payment Flow", "Initialising stripe card payment (elements)" );

    set_donation_processing( true );

    // Initialise the stripe cards etc.
    donate_stripe.initialise_stripe_payment( client_secret ).then( function ()
    {
        // Update the payment button.
        $( "#payment-form" ).removeClass( "hidden" );
        $( "#payment-form .cr-btn" ).removeClass( "btn-disabled" ).addClass( "btn-active" );
        $( "#error-message" ).addClass( "hidden" ).text( "" );

        vl_util.dbgout( "Payment Flow", "Stripe elements card payment successfully initialised." );

        has_card_initialised = true;
        set_donation_processing( false );

        cards.fireHook( "stripe_initialised" );
    } );
}

function set_donation_processing( is_processing )
{
    if ( is_processing )
    {
        $( "#complete-donation" ).addClass( "btn-disabled" ).removeClass( "btn-active" );
        $( "#complete-state-inactive" ).addClass( "hidden" );
        $( "#complete-state-active").removeClass( "hidden" );

        vl_util.dbgout( "Payment Flow", "Payment is now processing." );
    }
    else
    {
        $( "#complete-donation" ).removeClass( "btn-disabled" ).addClass( "btn-active" );
        $( "#complete-state-inactive" ).removeClass( "hidden" );
        $( "#complete-state-active").addClass( "hidden" );

        vl_util.dbgout( "Payment Flow", "Payment is no longer processing." );
    }

    processing = is_processing;
}

/**
 * Called on the event we failed to create a payment intent.
 */
function hk_on_failed_create_payment_intent()
{
    if ( ! ( is_one_off || vl_util.site_config().isMalaysia )  )
    {
        return;
    }

    $( "#error-message" ).removeClass( "hidden" ).text( "Server failed to create payment intent" );
    vl_util.dbgout( "Payment Flow", "Something went seriously wrong when making payment intent. Check network tab & replay the request." );
}

/**
 * Clears the payment element and the error message.
 */
function clear_payment_area()
{
    if ( ! ( is_one_off || vl_util.site_config().isMalaysia )  )
    {
        return;
    }
    
    // Clear the payment element in case user tried to pay before.
    jQuery( "#payment-element" ).html( "" );

    // Clear any previous error message content.
    jQuery( "#error-message" ).addClass( "hidden" ).html( "" );
}

export var payment_flow =
{
    initialise_payment_flow:    initialise_payment_flow,
    clear_payment_area:         clear_payment_area,
    initialise_new_card:        initialise_new_card,
    on_adding_new_card:         on_adding_new_card,
    on_using_known_cards:       on_using_known_cards,
    set_donation_processing:    set_donation_processing,
    click_but_not_click:        click_but_not_click,
    force_stripe_data:          force_stripe_data,
    hk_on_show_payment_details: hk_on_show_payment_details,
    do_payment:                 do_payment,
    generate_additional_data:   generate_additional_data,
    set_card_initialised:       function( v )
    {
        has_card_initialised = v;
    },
    set_known_unknown:          function ( v )
    {
        using_known_card = v;
    },
    set_using_paypal:           function ( v ) 
    {
        using_paypal = v;
    },
    is_one_off:                 function ()
    {
        return is_one_off;
    },
    is_using_paypal:            function ()
    {
        return using_paypal;
    },
    set_is_one_off:             function ( v )
    {
        is_one_off = v;
    },
    set_is_checkout:            function ( v )
    {
        is_checkout = v;
    },
    overrideRedirectUrl:        function ( newUrl )
    {
        redirectUrl = newUrl;
    },
    init: function ()
    {
        redirectUrl = vl_util.site_url() + `/api/v1/stripe/intermediary?payment_intent={paymentIntent}&payment_intent_client_secret={clientSecret}&subscription_id={subscription}`;

        // redirectUrl = vl_util.site_url() + `/${vl_util.site_config().donate_url}/completed?payment_intent={paymentIntent}&payment_intent_client_secret={clientSecret}&redirect_status=succeeded&subscription_id={subscription}`;
    },
    setSubscriptionId( subid ) 
    {
        subscription_id = subid;
    }
}
