import { inputs } from "./inputs";                         // Scripts for input validation etc.
import $                    from "jquery";
import jQuery               from "jquery";

/**
 * 
 * @returns 
 */
function initialise_donation_levels()
{
    // If this mapping isn't available, we can't do anything.
    if ( ! window._vl_donation_level_mapping )
    {
        return;
    }

    if ( $( "#is_campaign_donation" ).val() == "1" )
    {
        return;
    }

    const first_selected = $( "#project_name" ).find( ":selected" ).val();
        
    set_donation_levels_by_project_id( first_selected );

    $( "#project_name" ).on( "input", function ()
    {
        // Get the selected project ID.
        const project_id = $( this ).val();

        set_donation_levels_by_project_id( project_id );
    } );
}

/**
 * 
 * @param {*} project_id 
 */
function set_donation_levels_by_project_id( project_id )
{
    const donation_mappings = window._vl_donation_level_mapping;

    const possible_levels = donation_mappings[ project_id ];

    clear_donation_levels();

    for ( let index in possible_levels )
    {
        const level = possible_levels[ index ];

        insert_donation_level( level.id, level.target.split( "." )[ 0 ], level.symbol, level.description );
    }

    if ( $( "#enter_amount" ).val() === 10 )
    {
        setTimeout( () => { $( ".donate-select-val.cr-button:first-of-type" ).trigger( "click" ) }, 50 );
    }

    inputs.bind_amount_selection();
}

 /**
  * 
  */
function clear_donation_levels()
{
    jQuery( "#donation-levels" ).html( "" );
}
 
 /**
  * 
  * @param {*} id 
  * @param {*} amount 
  * @param {*} symbol 
  * @param {*} description 
  */
function insert_donation_level( id, amount, symbol, description )
{
    jQuery( "#donation-levels" ).append( `<button class="donate-select-val cr-button" data-amount="${amount}" data-description="${description}" data-id="${id}">${symbol}${amount}</button>` );
}

export var donation_levels = 
{
    initialise_donation_levels: initialise_donation_levels,
    set_donation_levels_by_project_id: set_donation_levels_by_project_id,
    clear_donation_levels: clear_donation_levels,
    insert_donation_level: insert_donation_level
};