import { Countdown } from "../lib/countdown";
import { ValidatableForm } from "../forms/ValidatableForm";
import { vl_util } from "../general/util";
import $                    from "jquery";
import jQuery               from "jquery";

/**
 * Events singular view page controller.
 */
export class EventsSingleController
{
    /**
     * The countdown.
     * 
     * @var {Countdown} countdown
     */
    countdown = null;

    /**
     * The registration form.
     * 
     * @var {ValidatableForm} registrationForm
     */
    registrationForm = null;

    /**
     * The current events slug.
     */
    eventSlug = null;

    /**
     * Events single controller class constructor.
     */
    constructor ()
    {
        // Get the event slug from the HTML tag config.
        this.eventSlug = $( "html" ).attr( "data-seg-1" );

        // If no slug is found, throw an error in case developer is confused.
        if ( ! this.eventSlug )
        {
            console.error( "Posting certain things may not be possible with this event: missing slug." );
        }

        // Initialise a new countdown for the event start date, may not exist.
        if ( $( "div.countdown[data-event-start-ts]" ).length ) 
        {
            this.countdown = new Countdown( "div.countdown[data-event-start-ts]" );
        }

        // Event registration form, may not exist.
        if ( $( "#event-register-form" ).length )
        {
            this.registrationForm = 
                ( new ValidatableForm( "event-register-form" ) )
                .onValidated( this.onRegistrationValidated, this )
                .onValidateError( this.onRegistrationInvalid, this );
        }
    }

    /**
     * Called when the registration form is fully validated.
     * 
     * @this {EventsSingleController}
     */
    onRegistrationValidated()
    {
        // Get the form data from our form.
        const formData = this.registrationForm.values();

        // Make a new async task to collect the response 
        $.ajax(
        {
            url: `${vl_util.site_url()}/api/v1/front/events/${this.eventSlug}/register`,
            method: "POST",
            dataType: "JSON",
            async: true,
            data: 
            {
                _token: vl_util.csrf_token(),
                ...formData
            }
        } )
        
        // Once a response is available, process it.
        .done( ( ( response, textStatus, jqXHR ) => 
        {
            if ( response.status)
            {
                $( "#event-register-form" ).addClass( "hidden" );
                $( "#event-on-registered" ).removeClass( "hidden" );
            }
            else
            {
                alert( response.error );
            }
        } ).bind( this ) )

        // On error, handle properly,
        .fail( ( jqXHR, textStatus, errorThrown ) => 
        {
            // Log error to console.
            console.error( errorThrown );

            // Show to user an error is ocurring.
            alert( "An error ocurred while creating your form submission!" );
        } );
    }

    /**
     * Called when the registration form could not be validated.
     * 
     * @this {EventsSingleController}
     */
    onRegistrationInvalid()
    {}
}