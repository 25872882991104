import { vl_util } from "./util";
import { cards } from "../donations/card-toggles";
import { inputs } from "../donations/inputs";
import { stripe_style } from "../donations/style-stripe-elements";
import { App } from "../../main";
import $                    from "jquery";
import jQuery               from "jquery";
import { payment_flow } from "../donations/payment-flow";

/**
 * Our stripe instance.
 */
var stripe = null;

/**
 * Our stripe elements instance.
 */
var elements = null;

/**
 * Last seen payment intent. Used for cancelling previous ones to prevent clogging up the payments page / backend as much.
 */
var last_payment_intent = null;

/**
 * The current users guest ID.
 */
var guest_id = null;

/**
 * Creates a payment intent for the desired amount, the amount value should be with normal decimal placing.
 * 
 * @param {Number} amount
 * @param {CallableFunction|null} done      Function to be called on success.
 * @param {CallableFunction|null} failed    Function to be called on failure.
 */
function create_payment_intent( amount, done = null, failed = null, additional_data = null )
{
    vl_util.dbgout( "Payment Flow", "Payment intent being generated.. " );
    
    // Multiply amount by 100 to get in pennies.
    amount = amount * 100;

    // Ensure its an integer value.
    amount = parseInt( "" + amount );

    if ( ! amount )
    {
        return;
    }

    // Data to be sent to server.
    var out_data = 
    {
        amount: amount,
        currency: $( "#donation_currency" ).val(),
        guest_id: guest_id
    };

    if ( additional_data )
    {
        out_data = 
        {
            ...out_data,
            ...additional_data
        };
    }

    // If a last payment intent is set, tell the server to request stripe to destroy it.
    if ( last_payment_intent )
    {
        out_data.last_payment_intent = last_payment_intent;
    }

    // On malaysia website, subscription is created on backend post for reocurring payments.
    if ( vl_util.site_config().isMalaysia && $( ".donation-type-selector.active#type-recurring" ).length )
    {
        out_data.create_subscription = true;
    }

    // The CSRF token.
    out_data._token             = $( `meta[name="csrf-token"]` ).attr( "content" );

    inputs.getNewRecaptchaToken( "create_payment_intent", ( function ( token ) 
    {
        // The reCAPTCHA token.
        out_data._recaptchaToken    = token;

        // Make the AJAX request.
        jQuery.ajax(
        {
            url: vl_util.site_url() + "/api/v1/stripe/create-payment",
            data: out_data,
            method: "POST",
            dataType: "JSON",

            // Function to be called on success
            success: function ( res )
            {
                if ( res && res.payment_intent_id )
                {
                    last_payment_intent = res.payment_intent_id;
                }

                if ( res.error )
                {
                    App.instance.modal_error_pop.setErrorString( res.user_error ).showHideModal();
                }

                if ( res && res.subscription_id )
                {
                    payment_flow.setSubscriptionId( res.subscription_id );
                }

                if ( done ) done( res );
            },
            
            // Function to be called on failure.
            error: function ( jqXHR, error )
            {
                if ( failed ) failed();
            }
        } );
    } ).bind( this ) );
}

/**
 * Attempts to cancel a payment intent by ID, sets the actual status to abandoned.
 * 
 * @param {String} payment_intent_id 
 */
function abandon_payment_intent( payment_intent_id, success, failure )
{
    if ( $( `html[data-seg-0="hifzsponsorship"][data-seg-1="donate"]` ).length )
    {
        success( {} );
        return;
    }

    // Don't try this twice.
    if ( payment_intent_id === last_payment_intent )
    {
        last_payment_intent = null;
    }

    jQuery.ajax(
    {
        url: vl_util.site_url() + "/api/v1/stripe/abandon-payment",
        data: 
        {
            payment_intent_id: payment_intent_id,
            _token: $( `meta[name="csrf-token"]` ).attr( "content" )
        },
        method: "POST",
        dataType: "JSON",

        success: function ( res )
        {
            if ( success ) success( res );
        },

        error: function ( jqHXR, error )
        {
            if ( failure ) failure( error );
        }
    } );
}

/**
 * Saves the users data.
 * 
 * @param {*} data 
 */
function save_user_data( data )
{
    if ( $( `html[data-seg-0="hifzsponsorship"][data-seg-1="donate"]` ).length )
    {
        $( "#paypal-step2-continue" ).addClass( "hidden" );
        $( "#paypal-step2-loading" ).removeClass( "hidden" );
    }

    // CSRF token.
    data._token             = $( `meta[name="csrf-token"]` ).attr( "content" );

    inputs.getNewRecaptchaToken( "store_user_information", ( function ( token ) 
    {
        // Recaptcha token.
        data._recaptchaToken    = token;

        jQuery.ajax(
        {
            url:                vl_util.site_url() + "/api/v1/front/store-udata",
            data:               data,
            method:             "POST",
            dataType:           "JSON",
        } )
        .always( function ( res )
        {
            let guestID = res.guestid;

            if ( ! guestID )
            {
                return;
            }

            // Store the guest ID for the payment initialisation.
            guest_id = guestID;

            // Finalise step 2.
            cards.finalise_step_2();
        } );
    } ).bind( this ) );
}

/**
 * Initialises our stripe instance.
 */
function initialise_stripe()
{
    stripe = Stripe( vl_util.site_config().publishable_key );

    return stripe;
}

/**
 * Initialise a stripe payment, creates the on screen cards too.
 */
function initialise_stripe_payment( client_secret_key )
{
    return ( async () => {
        const options = 
        {
            clientSecret: client_secret_key,
            // Fully customizable with appearance API.
            appearance: stripe_style.appearance(),
        };
        
        // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
        elements = stripe.elements(options);
        
        // Create and mount the Payment Element
        const paymentElement = elements.create( "payment" );

        paymentElement.mount( "#payment-element" );
        inputs.disable_complete_button();

        paymentElement.on( "ready", function ()
        {
            inputs.enable_complete_button();
        } );

        $( "#payment_details_loading" ).toggleClass( "hidden flex" );
    } )();  
}

/**
 * Gets the main stripe object.
 * 
 * @returns {Object}
 */
function get_stripe()
{
    return stripe;
}

/**
 * Gets the Stripe elements object .
 * 
 * @returns {Object}
 */
function get_elements()
{
    return elements;
}

/**
 * Gets the last payment intent, if it exists.
 * 
 * @return {String|null}
 */
function get_last_payment_intent()
{
    return last_payment_intent;
}

function set_last_payment_intent( x )
{
    last_payment_intent = x;
}

export var donate_stripe = window.donate_stripe = 
{
    initialise_stripe:          initialise_stripe,
    initialise_stripe_payment:  initialise_stripe_payment,

    create_payment_intent:      create_payment_intent,
    abandon_payment_intent:     abandon_payment_intent,
    save_user_data:             save_user_data,
    
    get_stripe:                 get_stripe,
    get_elements:               get_elements,
    get_last_payment_intent:    get_last_payment_intent,
    set_last_payment_intent:    set_last_payment_intent
};