import $                        from "jquery";                                          // JQuery
import jQuery                   from "jquery";
import tippy                    from 'tippy.js';                                        // Tippy popups
import getSymbolFromCurrency    from 'currency-symbol-map';                             // Currency mapping for 
import { jrumble }              from "./vendor/jrumble/jrumble";                        // JRumble, for rumbling inputs.
import "jquery-match-height";
import LazyLoad from "vanilla-lazyload";

import './components/donations/summary-box.js';                                             // Scripts for the donations box at bottom.
import './components/donations/checkboxes_radios_selects';                                  // Scripts that expand/collapse the cards.
import "./components/donations/completed";                                                  // Scripts for the completed page.

import { vl_util }                      from "./components/general/util";                       // Utility functions
import { cards }                        from "./components/donations/card-toggles.js";          // Scripts that expand/collapse the cards.
import { inputs }                       from "./components/donations/inputs";                   // Scripts for input validation etc.
import { payment_flow }                 from "./components/donations/payment-flow";             // Payment flow / scripts.
import { donate_stripe }                from "./components/general/stripe";                     // Stripe-related functions.
import { donation_levels }              from './components/donations/donation-levels.js';       // Donation levels functionality.
import { VL_Login_Modal }               from "./components/modals/login";                       // Login system / modal
import { VL_Register_Modal }            from './components/modals/register';                    // Register system / modal.
import { VL_PayPal_Cancel_Modal }       from "./components/modals/pp-cancel";                   // PayPal payment canceled modal. 
import { VL_Error_Modal }               from "./components/modals/error";                       // Error modal.
import { VL_Sub_Cancel_Modal }          from "./components/modals/subscription-cancel";         // Subscription cancellation modal.
import { ConfirmationWorker }           from "./components/donations/confirmation";             // Payment confirmation worker.
import { DonationCompleted }            from "./components/donations/completed";                // Payment completion worker.
import Flickity                         from "flickity-imagesloaded";
import { donation }                     from "./components/donations/current-donation";
import Alpine                           from 'alpinejs';

// Controllers.
import UserProfileController            from "./components/donations/user-profile";             // User profile page controller.
import BlogController                   from "./components/pages/blog";                         // Blog page controller
import CartController                   from "./components/checkout/cart";                      // Frontend cart controller
import CheckoutController               from "./components/checkout/checkout";                  // Frontend checkout controller
import GetInvolvedController            from "./components/pages/get-involved";                 // Get-involved page controller.
import QuranChallengeController         from "./components/quran-challenge/quran-challenge";    // Quran challenge controller.
import QuranChallengeDonateController   from "./components/quran-challenge/quran-challenge-donate"; // Quran challenge donate flow controller.
import FundraiserLevelsController       from "./components/general/fundraiser-levels";
import FoodFridaysController            from "./components/foodfridays/FoodFridaysController";
import FoodFridaysIndexController            from "./components/foodfridays/FoodFridaysIndexController";
import EventsArchiveController          from "./components/events/EventsArchiveController";
import { EventsSingleController }       from "./components/events/EventsSingleController";
import StepOverHungerController         from "./components/step-over-hunger/StepOverHungerController";
import SchoolsFundraisingController     from "./components/schools-fundraising/SchoolsFundraisingController";
import MountainsOfMakkahController      from "./components/mountains-of-makkah/MountainsOfMakkahController";
import RamadanChallengeController       from "./components/ramadan-challenge/RamadanChallengeController";
import Team365Controller                from "./components/team365/Team365Controller";
import CampaignDetailController         from "./components/fundraising/CampaignDetailController";
import { QuickDonateController }        from "./components/general/quick-donate";
import { NightOfPowerController }       from "./components/night-of-power/NightOfPowerController";
import NightOfPowerCompletedController  from "./components/night-of-power/NightOfPowerCompletedController";

import "lite-youtube-embed";

// Initialise jRumble on both instances of JQuery.
jrumble( jQuery );
jrumble( $ );

/**
 * Our app.
 */
export class App
{
    /**
     * The apps instance (singleton). 
     * 
     * @var {App|null} instance
     */
    static instance = null;

    /**
     * Loaded all donations?
     * 
     * @var {Boolean} loaded_all_donations
     */
    loaded_all_donations = false;

    /**
     * The login modal.
     * 
     * @var {VL_Login_Modal|null} modal_login
     */
    modal_login = null;

    /**
     * The register modal.
     * 
     * @var {VL_Register_Modal|null} modal_register
     */
    modal_register = null;

    /**
     * The PayPal donation cancel notice modal.
     * 
     * @var {VL_PayPal_Cancel_Modal|null} modal_pp_cancel
     */
    modal_pp_cancel = null;

    /**
     * Error popup modal.
     * 
     * @var {VL_Error_Modal|null} modal_error_pop
     */
    modal_error_pop = null;

    /**
     * The subscription
     * 
     * @var {VL_Sub_Cancel_Modal} modal_cancel_subscription
     */
    modal_cancel_subscription = null;

    /**
     * The donation completion page handler.
     * 
     * @var {DonationCompleted} completed_handler
     */
    completed_handler = null;

    /**
     * The user profile controller instance.
     * 
     * @var {UserProfileController} userProfile
     */
    userProfile = null;

    /**
     * The blog controller instance.
     * 
     * @var {BlogController} blog
     */
    blog = null;

    /**
     * Get involved controller.
     * 
     * @var {GetInvolvedController} getInvolved
     */
    getInvolved = null;

    /**
     * The cart system.
     * 
     * @var {CartController} cart
     */
    cart = null;

    /**
     * The checkout system.
     * 
     * @var {CheckoutController} checkout
     */
    checkout = null;

    /**
     * The quran challenge controller.
     * 
     * @var {QuranChallengeController} quranChallenge
     */
    quranChallenge = null;

    /**
     * Quran challenge donation flow controller.
     * 
     * @var {QuranChallengeDonateController} quranChallengeDonate
     */
    quranChallengeDonate = null;

    /**
     * Fundraiser levels controller.
     * 
     * @var {FundraiserLevelsController} fundraiserLevels
     */
    fundraiserLevels = null;

    /**
     * Food Fridays controller instance.
     * 
     * @var {FoodFridaysController} foodFridays
     */
    foodFridaysDonate = null;

    /**
     * Food Fridays index controller instance.
     * 
     * @var {FoodFridaysIndexController}
     */
    foodFridaysIndex = null;

    /**
     * Events archive page controller.
     * 
     * @var {EventsArchiveController} eventsArchiveController
     */
    eventsArchiveController = null;

    /**
     * Events single page controller.
     * 
     * @var {EventsSingleController} eventsSingleController
     */
    eventsSingleController = null;

    /**
     * Step over hunger page controller instance.
     * 
     * @var {StepOverHungerController} stepOverHungerController
     */
    stepOverHungerController = null;

    /**
     * Haroon Mota page controller instance.
     * 
     * @var {RamadanChallengeController} RamadanChallengeController
     */
    RamadanChallengeController = null;

    /**
     * Team365 controller instance.
     * 
     * @var {Team365Controller} team365Controller
     */
    team365Controller = null;
    
    /**
     * Schools fundraising controller.
     * 
     * @var {SchoolsFundraisingController} schoolsFundraisingController
     */
    schoolsFundraisingController = null;

    /**
     * Mountains of Makkah controller.
     * 
     * @var {MountainsOfMakkahController} mountainsOfMakkahController
     */
    mountainsOfMakkahController = null;

    /**
     * Campaign detail controller.
     * 
     * @var {CampaignDetailController} campaignDetailController
     */
    campaignDetailController = null;

    /**
     * Quick checkout.
     * 
     * @var {DonationViaPaymentRequest} quickCheckout
     */
    quickCheckout = null;

    /**
     * Quick donate handler.
     * 
     * @var {QuickDonateController} quickDonate
     */
    quickDonate = null;

    /**
     * Night of power widget controller
     * 
     * @var {NightOfPowerController} nightOfPowerWidget
     */
    nightOfPowerWidget = null;

    /**
     * @var {JQueryStatic} $
     */
    $ = null;

    /**
     * Constructor
     */
    constructor ( $, singleton = false )
    {
        this.$ = $;

        this.$( this.onReady.bind( this ) );

        $(function(){
            var keyStop = {
                // 8: ":not(input:text, textarea, input:file, input:password)", // stop backspace = back
                13: "input:text, input:password", // stop enter = submit 
        
                end: null
            };
            $(document).bind("keydown", function(event){
                var selector = keyStop[event.which];
           
                if(selector !== undefined && $(event.target).is(selector)) {
                    event.preventDefault(); //stop event
                }
                return true;
            });
        });

        if ( singleton )
        {
            // Update the singleton instance.
            App.instance = this;
        }

        jQuery.ajaxSetup(
        {
            headers: 
            {
                "X-CSRF-TOKEN": $( "meta[name=\"csrf-token\"]" ).attr( "content" )
            }
        } );
    
        $.ajaxSetup(
        {
            headers: 
            {
                "X-CSRF-TOKEN": $("meta[name=\"csrf-token\"]" ).attr( "content" )
            }
        } );
    }

    /**
     * On ready
     */
    onReady()
    {
        payment_flow.init();

        this.createTippys()
            .createPseudoAnchors()
            .createModals()
            .createHomepageHeroFlickity()
            .createHomePageBlogsFlickity()
            .createHomepageFundraiserFlickity()
            .createFundraiserFlickity1()
            .createFundraiserFlickity2()
            .createFundraiserFlickity3()
            .createFundraiserFlickity4()
            .createFundraiserFlickity5()
            .createTeamMemberFlickity()
            .createCountriesFlickity()
            .initialiseUserMenu()
            .initialiseCompletions()
            .initialiseContactDetails()
            .initialiseAuth()
            .initialiseCurrencyCodes()
            .initialiseFundraising()
            .initialiseGlobalCurrencyCodes()
            .initialiseUserBackend()
            .initialiseFoodFridaysLanding()
            .initialiseUserProfile()
            .initialiseBlog()
            .initialiseGetInvolved()
            .initialiseCart()
            .initialiseCheckout()
            .initialiseQuranChallenge()
            .initialiseQuranChallengeDonate()
            .initialiseMobileMenu()
            .initialiseFundraiserLevels()
            .initialiseHeader()
            .initialiseFoodFridaysIndex()
            .initialiseFoodFridaysDonate()
            .initialiseEventsArchive()
            .initialiseEventsSingle()
            .initialiseStepOverHunger()
            .initialiseHaroonMota()
            .initialiseTeam365()
            .initialiseCampaignDetailController()
            .initialiseQuickDonate()
            .initialiseNightOfPowerDonate();

        Alpine.start();
        
        if ( ! window.is_event )
        {
            if ( $( "html[data-checkout-flow]" ).length )
            {
                // Show the donor details.
                cards.toggle_donor_details();
            
                // Fire a hook.
                cards.fireHook( "step_1_complete" );   
            }
            else
            {
                this.initialiseDonations();
            }
        }
        else
        {
            this.initialiseEventTicketPayments();
        }

        $( "#create-account-final" ).on( "click", function ()
        {
            App.instance.modal_register.showHideModal();
        } );

        $( "#sign-in-final" ).on( "click", function ()
        {
            App.instance.modal_login.setTitleStringDonate( false );

            App.instance.modal_login.showHideModal();
        } );

        if ( window.is_food_fridays !== undefined && window.is_food_fridays )
        {
            $( ".dv2-total-recur" ).text( "/wk" );
        }

        $( "#vl-dropdown-cart" ).on( "click", function ()
        {
            $( "#vl-cart" ).toggleClass( "hidden" );
        } );

        $( "button.collapsed, button.collapser" ).on( "click", ( e ) => {  $( ".collapse-profile.show" ).removeClass( "show" ); $( $( e.target ).data( "target" ) ).toggleClass( "show" ) } );

        $( ".campaign-tab-btn[data-target-id]" ).on( "click", (e)=>{ 
            const targetId = $( e.target ).data( "target-id" );
            $( ".campaign-story-tab" ).addClass( "hidden" );
            $( `.campaign-story-tab[data-tab-id="${ targetId }"]` ).removeClass( "hidden" );

            $( ".campaign-tab-btn" ).removeClass( "active" );
            $( e.target ).addClass( "active" );
        } );

        $( ".team-365-tab" ).on( "click", ( e ) => 
        {
            const targetId = $( e.target ).data( "tab-id" );
            
            $( ".t365-tab-content" ).addClass( "hidden" );
            $( `.t365-tab-content[data-tab-id="${ targetId }"]` ).removeClass( "hidden" );

            $( ".team-365-tab" ).removeClass( "active" );
            $( e.target ).addClass( "active" );
        } );

        $("#more-faq-toggle").on('click', function() {
            $("#more-faq-section").toggleClass("h-0");
            const text = $("#more-faq-toggle").text();
            $("#more-faq-toggle").text(text == "More FAQ's" ? "Hide FAQ's" : "More FAQ's");
            console.log("working");
        });

        var lazy = new LazyLoad();

        // Schools fundraising controller.
        this.schoolsFundraisingController = new SchoolsFundraisingController();

        // Mountains of makkah controller.
        this.mountainsOfMakkahController = new MountainsOfMakkahController();

        $( document ).bind( "keydown", function ( event ) 
        {
            var selector = {
                13: "input:text, input:password",
                end: null
            }[ event.which ];
        
            if ( selector !== undefined && $( event.target ).is( selector ) ) 
            {
                event.preventDefault(); //stop event
            }

            return true;
        });

        // Bind the search input.
        $( ".header-campaign-search-go" ).on( "click", App.searchForCampaigns );
        $( "#search_text" ).off( "keyup" ).on( "keyup", function ( e ) 
        {
            // If pressing enter
            if (e.key === "Enter") 
            {   
                e.preventDefault();
                
                App.searchForCampaigns();
            }
        } );

        // ReCaptcha implementation.
        inputs.initialiseRecaptcha();
    }


    /**
     * Create homepage hero flickity.
     * 
     * @returns {App} Instance of this.
     */
    createHomepageHeroFlickity()
    {
        const container = $( ".vl-hero-slider" ).get( 0 );

        if ( ! container )
        {
            return this;
        }

        const flick = new Flickity( container,
        {
            cellAlign: "left",
            contain: true,
            imagesLoaded: true,
            wrapAround: true,
            draggable: true,
            prevNextButtons: false,
            adaptiveHeight: true,
        } );

        $( "#btn-left-hero" ).on( "click", function ()
        {
            flick.previous();
        } );

        $( "#btn-right-hero" ).on( "click", function ()
        {
            flick.next();
        } );

        return this;
    }

    /**
     * Create homepage fundraiser flickity.
     * 
     * @returns {App} Instance of this.
     */
    createHomepageFundraiserFlickity()
    {
        const container = $( ".vl-fundraising-slider" ).get( 0 );
        
        if ( ! container )
        {
            return this;
        }

        const flick = new Flickity( container, 
        {
            cellAlign:          "left",
            contain:            true,
            imagesLoaded:       true,
            wrapAround:         true,
            draggable:          true,
            prevNextButtons :   false,
        } );

        $( "#btn-left-appeals" ).on( "click", function ()
        {
            flick.previous();
        } );

        $( "#btn-right-appeals" ).on( "click", function ()
        {
            flick.next();
        } );

        return this;
    }

    /**
     * Create homepage blog flickity.
     * 
     * @returns {App} Instance of this.
     */
    createHomePageBlogsFlickity()
    {
        const container = $( "#_carousel_container" ).get( 0 );

        if ( ! container )
        {
            return this;
        }

        const flick = new Flickity( container,
        {
            cellAlign:          "center",
            contain:            true,
            imagesLoaded:       true,
            prevNextButtons:    false,
            pageDots:           false,
            wrapAround:         true,
            draggable:          false,
        } );

        $( ".move_mark .move[data-i=0]" ).addClass( "active" );

        $( "#btn-right-blog" ).on( "click", function ()
        {
            flick.next();

            $( ".move_mark .move" ).removeClass( "active" );
            $( `.move_mark .move[data-i=\"${flick.selectedIndex}\"]` ).addClass( "active" );
        } );

        $( "#btn-left-blog" ).on( "click", function ()
        {
            flick.previous();

            $( ".move_mark .move" ).removeClass( "active" );
            $( `.move_mark .move[data-i=\"${flick.selectedIndex}\"]` ).addClass( "active" );
        } );

        $( ".blog-carousel-cell" ).on( "click", function ()
        {
            window.location.href = $( this ).data( "href" );
        } );

        return this;
    }

    /**
     * Creates the first fundraiser flickity.
     * 
     * @returns {App} Instance of this.
     */
    createFundraiserFlickity1()
    {
        const container = $( ".fundraiser-1-flkty" ).get( 0 );
        
        if ( ! container )
        {
            return this;
        }

        const flick = new Flickity( container, 
        {
            cellAlign:          "left",
            contain:            true,
            imagesLoaded:       true,
            wrapAround:         true,
            draggable:          true,
            prevNextButtons :   false,
        } );

        $( "#btn-left-fundraiser-1" ).on( "click", function ()
        {
            flick.previous();
        } );

        $( "#btn-right-fundraiser-1" ).on( "click", function ()
        {
            flick.next();
        } );

        return this;
    }

    /**
     * Creates second fundraiser flickity.
     * 
     * @returns {App} Instance of this.
     */
    createFundraiserFlickity2()
    {
        const container = $( ".fundraiser-2-flkty" ).get( 0 );
        
        if ( ! container )
        {
            return this;
        }

        const flick = new Flickity( container, 
        {
            cellAlign:          "center",
            contain:            true,
            imagesLoaded:       true,
            wrapAround:         true,
            draggable:          true,
            prevNextButtons :   false,
        } );

        $( "#btn-left-fundraiser-2" ).on( "click", function ()
        {
            flick.previous();
        } );

        $( "#btn-right-fundraiser-2" ).on( "click", function ()
        {
            flick.next();
        } );

        return this;
    }

    /**
     * Create the third fundraiser flickity.
     * 
     * @returns {App} Instance of this.
     */
    createFundraiserFlickity3()
    {
        const container = $( ".fundraiser-3-flkty" ).get( 0 );
        
        if ( ! container )
        {
            return this;
        }

        const flick = new Flickity( container, 
        {
            cellAlign:          "center",
            contain:            true,
            imagesLoaded:       true,
            wrapAround:         true,
            draggable:          true,
            prevNextButtons :   false,
        } );

        $( "#btn-left-fundraiser-3" ).on( "click", function ()
        {
            flick.previous();
        } );

        $( "#btn-right-fundraiser-3" ).on( "click", function ()
        {
            flick.next();
        } );

        return this;
    }

    /**
     * Creates the fourth fundraiser flickity.
     * 
     * @returns {App} Instance of this.
     */
    createFundraiserFlickity4()
    {
        const container = $( ".fundraiser-4-flkty" ).get( 0 );
        
        if ( ! container )
        {
            return this;
        }

        const flick = new Flickity( container, 
        {
            cellAlign:          "center",
            contain:            true,
            imagesLoaded:       true,
            wrapAround:         true,
            draggable:          true,
            prevNextButtons :   false,
        } );

        $( "#btn-left-fundraiser-4" ).on( "click", function ()
        {
            flick.previous();
        } );

        $( "#btn-right-fundraiser-4" ).on( "click", function ()
        {
            flick.next();
        } );

        return this;
    }

    createFundraiserFlickity5()
    {
        const container = $( ".fundraiser-5-flkty" ).get( 0 );
        
        if ( ! container )
        {
            return this;
        }

        const flick = new Flickity( container, 
        {
            cellAlign:          "center",
            contain:            true,
            imagesLoaded:       true,
            wrapAround:         true,
            draggable:          true,
            prevNextButtons :   false,
        } );

        $( "#btn-left-fundraiser-5" ).on( "click", function ()
        {
            flick.previous();
        } );

        $( "#btn-right-fundraiser-5" ).on( "click", function ()
        {
            flick.next();
        } );

        return this;
    }

    createTeamMemberFlickity()
    {
        const container = $( ".team-member-flkty" ).get( 0 );
        
        if ( ! container )
        {
            return this;
        }

        const flick = new Flickity( container, 
        {
            cellAlign:          "left",
            contain:            true,
            imagesLoaded:       true,
            wrapAround:         false,
            draggable:          true,
            prevNextButtons :   false,
            watchCSS: true,
        } );

        $( "#btn-left-team-member-flkty" ).on( "click", function ()
        {
            flick.previous();
        } );

        $( "#btn-right-team-member-flkty" ).on( "click", function ()
        {
            flick.next();
        } );

        return this;
    }

    /**
     * Create Countries Flickity
     */
    createCountriesFlickity()
    {
        const container = $( ".countries-flkty" ).get( 0 );
        
        if ( ! container )
        {
            return this;
        }

        const flick = new Flickity( container, 
        {
            cellAlign:          "left",
            contain:            true,
            imagesLoaded:       true,
            wrapAround:         true,
            draggable:          true,
            prevNextButtons :   false,
        } );

        $( "#btn-left-country" ).on( "click", function ()
        {
            flick.previous();
        } );

        $( "#btn-right-country" ).on( "click", function ()
        {
            flick.next();
        } );

        return this;
    }

    /**
     * Initialise the tippys in the frontend.
     * 
     * @return {App} Instance of this.
     */
    createTippys()
    {
        // Initialise tippys.
        tippy( "[data-tippy-content]" );

        return this;
    }

    /**
     * Register the modal popups.
     * 
     * @return {App} Instance of this.
     */
    createModals()
    {
        // Initialise our modals.
        this.modal_login        = new VL_Login_Modal();
        this.modal_register     = new VL_Register_Modal();
        this.modal_pp_cancel    = new VL_PayPal_Cancel_Modal();
        this.modal_error_pop    = new VL_Error_Modal();

        return this;
    }

    /**
     * Bind the anchors which aren't anchors.
     * 
     * @return {App} Instance of this.
     */
    createPseudoAnchors()
    {
        // Psuedo-anchor bindings.
        $( ".psuedo-anchor" ).on( "click", function ()
        {
            window.location.href = $( this ).data( "href" );
        } );

        return this;
    }

    /**
     * Initialise currency codes where they may reside.
     * 
     * @return {App} Instance of this.
     */
    initialiseGlobalCurrencyCodes()
    {
        $( ".currency-code-many" ).each( function ()
        {
            const currency_code = $( this ).text();
            $( this ).text( getSymbolFromCurrency( currency_code ) ).removeClass( "hidden" );
        } );

        return this;
    }

    /**
     * Initialise the fundraising page.
     * 
     * @return {App} Instance of this.
     */
    initialiseFundraising()
    {
        $( "#show-supporters-modal, #show-supporters-modal-2" ).on( "click", { _this_ref: this }, function ( event )
        {
            const _this = event.data._this_ref;

            if ( _this.loaded_all_donations )
            {
                $( "#latest-donation-modal" ).addClass( "show" ).css( { display: "block" } );

                return;
            }

            jQuery.ajax( 
            {
                url: window._$$$_vl_config.site_url + "/campaign-donations-full",
                method: "POST",
                dataType: "JSON",
                data: 
                {
                    campaign_id: $( "#campaign_id" ).val()
                },
                success: function ( res )
                {
                    $( "#list-donations-full" ).html( res.html );
                    $( "#latest-donation-modal" ).addClass( "show" ).css( { display: "block" } );

                    _this.initialiseGlobalCurrencyCodes();

                    _this.loaded_all_donations = true;
                }
            } );
        } );

        $( "#latest-donation-modal-dismiss" ).on( "click", function ()
        {
            $( "#latest-donation-modal" ).removeClass( "show" ).css( { display: "none" } );
        } );

        // View all button on fundraising team.
        $( "#view-all-fundraising-team" ).on( "click", function ()
        {
            $( "#all-team-modal" ).addClass( "show" ).css( { display: "block" } );
        } );

        // Dismiss on fundraising team modal.
        $( "#all-team-modal-dismiss" ).on( "click", function ()
        {
            $( "#all-team-modal" ).removeClass( "show" ).css( { display: "none" } );
        } );

        return this;
    }

    /**
     * Initialises the user menu (dropdown).
     * 
     * @return {App} Instance of this.
     */
    initialiseUserMenu()
    {
        // When the user menu is clicked, we need to drop it down.
        $( "#show-user-menu" ).on( "click", function ()
        {
            $( ".user-chev, #user-nav" ).toggleClass( "!hidden" );
        } );

        return this;
    }

    /**
     * Call when on donation page, will do all the scripts for it.
     * 
     * @return {App} Instance of this.
     */
    initialiseDonations()
    {
        if ( $( "#donation_details_inner" ).length === 0 || $( `html[data-seg-0="hifzsponsorship"][data-seg-1="donate"]` ).length || $( "html[data-checkout-flow]" ).length )
        {
            return this;
        }

        // Initialise the inputs bindings.
        inputs.initialise_inputs( $ );

        // Initialise stripe
        donate_stripe.initialise_stripe( $ );

        // Initialise the payment flow (requires stripe)
        payment_flow.initialise_payment_flow( $ );

        // Initialise the donation levels changer code.
        donation_levels.initialise_donation_levels( $ );

        // Initialise the cards flow
        cards.initialise_cards( $ );
        
        return this.bindAccountButtons();
    }

    /**
     * Initialise the event ticket payments.
     * 
     * @return {App} Instance of this.
     */
    initialiseEventTicketPayments()
    {
        if ( $( "#donation_details_inner" ).length === 0 )
        {
            return this;
        }

        inputs.initialise_inputs( $ );

        donate_stripe.initialise_stripe( $ );

        payment_flow.initialise_payment_flow( $ );

        cards.initialise_cards( $ );

        $( "#ticket_quantity" ).on( "change", function ()
        {
            const value = $( this ).val();

            if ( ! value || value < 0 || value > 999 )
            {
                $( "#ticket_quantity" ).val( 1 );
                return;
            }

            donation.amounts.set_sub_total_quantity( value );

            inputs.update_totals();

            $( "#quant-mult" ).text( `x${value}` );
        } );

        return this.bindAccountButtons();
    }

    /**
     * Initialise the mobile menu.
     * 
     * @return {App} Instance of this.
     */
    initialiseMobileMenu()
    {
        function disableScroll() {
            jQuery("html,body").toggleClass("relative h-full overflow-hidden");
        }
        
        // change z-index on header just for the homepage
        function showMobileMenu() {
            jQuery("#mobile-menu-wrapper").toggleClass("hidden");
        }
        
        function showMenuOverlay() {
            jQuery("#mobile-menu-overlay").toggleClass("opacity-0 opacity-100");
        }
        
        function showMenuInner() {
            jQuery("#mobile-menu-inner").toggleClass(
                "opacity-0 opacity-100 translate-y-20 translate-y-0"
            );
        }

        function showMenuLinks() {
            jQuery("#mobile-menu-links").toggleClass("-translate-y-full");
        }

        
        // open the nav button
        var clicks = 0;
        jQuery(".toggle-mobile-menu").on("click", function () {
            if (clicks == 0) {
                $(this).addClass("active");
                setTimeout(disableScroll);
                setTimeout(showMobileMenu);
                setTimeout(showMenuOverlay, 100);
                setTimeout(showMenuInner, 200);
                setTimeout(showMenuLinks, 300);
                clicks++;
            } else {
                $(this).removeClass("active");
                setTimeout(disableScroll, 500);
                setTimeout(showMobileMenu, 500);
                setTimeout(showMenuOverlay, 300);
                setTimeout(showMenuInner, 200);
                setTimeout(showMenuLinks, 200);
                clicks--;
            }
        });

        return this;
    }

    /**
     * Initialise fundraiser levels.
     */
    initialiseFundraiserLevels()
    {
        // Ensure we're on the correct page.
        if ( ! $( `html[data-page="campaign-detail"]` ).length )
        {
            return this;
        }

        // Fundraiser levels controller.
        this.fundraiserLevels = new FundraiserLevelsController();

        return this;
    }

    /**
     * Bind the account buttons.
     * 
     * @return {App} Instance of this.
     */
    bindAccountButtons()
    {
        // When sign in button is clicked, we want to show the login modal.
        $( "#popup-sign-in" ).on( "click", function ()
        {
            // Show the login modal.
            if ( app.modal_login )
            {
                app.modal_login.elements.email.val( $( "#donor_email" ).val() );
                app.modal_login.doEmailValidation( true )
                app.modal_login.showHideModal();
            }
        } );

        $( "#create_account_checkbox, #create-account" ).on( "click", function ()
        {            
            // Set the contents of the email element.
            if ( App.instance.modal_register.elements.email && App.instance.modal_register.validators.email )
            {
                App.instance.modal_register.elements.email.val( $( "#donor_email" ).val() );
                inputs.is_valid( App.instance.modal_register.validators.email );
                
                // Hide the login modal, if it happens to be showing.
                App.instance.modal_register.showHideModal();
            }
        } );

        return this;
    }

    /**
     * Initialise the completion page.
     * 
     * @return {App} Instance of this.
     */
    initialiseCompletions()
    {
        let currency_code;
        
        if ( currency_code = getSymbolFromCurrency( $( $( ".currency-code" ).get( 0 ) ).text() ) )
        {
            $( ".currency-code" ).text( currency_code );
        }

        // If this element exists then we're awaiting confirmation and need to ping our server every second 
        // until the payment is detected via webhooks.
        const $_start_stripe_ping = $( "#_start_stripe_ping" );

        if ( $_start_stripe_ping.length )
        {
            // Get payment intent ID from this hidden input.
            const paymentIntent_id = $_start_stripe_ping.val();

            // Create new worker..
            const worker = new ConfirmationWorker( paymentIntent_id );

            // .. and put it to work.
            worker.start();
        }

        return this;
    }

    /**
     * Initialises functionality required for the authentication processes (login, logout, register)
     * 
     * @return {App} Instance of this.
     */
    initialiseAuth()
    {
        const $sign_in_main = $( "#sign-in-main" );
        const $logout_btn = $( ".logout-btn" );
        
        if ( $sign_in_main.length )
        {
            $sign_in_main.on( "click", { _this_ref: this }, function ( event )
            {
                const _this = event.data._this_ref;

                _this.modal_login.setTitleStringDonate( false );
                
                _this.modal_login.showHideModal();
            } );
        }

        if ( $logout_btn.length )
        {
            $logout_btn.on( "click", function ( event )
            {
                // In case it's an anchor, we don't want to do the anchors things.
                event.preventDefault();

                // AJAX to logout, then loop query params when done.
                jQuery.ajax(
                {
                    url: vl_util.site_url() + "/logout",
                    method: "POST",
                    dataType: "JSON",
                    success: function ()
                    {
                        inputs.loop_query_params();
                    },error: function ()
                    {
                        inputs.loop_query_params();
                    }
                } );
            } );
        }

        return this;
    } 

    /**
     * Initialises the contact details page.
     * 
     * @return {App} Instance of this.
     */
    initialiseContactDetails()
    {
        if ( ! $( "#contact-email" ).length || ! $( "#contact-phone" ).length)
        {
            return this;
        }

        this.completed_handler = new DonationCompleted();

        return this;
    }

    /**
     * When currency codes show on the site, we need to fix them. (currency lists -> their respective currency symbols from codes.)
     * 
     * @return {App} Instance of this.
     */
    initialiseCurrencyCodes()
    {
        const $options = $( "#donation_currency > option" );

        if ( ! $options.length )
        {
            return this;
        }

        $options.each( function ()
        {
            const currency_code = $( this ).val();
            const currency_symbol = getSymbolFromCurrency( currency_code );

            $( this ).text( currency_symbol.replace( /\s+/g, "" ) === "$" ? ( currency_code + currency_symbol ) : currency_symbol );
        } );

        return this;
    }

    /**
     * Initialises stuff for the user backend.
     * 
     * @return {App} Instance of this.
     */
    initialiseUserBackend()
    {
        // Known element to only show on user backend.
        const $cancel_subs = $( ".cancel-subscription" );

        // Set up modal for subscription cancellation confirmation.
        this.modal_cancel_subscription = new VL_Sub_Cancel_Modal();

        // When we click a cancel subscription button.
        $cancel_subs.on( "click", { _this: this }, function ( event )
        {
            const _this = event.data._this;

            _this.modal_cancel_subscription.openWithID( $( this ).data( "subscription-id" ), $( this ).data( "subscription-type" ) );
        } );

        return this;
    }

    /**
     * Initialises stuff for the food fridays landing page.
     * 
     * @return {App} Instance of this.
     */
    initialiseFoodFridaysLanding()
    {
        const $partner_slider = $( ".partner-slider" );

        // Ensure we're on the right page.
        if ( ! $partner_slider.length )
        {
            return this;
        }

        const partnerSliderDiv = $partner_slider.get( 0 );

        if ( ! partnerSliderDiv )
        {
            return this;
        }

        new Flickity( partnerSliderDiv,
        {
            cellAlign:          "left",
            contain:            true,
            imagesLoaded:       true,
            prevNextButtons:    false,
            pageDots:           false,
            wrapAround:         false,
            draggable:          true
        } );

        return this;
    }

    /**
     * Exposes a value to the DOM / Window.
     * 
     * @param {String} key 
     * @param {*} value 
     * @return {App} Instance of this.
     */
    exposeToDOM( key, value )
    {
        if ( vl_util.dbg_enabled() )
        {
            // vl_util.dbgout( "App Core", `Exposing value \"${key}\" to DOM.` );
        }

        window[ key ] = value;

        return this;
    }

    /**
     * Initialise the user profile system.
     * 
     * @return {App} Instance of this.
     */
    initialiseUserProfile()
    {
        this.userProfile = new UserProfileController();

        return this;
    }

    /**
     * Initialise the blog system.
     * 
     * @return {App} Instance of this.
     */
    initialiseBlog()
    {
        this.blog = new BlogController();

        return this;
    }

    /**
     * Initialise the product cart / basket.
     * 
     * @return {App} Instance of this.
     */
    initialiseCart()
    {
        this.cart = new CartController();
        
        return this;
    }

    /**
     * Initialise the get involved controller, if we need it.
     * 
     * @return {App} Instance of this.
     */
    initialiseGetInvolved()
    {
        this.getInvolved = new GetInvolvedController();

        return this;
    }

    /**
     * Initialise the checkout flow.
     * 
     * @return {App} Instance of this.
     */
    initialiseCheckout()
    {
        // Ensure we're on the checkout flow.
        if ( ! $( "html[data-checkout-flow]" ).length )
        {
            return this;
        }

        this.checkout = new CheckoutController();

        return this;
    }

    /**
     * Initialise the quran challenge main page / flows.
     * 
     * @return {App} Instance of this.
     */
    initialiseQuranChallenge()
    {
        if ( ! $( `html[data-route="dv2.quran-challenge"]` ).length )
        {
            return this;
        }
        
        this.quranChallenge = new QuranChallengeController();
        this.exposeToDOM( "quranChallenge", app.quranChallenge );

        return this;
    }

    /**
     * Initialises the quran challenge donation flow.
     * 
     * @returns {App} Instance of this.
     */
    initialiseQuranChallengeDonate()
    {
        if ( ! $( `html[data-seg-0="hifzsponsorship"][data-seg-1="donate"]` ).length )
        {
            return this;
        }

        this.quranChallenge = new QuranChallengeController( true );
        this.exposeToDOM( "quranChallenge", app.quranChallenge );

        this.quranChallengeDonate = new QuranChallengeDonateController( this.quranChallenge );
        this.exposeToDOM( "quranChallengeDonate", app.quranChallengeDonate );

        // Enable the create account checkbox etc.
        return this.bindAccountButtons();
    }

    initialiseHeader()
    {
        $( ".search-open" ).on( 'click', function () 
        {
            $( '.search-box' ).toggleClass( "active" );
        } );
        
        $( "#search-close" ).on( 'click', function () 
        {
            $( '.search-box' ).removeClass( "active" );
        } );

        return this;
    }

    /**
     * Initialises the Food Fridays index scripts.
     * 
     * @returns {App} this.
     */
    initialiseFoodFridaysIndex()
    {
        if ( ! App.onRoute( "foodfriday.foodfriday" ) )
        {
            return this;
        }

        this.foodFridaysIndex = new FoodFridaysIndexController();

        return this;
    }

    /**
     * Initialise the food fridays donation page.
     * 
     * @returns {App} this.
     */
    initialiseFoodFridaysDonate()
    {
        // Check we're on FF donate.
        if ( ! App.onRoute( "dv2.foodfridays.donate" ) )
        {
            return this;
        }

        this.foodFridaysDonate = new FoodFridaysController();

        return this;
    }

    /**
     * Initialises the archive events page.
     * 
     * @returns {App} this.
     */
    initialiseEventsArchive()
    {
        // Must be on events archive page to initialise the controller.
        if ( ! App.onRoute( "events" ) )
        {
            return this;
        }

        this.eventsArchiveController = new EventsArchiveController();

        return this;
    }

    /**
     * Initialises single events page.
     * 
     * @returns {App} this.
     */
    initialiseEventsSingle()
    {
        // Must be on events single page.
        if ( ! App.onRoute( "events.slugged" ) )
        {
            return this;
        }

        this.eventsSingleController = new EventsSingleController();

        return this;
    }

    /**
     * Initialises the step over hunger controller, if we're on the correct page.
     * 
     * @return {App} this.
     */
    initialiseStepOverHunger()
    {
        if ( ! App.onRoute( "dv2.step-over-hunger.index" ) )
        {
            return this;
        }

        this.stepOverHungerController = new StepOverHungerController();
        
        return this;
    }

    /**
     * Initialise haroon mota controller.
     */
    initialiseHaroonMota()
    {
        if ( ! App.onRoute( "dv2.ramadanchallenge.index" ) )
        {
            return this;
        }

        this.RamadanChallengeController = new RamadanChallengeController( this.modal_login, this.modal_register );

        return this;
    }

    /**
     * Initialise team365 controller.
     */
    initialiseTeam365()
    {
        if ( ! App.onRoute( "dv2.team365.index" ) )
        {
            return this;
        }

        this.team365Controller = new Team365Controller();

        return this;
    }

    /**
     * Initialise the campaign detail controller.
     */
    initialiseCampaignDetailController()
    {
        // Ensure user is on campaign detail.
        if ( ! ( App.onRoute( "campaign.detail1" ) || App.onRoute( "campaign.detail" ) ) )
        {
            return this;
        }

        this.campaignDetailController = new CampaignDetailController( $( "html" ).data( "seg-1" ) );

        return this;
    }

    /**
     * Quick donate.
     */
    initialiseQuickDonate()
    {
        this.quickDonate = new QuickDonateController( this );

        return this;
    }

    /**
     * Initialise the night of power donate.
     */
    initialiseNightOfPowerDonate()
    {
        this.nightOfPowerWidget = new NightOfPowerController( this );

        // NOP completed page controller.
        new NightOfPowerCompletedController();

        return this;
    }

    /**
     * Determines if we're on a given route.
     * 
     * @param {String} routeName 
     * @returns {Boolean}
     */
    static onRoute( routeName )
    {
        return !! $( `html[data-route="${routeName}"]` ).length;
    }
}


// Initialise App
const app = ( new App( jQuery, true ) );

// Google JS API will call this function on load.
app.exposeToDOM( "_vl_init_google", () => 
{
    // Initialise main google.
    inputs.initialise_google();

    // Initialise google maps on profile.
    if ( app.userProfile )
    {
        app.userProfile.initialiseGoogle();
    }

    SchoolsFundraisingController.initGoogle();
} );

// Removed from views/front/my_account.blade.php
app.exposeToDOM( "showFaq", ( object, id ) => 
{
    $( "#faq_categories" ).find( "a" ).removeClass( "active" );
    $( object ).addClass( "active" );
    
    $( ".div_faq" ).hide();
    
    $( "#panel_" + id ).show();
} );

app.exposeToDOM( "AppStatic", App );
app.exposeToDOM( "App", app );