import { vl_util } from "../general/util";
import $                    from "jquery";
import jQuery               from "jquery";

/**
 * Frontend blog controller class.
 */
export default class BlogController
{
    /**
     * Array[[Object]] of elements
     * 
     * @var {Object} elements
     * @var {JQuery} elements.show_more
     */
    elements = 
    {
        show_more: $()
    }

    /**
     * Current blog page.
     * 
     * @var {Number} page
     */
    page = 1

    /**
     * Class constructor.
     */
    constructor()
    {
        $( this.onReady.bind( this ) );
    }
 
    /**
     * JQuery / Document ready event will call this. Bind things in here.
     * 
     * @param {JQueryStatic} $ 
     */
    onReady( $ )
    {
        this.elements.show_more = $( "body.uri-seg-blog #show_more" );

        if ( this.elements.show_more.length )
        {
            this.elements.show_more.on( "click", this.onClickShowMore.bind( this ) );
        }
    }

    /**
     * Called when show more is clicked on blog bottom.
     * 
     * @param {MouseEvent} event 
     */
    onClickShowMore( event )
    {
        // Increment page number
        this.page += 1;

        // Load next page
        this.loadNextPage();
    }

    loadNextPage()
    {
        $.ajax(
        {
            url:    vl_util.site_url() + "/blog/posts",
            method: "GET",
            data: 
            {
                _token: vl_util.csrf_token(),
                page:   this.page
            },
            dataType: "json",

            success: function ( response )
            {
                if ( response.html.trim() === "" )
                {
                    $( "#show_more" ).hide();
                }

                $( "#post-data" ).append( response.html );
            },

            error: function ( jqXHR, error )
            {
                // ?? some error handling here ??
            }
        } );
    }
}