import $ from "jquery";

/**
 * Regular expression for matching TikTok links for automatic oembedding.
 */
export const TIKTOK_OEMBED_REGEX = /\bhttps?:\/\/(?:m|www|vm)\.tiktok\.com\/\S*?\b(?:(?:(?:usr|v|embed|user|video)\/|\?shareId=|\&item_id=)(\d+)|(?=\w{7})(\w*?[A-Z\d]\w*)(?=\s|\/$))\b/g;

/**
 * Regular expression for matching YouTube links for automatic oembedding.
 */
export const YOUTUBE_OEMBED_REGEX = /(?:https?:\/\/)?(?:youtu\.be\/|(?:www\.|m\.)?youtube\.com\/(?:watch|v|embed)(?:\.php)?(?:\?.*v=|\/))([a-zA-Z0-9\_-]+)/g;

export default class CampaignDetailController
{   
    /**
     * The current campaigns ID.
     * 
     * @var {Number|string}
     */
    campaignId = null;

    /**
     * List of TikTok video IDs that have been processed and appropriately embedded.
     * 
     * @var {String[]|Number[]}
     */
    processedTikTokIds = [];

    /**
     * List of YouTube video IDs that have been processed.
     * 
     * @var {String[]|Number[]}
     */
    processedYouTubeIds = [];

    tikTokMatches       = null;
    youTubeMatches      = null;
    youTubePlayers      = [];
    currentStoryHTML    = null;

    /**
     * Campaign detail controller.
     */
    constructor ( campaignId ) 
    {
        // Input campaign ID.
        if ( campaignId )
        {
            this.campaignId = campaignId;
        }

        this.initialise();
    }

    /**
     * Initialisation method ONLY called when we're on the correct page.
     */
    initialise()
    {
        this.initialiseStory().initialiseStoryEmbeds();
    }

    /**
     * Initialise the story stuff.
     * 
     * @returns {CampaignDetailController} This
     */
    initialiseStory()
    {
        $( "#story-read-more" ).on( "click", this.onClickStoryShowHide.bind( this ) );

        return this;
    }

    /**
     * Initialise the story OEmbed automations.
     */
    initialiseStoryEmbeds()
    {
        this.currentStoryHTML = $( "#campaign-story-oembed-target" ).html();

        // Get the matched groups.
        this.tikTokMatches = [ ...this.currentStoryHTML.matchAll( TIKTOK_OEMBED_REGEX ) ];
        this.youTubeMatches = [ ...this.currentStoryHTML.matchAll( YOUTUBE_OEMBED_REGEX ) ];

        this.tikTokMatches.forEach( this.processTikTokGroup.bind( this ) );
        // this.injectYouTubeAPI( this.onYouTubeAPIReady.bind( this ) );

        return this;
    }

    onYouTubeAPIReady()
    {
        // this.youTubeMatches.forEach( ( value, index, array ) => 
        // {
        //     const fullLink = value[ 0 ];
        //     const ID = value[ 1 ];

        //     // Current length of players list is the new index.
        //     let playerID = this.youTubePlayers.length;
            
        //     this.currentStoryHTML = this.currentStoryHTML.replace( fullLink, `<div id="ytPlayer_${ playerID }"></div>` );
        //     $( "#campaign-story-oembed-target" ).html( this.currentStoryHTML );

        //     // Set timeout to load the youtube player.
        //     setTimeout( this.loadYouTubePlayer.bind( this, ID, playerID ), 250 );
        // } );
    }

    /**
     * Loads YouTube player ID.
     * 
     * @param {String} videoID 
     * @param {Number} playerID
     */
    loadYouTubePlayer( videoID, playerID )
    {
        console.log( "loading new player with ID: " + videoID );

        this.youTubePlayers.push( new window.YT.Player( `ytPlayer_${ playerID }`, 
        {
            width: 522,
            videoId: videoID,
            playerVars: 
            {
                'playsinline': 1
            }
        } ) );
    }

    /**
     * 
     * @param {*} value 
     * @param {*} index 
     * @param {*} array 
     */
    async processTikTokGroup( value, index, array )
    {
        const fullLink = value[ 0 ];
        const ID = value[ 1 ];

        if ( this.processedTikTokIds.includes( ID ) )
        {
            return;
        }

        this.processedTikTokIds.push( ID );
        
        let x = await $.ajax(
        {
            url: `https://www.tiktok.com/oembed?url=${ fullLink }`,
            method: "GET"
        });

        if ( x.html )
        {
            if ( ! $( `a[href="${ fullLink }"]` ).replaceWith( x.html ).length )
            {
                this.currentStoryHTML = this.currentStoryHTML.replace( fullLink, x.html );
                $( "#campaign-story-oembed-target" ).html( this.currentStoryHTML );
            }
        }
    }

    /**
     * 
     * @param {*} value 
     * @param {*} index 
     * @param {*} array 
     */
    async processYouTubeGroups( value, index, array )
    {

    }

    /**
     * Injects the YouTube API script into the DOM so we can load YouTube when actually required.
     */
    injectYouTubeAPI( onReady = null )
    {
        var tag = document.createElement( "script" );
        tag.src = "https://www.youtube.com/iframe_api";
        
        var firstScriptTag = document.getElementsByTagName( "script" )[ 0 ];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        // If an onReady method is given, we define the callback for when the script is loaded successfully.
        if ( onReady )
        {
            window.onYouTubeIframeAPIReady = () => 
            {
                console.log( "YouTube embed API loaded." );
                onReady();
            }
        }
    }

    /**
     * Called on click to the show/hide more thing.
     * 
     * @param {MouseEvent} event 
     */
    onClickStoryShowHide( event = null ) 
    {
        $( ".campaign-story-tab").toggleClass("max-h-[350px]");
        $( "#story-gradient").toggleClass("h-32 sm:h-40 h-20");

        if ( $( "#story-read-more").text() === "Read more" ) 
        {
            $( "#story-read-more" ).text( "Hide" );
        } 
        else 
        {
            $( "#story-read-more" ).text( "Read more" );

            $( [ document.documentElement, document.body ] ).animate(
            {
                scrollTop: $( ".boxi2" ).offset().top - 100
            }, 300 );
        }

        $("#story-read-more").html()
    }
}