import { VL_Modal } from "../general/modal-factory";
import $                    from "jquery";
import jQuery               from "jquery";

/**
 * Represents functionality for our pop-up login modal.
 */
export class VL_Error_Modal extends VL_Modal
{
    elements = 
    {
        dismiss:                null,
        error:                  null
    };

    /**
     * Constructor
     */
    constructor ()
    {
        super( "error-popup" );

        // Args which will be passed to the bound function.
        const args = 
        { 
            _this_ref: this 
        };

        // Get the elements.
        this.elements.dismiss = $( "#error-dismiss" );
        this.elements.error = $( "#modal-error-string" );

        // Bind the elements.
        this.elements.dismiss.on( "click", args, this.onClickDismiss );
    }

    onClickDismiss( event )
    {
        const _this = event.data._this_ref;

        _this.showHideModal();
    }

    /**
     * Sets the error string.
     * 
     * @param {String} error 
     */
    setErrorString( error )
    {
        this.elements.error.text( error );

        return this;
    }
}